import { Injectable, EventEmitter } from '@angular/core';
import { AppDataEvent } from './models';

@Injectable({
  providedIn: 'root'
})
export class AppDataService {
  
 



  private eventData: EventEmitter<AppDataEvent> = new EventEmitter<AppDataEvent>();

  private data= {};
  private dataSource= {};
  private eventListenerKeyList = [];

  constructor() {
    this.restore()

  }


  setData(key: string, pData: any, dataSource: 'none' | 'db' | 'local') {
    this.data[key] = pData;
    this.dataSource[key] = dataSource;
    if (dataSource === 'local') {
      if(AppDataService.VALUE_TYPE[key]=='object'){
        //console.log("set: ", key,pData);
        
        localStorage.setItem(key, JSON.stringify(pData))
      }else{
        localStorage.setItem(key, pData)

      }
    }
    this.emitData(key, pData)


  }

  getData(key: string) {
    if (key in this.data) {
      return this.data[key];
    }
    else undefined;
  }

  /**
   * @deprecated use  @method subscribeDataAsync
   * @param key 
   */
  getDataAsync(key: string) {
    if(!this.eventListenerKeyList.includes(key)){
      this.eventListenerKeyList.push(key)

    }

    if (key in this.data) {
      this.emitData(key, this.data[key]);
    }
    return this.eventData

  }

  subscribeDataAsync(key: string) {
    if(!this.eventListenerKeyList.includes(key)){
      this.eventListenerKeyList.push(key)

    }
    //console.log("emitting 1: ", key, this.data);
    if (key in this.data) {
      this.emitData(key, this.data[key]);
    }
    return this.eventData

  }

  private emitData(key: string, value: any){

    //console.log("emitting: ", key);
    
    setTimeout(()=>this.eventData.emit({value: value, key: key} as AppDataEvent), 10)
    

  }

  private restore() {
    let size = localStorage.length;

    for (let index = 0; index < size; index++) {
      let key = localStorage.key(index)
      let value = localStorage.getItem(key)
      //console.log("eer 1: ",key);
      if(AppDataService.VALUE_TYPE[key]=='object'){

        value = JSON.parse(value)
        //console.log("eer: ",value);

      }else if(AppDataService.VALUE_TYPE[key]=='boolean'){
    
        value = ['true', 'TRUE', 'false', 'FALSE', true, false].includes(value) && JSON.parse(value);
        //console.log("eer2: ",value);
        // add when value is false
        if (!value) {
          this.data[key] = value
          this.dataSource[value] = 'local'
        }else{
          this.data[key]= false
          this.dataSource[value] = 'local'
        }

        // console.log("data: ", this.data);
        
      }
      if(value){
        this.data[key] = value
        this.dataSource[value] = 'local'
      }

    }
    //console.log("app data service: ", this.data);
    

  }

  clear() {
    this.data = {}
    this.remove(AppDataService.KEY.user);
    localStorage.clear();

  }
  remove(key: string) {
    localStorage.removeItem(key)

  }

  static KEY = {
    user: 'user_me',
    user_authorized: 'user_authorized',
    user_role_company: "user_role_company",
    current_job: "current_job",
    google_wait: "google_wait"
  }

  static VALUE_TYPE = {
    user_me: 'object',
    user_authorized: 'boolean',
    user_role_company: 'boolean',
    current_job: 'object',
    google_wait: 'boolean'
  }
}
