import { AdminModule } from './components/admin/admin.module';
import { ContainerModule } from "./container/container.module";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./route.module";
import { AppComponent } from "./app.component";
import { HttpClientModule } from '@angular/common/http';
import { RegistrationModule } from "./components/registration/registration.module";
import { CoreModule } from './core/core.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ApiModule } from './core/services/api.module';
import { DispatchManagerModule } from "./components/dispatch-manager/dispatch-manager.module";
import { DeliveryExecutiveModule } from './components/delivery-executive/delivery-executive.module';
import { DistributorModule } from './components/distributor/distributor.module';
import { AnalyticsModule } from './components/analytics/analytics.module';
import { LogisticsModule } from 'src/app/components/logistics/logistics.module';
import { OutstandingModule } from "src/app/components/outstanding/outstanding.module";
import { SupplyModule } from 'src/app/components/supply/supply.module';
import { WarehouseModule } from "src/app/components/warehouse/warehouse.module";
import { HelpDeskModule } from "src/app/components/help-desk/help-desk.module";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CreditReactComponentWrapperComponent } from './components/credit/credit-dashboard/components/credit-react-dashboard/CreditReactComponentWrapper';
import { CreditDashboardComponent } from './components/credit/credit-dashboard/credit-dashboard.component';
import { FinanceModule } from './components/finance/finance.module';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { OmsModule } from './components/oms/oms.module';
import { DemandComponent } from './components/demand/demand.component';
import { DemandModule } from './components/demand/demand.module';

@NgModule({
  declarations: [AppComponent,CreditReactComponentWrapperComponent,CreditDashboardComponent],
  imports: [
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    HttpClientModule,
    ContainerModule,
    ApiModule.forRoot(),
    RegistrationModule,
    AdminModule,
    DispatchManagerModule,
    DeliveryExecutiveModule,
    DistributorModule,
    AnalyticsModule,
    LogisticsModule,
    OutstandingModule,
    SupplyModule,
    WarehouseModule,
    HelpDeskModule,
    FinanceModule,
    NgxJsonViewerModule,
    OmsModule,
    DemandModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
