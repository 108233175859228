import { QRCodeModule } from 'angularx-qrcode';
import { CommonModule } from "@angular/common";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "src/app/material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatIconModule, MatRippleModule } from "@angular/material";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { PrintBillComponent } from './print-bill.component';



@NgModule({
  declarations: [PrintBillComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    MatIconModule,
    MatRippleModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    QRCodeModule
  ],
  exports: [PrintBillComponent]
})
export class PrintBillModule { }
