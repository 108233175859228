import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FinanceInvoiceListDialogModel } from './model';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { FinanceService } from 'src/app/core/services/api/finance.service';

@Component({
  selector: 'app-finance-invoice-list',
  templateUrl: './finance-invoice-list.component.html',
  styleUrls: ['./finance-invoice-list.component.scss']
})
export class FinanceInvoiceListComponent implements OnInit {
  invoices = [];

  constructor(
    public dialogRef: MatDialogRef<FinanceInvoiceListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FinanceInvoiceListDialogModel,
    private financeService: FinanceService,
    private popupService: PopupService
  ) {}


  ngOnInit(): void {
    this.popupService.openProgress();
    this.getInvoices();
  }

  getInvoices() {
    const params = {
      sellerInvoiceNumbers: this.data.debitNote.sellerInvoiceNumberList
    }
    this.financeService.getInvoices(params).subscribe(
      response => {
        this.invoices = response.data;
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  // open disputed by popup
  openFinanceDispute(id, disputedByFinance, disputedByOps, disputedAmount) {
    let financeDispute = false;
    let opsDispute = false;
    if (disputedByOps == 0) {
      opsDispute = false;
    } else {
      opsDispute = true
    }
    if (disputedByFinance == 0) {
      financeDispute = false;
    } else {
      financeDispute = true;
    }
    let disableUndo = true;
    if (financeDispute || opsDispute) {
      disableUndo = false;
    }
    console.log(opsDispute, financeDispute)
    this.popupService.openFinanceDispute(id, opsDispute, financeDispute, disputedAmount, disableUndo).afterClosed().subscribe(
      response => {
        if (response) {
          this.getInvoices();
        }
      }
    )
  }

  close() {
    this.dialogRef.close();
  }

}
