import { Component, OnInit, Inject,ElementRef,ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { PopupService } from 'src/app/core/services/popup/popup.service';

import { DialogModelNewMed } from './model';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Form, FormControl,Validators,FormGroupDirective,NgForm} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatChipsModule} from '@angular/material/chips'
 import { ProductMasterService } from 'src/app/core/services/api/product-master.service';
 import {ErrorStateMatcher} from '@angular/material/core';
 import { MatInput } from '@angular/material/input';
// import { timeStamp } from 'console';

//  export class MyErrorStateMatcher implements ErrorStateMatcher {
//   isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
//     const isSubmitted = form && form.submitted;
//     return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
//   }
// }

@Component({
  selector: 'app-add-new-medicine-to-map',
  templateUrl: './add-new-medicine-to-map.component.html',
  styleUrls: ['./add-new-medicine-to-map.component.scss']
})


export class AddNewMedicineToMapComponent implements OnInit {
 
 finalResponse={};
 activeChildCount=1;
 borderError=false;
 selectedSuperGroups=[]
 error=false;
 showParentError=true;
 superGroupExpand=false;
 showErrors=false;
 finalButtonStatus=""
 sprGrp:FormControl=new FormControl([]);
 subChild:FormControl=new FormControl();
 superGroupSelect:FormControl=new FormControl();
 groupSelect:FormControl=new FormControl();

//  matcher = new MyErrorStateMatcher();
  create_operation="CREATE";
  update_operation="UPDATE";
  delete_operation="DELETE";
  entry_type="TEMP";
  composition_variable="COMP_";
  manufacture_variable="MANF_";
  marketing_variable="MARK_";
  description_variable="DESC_";
  category_variable="CATG_";
  group_variable="GRP_";
  dpco_variable="DPCO_";
  atcn_variable="ATCN_";
  sideEffects_variable="SE_";
  cautions_variable="CAUT_";
  disease_variable="DIS_";
  child_variable="CHILD_";
  child_variable_name="CHILD_NAME_";
  indication_variable="INDI_";
  contradiction_variable="CONTRA_";
  dosage_variable="DOS_";
  form_variable="FORM_";
  type_variable="TYPE_";
  size_variable="SIZE_";
  unit_variable="UNIT_";
  subchild_variable="SUB_CHILD_";
  scheduleDrug_variable="SCHDRUG_";
  hashCode=null;
  options=[];
  showOptionsToggle=false;
  childByParent=[];
  childByParentCopy=[];
  filteredChildByParent=[];
  subChildCreated=1;
  subChildCountFromResponse=0;
  showGroups=true;
  testsubchildsCreated=[];
  deleteChildResponses=[];
  deletedParentResponse=[];
  productFormdata=[];
  productUnitdata=[];
  productTypedata=[];
  categoryData=[];
  toBeApprovedData=[];
  responseObj={
    id:null,
    type:'',
    parentRequestId:null,
    dataInteger:null,
    dataString:'',
    operationType:'',
    entryType:'',
    timeStamp:null
  }
  childCount=0;
  myControl = new FormControl();
  selectedParent={
    inpValue:{parentName:''},
    searchString:'',
    data:[],
    parentStatusLive:false,
    loader:false,
    edited:false,
    error:false,
    response:{ id:null,
      type:'',
      parentRequestId:null,
      dataInteger:null,
      dataString:'',
      operationType:'',
      entryType:'',
      timeStamp:null},
    createdParent:false,
    newParent:''
  }
  showParentMedOptions=false;
  updateResp={
    inputDisabled:true,
    showUpdate:false,
    error:false
  }
  selectedChild=[{
    inpValue:{name:''},
    searchString:'',
    loader:false,
    childStatusLive:false,
    edited:false,
    error:false,
    verifyResponse:Object.assign({},this.updateResp),
    response:{ id:"CHILD_1",
      type:'',
      parentRequestId:null,
      dataInteger:null,
      dataString:'',
      operationType:'',
      entryType:'',
      timeStamp:null},
      compositions:{
        data:[],
        show:false,
        loader:false,
        searchString:'',
        inpValue:{},
        error:false,
        edited:false,
        verifyResponse:Object.assign({},this.updateResp),
        response:{ id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        },
      },
      superGroup:{
        data:[],
        filteredData:[],
        showOptions:false,
        edited:false,
        error:false,
        inpValue:[{
          "id": "",
            "superGroup": "",
            "t":'',
            group:{id:"",group:""}
        }],
        selectedSuperGroups:[],
        selectedValue:{  "id": "",
        "superGroup": "",
        "t":'',
        group:{id:"",group:""}
         }
      },
      groups:{
        data:[],
        inpValue:[{
          "id": "",
            "t": ""
        }],
        edited:false,
        verifyResponse:Object.assign({},this.updateResp),
        response:[{ id:null,
          type:'',
          superGroupId:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        }]
      },
      scheduleDrug:{
        data:[],
        error:false,
        edited:false,
        inpValue:{
          id:"",
          t:""
        },
        verifyResponse:Object.assign({},this.updateResp),
         response:{ id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        }
      },
      sideEffects:{
        data:[],
        edited:false,
        selectedData:[],
        loader:false,
        searchString:'',
        verifyResponse:Object.assign({},this.updateResp),
        response:[]
      },
      cautions:{
        data:[],
        edited:false,
        selectedData:[],
        loader:false,
        searchString:'',
        verifyResponse:Object.assign({},this.updateResp),
        response:[]
      },
    
      diseases:{
        data:[],
        edited:false,
        selectedData:[],
        loader:false,
        searchString:'',
        verifyResponse:Object.assign({},this.updateResp),
        response:[]
      },
      dpco:{
        inpValue:"",
        edited:false,
        error:false,
        verifyResponse:Object.assign({},this.updateResp),
        response:{ id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        }
      },
      atcn:{
        inpValue:"",
        edited:false,
        verifyResponse:Object.assign({},this.updateResp),
        response:{ id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        }
      },
      indication:{
        inpValue:"",
        edited:false,
        verifyResponse:Object.assign({},this.updateResp),
        response:{ id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        }
      },
      contradiction:{
        inpValue:"",
        edited:false,
        verifyResponse:Object.assign({},this.updateResp),
        response:{ id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        }
      },
      dosage:{
        inpValue:"",
        edited:false,
        verifyResponse:Object.assign({},this.updateResp),
        response:{ id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        }
      },
      subChild:[{
        selected:false,
        edited:false,
        error:false,
        medicineId:null,
        response:{
           id:this.subchild_variable+this.childCount+this.subChildCreated,
          type:'PRODUCT_SUB_CHILD',
          parentRequestId:'CHILD_1',
          dataInteger:null,
          dataString:'',
          operationType:'CREATE',
          entryType:'TEMP',
          timeStamp:new Date().getTime()
        },
        form:{
          inpValue:{id:'',t:''},
          edited:false,
          error:false,
          showOptions:false,
          verifyResponse:Object.assign({},this.updateResp),
          updatedResponse:null,
          response:{
            id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
          }
        },
        type:{ 
          inpValue:{id:'',t:''},
          edited:false,
          showOptions:false,
          verifyResponse:Object.assign({},this.updateResp),
          error:false,
          updatedResponse:null,
          response:{
          id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
          }
        },
        size:{
          inpValue:'',
          edited:false,
          verifyResponse:Object.assign({},this.updateResp),
          error:false,
          response:{
          id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
          }
        },
        unit:{
          inpValue:{id:'',t:''},
          edited:false,
          error:false,
          showOptions:false,
          verifyResponse:Object.assign({},this.updateResp),
          updatedResponse:null,
          response:{
          id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
          }
        },
    
      }],
    createdChild:false,
    newChild:'',
    childCount:0
  }]
  showChildMedOptions=false;

  

  manufactures={
    data:[],
    edited:false,
    show:false,
    loader:false,
    error:false,
    searchString:'',
    inpValue:{},
    response:{ id:null,
      type:'',
      parentRequestId:null,
      dataInteger:null,
      dataString:'',
      operationType:'',
      entryType:'',
      timeStamp:null
    },
  }

  marketings={
    data:[],
    edited:false,
    show:false,
    loader:false,
    error:false,
    searchString:'',
    inpValue:{},
    response:{ id:null,
      type:'',
      parentRequestId:null,
      dataInteger:null,
      dataString:'',
      operationType:'',
      entryType:'',
      timeStamp:null
    }
  }

  description={
    inpValue:"",
    edited:false,
    error:false,
    response:{ id:null,
      type:'',
      parentRequestId:null,
      dataInteger:null,
      dataString:'',
      operationType:'',
      entryType:'',
      timeStamp:null
    }
  }

  category={
    data:[],
    edited:false,
    error:false,
    inpValue:{
      t:"",
      id:null
    },
    response:{ id:null,
      type:'',
      parentRequestId:null,
      dataInteger:null,
      dataString:'',
      operationType:'',
      entryType:'',
      timeStamp:null
    }
  }

  

  

  

  

  productForm={
    data:[],
    selectedData:{}
  }

  productType={
    data:[],
    selectedData:{}
  }
  productUnits={
    data:[],
    selectedData:{}
  }
  
  page=0;
  timer;
  scheduleDrugData=[]
  // chips selectors 
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  sideEffectsCtrl = new FormControl();
  cautionsCtrl = new FormControl();
  diseasesCtrl = new FormControl();
 superGroupResponseData=[];
userAccess=false;
oldItemCode=null;
childSelectedVerified=null;
  // chips selectors end 

// verfied data declarations //

fullparentverifiedData={};
parentUpdate=Object.assign({},this.updateResp)
manufacturesUpdate=Object.assign({},this.updateResp);
marketingsUpdate=Object.assign({},this.updateResp);
categoryUpdate=Object.assign({},this.updateResp);
descriptionUpdate=Object.assign({},this.updateResp)

  constructor(public dialogRef: MatDialogRef<AddNewMedicineToMapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelNewMed, private productMaster: ProductMasterService,private popupService:PopupService) {
       
      
     }
     @ViewChild('sideEffect',{static:false}) sideEffect: ElementRef<HTMLInputElement>;
     @ViewChild('inp',{static:false}) inp: ElementRef<HTMLInputElement>;
     @ViewChild('caution',{static:false}) caution: ElementRef<HTMLInputElement>;
     @ViewChild('disease',{static:false}) disease: ElementRef<HTMLInputElement>;
     @ViewChild('subDiv',{static:false}) subDiv: ElementRef<HTMLInputElement>;
     @ViewChild('manf',{static:false}) manufacture:  ElementRef<HTMLInputElement>;
     
     ngOnInit(): void {
  
     
    // if(this.data.data.create){
    //   this.create_operation="CREATE"
    // }else{
    //   this.create_operation="UPDATE"
    // }
    this.data.data.type=="MAP"?this.finalButtonStatus="REQUEST FOR CREATION":this.data.data.type=="CREATE"?this.finalButtonStatus="CREATE":this.data.data.type=="TO_BE_CREATE"?this.finalButtonStatus="MAP":this.finalButtonStatus="APPROVE"
     this.options=[]
   
     this.getSuperGroups('',false);
      this.getScheduleDrugs();
      this.getCategories();
      this.getProductUnit('',false);
      this.getProductForm('',false);
      this.getProductTypes('',false);
      this.selectedChild[this.childCount].subChild[0].response.parentRequestId=this.selectedChild[this.childCount].response.id;
      this.selectedChild[this.childCount].subChild[0].response.entryType="TEMP";
      this.selectedChild[this.childCount].subChild[0].response.timeStamp=new Date().getTime();
      this.selectedChild[this.childCount].subChild[0].response.operationType="CREATE";
      console.log(this.selectedChild[this.childCount].response.dataString.length);
      if(this.data.data.type=="TO_BE_CREATE" || this.data.data.type=="APPROVE"){
        this.getRequestCreationData(false,true)

      }else if(this.data.data.type==='VERIFY'){
        // this.getRequestCreationData(true,true)
        this.selectParentMed({id:this.data.data.parentId})
      }

      let userData=JSON.parse(localStorage.getItem('userData'))    
      // let userRoleStatus=userData.user.roles.some((role)=>role.id==='APPROVER')
      this.userAccess=true

      
  }

  
  
  updateVerifiedData(type){
    let errorChildCreate=false;
  if(type=='child'){
    
   
      for(let i=0;i<this.childByParent.length;i++){
        if(this.childByParent[i].t.toLowerCase()==this.selectedChild[this.childCount].response.dataString.toLowerCase()){
          errorChildCreate=true
          this.popupService.openError(`'${this.childByParent[i].t}' already exists`)
          this.selectedChild[this.childCount].response.dataString=this.selectedChild[this.childCount].newChild
          break;
        }
      }
        
  }

   if(this.userAccess && !errorChildCreate){
    let msg=`Do you want to update ${type}`
    this.popupService.openCommonConfirmPopup(msg).afterClosed().subscribe((resp)=>{
      if(resp){
        let resp;
        if(type==='parent'){
           if(this.selectedParent.inpValue.parentName.length>0){
             resp={
               dataInteger:this.selectedParent.response.dataInteger,
               dataString:this.selectedParent.inpValue.parentName,
               operationType:'UPDATE',
               parentNodeId:this.selectedParent.response.dataInteger,
               productClassType:'PRODUCT_PARENT_NAME'
             }
             this.parentUpdate.error=false;
           }else{
                 this.parentUpdate.error=true;
                 this.borderError=true;
           }
             
        }else if(type=='manufactures'){
            resp={
              dataInteger:this.manufactures.response.dataInteger,
              dataString:this.manufactures.response.dataString,
              operationType:'UPDATE',
              parentNodeId:this.selectedParent.response.dataInteger,
              productClassType:'MANUFACTURER'

            }
        }else if(type=='category'){
         resp={
           dataInteger:this.category.response.dataInteger,
           dataString:this.category.response.dataString,
           operationType:'UPDATE',
           parentNodeId:this.selectedParent.response.dataInteger,
           productClassType:'CATEGORY'

         }
       }
         else if(type=='marketings'){
           resp={
             dataInteger:this.marketings.response.dataInteger,
             dataString:this.marketings.response.dataString,
             operationType:'UPDATE',
             parentNodeId:this.selectedParent.response.dataInteger,
             productClassType:'MARKETED_BY'

           }
       }else if(type=='description'){
         resp={
           dataInteger:null,
           dataString:this.description.response.dataString,
           operationType:'UPDATE',
           parentNodeId:this.selectedParent.response.dataInteger,
           productClassType:'PRODUCT_PARENT_DESCRIPTION'

         }
       }else if(type=='composition'){
         resp={
           dataInteger:this.selectedChild[this.childCount].compositions.response.dataInteger,
           dataString:this.selectedChild[this.childCount].compositions.response.dataString,
           operationType:'UPDATE',
           parentNodeId:this.selectedChild[this.childCount].response.dataInteger,
           productClassType:'COMPOSITION'

         }



         }else if(type=='child'){
         
            if(this.selectedChild[this.childCount].response.dataString.length){
              resp={
                dataInteger:this.selectedChild[this.childCount].response.dataInteger,
                dataString:this.selectedChild[this.childCount].response.dataString,
                operationType:'UPDATE',
                parentNodeId:this.selectedChild[this.childCount].response.dataInteger,
                productClassType:'PRODUCT_CHILD_NAME'
              }
              this.selectedChild[this.childCount].error=false;
              this.borderError=false;
            }else{
              this.selectedChild[this.childCount].error=true;
              this.borderError=true;
            }
         

             
         }else if(type=='dpco'){
             resp={
             dataInteger:this.selectedChild[this.childCount].dpco.response.dataInteger,
             dataString:this.selectedChild[this.childCount].dpco.response.dataString,
             operationType:'UPDATE',
             parentNodeId:this.selectedChild[this.childCount].response.dataInteger,
             productClassType:'DPCO'}
         }else if(type=='scheduleDrug'){
           resp={
             dataInteger:this.selectedChild[this.childCount].scheduleDrug.response.dataInteger,
             dataString:this.selectedChild[this.childCount].scheduleDrug.response.dataString,
             operationType:'UPDATE',
             parentNodeId:this.selectedChild[this.childCount].response.dataInteger,
             productClassType:'SCHEDULE_TYPE'}
         }else if(type=='atcn'){
           resp={
             dataInteger:this.selectedChild[this.childCount].atcn.response.dataInteger,
             dataString:this.selectedChild[this.childCount].atcn.response.dataString,
             operationType:'UPDATE',
             parentNodeId:this.selectedChild[this.childCount].response.dataInteger,
             productClassType:'ATCN_CODE'}
         }else if(type=='indication'){
           resp={
             dataInteger:this.selectedChild[this.childCount].indication.response.dataInteger,
             dataString:this.selectedChild[this.childCount].indication.response.dataString,
             operationType:'UPDATE',
             parentNodeId:this.selectedChild[this.childCount].response.dataInteger,
             productClassType:'INDICATION'}
         }else if(type=='contradiction'){
           resp={
             dataInteger:this.selectedChild[this.childCount].contradiction.response.dataInteger,
             dataString:this.selectedChild[this.childCount].contradiction.response.dataString,
             operationType:'UPDATE',
             parentNodeId:this.selectedChild[this.childCount].response.dataInteger,
             productClassType:'CONTRAINDICATION'}
         }else if(type=='dosage'){
           resp={
             dataInteger:this.selectedChild[this.childCount].dosage.response.dataInteger,
             dataString:this.selectedChild[this.childCount].dosage.response.dataString,
             operationType:'UPDATE',
             parentNodeId:this.selectedChild[this.childCount].response.dataInteger,
             productClassType:'DOSAGE'}
         }
   
      if(!this.borderError){
       this.productMaster.updateVerifiedMeds({},resp).subscribe(response=>{
         
         if(type=="parent" || type=="manufactures" || type=="category" || type=="marketings" || type=="description"){
          this.clearAllParentFields();
          this.category.data=[...this.categoryData]
          this.selectParentMed({id:this.data.data.parentId})
         }else if(type!=="child"){
          this.clearAllChildFields(false);
          this.selectChildMed(this.childSelectedVerified)
         }else{
           this.selectedChild[this.childCount].verifyResponse.showUpdate=false
         }
        
        
       
        //  this.getRequestCreationData(true,false);
        
         this.parentUpdate.showUpdate=false;
         this.popupService.openSuccess(response.status)

     },error=>{
      //  this.clearAllParentFields();
      //  this.clearAllChildFields(false);
      if(type=="parent" || type=="manufactures" || type=="category" || type=="marketings" || type=="description"){
        this.clearAllParentFields();
        this.category.data=[...this.categoryData]
        this.selectParentMed({id:this.data.data.parentId})
       }else{
        this.clearAllChildFields(false);
        this.selectChildMed(this.childSelectedVerified)
       }
      
      //  this.getRequestCreationData(true,false);
       this.parentUpdate.showUpdate=false;
       this.popupService.openError(error.data)

     })
      }
       
      }

    })
   } 
    
   
         
  }
  updateVerifiedSubChildData(type,index){
   if(this.userAccess){
    let msg=`Do you want to update ${type}`
    this.popupService.openCommonConfirmPopup(msg).afterClosed().subscribe((resp)=>{
      if(resp){
        let resp;
    
        if(type=='form'){
         resp={
           dataInteger:this.selectedChild[this.childCount].subChild[index].form.response.dataInteger,
           dataString:this.selectedChild[this.childCount].subChild[index].form.response.dataString,
           operationType:'UPDATE',
           parentNodeId:this.selectedChild[this.childCount].subChild[index].response.dataInteger,
           productClassType:'FORM'}
        }else if(type=='size'){
         resp={
           dataInteger:this.selectedChild[this.childCount].subChild[index].size.response.dataInteger,
           dataString:this.selectedChild[this.childCount].subChild[index].size.response.dataString,
           operationType:'UPDATE',
           parentNodeId:this.selectedChild[this.childCount].subChild[index].response.dataInteger,
           productClassType:'PACK_SIZE'}
        }else if(type=='unit'){
         resp={
           dataInteger:this.selectedChild[this.childCount].subChild[index].unit.response.dataInteger,
           dataString:this.selectedChild[this.childCount].subChild[index].unit.response.dataString,
           operationType:'UPDATE',
           parentNodeId:this.selectedChild[this.childCount].subChild[index].response.dataInteger,
           productClassType:'PACK_UNIT'}
        }else if(type=='type'){
          resp={
            dataInteger:this.selectedChild[this.childCount].subChild[index].type.response.dataInteger,
            dataString:this.selectedChild[this.childCount].subChild[index].type.response.dataString,
            operationType:'UPDATE',
            parentNodeId:this.selectedChild[this.childCount].subChild[index].response.dataInteger,
            productClassType:'PACK_TYPE'}
         }
   
        let childCount=this.childCount
        if(!this.borderError){
         this.productMaster.updateVerifiedMeds({},resp).subscribe(response=>{
          //  this.clearAllParentFields();
             this.clearAllChildFields(false);
            //  this.getRequestCreationData(true,false);
            this.selectChildMed(this.childSelectedVerified)
           this.popupService.openSuccess(response.status)
   
       },error=>{
        //  this.clearAllParentFields();
         this.clearAllChildFields(false);
        //  this.getRequestCreationData(true,false);
        this.selectChildMed(this.childSelectedVerified)
         this.popupService.openError(error.error)
   
       })
        }
      }
    })
   } 

    
    
  }


  focusOut(type){
    let updateType=type+'Update'
    setTimeout(()=>{
      if(type==='manufactures' || type==='marketings'){
           if(!this.showOptionsToggle){
            this[updateType].inputDisabled=true;
            this[type].show && (this[type].show=false)
            this[type].searchString=this[type].response.dataString
           }
      }else{
        this[updateType].inputDisabled=true;
        this[type].show && (this[type].show=false)
        this[type].searchString=this[type].response.dataString
      }
     
      },200)

  }
  closeOptions(type){
    let updateType=type+'Update'
    this[updateType].inputDisabled=true;
    this[type].show && (this[type].show=false)
    this[type].searchString=this[type].response.dataString
    this.showOptionsToggle=false;
  }

  closeOptionsNonVerified(type){
    if(type==='composition'){
      if(this.selectedChild[this.childCount].compositions['updatedResponse']){
        this.selectedChild[this.childCount].compositions.searchString=this.selectedChild[this.childCount].compositions['updatedResponse'].dataString
      }else{
        this.selectedChild[this.childCount].compositions.searchString=this.selectedChild[this.childCount].compositions.response.dataString
      }
        this.selectedChild[this.childCount].compositions.show=false
    }else{
      this[type].show=false;
      if( this[type].updatedResponse){
        this[type].searchString=this[type].updatedResponse.dataString
      }else{
        this[type].searchString=this[type].response.dataString

      }
    }
    
    // console.log(this.manufactures);
    
  }
  focusOutComp(){
    console.log(this.showOptionsToggle);
    
    
      setTimeout(()=>{
        if(!this.showOptionsToggle){
        this.selectedChild[this.childCount].compositions.searchString=this.selectedChild[this.childCount].compositions.response.dataString
        this.selectedChild[this.childCount].compositions.verifyResponse.inputDisabled=true
          this.selectedChild[this.childCount].compositions.show=false
        }
      },200)
    
  }
  closeCompOptions(){
    this.selectedChild[this.childCount].compositions.searchString=this.selectedChild[this.childCount].compositions.response.dataString
    this.selectedChild[this.childCount].compositions.verifyResponse.inputDisabled=true
      this.selectedChild[this.childCount].compositions.show=false;
      this.showOptionsToggle=false;
  }
  // verified child focus out 
  focusOutChild(){
    
      this.selectedChild[this.childCount].verifyResponse.inputDisabled=true
    
  }
//  except verified data child focus out 
  childFocusOut(){
          setTimeout(()=>{
               this.showChildMedOptions=false;
               this.selectedChild[this.childCount].response.dataString=this.selectedChild[this.childCount].newChild
          },200)
  }
  // side effects functions 

  addSideEffect(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.selectedChild[this.childCount].sideEffects.selectedData.push(value);
    }
    this.sideEffectsCtrl.setValue(null);
  }

  removeSideEffect(fruit: string): void {
    const index = this.selectedChild[this.childCount].sideEffects.selectedData.indexOf(fruit);
    let responseIndex;
    this.selectedChild[this.childCount].sideEffects.response.forEach((resp,i)=>{
          if(this.selectedChild[this.childCount].sideEffects.selectedData[index].id===resp.dataInteger){
            return responseIndex=i;
          }
    })
    if (index >= 0) {

     
      // this.selectedChild[this.childCount].sideEffects.response[responseIndex].updatedResponse.parentRequestId= this.selectedChild[this.childCount].sideEffects.response[responseIndex].id
      if(this.data.data.type=='VERIFY'){
        let msg=`Do you want to remove SideEffect`
        this.popupService.openCommonConfirmPopup(msg).afterClosed().subscribe((resp)=>{
           if(resp){
             
            let effect=this.selectedChild[this.childCount].sideEffects.response[responseIndex]
            let resp={
              dataInteger:effect.dataInteger,
              dataString:effect.dataString,
              operationType:'DELETE',
              parentNodeId:this.selectedChild[this.childCount].response.dataInteger,
              productClassType:'SIDE_EFFECTS'}
           
           this.productMaster.updateVerifiedMeds({},resp).subscribe(response=>{
            // this.clearAllParentFields();
            this.clearAllChildFields(false); 
            this.selectChildMed(this.childSelectedVerified)
            this.category.data=[...this.categoryData]
            // this.getRequestCreationData(true,false);
            this.parentUpdate.showUpdate=false;
                   this.popupService.openSuccess(response.status)
             },error=>{
              // this.clearAllParentFields();
              this.clearAllChildFields(false); 
              this.selectChildMed(this.childSelectedVerified)
              this.category.data=[...this.categoryData]
        //  this.getRequestCreationData(true,false);
         this.parentUpdate.showUpdate=false;
            this.popupService.openError('')
    
            })
           }
        })
    
      }else{
        this.selectedChild[this.childCount].sideEffects.selectedData.splice(index, 1);
        this.selectedChild[this.childCount].sideEffects.response[responseIndex].operationType="DELETE"
        this.selectedChild[this.childCount].sideEffects.response[responseIndex].updatedResponse=Object.assign({},this.selectedChild[this.childCount].sideEffects.response[responseIndex])
        this.selectedChild[this.childCount].sideEffects.response[responseIndex].updatedResponse.id= this.selectedChild[this.childCount].sideEffects.response[responseIndex].id+'_n'
      }
    }

    console.log(this.selectedChild[this.childCount].sideEffects.response)
  }

  selectedSideEffect(event,update): void {
    const index = this.selectedChild[this.childCount].sideEffects.selectedData.findIndex((x)=>x.id==event.option.value.id);
    if(index===-1){
      // console.log(this.selectedChild[this.childCount].sideEffects.selectedData,event.option.value);
      if(this.data.data.type!=='VERIFY'){
        this.selectedChild[this.childCount].sideEffects.selectedData.push(event.option.value);
      }else if(this.data.data.type==='VERIFY'){
        let resp={
          dataInteger:event.option.value.id,
          dataString:event.option.value.t,
          operationType:'CREATE',
          parentNodeId:this.selectedChild[this.childCount].response.dataInteger,
          productClassType:'SIDE_EFFECTS'}
       
       this.productMaster.updateVerifiedMeds({},resp).subscribe(response=>{
        // this.clearAllParentFields();
        this.clearAllChildFields(false); 
        this.selectChildMed(this.childSelectedVerified)
        this.category.data=[...this.categoryData]
        // this.getRequestCreationData(true,false);
        this.parentUpdate.showUpdate=false;
               this.popupService.openSuccess(response.status)
               this.selectedChild[this.childCount].sideEffects.selectedData.push(event.option.value);
         },error=>{
        this.popupService.openError('')
        })
       }
      
      
    }

  //  console.log(index,event.option.value,this.sideEffects.selectedData);
    let responseIndex=null;
    this.selectedChild[this.childCount].sideEffects.response.forEach((resp,i)=>{
      if(event.option.value.id==resp.dataInteger){
        return responseIndex=i;
      }
   })
  if(responseIndex!==null){
        this.selectedChild[this.childCount].sideEffects.response[responseIndex].operationType=this.create_operation
  }else{
   
    this.selectedChild[this.childCount].sideEffects.response.push({ id:this.sideEffects_variable+this.selectedChild[this.childCount].response.id+'_'+event.option.value.id,
      type:'SIDE_EFFECTS',
      parentRequestId:this.selectedChild[this.childCount].response.id,
      dataInteger:event.option.value.id,
      dataString:event.option.value.t,
      operationType:this.create_operation,
      entryType:this.selectedChild[this.childCount].response.entryType,
      timeStamp:new Date().getTime()
    });
    if(this.data.data.type==='APPROVE' && update){
      let length=this.selectedChild[this.childCount].sideEffects.response.length
      this.selectedChild[this.childCount].sideEffects.response[length-1].edited=true;
    }
    
  }
     
    this.sideEffect.nativeElement.value = '';
    this.sideEffectsCtrl.setValue(null);

    console.log(this.selectedChild[this.childCount].sideEffects.selectedData)
  }

  getAllSideEffects(focus){
    this.selectedChild[this.childCount].compositions.show=false;
    this.manufactures.show=false;
    this.marketings.show=false;
    if(!focus){
      this.page=0;
    }

    clearInterval(this.timer)
    this.timer=setTimeout(()=>{
    
      this.selectedChild[this.childCount].sideEffects.loader=true;
      let searchString;
      if(this.selectedChild[this.childCount].sideEffects.searchString==null ){
        searchString=''
      }else{
         searchString=this.selectedChild[this.childCount].sideEffects.searchString
      }
     const params={
       page:this.page,
       name:searchString
  }
  
  this.productMaster.getAllSideEffects(params).subscribe(
    response => {
 
         this.selectedChild[this.childCount].sideEffects.data=response.data.t
         this.selectedChild[this.childCount].sideEffects.loader=false;
    },
    error => {
     this.selectedChild[this.childCount].sideEffects.loader=false;
      this.popupService.closeProgress()
      this.popupService.openError(error.error.error.error);
    }
  )
    },300)
    
  
  }



  // cautions functions 

  addCaution(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.selectedChild[this.childCount].cautions.selectedData.push(value);
    }
    this.cautionsCtrl.setValue(null);
  }

  removeCaution(fruit: string): void {
    const index = this.selectedChild[this.childCount].cautions.selectedData.indexOf(fruit);
    let responseIndex;
    console.log( this.selectedChild[this.childCount].cautions.response)
    this.selectedChild[this.childCount].cautions.response.forEach((resp,i)=>{
      if(this.selectedChild[this.childCount].cautions.selectedData[index].id===resp.dataInteger){
        console.log(this.selectedChild[this.childCount].cautions.selectedData[index])
        return responseIndex=i;
      }
    })
    if (index >= 0) {
      
      // this.selectedChild[this.childCount].cautions.response[responseIndex].updatedResponse.parentRequestId= this.selectedChild[this.childCount].cautions.response[responseIndex].id
      if(this.data.data.type=='VERIFY'){
        let msg=`Do you want to remove Caution`
        this.popupService.openCommonConfirmPopup(msg).afterClosed().subscribe((resp)=>{
           if(resp){
            let effect=this.selectedChild[this.childCount].cautions.response[responseIndex]
            console.log(effect)
            let resp={
              dataInteger:effect.dataInteger,
              dataString:effect.dataString,
              operationType:'DELETE',
              parentNodeId:this.selectedChild[this.childCount].response.dataInteger,
              productClassType:'CAUTIONS'}
           
           this.productMaster.updateVerifiedMeds({},resp).subscribe(response=>{
            // this.clearAllParentFields();
            this.clearAllChildFields(false); 
            this.selectChildMed(this.childSelectedVerified)
            this.category.data=[...this.categoryData]
            //  this.getRequestCreationData(true,false);
            this.parentUpdate.showUpdate=false;
                this.popupService.openSuccess(response.status)
             },error=>{
              // this.clearAllParentFields();
              this.clearAllChildFields(false); 
              this.selectChildMed(this.childSelectedVerified)
              this.category.data=[...this.categoryData]
             //  this.getRequestCreationData(true,false);
            this.parentUpdate.showUpdate=false;
            this.popupService.openError('')
    
            })
           }
           })
        
      }else{
        this.selectedChild[this.childCount].cautions.selectedData.splice(index, 1);
      this.selectedChild[this.childCount].cautions.response[responseIndex].operationType="DELETE";
      this.selectedChild[this.childCount].cautions.response[responseIndex].updatedResponse=Object.assign({},this.selectedChild[this.childCount].cautions.response[responseIndex]) 
      this.selectedChild[this.childCount].cautions.response[responseIndex].updatedResponse.id= this.selectedChild[this.childCount].cautions.response[responseIndex].id+'_n'
      }
    }
  }

  selectedCaution(event,update): void {
    const index = this.selectedChild[this.childCount].cautions.selectedData.findIndex((x)=>x.id==event.option.value.id);
    if(index===-1){
      if(this.data.data.type!=='VERIFY'){
        this.selectedChild[this.childCount].cautions.selectedData.push(event.option.value);
      }else if(this.data.data.type==='VERIFY'){
        let resp={
          dataInteger:event.option.value.id,
          dataString:event.option.value.t,
          operationType:'CREATE',
          parentNodeId:this.selectedChild[this.childCount].response.dataInteger,
          productClassType:'CAUTIONS'}
       
       this.productMaster.updateVerifiedMeds({},resp).subscribe(response=>{
        // this.clearAllParentFields();
        this.clearAllChildFields(false); 
        this.selectChildMed(this.childSelectedVerified)
        this.category.data=[...this.categoryData]
        // this.getRequestCreationData(true,false);
        this.parentUpdate.showUpdate=false;
               this.popupService.openSuccess(response.status)
               this.selectedChild[this.childCount].cautions.selectedData.push(event.option.value);
         },error=>{
        this.popupService.openError('')
        })
       }
    }

  //  console.log(index,event.option.value,this.sideEffects.selectedData);
    let responseIndex=null;
    this.selectedChild[this.childCount].cautions.response.forEach((resp,i)=>{
      if(event.option.value.id==resp.dataInteger){
        return responseIndex=i;
      }
   })
  if(responseIndex!==null){
        this.selectedChild[this.childCount].cautions.response[responseIndex].operationType=this.create_operation
  }else{

    this.selectedChild[this.childCount].cautions.response.push({ id:this.cautions_variable+this.selectedChild[this.childCount].response.id+'_'+event.option.value.id,
      type:'CAUTIONS',
      parentRequestId:this.selectedChild[this.childCount].response.id,
      dataInteger:event.option.value.id,
      dataString:event.option.value.t,
      operationType:this.create_operation,
      entryType:this.entry_type,
      timeStamp:new Date().getTime()
    });

    if(this.data.data.type==='APPROVE' && update){
      let length=this.selectedChild[this.childCount].cautions.response.length
      this.selectedChild[this.childCount].cautions.response[length-1].edited=true;
    }
  }


    this.caution.nativeElement.value = '';
    this.cautionsCtrl.setValue(null);

    
    
  }

  getAllCautions(focus){
    this.selectedChild[this.childCount].compositions.show=false;
    this.manufactures.show=false;
    this.marketings.show=false;
    
    if(!focus){
      this.page=0;
    }

    clearInterval(this.timer)
    this.timer=setTimeout(()=>{
    
      this.selectedChild[this.childCount].cautions.loader=true;
      let searchString;
      if(this.selectedChild[this.childCount].cautions.searchString==null){
        searchString=''
      }else{
         searchString=this.selectedChild[this.childCount].cautions.searchString
      }
     const params={
       page:this.page,
       name:searchString
  }
  this.productMaster.getAllCautions(params).subscribe(
    response => {
 
         this.selectedChild[this.childCount].cautions.data=response.data.t
         this.selectedChild[this.childCount].cautions.loader=false;
    },
    error => {
     this.selectedChild[this.childCount].cautions.loader=false;
      this.popupService.closeProgress()
      this.popupService.openError(error.error.error.error);
    }
  )
    },300)
    
  
  }

  
  // diseases functions 

  addDisease(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.selectedChild[this.childCount].diseases.selectedData.push(value);
    }
    this.diseasesCtrl.setValue(null);
  }

  removeDisease(fruit: string): void {
    const index = this.selectedChild[this.childCount].diseases.selectedData.indexOf(fruit);
    let responseIndex;
    console.log( this.selectedChild[this.childCount].diseases.response)
    this.selectedChild[this.childCount].diseases.response.forEach((resp,i)=>{
      if(this.selectedChild[this.childCount].diseases.selectedData[index].id===resp.dataInteger){
        return responseIndex=i;
      }
    })
    if (index >= 0) {

     
      // this.selectedChild[this.childCount].diseases.response[responseIndex].updatedResponse.parentRequestId=this.selectedChild[this.childCount].diseases.response[responseIndex].id
      if(this.data.data.type=='VERIFY'){
        let msg=`Do you want to remove Caution`
        this.popupService.openCommonConfirmPopup(msg).afterClosed().subscribe((resp)=>{
           if(resp){
           
            let effect=this.selectedChild[this.childCount].diseases.response[responseIndex]
            let resp={
              dataInteger:effect.dataInteger,
              dataString:effect.dataString,
              operationType:'DELETE',
              parentNodeId:this.selectedChild[this.childCount].response.dataInteger,
              productClassType:'DISEASES'}
           
           this.productMaster.updateVerifiedMeds({},resp).subscribe(response=>{
            // this.clearAllParentFields();
            this.clearAllChildFields(false); 
            this.selectChildMed(this.childSelectedVerified)
            this.category.data=[...this.categoryData]
            // this.getRequestCreationData(true,false);
            this.parentUpdate.showUpdate=false;
                   this.popupService.openSuccess(response.status)
             },error=>{
              // this.clearAllParentFields();
              this.clearAllChildFields(false); 
              this.selectChildMed(this.childSelectedVerified)
              this.category.data=[...this.categoryData]
              // this.getRequestCreationData(true,false);
              this.parentUpdate.showUpdate=false;
            this.popupService.openError('')
    
            })
           }})
        
      }else{
        this.selectedChild[this.childCount].diseases.selectedData.splice(index, 1);
        this.selectedChild[this.childCount].diseases.response[responseIndex].operationType="DELETE"
        this.selectedChild[this.childCount].diseases.response[responseIndex].updatedResponse=Object.assign({},this.selectedChild[this.childCount].diseases.response[responseIndex]) 
        this.selectedChild[this.childCount].diseases.response[responseIndex].updatedResponse.id=this.selectedChild[this.childCount].diseases.response[responseIndex].id+'_n'
      }
    }

  }

  selectedDisease(event,update): void {
    const index = this.selectedChild[this.childCount].diseases.selectedData.findIndex((x)=>x.id===event.option.value.id);
    if(index===-1){
      if(this.data.data.type!=='VERIFY'){
        this.selectedChild[this.childCount].diseases.selectedData.push(event.option.value);
      }else if(this.data.data.type==='VERIFY'){
        let resp={
          dataInteger:event.option.value.id,
          dataString:event.option.value.t,
          operationType:'CREATE',
          parentNodeId:this.selectedChild[this.childCount].response.dataInteger,
          productClassType:'DISEASES'}
       
       this.productMaster.updateVerifiedMeds({},resp).subscribe(response=>{
        // this.clearAllParentFields();
        this.clearAllChildFields(false); 
        this.selectChildMed(this.childSelectedVerified)
        this.category.data=[...this.categoryData]
        // this.getRequestCreationData(true,false);
        this.parentUpdate.showUpdate=false;
               this.popupService.openSuccess(response.status)
               this.selectedChild[this.childCount].diseases.selectedData.push(event.option.value);
         },error=>{
        this.popupService.openError('')
        })
       }
    }

  //  console.log(index,event.option.value,this.sideEffects.selectedData);
    let responseIndex=null;
    this.selectedChild[this.childCount].diseases.response.forEach((resp,i)=>{
      if(event.option.value.id==resp.dataInteger){
        return responseIndex=i;
      }
   })
  if(responseIndex!==null){
        this.selectedChild[this.childCount].diseases.response[responseIndex].operationType=this.create_operation
  }else{

    this.selectedChild[this.childCount].diseases.response.push({ id:this.disease_variable+this.selectedChild[this.childCount].response.id+'_'+event.option.value.id,
      type:'DISEASES',
      parentRequestId:this.selectedChild[this.childCount].response.id,
      dataInteger:event.option.value.id,
      dataString:event.option.value.t,
      operationType:this.create_operation,
      entryType:this.entry_type,
      timeStamp:new Date().getTime()
    });
    if(this.data.data.type==='APPROVE' && update){
      let length=this.selectedChild[this.childCount].diseases.response.length
      this.selectedChild[this.childCount].diseases.response[length-1].edited=true;
    }
  }
    this.disease.nativeElement.value = '';
    this.diseasesCtrl.setValue(null);
    console.log(this.selectedChild[this.childCount].diseases.response);
    
  }

  getAllDiseases(focus){
    this.selectedChild[this.childCount].compositions.show=false;
    this.manufactures.show=false;
    this.marketings.show=false;
    
    if(!focus){
      this.page=0;
    }

    clearInterval(this.timer)
    this.timer=setTimeout(()=>{
    
      this.selectedChild[this.childCount].diseases.loader=true;
      let searchString;
      if(this.selectedChild[this.childCount].diseases.searchString===null){
        searchString=''
      }else{
         searchString=this.selectedChild[this.childCount].diseases.searchString
      }
     const params={
       page:this.page,
       name:searchString
  }
  this.productMaster.getAllDiseases(params).subscribe(
    response => {
 
         this.selectedChild[this.childCount].diseases.data=response.data.t
         this.selectedChild[this.childCount].diseases.loader=false;
    },
    error => {
     this.selectedChild[this.childCount].diseases.loader=false;
      this.popupService.closeProgress()
      this.popupService.openError(error.error.error.error);
    }
  )
    },300)
    
  
  }
  closeAllOptions(event){
  //   let arr=['Select Composition','Select Marketing','chevron_left',
  //   'chevron_right','Select Manufacturer','mat-input-11 ','mat-input-8','chevron_left chevron_right']
  //  if(!arr.includes(event.target.innerText)){
  //   this.manufactures.show=false;
  //   this.marketings.show=false;
  //   this.selectedChild[this.childCount].compositions.show=false;
  //  }
  //  console.log('vbnv',event);
   
    // },200)    
  }

  searchParentMed(event,keyup){
    
   if(keyup){
    this.page=0;
    this.selectedParent.data=[];
   }
       
      this.showParentMedOptions=true;
      this.selectedParent.loader=true;

      // if(!event.target.value.match('^[a-zA-Z0-9 ]+$')){
      //    let trimString=event.target.value.replace(/[^a-zA-Z0-9 ]/g, "")
      //     this.selectedParent.inpValue.parentName=trimString;
      //     this.selectedParent.searchString=trimString
      // }else{
      //   this.selectedParent.searchString=event.target.value;
      // }
      let trimString=event.target.value.replace(/[*?^${}()~`:;^#@!_><="'|[\]\\]/g, '');
           trimString=trimString.replace(/ {1,}/g," ");
      this.selectedParent.inpValue.parentName=trimString;
  
      this.selectedParent.searchString=trimString;
      if(event.target.value.length==0){
        this.showParentMedOptions=false
      }
      const params={
        name:trimString.trim(),
        page:this.page
      }
      this.productMaster.getParentsBySearch(params).subscribe(
        response=>{
           this.selectedParent.data.push(...response.data.t)
           this.selectedParent.loader=false
           
        },
        error=>{
          this.selectedParent.loader=false
          this.popupService.openError(error.error.error.error);
        }
        
      )


  }

  onScrollDown(ev: any,type) {
    console.log("scrolled down!!", ev);

    this.page++;
    this.appendItems(type);
  }

  appendItems(type) {
   type==="PARENT" && this.searchParentMed({target:{value:this.selectedParent.inpValue.parentName}},false)
   type==="SUPER_GROUP" && this.getSuperGroups(this.selectedChild[this.childCount].superGroup.inpValue[0]
    .superGroup,false);
  }

  onScrollDownSubChilds(ev: any,type,val) {
    console.log("scrolled down!!", ev);

    this.page++;
   type==='form' && this.getProductForm(val,false)
   type==='type' && this.getProductTypes(val,false);
   type==='unit' && this.getProductUnit(val,false)
  }

 searchChildMed(event){
    this.showChildMedOptions=true
    let trimString=event.target.value.replace(/[*?^${}()~`:;^#@!_><="'|[\]\\]/g, '');
    trimString=trimString.replace(/ {1,}/g," ");
    this.selectedChild[this.childCount].response.dataString=trimString
    this.selectedChild[this.childCount].searchString=trimString.trim();
    this.childByParent=this.childByParentCopy.filter((child)=>child.t.toLowerCase().includes(event.target.value.toLowerCase().trim()))
 
    // if(event.target.value.length==0){
    //   this.showChildMedOptions=false
    // }
  
  }
  
  getChildMedByParent(){
    this.showChildMedOptions=true
    const params={
      parentId:this.selectedParent.response.dataInteger
    }
    this.productMaster.getChildByParentId(params).subscribe(
      response=>{
          //  if(!this.data.data.create){
            this.childByParentCopy=response.data.t
            this.childByParent=response.data.t
          //  }   
           
      },
      error=>{
        this.selectedParent.loader=false
        this.popupService.openError(error.error.error.error);
      }
      
    )
  }

  searchComposition(focus){
   this.manufactures.show=false;
   this.marketings.show=false
    if(!focus){
      this.page=0;
    }

    this.selectedChild[this.childCount].compositions.show=true;
    clearInterval(this.timer)
    this.timer=setTimeout(()=>{
    
      this.selectedChild[this.childCount].compositions.loader=true;
     const params={
       page:this.page,
       name:this.selectedChild[this.childCount].compositions.searchString
  }
  this.productMaster.getAllCompositions(params).subscribe(
    response => {
 
         this.selectedChild[this.childCount].compositions.data=response.data.t

         this.selectedChild[this.childCount].compositions.loader=false;
    },
    error => {
     this.selectedChild[this.childCount].compositions.loader=false;
      this.popupService.closeProgress()
      this.popupService.openError(error.error.error.error);
    }
  )
    },300)
    
  
    
  
   
  }

  

  selectComposition(option,update){
         let operationType="CREATE";
         this.selectedChild[this.childCount].compositions.show=false;
         this.selectedChild[this.childCount].compositions.inpValue=option;
         this.selectedChild[this.childCount].compositions.searchString=option.t;
         this.showOptionsToggle=false;
         if(this.data.data.type==="APPROVE" && update){
           if(this.selectedChild[this.childCount].compositions.response.id){
            operationType="UPDATE"
            this.selectedChild[this.childCount].compositions['updatedResponse']={ id:(this.composition_variable+this.childCount+1)+'_n',
             type:'COMPOSITION',
             parentRequestId:this.selectedChild[this.childCount].response.id,
             dataInteger:option.id,
             dataString:option.t,
             operationType:operationType,
             entryType:this.selectedChild[this.childCount].response.entryType,
             timeStamp:new Date().getTime()
           }
           }else{
            this.selectedChild[this.childCount].compositions['updatedResponse']={ id:(this.composition_variable+this.childCount+1)+'_n',
             type:'COMPOSITION',
             parentRequestId:this.selectedChild[this.childCount].response.id,
             dataInteger:option.id,
             dataString:option.t,
             operationType:'CREATE',
             entryType:this.selectedChild[this.childCount].response.entryType,
             timeStamp:new Date().getTime()
           }
           }
          
         }else{
          this.selectedChild[this.childCount].compositions.response={ id:this.composition_variable+this.childCount+1,
            type:'COMPOSITION',
            parentRequestId:this.selectedChild[this.childCount].response.id,
            dataInteger:option.id,
            dataString:option.t,
            operationType:operationType,
            entryType:this.selectedChild[this.childCount].response.entryType,
            timeStamp:new Date().getTime()
          }
         }


         if(this.data.data.type=='VERIFY' && update){

           
              this.selectedChild[this.childCount].compositions['verifyResponse'].showUpdate=true
            }else{
              this.selectedChild[this.childCount].compositions['verifyResponse'].showUpdate=false;

            }
         
       
      
        console.log(this.selectedChild[this.childCount].compositions.response)
        
  }


  searchManufacture(focus){
    this.selectedChild[this.childCount].compositions.show=false;
    this.marketings.show=false
     if(!focus){
       this.page=0
     }
     this.manufactures.show=true;

     clearInterval(this.timer)
     this.timer=setTimeout(()=>{
      this.manufactures.loader=true;
      const params={
        page:this.page,
        name:this.manufactures.searchString
   }
   this.productMaster.getAllManufacturers(params).subscribe(
     response => {
  
          this.manufactures.data=response.data.t
          this.manufactures.loader=false;
     },
     error => {
      this.manufactures.loader=false;
       this.popupService.closeProgress()
       this.popupService.openError(error.error.error.error);
     }
   )

     },300) 
      
   
     
   
    
   }
 
   
 
   selectManufacture(option,updated){
     let operationType="CREATE";
     this.showOptionsToggle=false;
    if(this.data.data.type==="APPROVE" && updated){
      operationType="UPDATE";
      this.manufactures.show=false;
      this.manufactures.inpValue=option;
      this.manufactures.searchString=option.t;
      this.manufactures['updatedResponse']={ id:(this.manufacture_variable+1)+'_n',
       type:'MANUFACTURER',
       parentRequestId:this.selectedParent.response.id,
       dataInteger:option.id,
       dataString:option.t,
       operationType:operationType,
       entryType:this.selectedParent.response.entryType,
       timeStamp:new Date().getTime()
     }
    }else{
      this.manufactures.show=false;
      this.manufactures.inpValue=option;
      this.manufactures.searchString=option.t;
      this.manufactures.response={ id:this.manufacture_variable+1,
       type:'MANUFACTURER',
       parentRequestId:this.selectedParent.response.id,
       dataInteger:option.id,
       dataString:option.t,
       operationType:operationType,
       entryType:this.selectedParent.response.entryType,
       timeStamp:new Date().getTime()
     }
    }

    if(this.data.data.type=='VERIFY' && updated){
          this.manufacturesUpdate.showUpdate=true
    }else{
      this.manufacturesUpdate.showUpdate=false;
    }

    if(updated && this.data.data.type!=='VERIFY'){
      this.marketings.searchString= this.manufactures.searchString
      this.searchMarketing(false)
    }
    
   }
 

   searchMarketing(focus){
    this.selectedChild[this.childCount].compositions.show=false;
    this.manufactures.show=false;

     if(!focus){
       this.page=0
     }
     this.marketings.show=true;

     clearInterval(this.timer)
     this.timer=setTimeout(()=>{
      this.marketings.loader=true;
      const params={
        page:this.page,
        name:this.marketings.searchString
   }
   this.productMaster.getAllMarketings(params).subscribe(
     response => {
  
          this.marketings.data=response.data.t
          this.marketings.loader=false;
     },
     error => {
      this.marketings.loader=false;
       this.popupService.closeProgress()
       this.popupService.openError(error.error.error.error);
     }
   )

     },300) 
      
   
     
   
    
   }
 
   
 
   selectMarketing(option,updated){
         this.marketings.show=false;
         this.marketings.inpValue=option;
         this.marketings.searchString=option.t;
         this.showOptionsToggle=false;
         let operationType="CREATE";
         if(this.data.data.type==="APPROVE" && updated){
           operationType="UPDATE"
           this.marketings['updatedResponse']={ id:this.marketing_variable+1,
            type:'MARKETED_BY',
            parentRequestId:this.selectedParent.response.id,
            dataInteger:option.id,
            dataString:option.t,
            operationType:operationType,
            entryType:this.selectedParent.response.entryType,
            timeStamp:new Date().getTime()
          }
         }else{
          this.marketings.response={ id:this.marketing_variable+1,
            type:'MARKETED_BY',
            parentRequestId:this.selectedParent.response.id,
            dataInteger:option.id,
            dataString:option.t,
            operationType:operationType,
            entryType:this.selectedParent.response.entryType,
            timeStamp:new Date().getTime()
          }
         }
         
         if(this.data.data.type==='VERIFY' && updated){
           this.marketingsUpdate.showUpdate=true
         }else{
          this.marketingsUpdate.showUpdate=false

         }
        console.log(this.marketings.response)
   }
 
   getCategories(){
     const params={}
    this.productMaster.getAllCategories({name:'',page:0}).subscribe(
      response => {
           this.category.data=response.data.t
          this.categoryData=[...response.data.t]
      },
      error => {
     
        this.popupService.closeProgress()
        this.popupService.openError(error.error.error.error);
      }
    )   }

   categoryChange(event,updated){

        this.category.inpValue=event.value;
        let operationType="CREATE";
        if(this.data.data.type==="APPROVE" && updated){
          operationType="UPDATE"
          this.category['updatedResponse']={
            id:(this.category_variable+1)+'_n',
            type:'CATEGORY',
            parentRequestId:this.selectedParent.response.id,
            dataInteger:this.category.inpValue.id,
            dataString:this.category.inpValue.t,
            operationType:operationType,
            entryType:this.selectedParent.response.entryType,
            timeStamp:new Date().getTime()
          }
        }else{
          this.category.response={
            id:this.category_variable+1,
            type:'CATEGORY',
            parentRequestId:this.selectedParent.response.id,
            dataInteger:this.category.inpValue.id,
            dataString:this.category.inpValue.t,
            operationType:operationType,
            entryType:this.selectedParent.response.entryType,
            timeStamp:new Date().getTime()
          }
        }
        
        if(this.data.data.type==='VERIFY' && updated){
          this.categoryUpdate.showUpdate=true;
        }else{
          this.categoryUpdate.showUpdate=false;

        }
        console.log('test',this.category.inpValue);
        
   }


   getSuperGroups(name,keyup){
  
    if(keyup){
      this.page=0
      this.selectedChild[this.childCount].superGroup.showOptions=true;
      clearInterval(this.timer)
      this.timer=setTimeout(()=>{
      const params={
        name:name,
        page:this.page
      }
      
     this.productMaster.getAllSuperGroups(params).subscribe(
       response => {
         if(keyup){
          this.selectedChild[this.childCount].superGroup.data=[];
         }
            this.superGroupResponseData=[...response.data.t]
            let data=response.data.t.map((data)=>{
                   data.group={"id":'',"group":""};
                   data.superGroup=data.t;
                   return data
            });
            this.selectedChild[this.childCount].superGroup.data.push(...data)
            
            
       },
       error => {
      
         this.popupService.closeProgress()
         this.popupService.openError(error.error.error.error);
       }
     )   
      },300)
    }else{
      const params={
        name:name,
        page:this.page
      }
      
     this.productMaster.getAllSuperGroups(params).subscribe(
       response => {
         if(keyup){
          this.selectedChild[this.childCount].superGroup.data=[];
         }
            this.superGroupResponseData=[...response.data.t]
            let data=response.data.t.map((data)=>{
                   data.group={"id":'',"group":""};
                   data.superGroup=data.t;
                   return data
            });
            this.selectedChild[this.childCount].superGroup.data.push(...data)
            
            
       },
       error => {
      
         this.popupService.closeProgress()
         this.popupService.openError(error.error.error.error);
       }
     )   
    }
    
   
   
  }
  prepareSuperGroupData(){
    this.selectedChild[this.childCount].superGroup.data=this.superGroupResponseData.map((data)=>{
      data.group={"id":'',"group":""};
      data.superGroup=data.t;
      return data
});
  }

  superGroupChange(event){
    console.log('sprgrp',event.value)
    this.selectedChild[this.childCount].superGroup.inpValue[0]=event.value;
    this.selectedChild[this.childCount].superGroup.showOptions=false;
    this.getGroupsBySuperGroup();


  }
  // groupOptionClick(event){
  //   console.log('grp',event);
   
    
  //   let index=this.selectedChild[this.childCount].superGroup.inpValue.indexOf(event);

  //   if(index===-1){
  //     this.showGroups=false;
  //     // document.getElementsByTagName("head")[0].lastChild.remove();    
  //     // this.metMenuStyle('none')
  //     // document.getElementById("groupsMenu").style.display="none"
  //     this.selectedChild[this.childCount].groups.response.forEach((group,i)=>{
  //            if(group.superGroupId===event.id){
  //             this.selectedChild[this.childCount].groups.response[i].operationType="DELETE"
  //            }
  //            console.log(group.superGroupId,event.id);
             
  //     })
  //   }else{
  //     this.selectedChild[this.childCount].superGroup.selectedValue=event;
  //     this.getGroupsBySuperGroup();
  //     this.showGroups=true;
  //     // this.metMenuStyle('block')
  //     // document.getElementById("groupsMenu").style.display="block";
  //   }

  //   console.log(event.id,index,this.selectedChild[this.childCount].groups.response)

  // }

  // metMenuStyle(display){
  //   var cssStyle = document.createElement('style');
  //   // cssStyle.type = 'text/css';
  //   var style = document.createTextNode(`::ng-deep .matMenu {display:'${display}'}`);
  //   cssStyle.appendChild(style);

  //   document.getElementsByTagName("head")[0].appendChild(cssStyle);
  // }

  getGroupsBySuperGroup(){
    const params={
      superGroupId:this.selectedChild[this.childCount].superGroup.inpValue[0].id
    }
   this.productMaster.getAllGroupsBySuperGroup(params).subscribe(
     response => {
          this.selectedChild[this.childCount].groups.data=response.data.t

     },
     error => {
    
       this.popupService.closeProgress()
       this.popupService.openError(error.error.error.error);
     }
   )   
  }

  groupChange(update){
    let operationType="CREATE";
    // if(this.data.data.type==="APPROVE" && update){
    //      operationType="CRE"
    // }
    // this.selectedChild[this.childCount].groups.inpValue=event;
    // this.selectedChild[this.childCount].groups.response.forEach((grp,i)=>{
    //   if(grp.superGroupId=== this.selectedChild[this.childCount].superGroup.inpValue[0].id){
    //        this.selectedChild[this.childCount].groups.response.splice(i,1)
    //   }
    // })
    let verifyUpdateStatus=false    
    if(this.data.data.type=='VERIFY'){
      if(this.selectedChild[this.childCount].groups.inpValue.length>1){
        verifyUpdateStatus=true
        this.popupService.openError("You can add only one group at a time")
      }
    } 
    this.selectedChild[this.childCount].superGroup.inpValue[0] && this.selectedChild[this.childCount].groups.inpValue.length &&  this.selectedChild[this.childCount].groups.inpValue.forEach((event)=>{
      
      let duplicate=this.selectedChild[this.childCount].groups.response.filter((x)=>{ if(x.dataInteger===event.id && x.operationType!=='DELETE'){return x}}) 
      console.log(duplicate);
      
        if(!duplicate.length && this.data.data.type!=='VERIFY'){
          this.selectedChild[this.childCount].groups.response.push({ id:this.group_variable+this.childCount+event.id,
            type:'SUPER_GROUP_GROUP',
            superGroupId:this.selectedChild[this.childCount].superGroup.inpValue[0].id,
            parentRequestId:this.selectedChild[this.childCount].response.id,
            dataInteger:event.id,
            dataString:event.t,
            operationType:operationType,
            entryType:this.selectedChild[this.childCount].response.entryType,
            timeStamp:new Date().getTime()
          })
        this.data.data.type==='APPROVE' && (this.selectedChild[this.childCount].groups.response[this.selectedChild[this.childCount].groups.response.length-1]['groupFromResponse']=true);
        } 

       if(this.data.data.type==='VERIFY' && !verifyUpdateStatus){
         
         let resp={
            dataInteger:event.id,
            dataString:event.t,
            operationType:'CREATE',
            parentNodeId:this.selectedChild[this.childCount].response.dataInteger,
            productClassType:'SUPER_GROUP_GROUP'}
         
         this.productMaster.updateVerifiedMeds({},resp).subscribe(response=>{
                 this.popupService.openSuccess(response.status)
                //  this.selectedChild[this.childCount].groups.response.push({ id:this.group_variable+this.childCount+event.id,
                //   type:'SUPER_GROUP_GROUP',
                //   superGroupId:this.selectedChild[this.childCount].superGroup.inpValue[0].id,
                //   parentRequestId:this.selectedChild[this.childCount].response.id,
                //   dataInteger:event.id,
                //   dataString:event.t,
                //   operationType:operationType,
                //   entryType:this.selectedChild[this.childCount].response.entryType,
                //   timeStamp:new Date().getTime()
                // })
                // this.clearAllParentFields();
                this.clearAllChildFields(false);
                this.selectChildMed(this.childSelectedVerified)
                this.category.data=[...this.categoryData]
                // this.getRequestCreationData(true,false);
                this.parentUpdate.showUpdate=false;
           },error=>{
          this.popupService.openError(error.error.data.localizedMessage)
          })
         }
      
      
     
    })
    this.superGroupSelect.setValue('')
    this.groupSelect.setValue('')
    // let sprGroupIndex=this.selectedChild[this.childCount].superGroup.inpValue.indexOf(this.selectedChild[this.childCount].superGroup.selectedValue);
    // this.selectedChild[this.childCount].superGroup.inpValue[sprGroupIndex].group={id:event.id,group:event.t}
     this.getSelectedGroups('')
    console.log(this.selectedChild[this.childCount].groups.response)
  }

  getSelectedGroups(event){
   

    let resp=[...this.selectedChild[this.childCount].superGroup.data]
    resp.forEach((x)=>{return x['selectedGroups']=[]});

    this.selectedChild[this.childCount].groups.response.forEach((x)=>{
           resp.forEach((y,i)=>{
               
                 if( x.superGroupId===y.id && x.operationType!=='DELETE'){
                     
                    
                       return resp[i]['selectedGroups'].push(x)
                     
                  
                 } 
           })
    })
    

    this.selectedChild[this.childCount].superGroup.selectedSuperGroups=resp;    
    
  }

  removeGroup(id){
    
    let operationType="CREATE";
   
    this.selectedChild[this.childCount].groups.response.forEach((group,i)=>{
      console.log(id,group.dataInteger);
         if(group.dataInteger===id){
          if(this.data.data.type==="APPROVE" && !group['groupFromResponse']){
            operationType="DELETE"
            this.selectedChild[this.childCount].groups.response[i].operationType="DELETE";
            this.selectedChild[this.childCount].groups.response[i]['updatedResponse']=Object.assign({}, this.selectedChild[this.childCount].groups.response[i])
            this.selectedChild[this.childCount].groups.response[i]['updatedResponse'].id=this.selectedChild[this.childCount].groups.response[i].id+'_n'
            // this.selectedChild[this.childCount].groups.response[i]['updatedResponse'].parentRequestId=this.selectedChild[this.childCount].groups.response[i].id
          }else if(this.data.data.type!=='VERIFY'){
            this.selectedChild[this.childCount].groups.response.splice(i,1)
           }

          if(this.data.data.type==='VERIFY'){
            let msg="Do you want remove Group"
            this.popupService.openCommonConfirmPopup(msg).afterClosed().subscribe((resp)=>{
              if(resp){
                let resp={
                  dataInteger:group.dataInteger,
                  dataString:group.dataString,
                  operationType:'DELETE',
                  parentNodeId:this.selectedChild[this.childCount].response.dataInteger,
                  productClassType:'SUPER_GROUP_GROUP'}
               
               this.productMaster.updateVerifiedMeds({},resp).subscribe(response=>{
                       this.popupService.openSuccess(response.status)
                       this.selectedChild[this.childCount].groups.response.splice(i,1)
                       //  this.clearAllParentFields();
                       this.clearAllChildFields(false);
                       this.category.data=[...this.categoryData]
                       this.selectChildMed(this.childSelectedVerified)
                       //  this.getRequestCreationData(true,false);
                       this.parentUpdate.showUpdate=false;
                 },error=>{
                this.popupService.openError('')
                })
              }
            })
            
           }
          }

    })
    this.getSelectedGroups('');
  }
  getScheduleDrugs(){
    const params={}
   this.productMaster.getAllScheduleDrugs(params).subscribe(
     response => {
          this.selectedChild[this.childCount].scheduleDrug.data=response.data.t
          this.scheduleDrugData=[...response.data.t]
     },
     error => {
    
       this.popupService.closeProgress()
       this.popupService.openError(error.error.error.error);
     }
   )   
  }
  
  scheduleDrugChange(event,update){
    let operationType="CREATE";
    this.selectedChild[this.childCount].scheduleDrug.inpValue=event.value;
    if(this.data.data.type==="APPROVE" && update){
      if(this.selectedChild[this.childCount].scheduleDrug.response.id){
        operationType="UPDATE";
        this.selectedChild[this.childCount].scheduleDrug['updatedResponse']={ id:this.scheduleDrug_variable+this.childCount+'_n',
          type:'SCHEDULE_TYPE',
          parentRequestId:this.selectedChild[this.childCount].response.id,
          dataInteger:this.selectedChild[this.childCount].scheduleDrug.inpValue.id,
          dataString:this.selectedChild[this.childCount].scheduleDrug.inpValue.t,
          operationType:operationType,
          entryType:this.selectedChild[this.childCount].response.entryType,
          timeStamp:new Date().getTime()
        }
      }else{
        operationType="UPDATE";
        this.selectedChild[this.childCount].scheduleDrug['updatedResponse']={ id:this.scheduleDrug_variable+this.childCount+'_n',
          type:'SCHEDULE_TYPE',
          parentRequestId:this.selectedChild[this.childCount].response.id,
          dataInteger:this.selectedChild[this.childCount].scheduleDrug.inpValue.id,
          dataString:this.selectedChild[this.childCount].scheduleDrug.inpValue.t,
          operationType:'CREATE',
          entryType:this.selectedChild[this.childCount].response.entryType,
          timeStamp:new Date().getTime()
        }
      }
       
    }else{
      this.selectedChild[this.childCount].scheduleDrug.response={ id:this.scheduleDrug_variable+this.childCount,
        type:'SCHEDULE_TYPE',
        parentRequestId:this.selectedChild[this.childCount].response.id,
        dataInteger:this.selectedChild[this.childCount].scheduleDrug.inpValue.id,
        dataString:this.selectedChild[this.childCount].scheduleDrug.inpValue.t,
        operationType:operationType,
        entryType:this.selectedChild[this.childCount].response.entryType,
        timeStamp:new Date().getTime()
      }
    }
    
    if(this.data.data.type==='VERIFY' && update){
      this.selectedChild[this.childCount].scheduleDrug.verifyResponse.showUpdate=true
    }else{
      this.selectedChild[this.childCount].scheduleDrug.verifyResponse.showUpdate=false

    }
   
  }

  getProductTypes(name,keyup){
    let time=0
    if(keyup){time=300}
    clearInterval(this.timer)
    this.timer=setTimeout(()=>{
    const params={
      name:name,
      page:this.page
    }
   this.productMaster.getAllProductType(params).subscribe(
     response => {
       if(keyup){
        this.productType.data=[];
        this.productTypedata=[];
       }
          this.productType.data.push(...response.data.t)
          this.productTypedata=[...response.data.t]
     },
     error => {
    
       this.popupService.closeProgress()
       this.popupService.openError(error.error.error.error);
     }
   )   
    },time)
  }

  searchType(event,keyup,index){
    
    if(keyup){
      this.page=0
    }
 
    
    this.selectedChild[this.childCount].subChild[index].type.showOptions=true
    this.getProductTypes(event.target.value,keyup)    
}
  
  productTypeChange(event,index,update){
    let operationType="CREATE";
    event.value=Object.assign({},event.value)
    if(this.data.data.type==="APPROVE" && update && this.selectedChild[this.childCount].subChild[index].response.dataInteger){
      operationType="UPDATE"
      this.selectedChild[this.childCount].subChild[index].type.inpValue=event.value;
      this.selectedChild[this.childCount].subChild[index].type.edited=true;
      this.selectedChild[this.childCount].subChild[index].type['updatedResponse']={
       id:this.type_variable+this.childCount+index+'_n',
        type:'PACK_TYPE',
        parentRequestId:this.selectedChild[this.childCount].subChild[index].response.id,
        dataInteger:event.value.id,
        dataString:event.value.t,
        operationType:operationType,
        entryType:this.selectedChild[this.childCount].response.entryType,
        timeStamp:new Date().getTime()
       }
    }else{
      this.productType.selectedData=event.value;
      this.selectedChild[this.childCount].subChild[index].type={
        inpValue:event.value,
        edited:false,
        error:false,
        showOptions:false,
        verifyResponse:Object.assign({},this.updateResp),
        updatedResponse:null,
        response:{
        id:this.type_variable+this.childCount+index,
         type:'PACK_TYPE',
         parentRequestId:this.selectedChild[this.childCount].subChild[index].response.id,
         dataInteger:event.value.id,
         dataString:event.value.t,
         operationType:operationType,
         entryType:this.selectedChild[this.childCount].response.entryType,
         timeStamp:new Date().getTime()
        }
     }
    }

    if(this.data.data.type=='VERIFY' && update){
      this.selectedChild[this.childCount].subChild[index].type.verifyResponse.showUpdate=true
    }
    
     console.log(this.selectedChild[this.childCount].subChild);
  }

  sizeChange(event,index,update){     
    let operationType="CREATE";
    if(this.data.data.type==="APPROVE" && update && this.selectedChild[this.childCount].subChild[index].response.dataInteger){
      operationType="UPDATE"
      this.selectedChild[this.childCount].subChild[index].size.inpValue=event.target.value;
      this.selectedChild[this.childCount].subChild[index].size.edited=true;
      this.selectedChild[this.childCount].subChild[index].size['updatedResponse']={
       id:this.size_variable+this.childCount+index+'_n',
        type:'PACK_SIZE',
        parentRequestId:this.selectedChild[this.childCount].subChild[index].response.id,
        dataInteger:null,
        dataString:event.target.value,
        operationType:operationType,
        entryType:this.selectedChild[this.childCount].response.entryType,
        timeStamp:new Date().getTime()
       }
    }else{
      this.selectedChild[this.childCount].subChild[index].size={
        inpValue:event.target.value,
        edited:false,
        error:false,
        verifyResponse:Object.assign({},this.updateResp),
        response:{
        id:this.size_variable+this.childCount+index,
         type:'PACK_SIZE',
         parentRequestId:this.selectedChild[this.childCount].subChild[index].response.id,
         dataInteger:null,
         dataString:event.target.value,
         operationType:operationType,
         entryType:this.selectedChild[this.childCount].response.entryType,
         timeStamp:new Date().getTime()
        }
     }
    }

    if(this.data.data.type=='VERIFY' && update){
      this.selectedChild[this.childCount].subChild[index].size.verifyResponse.showUpdate=true
    }
   
    console.log(this.selectedChild[this.childCount].subChild);
  }

  getProductForm(name,keyup){
    let time=0;
    keyup && (time=300)
    clearInterval(this.timer)
    this.timer=setTimeout(()=>{
    
    const params={
      name:name,
      page:keyup?0:this.page
    }
   this.productMaster.getAllProductForms(params).subscribe(
     response => {
       if(keyup){
        this.productForm.data=[];
        this.productFormdata=[];
       }
          this.productForm.data.push(...response.data.t)
           this.productFormdata=[...response.data.t]
     },
     error => {
    
       this.popupService.closeProgress()
       this.popupService.openError(error.error.error.error);
     }
   )   
    },time)
  }

  searchForm(event,keyup,index){
    if(keyup){
      this.page=0
    }
    console.log(  this.selectedChild[this.childCount].subChild,index);
    
    this.selectedChild[this.childCount].subChild[index].form.showOptions=true
    this.getProductForm(event.target.value,keyup)   
  }
  closeSubChilds(type,index){
       setTimeout((x)=>{
        this.selectedChild[this.childCount].subChild[index][type].showOptions=false
       },200)
  }
  productFormChange(event,index,update){
    this.selectedChild[this.childCount].subChild[index].form.showOptions=false
    let operationType="CREATE";
    event.value=Object.assign({},event.value)
    this.productForm.selectedData=event.value;

   if(this.data.data.type==="APPROVE" && update && this.selectedChild[this.childCount].subChild[index].response.dataInteger){
     operationType="UPDATE"
     this.selectedChild[this.childCount].subChild[index].form.inpValue=event.value;
     this.selectedChild[this.childCount].subChild[index].form.edited=true;
     this.selectedChild[this.childCount].subChild[index].form['updatedResponse']={
      id:this.form_variable+this.childCount+index+'_n',
       type:'FORM',
       parentRequestId:this.selectedChild[this.childCount].subChild[index].response.id,
       dataInteger:event.value.id,
       dataString:event.value.t,
       operationType:operationType,
       entryType:this.selectedChild[this.childCount].response.entryType,
       timeStamp:new Date().getTime()
      }
   }else{
    this.selectedChild[this.childCount].subChild[index].form={
      inpValue:event.value,
      edited:false,
      error:false,
      showOptions:false,
      verifyResponse:Object.assign({},this.updateResp),
      updatedResponse:null,
      response:{
      id:this.form_variable+this.childCount+index,
       type:'FORM',
       parentRequestId:this.selectedChild[this.childCount].subChild[index].response.id,
       dataInteger:event.value.id,
       dataString:event.value.t,
       operationType:operationType,
       entryType:this.selectedChild[this.childCount].response.entryType,
       timeStamp:new Date().getTime()
      }
   }
   }     
     
   if(this.data.data.type=='VERIFY' && update){
     this.selectedChild[this.childCount].subChild[index].form.verifyResponse.showUpdate=true
   }
     console.log(this.selectedChild[this.childCount].subChild);
  }

  getProductUnit(name,keyup){
    const params={
      name:name,
      page:this.page
    }
   this.productMaster.getAllProductUnits(params).subscribe(
     response => {
       if(keyup){
        this.productUnits.data=[];
        this.productUnitdata=[];
       }
          this.productUnits.data.push(...response.data.t)
          this.productUnitdata=[...response.data.t]
     },
     error => {
    
       this.popupService.closeProgress()
       this.popupService.openError(error.error.error.error);
     }
   )   
  }
  searchUnit(event,keyup,index){
  
    if(keyup){
      this.page=0
    }    
    this.selectedChild[this.childCount].subChild[index].unit.showOptions=true
    this.getProductUnit(event.target.value,keyup)   
}
  
  productUnitChange(event,index,update){
    let operationType="CREATE";
    event.value=Object.assign({},event.value)
    if(this.data.data.type==="APPROVE" && update && this.selectedChild[this.childCount].subChild[index].response.dataInteger){
      operationType="UPDATE"
      this.selectedChild[this.childCount].subChild[index].unit.inpValue=event.value;
      this.selectedChild[this.childCount].subChild[index].unit.edited=true;
      this.selectedChild[this.childCount].subChild[index].unit['updatedResponse']={
       id:this.unit_variable+this.childCount+index+'_n',
        type:'PACK_UNIT',
        parentRequestId:this.selectedChild[this.childCount].subChild[index].response.id,
        dataInteger:event.value.id,
        dataString:event.value.t,
        operationType:operationType,
        entryType:this.selectedChild[this.childCount].response.entryType,
        timeStamp:new Date().getTime()
       }
    }else{
      this.productUnits.selectedData=event.value;
      this.selectedChild[this.childCount].subChild[index].unit={
        inpValue:event.value,
        edited:false,
        error:false,
        showOptions:false,
        verifyResponse:Object.assign({},this.updateResp),
        updatedResponse:null,
        response:{
       id:this.unit_variable+this.childCount+index,
        type:'PACK_UNIT',
        parentRequestId:this.selectedChild[this.childCount].subChild[index].response.id,
        dataInteger:event.value.id,
        dataString:event.value.t,
        operationType:operationType,
        entryType:this.selectedChild[this.childCount].response.entryType,
        timeStamp:new Date().getTime()
        }
      
    }
    }
     
    if(this.data.data.type=='VERIFY' && update){
      this.selectedChild[this.childCount].subChild[index].unit.verifyResponse.showUpdate=true
    }
    console.log(this.selectedChild[this.childCount].subChild);
  }




  nextPage(type){
    this.page=+this.page+1;
    if(type==='composition'){
      this.showOptionsToggle=true
      this.searchComposition(true)      
    }else if(type==='manufacture'){
      this.showOptionsToggle=true
    this.searchManufacture(true)
    }else if(type==='marketing'){
      this.showOptionsToggle=true
      this.searchMarketing(true)
    }else if(type==='sideEffects'){
      this.getAllSideEffects(true)
    }else if(type==='caution'){
      this.getAllCautions(true)
    }else if(type==='disease'){
      this.getAllDiseases(true)
    }
  }

  previousPage(type){
    this.page=+this.page-1;
    if(type==='composition'){
      this.showOptionsToggle=true
      this.searchComposition(true)
    }else if(type==='manufacture'){
      this.showOptionsToggle=true
      this.searchManufacture(true)
  }else if(type==='marketing'){
    this.showOptionsToggle=true
    this.searchMarketing(true)
  }else if(type==='sideEffects'){
    this.getAllSideEffects(true)
  }else if(type==='caution'){
    this.getAllCautions(true)
  }else if(type==='disease'){
    this.getAllDiseases(true)
  }
  }

  pageChange(event,type){
    this.page=+event.target.value
    if(type==='composition'){
      this.showOptionsToggle=true
        this.searchComposition(true)
        console.log(this.showOptionsToggle);
        
    }else if(type==='manufacture'){
      this.showOptionsToggle=true
      this.searchManufacture(true)
  }else if(type==='marketing'){
    this.showOptionsToggle=true
    this.searchMarketing(true)
  }else if(type==='sideEffects'){
    this.getAllSideEffects(true)
  }else if(type==='caution'){
    this.getAllCautions(true)
  }
  else if(type==='disease'){
    this.getAllDiseases(true)
  }
  }


  removeSubChild(index){
    this.selectedChild[this.childCount].subChild.splice(index,1)

   }
  addSubChild(){
   console.log( this.subChildCountFromResponse,this.selectedChild[this.childCount].subChild.length+1);
      this.subChildCreated++;
    if(this.data.data.type=="MAP"){this.finalButtonStatus="MAP"}
    let selected=true;
    if(this.data.data.type=='MAP' || this.data.data.request==='APPROVE_MAP'){
      selected=false;
    }
    this.selectedChild[this.childCount].subChild.push({
      selected:selected,
      edited:false,
      error:false,
      medicineId:null,
      response:{ id:this.subchild_variable+this.childCount+this.subChildCreated,
        type:'PRODUCT_SUB_CHILD',
        parentRequestId:this.selectedChild[this.childCount].response.id,
        dataInteger:null,
        dataString:'',
        operationType:'CREATE',
        entryType:this.entry_type,
        timeStamp:new Date().getTime()
      },
        form:{
          inpValue:{id:'',t:''},
          edited:false,
          error:false,
          showOptions:false,
          verifyResponse:Object.assign({},this.updateResp),
          updatedResponse:null,
          response:{
          id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
          }
        },
        type:{
          inpValue:{id:'',t:''},
          error:false,
          edited:false,
          showOptions:false,
          verifyResponse:Object.assign({},this.updateResp),
          updatedResponse:null,
          response:{
          id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
          }
        },
        size:{
          inpValue:'',
          edited:false,
          verifyResponse:Object.assign({},this.updateResp),
          error:false,
          response:{
          id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
          }
        },
        unit:{ 
          inpValue:{id:'',t:''},
          error:false,
          edited:false,
          showOptions:false,
          verifyResponse:Object.assign({},this.updateResp),
          updatedResponse:null,
          response:{
          id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
          }
        }
    
      
    })
  }

  cancel() {
    this.dialogRef.close(true);
  }



  parentCreate(){
    let existParentCheck=false;
    this.selectedParent.data.forEach((parent)=>{
        let checkString=parent.t.toLowerCase().trim()
        let searchString=this.selectedParent.searchString.toLowerCase().trim()
        console.log(','+checkString+','+searchString);
        
          if(checkString==searchString){
              this.popupService.openError(`Parent already exist with name ${this.selectedParent.searchString}`);
              existParentCheck=true;
          }
    })

  if(!existParentCheck){
    this.showParentMedOptions=false;
    this.showChildMedOptions=false;
    this.deletedParentResponse.push(this.selectedParent.response)
    
    if(this.selectedParent.parentStatusLive){
     this.clearAllChildFields(false);
    this.clearAllParentFields();
    this.getCategories();
     this.getSuperGroups('',false);
      this.getScheduleDrugs();
    }
    this.childByParent=[];
  this.childByParentCopy=[];
    this.selectedParent.createdParent=true;
    this.selectedParent.parentStatusLive=false;
    this.parentChange();
    //   this.getProductUnit();
    //   this.getProductForm();
    //   this.getProductTypes();
      this.selectedChild[this.childCount].subChild[0].response.parentRequestId=this.selectedChild[this.childCount].response.id
  }

    
  }

  parentChange(){
    let operationType="CREATE";
    let id=this.selectedParent.response.dataInteger; 
  
  if(this.selectedParent.createdParent){
     this.selectedParent.newParent=this.selectedParent.searchString;

     this.selectedParent.response={
      id:'1',
      type:'PRODUCT_PARENT',
      parentRequestId:null,
      dataInteger:null,
      dataString:this.selectedParent.searchString.trim(),
      operationType:operationType,
      entryType:this.entry_type,
      timeStamp:new Date().getTime()
     }
     this.selectedParent['updatedResponse']={
      id:'2',
      type:'PRODUCT_PARENT_NAME',
      parentRequestId:'1',
      dataInteger:null,
      dataString:this.selectedParent.searchString.trim(),
      operationType:"CREATE",
      entryType:this.entry_type,
      timeStamp:new Date().getTime()
     }
        
   }

   if(this.data.data.type==="APPROVE"){
    this.selectedParent['updatedResponse'].operationType="UPDATE"
  } 
  }

  parentNameUpdate(){
    let existParentCheck=false;
    this.selectedParent.data.forEach((parent)=>{
      let checkString=parent.t.toLowerCase().trim()
      let searchString=this.selectedParent.searchString.toLowerCase().trim()
          if(checkString==searchString){
              this.popupService.openError(`Parent already exist with name ${this.selectedParent.searchString}`);
              existParentCheck=true;
          }
    })
   if(!existParentCheck){
    let operationType="CREATE";
    this.showParentMedOptions=false;
    this.showChildMedOptions=false;
    if(this.data.data.type==="APPROVE"){operationType="UPDATE"} 
     this.selectedParent['updatedResponse']={
       id:'2',
       type:'PRODUCT_PARENT_NAME',
       parentRequestId:'1',
       dataInteger:null,
       dataString:this.selectedParent.searchString.trim(),
       operationType:operationType,
       entryType:this.entry_type,
       timeStamp:new Date().getTime()
      }
   } 

  
  }

  getRequestCreationData(verifiedData,progress){
    let params;
    let method;
    let childCount;
   if(verifiedData){
     params={
      parentId:this.data.data.parentId,
      type:this.data.data.mappingType
    }
    method=this.productMaster.getFullParentDataById(params)

   }else{
     params={
      bridgeId:this.data.data.bridgeId,
      type:this.data.data.mappingType
    }
    method=this.productMaster.getToBeCreatedMedData(params)
   } 
    
    let operationType="CREATE"
    if(progress){
      this.popupService.openProgress()
    }
    this.superGroupExpand=false;

    method.subscribe(
      response=>{
        this.oldItemCode=response.data.itemCode
        if(progress){
          this.popupService.closeProgress()
        }
        let resp
        if(verifiedData){
             resp=response.data
            
        }else{
          resp=response.data.productMasterMedicineResponse
        }
        this.hashCode=response.data.hasCode
        this.selectedParent.inpValue['parentName']=resp.parentName
        if(resp.status==="LIVE"){
         this.selectedParent.parentStatusLive=true;
        }

                
        this.showParentMedOptions=false;
        this.selectedParent.createdParent=false;
        this.selectedParent.response={
          id:'1',
          type:'PRODUCT_PARENT',
          parentRequestId:null,
          dataInteger:resp.id,
          dataString:resp.parentName,
          operationType:operationType,
          entryType:resp.status,
          timeStamp:new Date().getTime()
         }


         this.selectManufacture(resp.manufacturer,false);  
         let categoryIndex
         this.category.data.map((x,i)=>{if(x.id==resp.category.id){categoryIndex=i}})  
         
         this.category.data.splice(categoryIndex,1)              
         this.category.data=[resp.category,...this.category.data];  
         console.log('test',this.category,categoryIndex,resp.category);
                         
         this.categoryChange({value:resp.category},false);
         this.selectMarketing(resp.marketedId,false);
         this.descriptionChange({target:{value:resp.description}},false)
         let childData=resp.childMedicineResponseDTOList;
         childData.forEach((child,i)=>{
           console.log(this.selectedChild,child);
           
          if(i<childData.length && i>0){
                this.addChild();
          }
       
          this.selectedChild[this.childCount].superGroup.data=[...this.superGroupResponseData]
   
          this.selectedChild[this.childCount].inpValue['t']=child.childName;
          this.selectedChild[this.childCount].newChild=child.childName;
          this.selectedChild[this.childCount].searchString=child.childName;
          this.selectedChild[this.childCount]['childFromResponse']=true;
          if(child.status==="LIVE"){
            this.selectedChild[this.childCount].createdChild=false;
            this.selectedChild[this.childCount].childStatusLive=true;
          }else{
            this.selectedChild[this.childCount].createdChild=true;
            this.selectedChild[this.childCount].childStatusLive=false;

          }
          let childId= +this.childCount+1;
          this.selectedChild[this.childCount].response={
            id:this.child_variable+childId,
            type:'PRODUCT_CHILD',
            parentRequestId:this.selectedParent.response.id,
            dataInteger:child.id,
            dataString:child.childName,
            operationType:operationType,
            entryType:child.status,
            timeStamp:new Date().getTime()
          }
          // this.selectedChild[this.childCount].scheduleDrug.data=[child.scheduleDrug]
          let scheduleDrugIndex;
        this.selectedChild[this.childCount].scheduleDrug.data=[...this.scheduleDrugData]
        this.selectedChild[this.childCount].scheduleDrug.data.map((x,i)=>{if(x.id==child.scheduleDrug.id){scheduleDrugIndex=i}})  
        this.selectedChild[this.childCount].scheduleDrug.data.splice(scheduleDrugIndex,1)              
        this.selectedChild[this.childCount].scheduleDrug.data=[...this.selectedChild[this.childCount].scheduleDrug.data,child.scheduleDrug];
        this.selectComposition(child.composition,false)         
         this.scheduleDrugChange({value:child.scheduleDrug},false)
         console.log(this.childCount,this.selectedChild[this.childCount]);

        //  this.selectedChild[this.childCount].superGroup.data=[];
         this.selectedChild[this.childCount].superGroup.inpValue=[];
         this.selectedChild[this.childCount].groups.response=[];
         this.subChildCountFromResponse= child.productMasterSubChildResponseList.length
         let sprGroupData=[];
         child.superGroupWithGroupResponseList.forEach((item,i)=>{
         
          sprGroupData.push({
                "id":item.superGroupId,
                  "superGroup":item.superGroup,
                  "t":item.superGroup,
                  group:{id:item.superGroupGroupMappingId,group:item.group}
              })
     
              this.selectedChild[this.childCount].groups.response[i]={ id:this.group_variable+item.superGroupGroupMappingId,
                type:'SUPER_GROUP_GROUP',
                superGroupId:item.superGroupId,
                parentRequestId:this.selectedChild[this.childCount].response.id,
                dataInteger:item.superGroupGroupMappingId,
                dataString:item.group,
                operationType:this.create_operation,
                entryType:response.data.status,
                timeStamp:new Date().getTime()
              }
         })
         this.getSelectedGroups('');
         
        //  this.selectedChild[this.childCount].superGroup.data=sprGroupData;
         this.selectedChild[this.childCount].superGroup.inpValue=sprGroupData;        
         this.sprGrp.setValue(sprGroupData)
         if(sprGroupData.length>0){
          this.superGroupExpand=true;
         }else{
          this.superGroupExpand=false
         }
         if(child.dpco===true){
          this.dpcoChange({value:1},false)
         
         }else if(child.dpco===false){
          this.dpcoChange({value:0},false)

         }else{
          this.dpcoChange({value:'NOT_SPECIFIED'},false)
         }
         
         this.atcnChange({target:{value:child.atcnCode}},false)
         this.indicationChange({target:{value:child.indicators}},false)
         this.contraChange({target:{value:child.contraIndicators}},false)
         this.selectedChild[this.childCount].sideEffects.selectedData=child.sideEffectList
         child.sideEffectList.forEach((item)=>{
          this.selectedSideEffect({'option':{'value':item}},false)
         })
         this.selectedChild[this.childCount].cautions.selectedData=child.cautionsList
         child.cautionsList.forEach((item)=>{
          this.selectedCaution({'option':{'value':item}},false)
         })
         this.selectedChild[this.childCount].diseases.selectedData=child.diseasesList  
         child.diseasesList.forEach((item)=>{
          this.selectedDisease({'option':{'value':item}},false)
         })
         child.productMasterSubChildResponseList.forEach((item,i)=>{
          //  this.selectedChild[this.childCount].subChild[i].form.inpValue=item.form
          if(i<child.productMasterSubChildResponseList.length && i>0){
            this.addSubChild();
            
          }
          this.selectedChild[this.childCount].subChild[i].medicineId=item.medicineId
           this.selectedChild[this.childCount].subChild[i].response.parentRequestId=this.selectedChild[this.childCount].response.id;
           this.selectedChild[this.childCount].subChild[i].response.dataInteger=item.id;
           this.selectedChild[this.childCount].subChild[i].response.entryType= item.status;
           this.selectedChild[this.childCount].subChild[i].response.type="PRODUCT_SUB_CHILD";
           this.selectedChild[this.childCount].subChild[i].response.operationType="CREATE";
           this.selectedChild[this.childCount].subChild[i].response.timeStamp=new Date().getTime();
            if(this.data.data.type=='APPROVE'){
                 if(item.mapped){
                  this.selectedChild[this.childCount].subChild[i].selected=true
                 }else{
                  this.selectedChild[this.childCount].subChild[i].selected=false
                 }
            }
            // this.selectedChild[this.childCount].subChild[i]['subChildFromResponse']=true
            // this.productForm.data.forEach((form)=>{
            //   if(form.id==item.form.id){
                this.productFormChange({value:item.form},i,false)
            //   }
            // })                       
          //  this.productType.data.forEach((type)=>{
          //    if(type.id==item.type.id){
              this.productTypeChange({value:item.type},i,false)
          //    }
          //  })
          // this.productUnits.data.forEach((unit)=>{
          //  if(unit.id==item.unit.id){
          this.productUnitChange({value:item.unit},i,false)
          // }
          // })
          this.sizeChange({target:{value:item.size}},i,false)

         })
          
        })

       

      },error=>{
        this.popupService.closeProgress()

        this.popupService.openError(error.error.data.localizedMessage)          
      }
    )
    
  }

  selectParentMed(option){
    const params={
      parentId:option.id
    }
    let operationType="CREATE"
    this.selectedParent.loader=true;
    this.selectedParent.createdParent=false;
    this.productMaster.getParentsById(params).subscribe(
      response=>{
         if(this.data.data.type==="APPROVE" || this.data.data.type==="TO_BE_CREATE"){
           this.deletedParentResponse.push(this.selectedParent.response);
          operationType="CREATE"
          this.selectedParent.edited=true;
        }
         this.selectedParent.inpValue=response.data
         this.selectedParent.inpValue.parentName=this.selectedParent.inpValue.parentName.toUpperCase()
         if(response.data.status==="LIVE"){
          this.selectedParent.parentStatusLive=true;
         }
                 
         this.showParentMedOptions=false;
         this.selectedParent.createdParent=false;
         this.selectedParent.response={
           id:'1',
           type:'PRODUCT_PARENT',
           parentRequestId:null,
           dataInteger:option.id,
           dataString:option.t,
           operationType:operationType,
           entryType:response.data.status,
           timeStamp:new Date().getTime()
          }
          this.selectManufacture(response.data.manufacturer,false);
         this.data.data.type!=='VERIFY'&& (this.category.data=[response.data.category])
         if(this.data.data.type==="VERIFY"){
           let categoryIndex;
          this.category.data.map((x,i)=>{if(x.id==response.data.category.id){categoryIndex=i}})  
         
          this.category.data.splice(categoryIndex,1)              
          this.category.data=[response.data.category,...this.category.data]; 
         }
          this.categoryChange({value:response.data.category},false);
          this.selectMarketing(response.data.marketedId,false);
          this.descriptionChange({target:{value:response.data.description}},false)
          // this.category.inpValue=response.data.category
          this.clearAllChildFields(false);
          // this.getCategories();
          this.getSuperGroups('',false);
          this.getScheduleDrugs();
          this.getProductUnit('',false);
          this.getProductForm('',false);
          this.getProductTypes('',false);
          this.selectedParent.loader=false;
          this.showChildMedOptions=false;
      },error=>{
        this.selectedParent.loader=false;
        this.popupService.openError(error.error.error.error);
      }
    )
   

     console.log(this.selectedParent.response)
    
 }

 childCreate(){
  
  let errorChildCreate=false;
  this.childByParent.forEach((child)=>{
       if(child.t.toLowerCase().trim()==this.selectedChild[this.childCount].response.dataString.toLowerCase().trim()){
         errorChildCreate=true
         this.popupService.openError(`'${child.t}' already exists`)
         this.selectedChild[this.childCount].response.dataString=this.selectedChild[this.childCount].newChild
        //  this.clearAllChildFields(true)
       }
  })
  this.showChildMedOptions=false;
  if(!errorChildCreate){
    if(this.selectedChild[this.childCount].response.entryType==="LIVE"){
      this.clearAllChildFields(true);
        this.getSuperGroups('',false);
        this.getScheduleDrugs();
        //  this.getProductUnit();
        //  this.getProductForm();
        //  this.getProductTypes();
    }
    this.selectedChild[this.childCount].createdChild=true;
    this.childChange();
  }
 
  

 }
 closeParentMedOptions(){
  this.showParentMedOptions=false;
   this.selectedParent.inpValue.parentName=this.selectedParent.response.dataString;
 }
 childChange(){
   let operationType="CREATE";
   let id=null;
   let childId= +this.childCount+1;
   id=this.selectedChild[this.childCount].response.id
  
   
  if(this.selectedChild[this.childCount].createdChild){
    this.selectedChild[this.childCount].newChild=this.selectedChild[this.childCount].searchString.trim();
    this.selectedChild[this.childCount].response={
     id:this.child_variable+childId,
     type:'PRODUCT_CHILD',
     parentRequestId:this.selectedParent.response.id,
     dataInteger:null,
     dataString:this.selectedChild[this.childCount].newChild,
     operationType:operationType,
     entryType:'TEMP',
     timeStamp:new Date().getTime()
    }

    this.selectedChild[this.childCount]['updatedResponse']={
      id:this.child_variable_name+childId,
      type:'PRODUCT_CHILD_NAME',
      parentRequestId:this.child_variable+childId,
      dataInteger:null,
      dataString:this.selectedChild[this.childCount].newChild,
      operationType:operationType,
      entryType:'TEMP',
      timeStamp:new Date().getTime()
     }
    console.log(this.selectedChild[this.childCount])
    
  }

  // if(this.data.data.type==="APPROVE"){
      
  //   this.selectedChild[this.childCount]['updatedResponse'].operationType="UPDATE"
  //  }
 }
 updateChildName(){
  let errorChildCreate=false;
  this.childByParent.forEach((child)=>{
       if(child.t.toLowerCase()==this.selectedChild[this.childCount].response.dataString.toLowerCase()){
         errorChildCreate=true
         this.popupService.openError(`'${child.t}' already exists`)
         this.selectedChild[this.childCount].response.dataString=this.selectedChild[this.childCount].newChild
       }
  })
  if(!errorChildCreate){
    this.showChildMedOptions=false;
   this.selectedChild[this.childCount].newChild=this.selectedChild[this.childCount].response.dataString
    this.selectedChild[this.childCount]['updatedResponse']={
      id:this.child_variable_name+this.childCount+'_n',
      type:'PRODUCT_CHILD_NAME',
      parentRequestId:this.selectedChild[this.childCount].response.id,
      dataInteger:null,
      dataString:this.selectedChild[this.childCount].searchString,
      operationType:'UPDATE',
      entryType:'TEMP',
      timeStamp:new Date().getTime()
     }
  }
  
 }

 selectChildMed(option){
  let operationType="CREATE";
  this.childSelectedVerified=option
  if(this.data.data.type==="APPROVE" || this.data.data.type==="TO_BE_CREATE"){
     if(this.selectedChild[this.childCount].response.dataInteger){
      operationType="UPDATE";
     }
   
    this.selectedChild[this.childCount]['deletedResponse']=this.selectedChild[this.childCount].response

    this.selectedChild[this.childCount]['deletedResponse'].operationType="DELETE"
    this.selectedChild[this.childCount]['deletedResponse'].parentRequestId=this.selectedChild[this.childCount].response.id
  }
  this.clearAllChildFields(true);
  // this.getCategories();
  this.getSuperGroups('',false);
   this.getScheduleDrugs();
  //  this.getProductUnit();
  //  this.getProductForm();
  //  this.getProductTypes();
  this.selectedChild[this.childCount].loader=true;
  this.selectedChild[this.childCount].inpValue=option;
  this.selectedChild[this.childCount].searchString=option.t;
  this.selectedChild[this.childCount].newChild=option.t;
  this.selectedChild[this.childCount].createdChild=false;
  let childId= +this.childCount+1;
  
  this.selectedChild[this.childCount].response={
    id:this.child_variable+childId,
    type:'PRODUCT_CHILD',
    parentRequestId:this.selectedParent.response.id,
    dataInteger:option.id,
    dataString:option.t.toUpperCase(),
    operationType:operationType,
    entryType:this.entry_type,
    timeStamp:new Date().getTime()
  }
  
  const params={
    childId:option.id
  }
  this.productMaster.getChildById(params).subscribe(
    response=>{
      this.selectedChild[this.childCount].response.entryType=response.data.status
         if(response.data.status==="LIVE"){
          this.selectedChild[this.childCount].childStatusLive=true;
         }

         this.selectedChild[this.childCount].scheduleDrug.data=[response.data.scheduleDrug]
         this.selectComposition(response.data.composition,false)
         this.scheduleDrugChange({value:response.data.scheduleDrug},false)
        //  this.selectedChild[this.childCount].superGroup.data=[];
         this.selectedChild[this.childCount].superGroup.inpValue=[];
         this.selectedChild[this.childCount].groups.response=[];
         this.subChildCountFromResponse= response.data.productMasterSubChildResponseList.length
         let sprGroupData=[];
         response.data.superGroupWithGroupResponseList.forEach((item,i)=>{
         
          sprGroupData.push({
                "id":item.superGroupId,
                  "superGroup":item.superGroup,
                  "t":item.superGroup,
                  group:{id:item.superGroupGroupMappingId,group:item.group}
              })
     
              this.selectedChild[this.childCount].groups.response[i]={ id:this.group_variable+item.superGroupGroupMappingId,
                type:'SUPER_GROUP_GROUP',
                superGroupId:item.superGroupId,
                parentRequestId:this.selectedChild[this.childCount].response.id,
                dataInteger:item.superGroupGroupMappingId,
                dataString:item.group,
                operationType:this.create_operation,
                entryType:response.data.status,
                timeStamp:new Date().getTime()
              }
         })
         this.getSelectedGroups('');
        //  this.selectedChild[this.childCount].superGroup.data=sprGroupData;
         this.selectedChild[this.childCount].superGroup.inpValue=sprGroupData;        
         this.sprGrp.setValue(sprGroupData)
         if(sprGroupData.length>0){
           this.superGroupExpand=true;
         }else{
          this.superGroupExpand=false;
         }
         if(response.data.dpco===true){
          this.dpcoChange({value:1},false)
         
         }else if(response.data.dpco===false){
          this.dpcoChange({value:0},false)

         }else{
          this.dpcoChange({value:'NOT_SPECIFIED'},false)
         }
         this.atcnChange({target:{value:response.data.atcnCode}},false)
         this.indicationChange({target:{value:response.data.indicators}},false)
         this.contraChange({target:{value:response.data.contraIndicators}},false)
         this.selectedChild[this.childCount].sideEffects.selectedData=response.data.sideEffectList
         response.data.sideEffectList.forEach((item)=>{
          this.selectedSideEffect({'option':{'value':item}},false)
         })
         this.selectedChild[this.childCount].cautions.selectedData=response.data.cautionsList
         response.data.cautionsList.forEach((item)=>{
          this.selectedCaution({'option':{'value':item}},false)
         })
         this.selectedChild[this.childCount].diseases.selectedData=response.data.diseasesList 
         response.data.diseasesList.forEach((item)=>{
          this.selectedDisease({'option':{'value':item}},false)
         })        
         response.data.productMasterSubChildResponseList.forEach((item,i)=>{
            //  this.selectedChild[this.childCount].subChild[i].form.inpValue=item.form
            if(i<response.data.productMasterSubChildResponseList.length && i>0){
              this.addSubChild();
              this.selectedChild[this.childCount].subChild[i].medicineId=item.medicineId
              this.selectedChild[this.childCount].subChild[i].selected=false;
              this.selectedChild[this.childCount].subChild[i].response.parentRequestId=this.selectedChild[this.childCount].response.id;
              this.selectedChild[this.childCount].subChild[i].response.dataInteger= response.data.productMasterSubChildResponseList[i].id;
              this.selectedChild[this.childCount].subChild[i].response.entryType= response.data.productMasterSubChildResponseList[i].status;
              this.selectedChild[this.childCount].subChild[i].response.timeStamp=new Date().getTime();
              this.selectedChild[this.childCount].subChild[i].response.type="PRODUCT_SUB_CHILD";
            }else{
              this.selectedChild[this.childCount].subChild[i].medicineId=item.medicineId
             this.selectedChild[this.childCount].subChild[0].response.parentRequestId=this.selectedChild[this.childCount].response.id;
             this.selectedChild[this.childCount].subChild[0].response.dataInteger= response.data.productMasterSubChildResponseList[0].id;
             this.selectedChild[this.childCount].subChild[0].response.entryType= response.data.productMasterSubChildResponseList[0].status;
             this.selectedChild[this.childCount].subChild[0].response.type="PRODUCT_SUB_CHILD";
             this.selectedChild[this.childCount].subChild[0].response.operationType="CREATE";
             this.selectedChild[this.childCount].subChild[0].response.timeStamp=new Date().getTime();
             if(this.data.data.type==='MAP'){
              this.finalButtonStatus="MAP"
             }
             if(this.data.data.type==='CREATE'){
              this.selectedChild[this.childCount].subChild[0].selected=false;          
             }

           }
            // this.productForm.data.forEach((form)=>{
            //         if(form.id==item.form.id){
                      this.productFormChange({value:item.form},i,false)
            //         }
            // })                       
            // this.productType.data.forEach((type)=>{
            //        if(type.id==item.type.id){
                    this.productTypeChange({value:item.type},i,false)
            //        }
            // })
            // this.productUnits.data.forEach((unit)=>{
            //   if(unit.id==item.unit.id){
                this.productUnitChange({value:item.unit},i,false)
            //   }
            // })
            this.sizeChange({target:{value:item.size}},i,false)
          
         })

         this.selectedChild[this.childCount].loader=false;
         this.showChildMedOptions=false;
         console.log(this.selectedChild);
         
    },
    error=>{
      this.selectedParent.loader=false
      this.popupService.openError(error.data.localizedMessage);
    }
    
  )
  
  
}


descriptionChange(event,updated){
    this.description.inpValue=event.target.value;
    let operationType="CREATE";
    if(this.data.data.type==="APPROVE" && updated){
      operationType="UPDATE"
      this.description['updatedResponse']={
        id:(this.description_variable+1)+'_n',
        type:'PRODUCT_PARENT_DESCRIPTION',
        parentRequestId:this.selectedParent.response.id,
        dataInteger:null,
        dataString:event.target.value,
        operationType:operationType,
        entryType:this.selectedParent.response.entryType,
        timeStamp:new Date().getTime()
      }
    }else{
      this.description.response={
        id:this.description_variable+1,
        type:'PRODUCT_PARENT_DESCRIPTION',
        parentRequestId:this.selectedParent.response.id,
        dataInteger:null,
        dataString:event.target.value,
        operationType:operationType,
        entryType:this.selectedParent.response.entryType,
        timeStamp:new Date().getTime()
      }
    }

    if(this.data.data.type==='VERIFY' && updated){
      this.descriptionUpdate.showUpdate=true;
    }else{
      this.descriptionUpdate.showUpdate=false;
    } 
    
    console.log(this.description.inpValue);
    

}

dpcoChange(event,update){
    let operationType="CREATE";
   
    this.selectedChild[this.childCount].dpco.inpValue=event.value;
     if(this.data.data.type==="APPROVE" && update){
       if(this.selectedChild[this.childCount].response.dataInteger){
        operationType="UPDATE";
        this.selectedChild[this.childCount].dpco['updatedResponse']={ id:this.dpco_variable+1+'_n',
         type:'DPCO',
         parentRequestId: this.selectedChild[this.childCount].response.id,
         dataInteger:event.value==='NOT_SPECIFIED'?null:event.value,
         dataString:event.value==='NOT_SPECIFIED'?null:event.value,
         operationType:operationType,
         entryType:this.selectedChild[this.childCount].response.entryType,
         timeStamp:new Date().getTime()
       }
       
       }else{
         
        this.selectedChild[this.childCount].dpco.response={ id:this.dpco_variable+1,
          type:'DPCO',
          parentRequestId:this.selectedChild[this.childCount].response.id,
          dataInteger:event.value==='NOT_SPECIFIED'?null:event.value,
          dataString:event.value==='NOT_SPECIFIED'?null:event.value,
          operationType:operationType,
          entryType:this.selectedChild[this.childCount].response.entryType,
          timeStamp:new Date().getTime()
        }
       }
      
     }else{
      this.selectedChild[this.childCount].dpco.response={ id:this.dpco_variable+1,
        type:'DPCO',
        parentRequestId:this.selectedChild[this.childCount].response.id,
        dataInteger:event.value==='NOT_SPECIFIED'?null:event.value,
        dataString:event.value==='NOT_SPECIFIED'?null:event.value,
        operationType:operationType,
        entryType:this.selectedChild[this.childCount].response.entryType,
        timeStamp:new Date().getTime()
      }
     }


     if(this.data.data.type==='VERIFY' && update){
          this.selectedChild[this.childCount].dpco.verifyResponse.showUpdate=true;
     }else{
      this.selectedChild[this.childCount].dpco.verifyResponse.showUpdate=false;
     }

}

atcnChange(event,update){
   let operationType="CREATE";
   this.selectedChild[this.childCount].atcn.inpValue=event.target.value;
   if(this.data.data.type==="APPROVE" && update){
     operationType="UPDATE";
      if(this.selectedChild[this.childCount]['childFromResponse']){
        this.selectedChild[this.childCount].atcn.response={ id:this.atcn_variable+this.childCount+1,
          type:'ATCN_CODE',
          parentRequestId: this.selectedChild[this.childCount].response.id,
          dataInteger:null,
          dataString:event.target.value,
          operationType:operationType,
          entryType:this.selectedChild[this.childCount].response.entryType,
          timeStamp:new Date().getTime()
        }  
      }else{
        this.selectedChild[this.childCount].atcn.response={ id:this.atcn_variable+this.childCount+1,
          type:'ATCN_CODE',
          parentRequestId:this.selectedChild[this.childCount].response.id,
          dataInteger:null,
          dataString:event.target.value,
          operationType:'CREATE',
          entryType:this.selectedChild[this.childCount].response.entryType,
          timeStamp:new Date().getTime()
        }
      }
   }else{
    this.selectedChild[this.childCount].atcn.response={ id:this.atcn_variable+this.childCount+1,
      type:'ATCN_CODE',
      parentRequestId:this.selectedChild[this.childCount].response.id,
      dataInteger:null,
      dataString:event.target.value,
      operationType:operationType,
      entryType:this.selectedChild[this.childCount].response.entryType,
      timeStamp:new Date().getTime()
    }
   }
  
  if(this.data.data.type==='VERIFY' && update){
    this.selectedChild[this.childCount].atcn.verifyResponse.showUpdate=true
  }else{
    this.selectedChild[this.childCount].atcn.verifyResponse.showUpdate=false
  }
  console.log(this.selectedChild[this.childCount].atcn.response)
}

indicationChange(event,update){
  let operationType="CREATE";
  this.selectedChild[this.childCount].indication.inpValue=event.target.value;

   if(this.data.data.type==="APPROVE" && update){
     if( this.selectedChild[this.childCount]['childFromResponse']){
      operationType="UPDATE";
      this.selectedChild[this.childCount].indication.response={ id:this.indication_variable+this.childCount+1+'_n',
       type:'INDICATION',
       parentRequestId:this.selectedChild[this.childCount].response.id,
       dataInteger:null,
       dataString:event.target.value,
       operationType:operationType,
       entryType:this.selectedChild[this.childCount].response.entryType,
       timeStamp:new Date().getTime()
     }
     }else{
      this.selectedChild[this.childCount].indication.response={ id:this.indication_variable+this.childCount+1,
        type:'INDICATION',
        parentRequestId:this.selectedChild[this.childCount].response.id,
        dataInteger:null,
        dataString:event.target.value,
        operationType:operationType,
        entryType:this.selectedChild[this.childCount].response.entryType,
        timeStamp:new Date().getTime()
      }
     }
    
   }else{
    this.selectedChild[this.childCount].indication.response={ id:this.indication_variable+this.childCount+1,
      type:'INDICATION',
      parentRequestId:this.selectedChild[this.childCount].response.id,
      dataInteger:null,
      dataString:event.target.value,
      operationType:operationType,
      entryType:this.selectedChild[this.childCount].response.entryType,
      timeStamp:new Date().getTime()
    }
   }

   if(this.data.data.type==='VERIFY' && update){
     this.selectedChild[this.childCount].indication.verifyResponse.showUpdate=true
   }else{
    this.selectedChild[this.childCount].indication.verifyResponse.showUpdate=false

   }
  
  console.log(this.selectedChild[this.childCount].indication.response)
}
contraChange(event,update){
  let operationType="CREATE";
  this.selectedChild[this.childCount].contradiction.inpValue=event.target.value;

   if(this.data.data.type==="APPROVE" && update){
     if(this.selectedChild[this.childCount]['childFromResponse']){
      operationType="UPDATE";
      this.selectedChild[this.childCount].contradiction.response={ id:this.contradiction_variable+this.childCount+1+'_n',
       type:'CONTRAINDICATION',
       parentRequestId: this.selectedChild[this.childCount].response.id,
       dataInteger:null,
       dataString:event.target.value,
       operationType:operationType,
       entryType:this.selectedChild[this.childCount].response.entryType,
       timeStamp:new Date().getTime()
     }
     }else{
      this.selectedChild[this.childCount].contradiction.response={ id:this.contradiction_variable+this.childCount+1,
        type:'CONTRAINDICATION',
        parentRequestId:this.selectedChild[this.childCount].response.id,
        dataInteger:null,
        dataString:event.target.value,
        operationType:operationType,
        entryType:this.selectedChild[this.childCount].response.entryType,
        timeStamp:new Date().getTime()
      }
     }
    
   }else{
    this.selectedChild[this.childCount].contradiction.response={ id:this.contradiction_variable+this.childCount+1,
      type:'CONTRAINDICATION',
      parentRequestId:this.selectedChild[this.childCount].response.id,
      dataInteger:null,
      dataString:event.target.value,
      operationType:operationType,
      entryType:this.selectedChild[this.childCount].response.entryType,
      timeStamp:new Date().getTime()
    }
   }
  
   if(this.data.data.type==='VERIFY' && update){
    this.selectedChild[this.childCount].contradiction.verifyResponse.showUpdate=true
  }else{
   this.selectedChild[this.childCount].contradiction.verifyResponse.showUpdate=false

  }
  console.log(this.selectedChild[this.childCount].contradiction.response)
}
dosageChange(event,update){
  let operationType="CREATE";
  this.selectedChild[this.childCount].dosage.inpValue=event.target.value;

   if(this.data.data.type==="APPROVE" && update){
     if(this.selectedChild[this.childCount]['childFromResponse']){
      operationType="UPDATE";
      this.selectedChild[this.childCount].dosage.response={ id:this.dosage_variable+this.childCount+1+'_n',
       type:'DOSAGE',
       parentRequestId: this.selectedChild[this.childCount].response.id,
       dataInteger:null,
       dataString:event.target.value,
       operationType:operationType,
       entryType:this.selectedChild[this.childCount].response.entryType,
       timeStamp:new Date().getTime()
     }
     }else{
      this.selectedChild[this.childCount].dosage.response={ id:this.dosage_variable+this.childCount+1,
        type:'DOSAGE',
        parentRequestId:this.selectedChild[this.childCount].response.id,
        dataInteger:null,
        dataString:event.target.value,
        operationType:operationType,
        entryType:this.selectedChild[this.childCount].response.entryType,
        timeStamp:new Date().getTime()
      }
     }
    
   }else{
    this.selectedChild[this.childCount].dosage.response={ id:this.dosage_variable+this.childCount+1,
      type:'DOSAGE',
      parentRequestId:this.selectedChild[this.childCount].response.id,
      dataInteger:null,
      dataString:event.target.value,
      operationType:operationType,
      entryType:this.selectedChild[this.childCount].response.entryType,
      timeStamp:new Date().getTime()
    }
   }
  
   if(this.data.data.type==='VERIFY' && update){
    this.selectedChild[this.childCount].dosage.verifyResponse.showUpdate=true
  }else{
   this.selectedChild[this.childCount].dosage.verifyResponse.showUpdate=false

  }
  console.log(this.selectedChild[this.childCount].dosage.response)
}
addChild(){
  
  // this.childCount=this.selectedChild[this.selectedChild.length-1].childCount;
  for(let i=this.selectedChild.length-1;i>=0;i--){
    if(this.selectedChild[i]!==null){
      this.childCount=this.selectedChild[i].childCount;
      break;

    }
 }
 
 this.childCount++;
 this.subChildCreated++;
  let childId= +this.childCount+1;
  this.selectedChild[this.childCount]={
    inpValue:{name:''},
    searchString:'',
    loader:false,
    childStatusLive:false,
    edited:false,
    error:false,
    verifyResponse:Object.assign({},this.updateResp),
    response:{ id:"CHILD_1",
      type:'',
      parentRequestId:null,
      dataInteger:null,
      dataString:'',
      operationType:'',
      entryType:'TEMP',
      timeStamp:null},
      compositions:{
        data:[],
        show:false,
        error:false,
        loader:false,
        searchString:'',
        inpValue:{},
        edited:false,
        verifyResponse:Object.assign({},this.updateResp),
        response:{ id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        },
      },
      superGroup:{
        data:[...this.selectedChild[0].superGroup.data],
        filteredData:[],
        showOptions:false,
        edited:false,
        error:false,
        inpValue:[{
          "id": "",
            "superGroup": "",
            "t":'',
            group:{id:"",group:""}
        }],
        selectedSuperGroups:[],
        selectedValue:{  "id": "",
        "superGroup": "",
        "t":'',
        group:{id:"",group:""}
         }
      },
      groups:{
        data:[],
        inpValue:[{
          "id": "",
            "t": ""
        }],
        edited:false,
        verifyResponse:Object.assign({},this.updateResp),
        response:[{ id:null,
          type:'',
          superGroupId:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        }]
      },
      scheduleDrug:{
        data:this.scheduleDrugData,
        edited:false,
        error:false,
        inpValue:{
          id:"",
          t:""
        },
        verifyResponse:Object.assign({},this.updateResp),
         response:{ id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        }
      },
      sideEffects:{
        data:[],
        verifyResponse:Object.assign({},this.updateResp),
        edited:false,
        selectedData:[],
        loader:false,
        searchString:'',
        response:[]
      },
      cautions:{
        data:[],
        verifyResponse:Object.assign({},this.updateResp),
        edited:false,
        selectedData:[],
        loader:false,
        searchString:'',
        response:[]
      },
    
      diseases:{
        data:[],
        verifyResponse:Object.assign({},this.updateResp),
        edited:false,
        selectedData:[],
        loader:false,
        searchString:'',
        response:[]
      },
      dpco:{
        inpValue:"",
        edited:false,
        error:false,
        verifyResponse:Object.assign({},this.updateResp),
        response:{ id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        }
      },
      atcn:{
        inpValue:"",
        edited:false,
        verifyResponse:Object.assign({},this.updateResp),
        response:{ id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        }
      },
      indication:{
        inpValue:"",
        edited:false,
        verifyResponse:Object.assign({},this.updateResp),
        response:{ id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        }
      },
      contradiction:{
        inpValue:"",
        edited:false,
        verifyResponse:Object.assign({},this.updateResp),
        response:{ id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        }
      },
      dosage:{
        inpValue:"",
        edited:false,
        verifyResponse:Object.assign({},this.updateResp),
        response:{ id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        }
      },
      subChild:[{
        selected:true,
        edited:false,
        error:false,
        medicineId:null,
        response:{
           id:this.subchild_variable+this.childCount+this.subChildCreated,
          type:'PRODUCT_SUB_CHILD',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'CREATE',
          entryType:'TEMP',
          timeStamp:new Date().getTime()
        },
        form:{
          inpValue:{id:'',t:''},
          edited:false,
          error:false,
          showOptions:false,
          verifyResponse:Object.assign({},this.updateResp),
          updatedResponse:null,
          response:{
            id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
          }
        },
        type:{ 
          inpValue:{id:'',t:''},
          error:false,
          showOptions:false,
          verifyResponse:Object.assign({},this.updateResp),
          edited:false,
          updatedResponse:null,
          response:{
          id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
          }
        },
        size:{
          inpValue:'',
          edited:false,
          verifyResponse:Object.assign({},this.updateResp),
          error:false,
          response:{
          id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
          }
        },
        unit:{
          inpValue:{id:'',t:''},
          edited:false,
          error:false,
          showOptions:false,
          verifyResponse:Object.assign({},this.updateResp),
          updatedResponse:null,
          response:{
          id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
          }
        },
    
      }],
    createdChild:false,
    newChild:'',
    childCount:this.childCount
  }
    this.selectedChild[this.childCount].response.id=this.child_variable+childId
    this.selectedChild[this.childCount].response.timeStamp=new Date().getTime();
    this.selectedChild[this.childCount].childCount=this.childCount;
    this.selectedChild[this.childCount].subChild[0].response.id=this.subchild_variable+this.subChildCreated;
    this.selectedChild[this.childCount].subChild[0].response.parentRequestId=this.child_variable+childId
    this.selectedChild[this.childCount].subChild[0].response.timeStamp=new Date().getTime()
    
  // this.getSuperGroups();
  // this.getScheduleDrugs();
  // this.subChildCreated++;
  this.activeChildCount++;
  console.log( this.selectedChild[this.childCount],this.childCount);
  
}

removeChild(index){
  const confirmString = "Do you want to delete child ?";
    this.popupService.openCommonConfirmPopup(confirmString).afterClosed().subscribe(
      response => {
        if (response) {
          if(this.selectedChild[index]['childFromResponse']){
            this.deleteChildResponses.push(this.selectedChild[index].response)
           }
          this.selectedChild[index].response.operationType="DELETE";
          this.activeChildCount--;
          console.log(this.selectedChild.length,this.activeChildCount);
          if(this.activeChildCount==0){
            this.clearAllChildFields(false)
            this.getSuperGroups('',false)
            this.getScheduleDrugs();
          }
          for(let i=this.selectedChild.length-1;i>=0;i--){
             if(this.selectedChild[i]!==null && this.selectedChild[i].response.operationType!="DELETE"){
               return this.childCount=this.selectedChild[i].childCount;
        
             }
          }
        }
      }
    )
   
}

childClick(index){
  
  this.childCount=this.selectedChild[index].childCount;
  this.getSelectedGroups('');
  if(this.selectedChild[this.childCount].superGroup.inpValue.length>0){
    this.superGroupExpand=true;
  }else{
    this.superGroupExpand=false;
  }
    
}

mapSubmit(){
this.borderError=false;
let selectedSubChild;
  let resp={
      mappingId:this.data.data.mappingId?this.data.data.mappingId:null,
      bridgeId:this.data.data.bridgeId?this.data.data.bridgeId:null,
      status:'',
      productMappingRequestDTOList:[],
   }
   let status=null;
  let childs=[];
  this.selectedChild.forEach((child)=>{
    if(child!==null){
        let childResponse={};
        childResponse['response']=child.response;
        childResponse['childFromResponse']=child['childFromResponse']?child['childFromResponse']:false;
        childResponse['updatedResponse']=child['updatedResponse'];
        childResponse['compositions']=child.compositions.response;
        child.compositions['updatedResponse'] && (childResponse['compositionsUpdatedResponse']=Object.assign({},child.compositions['updatedResponse']) );
        childResponse['groups']=child.groups.response;
        childResponse['scheduleDrug']=child.scheduleDrug.response;
        child.scheduleDrug['updatedResponse'] && (childResponse['scheduleDrugUpdatedResponse']=Object.assign({},child.scheduleDrug['updatedResponse']) );
        childResponse['sideEffects']=child.sideEffects.response;
        childResponse['cautions']=child.cautions.response;
        childResponse['diseases']=child.diseases.response;
        childResponse['dpco']=child.dpco.response;
        child.dpco['updatedResponse'] && (childResponse['dpcoUpdatedResponse']=Object.assign({},child.dpco['updatedResponse']) )
        childResponse['atcn']=child.atcn.response;
        child.atcn['updatedResponse'] && (childResponse['atcnUpdatedResponse']=Object.assign({},child.atcn['updatedResponse']) )
        childResponse['indication']=child.indication.response;
        child.indication['updatedResponse'] && (childResponse['indicationUpdatedResponse']=Object.assign({},child.indication['updatedResponse']) )
        childResponse['contradication']=child.contradiction.response;
        child.contradiction['updatedResponse'] && (childResponse['contradictionUpdatedResponse']=Object.assign({},child.contradiction['updatedResponse']) )
        childResponse['dosage']=child.dosage.response;
        child.dosage['updatedResponse'] && (childResponse['dosageUpdatedResponse']=Object.assign({},child.dosage['updatedResponse']) )
        childResponse['subChild']=child.subChild;
        childs.push(childResponse); 
    }
  })

   let finalResponse2=[];
   if(this.selectedParent.response.dataString.length){
     finalResponse2.push(this.selectedParent.response)
     this.selectedParent.error=false;
     this.showErrors=false;
   }else{if(this.data.data.type!=='MAP') {this.selectedParent.error=true}}

   if(this.data.data.type==="CREATE"){
    let statusError=false;
    let parentStatusLive=false;
    let childStatusLive=true;
    let subChildStatusLive=true;
    if(!this.selectedParent.parentStatusLive){
      finalResponse2.push(this.selectedParent['updatedResponse'])
      if(this.manufactures.response.dataInteger){finalResponse2.push(this.manufactures.response);this.manufactures.error=false}else{this.manufactures.error=true;statusError=true;};
      if(this.marketings.response.dataInteger){finalResponse2.push(this.marketings.response);this.marketings.error=false}else{this.marketings.error=true;statusError=true;};
      if(this.category.response.dataInteger){finalResponse2.push(this.category.response); this.category.error=false}else{this.category.error=true;statusError=true;}
      if(this.description.response.dataString.length>0){finalResponse2.push(this.description.response);this.description.error=false}
    }else{
      parentStatusLive=true;
      this.manufactures.error=false;this.marketings.error=false;this.category.error=false;this.description.error=false
    } 
    //  childs.forEach((child,i)=>
     for(let i=0;i<childs.length;i++){
       let child=childs[i]
        
       if( child.response.operationType!=='DELETE' && child.response.dataString.length>0){
         if(child.response.dataString.length>0){ finalResponse2.push(child.response);this.selectedChild[i].error=false}else{this.selectedChild[i].error=true; this.childCount=i;statusError=true;statusError=true;}
       
        if(child.response.entryType!=="LIVE"){
          childStatusLive=false;
          finalResponse2.push(child['updatedResponse'])
          if(child.compositions.dataInteger){finalResponse2.push(child.compositions);this.selectedChild[i].compositions.error=false}else{this.selectedChild[i].compositions.error=true;this.childCount=i;statusError=true;}
         if(child.scheduleDrug.dataInteger ){finalResponse2.push(child.scheduleDrug);this.selectedChild[i].scheduleDrug.error=false}
         else{
              finalResponse2.push({ id:this.scheduleDrug_variable+i,
                type:'SCHEDULE_TYPE',
                parentRequestId:child.response.id,
                dataInteger:this.scheduleDrugData[0].id,
                dataString:this.scheduleDrugData[0].t,
                operationType:'CREATE',
                entryType:child.response.entryType,
                timeStamp:new Date().getTime()
              })
         }
          if(child.groups && child.groups.length){
            let activeGroupCount=0;
            let childGroups=child.groups.slice(0);
            childGroups.forEach((group)=>{
              
              // delete group.superGroupId;
             if(group.dataInteger && group.operationType!=="DELETE"){let res=Object.assign({},group);delete res['superGroupId']; finalResponse2.push(res); activeGroupCount++}else{}

                })
            //   if(activeGroupCount<1){
            //     this.selectedChild[i].superGroup.error=true;
            //     this.childCount=i;
            //     this.superGroupExpand=true;
            //     statusError=true;
            //     console.log(this.selectedChild[i].superGroup);
                
            //   }else{
            //     this.selectedChild[i].superGroup.error=false;
            //   }  

            //  console.log(child.groups);
             

          }
          // else{
          //   this.selectedChild[i].superGroup.error=true;
          //   statusError=true;
          //   console.log(this.selectedChild[i].superGroup);

          //   this.childCount=i;
          //   this.superGroupExpand=true;
          // }
         
          child.sideEffects.forEach((effect)=>{
            effect.dataInteger && effect.operationType!=="DELETE" && finalResponse2.push(effect)
          })
  
          child.cautions.forEach((effect)=>{
            effect.dataInteger && effect.operationType!=="DELETE" && finalResponse2.push(effect)
          })
  
          child.diseases.forEach((effect)=>{
            effect.dataInteger && effect.operationType!=="DELETE" && finalResponse2.push(effect)
          })
        
          if(child.dpco.dataInteger==0 || child.dpco.dataInteger==1){finalResponse2.push(child.dpco);this.selectedChild[i].dpco.error=false}
          child.atcn.dataString && child.atcn.dataString.length>0 && finalResponse2.push(child.atcn)
          child.indication.dataString && child.indication.dataString.length>0 && finalResponse2.push(child.indication)
          child.contradication.dataString && child.contradication.dataString.length>0 && finalResponse2.push(child.contradication)
          child.dosage.dataString && child.dosage.dataString.length>0 && finalResponse2.push(child.dosage)
        }
        
        
        childs[i].subChild.forEach((item,index)=>{
          if(item.selected && item.response.id){
            subChildStatusLive=false;
            finalResponse2.push(item.response)
           if(item.form.response.dataInteger) {finalResponse2.push(item.form.response);this.selectedChild[i].subChild[index].form.error=false}else{this.selectedChild[i].subChild[index].form.error=true;statusError=true;}
           if(item.type.response.dataInteger) {finalResponse2.push(item.type.response);this.selectedChild[i].subChild[index].type.error=false}else{this.selectedChild[i].subChild[index].type.error=true;statusError=true;}
           if(item.size.response.dataString){finalResponse2.push(item.size.response);this.selectedChild[i].subChild[index].size.error=false}else{this.selectedChild[i].subChild[index].size.error=true;statusError=true;}
            if(item.unit.response.dataInteger){finalResponse2.push(item.unit.response);this.selectedChild[i].subChild[index].unit.error=false}else{this.selectedChild[i].subChild[index].unit.error=true;statusError=true;}
          }
        })
       }
      
     }

     resp.status="CREATE";
     resp.productMappingRequestDTOList=finalResponse2 ;
     if(parentStatusLive==true && childStatusLive==true && subChildStatusLive==true){
      console.log(parentStatusLive,childStatusLive,subChildStatusLive);
       statusError=true
     }

      if(!this.selectedParent.error && !statusError){
        this.popupService.openProgress();
    this.productMaster.mapMedicineRequest({},resp).subscribe(response=>{

      this.popupService.closeProgress();
      this.dialogRef.close(true);
      this.popupService.openSuccess('Submitted for Approval');  
     },
    error => {
  
    this.popupService.closeProgress()
    
    this.popupService.openError(error.error.data.localizedMessage);
    })
    }else{
      console.log(parentStatusLive,childStatusLive,subChildStatusLive);
      
      this.popupService.closeProgress();
      this.borderError=true ;
      this.subDiv.nativeElement.classList.add('errorAnime')
      this.subDiv.nativeElement.classList.add('borderError')
         setTimeout(()=>{
          this.subDiv.nativeElement.classList.remove('errorAnime')
         },200)

         if(parentStatusLive && childStatusLive && subChildStatusLive){
         this.popupService.openError("Please create atleast one new entry")
   
        }   
       
    }
     
   }else if(this.data.data.type==="MAP"){
    this.selectedChild[0].response.dataInteger?status="MAPPED":status="REQUEST_FOR_CREATION";

    childs[0].response.dataInteger && finalResponse2.push(childs[0].response);
    let statusError=false;
    let subChildCount=0
    
    this.selectedChild[0].response.dataInteger && childs[0].subChild.forEach((item,i)=>{   

        if(item.selected && item.response.dataInteger){
          finalResponse2.push(item.response)
           subChildCount++;
        }else if(item.selected){
          finalResponse2.push(item.response)
          if(item.form.response.dataInteger) {finalResponse2.push(item.form.response);this.selectedChild[0].subChild[i].form.error=false}else{this.selectedChild[0].subChild[i].form.error=true;statusError=true;}
          if(item.type.response.dataInteger) {finalResponse2.push(item.type.response);this.selectedChild[0].subChild[i].type.error=false}else{this.selectedChild[0].subChild[i].type.error=true;statusError=true;}
          if(item.size.response.dataString){finalResponse2.push(item.size.response);this.selectedChild[0].subChild[i].size.error=false}else{this.selectedChild[0].subChild[i].size.error=true;statusError=true;}
          if(item.unit.response.dataInteger){finalResponse2.push(item.unit.response);this.selectedChild[0].subChild[i].unit.error=false}else{this.selectedChild[0].subChild[i].unit.error=true;statusError=true;}
          subChildCount++
        }
        if(subChildCount>1){
          statusError=true;
          this.popupService.openError("Please select only one subchild")
        }


    })
    if(subChildCount<1){status="REQUEST_FOR_CREATION"}
    resp.status=status;
    resp.productMappingRequestDTOList=finalResponse2
  if(!this.selectedParent.error && !statusError){
    this.borderError=false;
    this.popupService.openProgress();

    this.productMaster.mapMedicineRequest({},resp).subscribe(response=>{

      this.popupService.closeProgress();
      if(status==="REQUEST_FOR_CREATION"){
        this.popupService.openSuccess('Submitted for Creation Successfully');
      }else{
        this.popupService.openSuccess('Submitted for Approval Successfully');
      }
     
      this.dialogRef.close(true);
     },
    error => {
  
    this.popupService.closeProgress()
    this.popupService.openError(error.error.data.localizedMessage);
    })
  }else{
    this.popupService.closeProgress();
    this.borderError=true;
    this.subDiv.nativeElement.classList.add('errorAnime')
    this.subDiv.nativeElement.classList.add('borderError')
       setTimeout(()=>{
        this.subDiv.nativeElement.classList.remove('errorAnime')
       },200)
  }
       

   }else if(this.data.data.type==="TO_BE_CREATE"){
    finalResponse2=[];
     if(this.deletedParentResponse.length && this.deletedParentResponse[0].dataInteger){
        this.deletedParentResponse[0].operationType="CREATE"
        this.deletedParentResponse[0].id='3'
        // this.deletedParentResponse[0].timeStamp=new Date().getTime()
        finalResponse2[0]=Object.assign({},this.deletedParentResponse[0])
        let deleteResponse=Object.assign({},this.deletedParentResponse[0])
          deleteResponse.operationType="DELETE"
          deleteResponse.parentRequestId=finalResponse2[0].id
          deleteResponse.id='4';
        finalResponse2.push(deleteResponse)
       
     }

     if(this.selectedParent.response.dataString.length){
      finalResponse2.push(this.selectedParent.response)
      this.selectedParent.error=false;
      this.showErrors=false;
    }else{this.selectedParent.error=true;}

    let statusError=false;
    if(!this.selectedParent.parentStatusLive){
      finalResponse2.push(this.selectedParent['updatedResponse'])
      if(this.manufactures.response.dataInteger){finalResponse2.push(this.manufactures.response);this.manufactures.error=false}else{this.manufactures.error=true;statusError=true;};
      if(this.marketings.response.dataInteger){finalResponse2.push(this.marketings.response);this.marketings.error=false}else{this.marketings.error=true;statusError=true;};
      if(this.category.response.dataInteger){finalResponse2.push(this.category.response); this.category.error=false}else{this.category.error=true;statusError=true;}
      if(this.description.response.dataString.length>0){finalResponse2.push(this.description.response);this.description.error=false}
    }else{
      this.manufactures.error=false;this.marketings.error=false;this.category.error=false;this.description.error=false
    } 
  console.log(this.deleteChildResponses.length);
  
    if(this.deleteChildResponses.length && this.deleteChildResponses[0].dataInteger){

        let deleteChildParentResp=Object.assign({},this.deleteChildResponses[0]);
        deleteChildParentResp.operationType="CREATE";
        deleteChildParentResp.id="CHILD_03"
        finalResponse2.push(deleteChildParentResp)
        let childDeleteResp=Object.assign({},this.deleteChildResponses[0]);
        childDeleteResp.operationType="DELETE";
        childDeleteResp.id="CHILD_04";
        childDeleteResp.parentRequestId="CHILD_03"
        finalResponse2.push(childDeleteResp);

        
    }
   
    if(childs[0].response.dataInteger){
      finalResponse2.push(childs[0].response);
     
      let subChildCount=0
      
      childs[0].subChild.forEach((item,i)=>{ 

        console.log(  childs[0].subChild[i]);
  
          if(item.selected && item.response.dataInteger){
            finalResponse2.push(item.response)
             subChildCount++;
          }else if(item.selected){
            finalResponse2.push(item.response)
            if(item.form.response.dataInteger) {finalResponse2.push(item.form.response);this.selectedChild[0].subChild[i].form.error=false}else{this.selectedChild[0].subChild[i].form.error=true;statusError=true;}
            if(item.type.response.dataInteger) {finalResponse2.push(item.type.response);this.selectedChild[0].subChild[i].type.error=false}else{this.selectedChild[0].subChild[i].type.error=true;statusError=true;}
            if(item.size.response.dataString){finalResponse2.push(item.size.response);this.selectedChild[0].subChild[i].size.error=false}else{this.selectedChild[0].subChild[i].size.error=true;statusError=true;}
            if(item.unit.response.dataInteger){finalResponse2.push(item.unit.response);this.selectedChild[0].subChild[i].unit.error=false}else{this.selectedChild[0].subChild[i].unit.error=true;statusError=true;}
            subChildCount++
          }
          
         
      })
      if(subChildCount>1){
        statusError=true;
        this.popupService.openError("Please select only one subchild")
      }else if(subChildCount<1){
        statusError=true;
        this.popupService.openError("Please select atleast one subchild")
      }
    }else{
      for(let i=0;i<childs.length;i++){
        let child=childs[i]
         
        if( child.response.operationType!=='DELETE' ){
          if(child.response.dataString.length>0){ finalResponse2.push(child.response);this.selectedChild[i].error=false}else{this.selectedChild[i].error=true; this.childCount=i;statusError=true;statusError=true;}
        
         if(child.response.entryType!=="LIVE"){
           finalResponse2.push(child['updatedResponse'])
           if(child.compositions.dataInteger){finalResponse2.push(child.compositions);this.selectedChild[i].compositions.error=false}else{this.selectedChild[i].compositions.error=true;this.childCount=i;statusError=true;}
          if(child.scheduleDrug.dataInteger ){finalResponse2.push(child.scheduleDrug);this.selectedChild[i].scheduleDrug.error=false}
          else{
            finalResponse2.push({ id:this.scheduleDrug_variable+i,
              type:'SCHEDULE_TYPE',
              parentRequestId:this.selectedChild[this.childCount].response.id,
              dataInteger:this.scheduleDrugData[0].id,
              dataString:this.scheduleDrugData[0].t,
              operationType:'CREATE',
              entryType:child.response.entryType,
              timeStamp:new Date().getTime()
            })
       }
           if(child.groups && child.groups.length){
             let activeGroupCount=0;
             let childGroups=child.groups.slice(0)
             childGroups.forEach((group)=>{
               
               // delete group.superGroupId;
              if(group.dataInteger && group.operationType!=="DELETE"){let res=Object.assign({},group);delete res['superGroupId']; finalResponse2.push(res); activeGroupCount++}else{}
 
                 })
              //  if(activeGroupCount<1){
              //    this.selectedChild[i].superGroup.error=true;
              //    this.childCount=i;
              //    this.superGroupExpand=true;
              //    statusError=true;
              //    console.log(this.selectedChild[i].superGroup);
                 
              //  }else{
              //    this.selectedChild[i].superGroup.error=false;
              //  }  
 
              // console.log(activeGroupCount);
              
 
           }
          //  else{
          //    this.selectedChild[i].superGroup.error=true;
          //    statusError=true;
          //    console.log(this.selectedChild[i].superGroup);
 
          //    this.childCount=i;
          //    this.superGroupExpand=true;
          //  }
          
           child.sideEffects.forEach((effect)=>{
             effect.dataInteger && effect.operationType!=="DELETE" && finalResponse2.push(effect)
           })
   
           child.cautions.forEach((effect)=>{
             effect.dataInteger && effect.operationType!=="DELETE" && finalResponse2.push(effect)
           })
   
           child.diseases.forEach((effect)=>{
             effect.dataInteger && effect.operationType!=="DELETE" && finalResponse2.push(effect)
           })
         
           if(child.dpco.dataInteger==0 || child.dpco.dataInteger==1){finalResponse2.push(child.dpco);this.selectedChild[i].dpco.error=false}
           child.atcn.dataString && child.atcn.dataString.length>0 && finalResponse2.push(child.atcn)
           child.indication.dataString && child.indication.dataString.length>0 && finalResponse2.push(child.indication)
           child.contradication.dataString && child.contradication.dataString.length>0 && finalResponse2.push(child.contradication)
           child.dosage.dataString && child.dosage.dataString.length>0 && finalResponse2.push(child.dosage)
         }
         
         let subChildCount=0
         childs[i].subChild.forEach((item,index)=>{
           if(item.selected && item.response.id){
             subChildCount++
             finalResponse2.push(item.response)
            if(item.form.response.dataInteger) {finalResponse2.push(item.form.response);this.selectedChild[i].subChild[index].form.error=false}else{this.selectedChild[i].subChild[index].form.error=true;statusError=true;}
            if(item.type.response.dataInteger) {finalResponse2.push(item.type.response);this.selectedChild[i].subChild[index].type.error=false}else{this.selectedChild[i].subChild[index].type.error=true;statusError=true;}
            if(item.size.response.dataString){finalResponse2.push(item.size.response);this.selectedChild[i].subChild[index].size.error=false}else{this.selectedChild[i].subChild[index].size.error=true;statusError=true;}
             if(item.unit.response.dataInteger){finalResponse2.push(item.unit.response);this.selectedChild[i].subChild[index].unit.error=false}else{this.selectedChild[i].subChild[index].unit.error=true;statusError=true;}
           }
         })
         if(subChildCount>1){
           statusError=true;
           
           this.popupService.openError("Please select only one subchild")
         }else if(subChildCount==0){
           statusError=true;
           this.popupService.openError("Please select atleast one subchild")
         }
        }
       
      }
       
    }
   
    resp.status="MAP_CREATE";
    resp.productMappingRequestDTOList=finalResponse2 ;
   let hasCode={hasCode:this.hashCode?this.hashCode:''}
     if(!this.selectedParent.error && !statusError){
      this.popupService.openProgress();
   this.productMaster.updateMedicineRequest(hasCode,resp).subscribe(response=>{

     this.popupService.closeProgress();
     this.popupService.openSuccess('Submitted for Approval Successfully');
     this.dialogRef.close(true);
    },
   error => {
 
   this.popupService.closeProgress()
   this.popupService.openError(error.error.data.localizedMessage);
  })
   }else{
     this.borderError=true;
     this.subDiv.nativeElement.classList.add('errorAnime')
     this.subDiv.nativeElement.classList.add('borderError')
        setTimeout(()=>{
         this.subDiv.nativeElement.classList.remove('errorAnime')
        },200)
   }

        

   }else if(this.data.data.type==="APPROVE"){
       
    finalResponse2=[];
    if(this.deletedParentResponse.length && this.deletedParentResponse[0].dataInteger){
       this.deletedParentResponse[0].operationType="CREATE"
       this.deletedParentResponse[0].id='3'
       // this.deletedParentResponse[0].timeStamp=new Date().getTime()
       finalResponse2[0]=Object.assign({},this.deletedParentResponse[0])
       let deleteResponse=Object.assign({},this.deletedParentResponse[0])
         deleteResponse.operationType="DELETE"
         deleteResponse.parentRequestId=finalResponse2[0].id
         deleteResponse.id='4';
       finalResponse2.push(deleteResponse)
      
    }

    if(this.selectedParent.response.dataString.length){
     finalResponse2.push(this.selectedParent.response)
     this.selectedParent.error=false;
     this.showErrors=false;
   }else{this.selectedParent.error=true;}

   let statusError=false;
   if(!this.deletedParentResponse.length){
    if(this.selectedParent['updatedResponse']){
      finalResponse2.push(this.selectedParent['updatedResponse'])
     }
   if(this.manufactures['updatedResponse']){finalResponse2.push(this.manufactures['updatedResponse']);this.manufactures.error=false}
   if(this.marketings['updatedResponse']){finalResponse2.push(this.marketings['updatedResponse']);this.marketings.error=false}
   if(this.category['updatedResponse']){finalResponse2.push(this.category['updatedResponse']); this.category.error=false}
   if(this.description['updatedResponse']){finalResponse2.push(this.description['updatedResponse']);this.description.error=false}
  }else{
    //  if(!this.selectedParent.parentStatusLive){
    //  finalResponse2.push(this.selectedParent['updatedResponse'])
    //  if(this.manufactures.response.dataInteger){finalResponse2.push(this.manufactures.response);this.manufactures.error=false}else{this.manufactures.error=true;statusError=true;};
    //  if(this.marketings.response.dataInteger){finalResponse2.push(this.marketings.response);this.marketings.error=false}else{this.marketings.error=true;statusError=true;};
    //  if(this.category.response.dataInteger){finalResponse2.push(this.category.response); this.category.error=false}else{this.category.error=true;statusError=true;}
    //  if(this.description.response){finalResponse2.push(this.description.response);this.description.error=false}else{this.description.error=true;statusError=true;};
    if(!this.selectedParent.parentStatusLive){
    finalResponse2.push(this.selectedParent['updatedResponse'])
    if(this.manufactures['updatedResponse']){this.manufactures['updatedResponse'].operationType="CREATE"; finalResponse2.push(this.manufactures['updatedResponse']);this.manufactures.error=false}else{this.manufactures.error=true;statusError=true;};
    if(this.marketings['updatedResponse']){this.marketings['updatedResponse'].operationType="CREATE"; finalResponse2.push(this.marketings['updatedResponse']);this.marketings.error=false}else{this.marketings.error=true;statusError=true;};
    if(this.category['updatedResponse']){this.category['updatedResponse'].operationType="CREATE"; finalResponse2.push(this.category['updatedResponse']); this.category.error=false}else{this.category.error=true;statusError=true;}
    if(this.description['updatedResponse'].dataString.length>0){this.description['updatedResponse'].operationType="CREATE"; finalResponse2.push(this.description['updatedResponse']);this.description.error=false}
   }else{
     this.manufactures.error=false;this.marketings.error=false;this.category.error=false;this.description.error=false
   } 
  }


  if(this.deleteChildResponses.length){
    this.deleteChildResponses.forEach((childDel)=>{

      let deleteChildParentResp=Object.assign({},childDel);
      deleteChildParentResp.operationType="CREATE";
      deleteChildParentResp.id=childDel.id+'_c'
      finalResponse2.push(deleteChildParentResp)
      let childDeleteResp=Object.assign({},childDel);
      childDeleteResp.operationType="DELETE";
      childDeleteResp.id=childDel.id+'_d'
      childDeleteResp.parentRequestId=childDel.id+'_c'
      finalResponse2.push(childDeleteResp);
    })   
    }
  
    for(let i=0;i<childs.length;i++){
      let child=childs[i]
      let condition;
      if(this.data.data.request==='APPROVE_CREATE'){
         condition=child.response.dataString.length>0;
      }else{
        condition=true
      }
      if( child.response.operationType!=='DELETE' && condition){

        if(child.response.dataString.length>0){ finalResponse2.push(child.response);this.selectedChild[i].error=false}else{this.selectedChild[i].error=true; this.childCount=i;statusError=true;statusError=true;}
        if(child.childFromResponse){
          if(child.updatedResponse){finalResponse2.push(child.updatedResponse)}
          if(child.compositionsUpdatedResponse && child.compositionsUpdatedResponse.dataInteger){finalResponse2.push(child.compositionsUpdatedResponse);this.selectedChild[i].compositions.error=false}
          if(child.scheduleDrugUpdatedResponse && child.scheduleDrugUpdatedResponse.dataInteger ){finalResponse2.push(child.scheduleDrugUpdatedResponse);this.selectedChild[i].scheduleDrug.error=false}
         
          if(child.groups && child.groups.length){
            let activeGroupCount=0;
                 
                child.groups.forEach((group)=>{
                       if(group.updatedResponse){group.operationType="CREATE";let upResp=Object.assign({},group.updatedResponse); delete group['updatedResponse']; finalResponse2.push(upResp)}
                       if(group.dataInteger && group.operationType!=="DELETE"){
                           group.groupFromResponse && !group.updatedResponse && finalResponse2.push(group)
                          activeGroupCount++
                        }

                })

                // if(activeGroupCount<1){
                //   this.selectedChild[i].superGroup.error=true;
                //   this.childCount=i;
                //   this.superGroupExpand=true;
                //   statusError=true;
                //   console.log(this.selectedChild[i].superGroup);
                  
                // }else{
                //   this.selectedChild[i].superGroup.error=false;
                // }  
    
            }
            // else{
            //   this.selectedChild[i].superGroup.error=true;
            //   statusError=true;
            //   console.log(this.selectedChild[i].superGroup);
  
            //   this.childCount=i;
            //   this.superGroupExpand=true;
            // }

            child.sideEffects.forEach((effect)=>{
              if(effect.updatedResponse){ let upResp=Object.assign({},effect.updatedResponse); delete effect['updatedResponse']; finalResponse2.push(upResp) }
              if(effect.dataInteger && effect.operationType!=="DELETE" && effect.edited){delete effect['edited'] ; finalResponse2.push(effect)}
            })
            child.cautions.forEach((effect)=>{
              if(effect.updatedResponse){ let upResp=Object.assign({},effect.updatedResponse); delete effect['updatedResponse']; finalResponse2.push(upResp) }
              if(effect.dataInteger && effect.operationType!=="DELETE" && effect.edited){delete effect['edited'] ; finalResponse2.push(effect)}

            })
            child.diseases.forEach((effect)=>{
              console.log(effect);
              
              if(effect.updatedResponse){ let upResp=Object.assign({},effect.updatedResponse); delete effect['updatedResponse']; finalResponse2.push(upResp) }
              if(effect.dataInteger && effect.operationType!=="DELETE" && effect.edited){delete effect['edited'] ; finalResponse2.push(effect)}

            })
             
            child.atcn.operationType==='UPDATE' && child.atcn.dataString && child.atcn.dataString.length>0 && finalResponse2.push(child.atcn)
            child.indication.operationType==='UPDATE' &&  child.indication.dataString && child.indication.dataString.length>0 && finalResponse2.push(child.indication)
            child.contradication.operationType==='UPDATE' && child.contradication.dataString && child.contradication.dataString.length>0 && finalResponse2.push(child.contradication)
            child.dosage.operationType==='UPDATE' && child.dosage.dataString && child.dosage.dataString.length>0 && finalResponse2.push(child.dosage) 
            
            if(child.dpcoUpdatedResponse){finalResponse2.push(child.dpcoUpdatedResponse);this.selectedChild[i].dpco.error=false}
          child.atcnUpdatedResponse && child.atcnUpdatedResponse.dataString.length>0 && finalResponse2.push(child.atcnUpdatedResponse)
          child.indicationUpdatedResponse && child.indicationUpdatedResponse.dataString.length>0 && finalResponse2.push(child.indicationUpdatedResponse)
          child.contradicationUpdatedResponse && child.contradicationUpdatedResponse.dataString.length>0 && finalResponse2.push(child.contradicationUpdatedResponse)
          child.dosageUpdatedResponse && child.dosageUpdatedResponse.dataString.length>0 && finalResponse2.push(child.dosageUpdatedResponse)
            let subChildCount=0;
          childs[i].subChild.forEach((item,index)=>{ 

            console.log(  childs[i].subChild[index]);
            if(item.updatedResponse){
              finalResponse2.push(item.response,item.updatedResponse)
               }
              if(item.selected && item.response.dataInteger){
                finalResponse2.push(item.response);
                selectedSubChild=item;
                  item.form.updatedResponse && finalResponse2.push(item.form.updatedResponse)
                  item.type.updatedResponse && finalResponse2.push(item.type.updatedResponse)
                  item.size.updatedResponse && finalResponse2.push(item.size.updatedResponse)
                  item.unit.updatedResponse && finalResponse2.push(item.unit.updatedResponse)
               
                 
                 subChildCount++;
              }else if(item.selected){
                selectedSubChild=item;
                finalResponse2.push(item.response)
                if(item.form.response.dataInteger) {finalResponse2.push(item.form.response);this.selectedChild[i].subChild[index].form.error=false}else{this.selectedChild[i].subChild[index].form.error=true;statusError=true;}
                if(item.type.response.dataInteger) {finalResponse2.push(item.type.response);this.selectedChild[i].subChild[index].type.error=false}else{this.selectedChild[i].subChild[index].type.error=true;statusError=true;}
                if(item.size.response.dataString){finalResponse2.push(item.size.response);this.selectedChild[i].subChild[index].size.error=false}else{this.selectedChild[i].subChild[index].size.error=true;statusError=true;}
                if(item.unit.response.dataInteger){finalResponse2.push(item.unit.response);this.selectedChild[i].subChild[index].unit.error=false}else{this.selectedChild[i].subChild[index].unit.error=true;statusError=true;}
                subChildCount++
              }

             
              
             
          })
          if(this.data.data.request==='APPROVE_MAP'){
            if(subChildCount>1){
              statusError=true;
              this.popupService.openError("Please select only one subchild")
            }else if(subChildCount<1){
              statusError=true;
              this.popupService.openError("Please select atleast one subchild")
            }
          }
          console.log(subChildCount);
        }else{
          if(child.response.entryType!=="LIVE"){
            finalResponse2.push(child['updatedResponse'])
            console.log(child);
            
            if(child.compositionsUpdatedResponse && child.compositionsUpdatedResponse.dataInteger){child.compositionsUpdatedResponse.operationType="CREATE"; finalResponse2.push(child.compositionsUpdatedResponse);this.selectedChild[i].compositions.error=false}else{this.selectedChild[i].compositions.error=true;this.childCount=i;statusError=true;}
           if(child.scheduleDrugUpdatedResponse && child.scheduleDrugUpdatedResponse.dataInteger ){child.scheduleDrugUpdatedResponse.operationType="CREATE"; finalResponse2.push(child.scheduleDrugUpdatedResponse);this.selectedChild[i].scheduleDrug.error=false}
           else{
                finalResponse2.push({ id:this.scheduleDrug_variable+i,
                  type:'SCHEDULE_TYPE',
                  parentRequestId:child.response.id,
                  dataInteger:this.scheduleDrugData[0].id,
                  dataString:this.scheduleDrugData[0].t,
                  operationType:'CREATE',
                  entryType:child.response.entryType,
                  timeStamp:new Date().getTime()
                })
           }
           if(child.groups && child.groups.length){
              let activeGroupCount=0;
              let childGroups=child.groups.slice(0)
              childGroups.forEach((group)=>{
                
                // delete group.superGroupId;
               if(group.dataInteger && group.operationType!=="DELETE"){let res=Object.assign({},group);delete res['superGroupId'];  finalResponse2.push(res); activeGroupCount++}else{}
  
                  })
              //   if(activeGroupCount<1){
              //     this.selectedChild[i].superGroup.error=true;
              //     this.childCount=i;
              //     this.superGroupExpand=true;
              //     statusError=true;
              //     console.log(this.selectedChild[i].superGroup);
                  
              //   }else{
              //     this.selectedChild[i].superGroup.error=false;
              //   }  
  
              //  console.log(activeGroupCount);
               
  
            }
            // else{
            //   this.selectedChild[i].superGroup.error=true;
            //   statusError=true;
            //   console.log(this.selectedChild[i].superGroup);
  
            //   this.childCount=i;
            //   this.superGroupExpand=true;
            // }
           
            child.sideEffects.forEach((effect)=>{
              delete effect['edited']
              effect.dataInteger && effect.operationType!=="DELETE" && finalResponse2.push(effect)
            })
    
            child.cautions.forEach((effect)=>{
              delete effect['edited']
              effect.dataInteger && effect.operationType!=="DELETE" && finalResponse2.push(effect)
            })
    
            child.diseases.forEach((effect)=>{
              delete effect['edited']
              effect.dataInteger && effect.operationType!=="DELETE" && finalResponse2.push(effect)
            })
          
            if(child.dpco.dataInteger==0 || child.dpco.dataInteger==1){finalResponse2.push(child.dpco);this.selectedChild[i].dpco.error=false}
            child.atcn.dataString && child.atcn.dataString.length>0 && finalResponse2.push(child.atcn)
            child.indication.dataString && child.indication.dataString.length>0 && finalResponse2.push(child.indication)
            child.contradication.dataString && child.contradication.dataString.length>0 && finalResponse2.push(child.contradication)
            child.dosage.dataString && child.dosage.dataString.length>0 && finalResponse2.push(child.dosage)
            let subChildCount=0;
            childs[i].subChild.forEach((item,index)=>{ 

              console.log(  childs[i].subChild[index]);
        
                if(item.selected && item.response.dataInteger){
                  finalResponse2.push(item.response)
                  selectedSubChild=item
                   subChildCount++;
                }else if(item.selected){
                  selectedSubChild=item
                  finalResponse2.push(item.response)
                  if(item.form.response.dataInteger) {finalResponse2.push(item.form.response);this.selectedChild[i].subChild[index].form.error=false}else{this.selectedChild[i].subChild[index].form.error=true;statusError=true;}
                  if(item.type.response.dataInteger) {finalResponse2.push(item.type.response);this.selectedChild[i].subChild[index].type.error=false}else{this.selectedChild[i].subChild[index].type.error=true;statusError=true;}
                  if(item.size.response.dataString){finalResponse2.push(item.size.response);this.selectedChild[i].subChild[index].size.error=false}else{this.selectedChild[i].subChild[index].size.error=true;statusError=true;}
                  if(item.unit.response.dataInteger){finalResponse2.push(item.unit.response);this.selectedChild[i].subChild[index].unit.error=false}else{this.selectedChild[i].subChild[index].unit.error=true;statusError=true;}
                  subChildCount++
                }
                // console.log(subChildCount);
               
               
            })
            if(this.data.data.request==='APPROVE_MAP'){
              if(subChildCount>1){
                statusError=true;
                this.popupService.openError("Please select only one subchild")
              }else if(subChildCount<1){
                statusError=true;
                this.popupService.openError("Please select atleast one subchild")
              }
            }
          }else if(child.response.entryType==="LIVE"){
            let subChildCount=0;
            childs[i].subChild.forEach((item,index)=>{ 
        
                if(item.selected && item.response.dataInteger){
                  finalResponse2.push(item.response)
                   subChildCount++;
                   selectedSubChild=item
                }else if(item.selected){
                  selectedSubChild=item
                  finalResponse2.push(item.response)
                  if(item.form.response.dataInteger) {finalResponse2.push(item.form.response);this.selectedChild[i].subChild[index].form.error=false}else{this.selectedChild[i].subChild[index].form.error=true;statusError=true;}
                  if(item.type.response.dataInteger) {finalResponse2.push(item.type.response);this.selectedChild[i].subChild[index].type.error=false}else{this.selectedChild[i].subChild[index].type.error=true;statusError=true;}
                  if(item.size.response.dataString){finalResponse2.push(item.size.response);this.selectedChild[i].subChild[index].size.error=false}else{this.selectedChild[i].subChild[index].size.error=true;statusError=true;}
                  if(item.unit.response.dataInteger){finalResponse2.push(item.unit.response);this.selectedChild[i].subChild[index].unit.error=false}else{this.selectedChild[i].subChild[index].unit.error=true;statusError=true;}
                  subChildCount++
                }
                // console.log(subChildCount);
              
               
            })
            if(this.data.data.request==='APPROVE_MAP'){
              if(subChildCount>1){
                statusError=true;
                this.popupService.openError("Please select only one subchild")
              }else if(subChildCount<1){
                statusError=true;
                this.popupService.openError("Please select atleast one subchild")
              }
            }
          }  
        }
        
        
      }
      }
      // finalResponse2.forEach((resp,i)=>{
      //   if(resp.type==='PRODUCT_PARENT' || resp.type==='PRODUCT_CHILD' || resp.type==='PRODUCT_SUB_CHILD'){
           
      //      let fieldUpdated=finalResponse2.filter((x)=>x.parentRequestId===resp.id)
      //      if(!fieldUpdated.length){
               
      //      }
      //   }
      // })
      resp.status="APPROVED";
      resp.productMappingRequestDTOList=finalResponse2 ;
     let hasCode={hasCode:this.hashCode?this.hashCode:''}

     if(this.data.data.mappingType==="DO_NOT_CREATE_NEW" && selectedSubChild.medicineId){
               statusError=true;
               this.popupService.openError("You selected already mapped subchild")
       }
       if(!this.selectedParent.error && !statusError){

        if(this.data.data.request==='APPROVE_MAP'){
          let data={medName:this.data.data.medName,distributor:this.data.data.distributor,subChild:selectedSubChild,parentName:this.selectedParent['updatedResponse']?this.selectedParent['updatedResponse'].dataString:this.selectedParent.response.dataString,
            child:this.selectedChild[0].response.dataString,mappingType:this.data.data.mappingType,newItemCode:this.data.data.itemCode,oldItemCode:this.oldItemCode}
           this.popupService.openApproveConfirm(data).afterClosed().subscribe(
            response => {
              if (response) {
                this.popupService.openProgress();
                this.productMaster.updateMedicineRequest(hasCode,resp).subscribe(response=>{
             
                  this.popupService.closeProgress();
                  this.popupService.openSuccess('Approved Successfully');
                  this.dialogRef.close(true);
                 },
                error => {
              
                this.popupService.closeProgress()
                this.popupService.openError(error.error.data.localizedMessage);
               })
              }
            }
          )
        }else{
          this.popupService.openProgress();
          this.productMaster.updateMedicineRequest(hasCode,resp).subscribe(response=>{
       
            this.popupService.closeProgress();
            this.popupService.openSuccess('Approved Successfully');
            this.dialogRef.close(true);
           },
          error => {
        
          this.popupService.closeProgress()
          this.popupService.openError(error.error.data.localizedMessage);
         })
        }
         
        
     }else{
       this.borderError=true;
       this.subDiv.nativeElement.classList.add('errorAnime')
       this.subDiv.nativeElement.classList.add('borderError')
          setTimeout(()=>{
           this.subDiv.nativeElement.classList.remove('errorAnime')
          },200)
     }
  

  

   }

 

    
     
  
   



}

showError(err){
 this.error=true;
 this.popupService.openError(err)
}

checkBoxChange(event,index){

       if(this.data.data.request=="MAPPING" || this.data.data.type=="MAP"){

        this.selectedChild[this.childCount].subChild.forEach((sub,i)=>{
          if(i!==index){
            this.selectedChild[this.childCount].subChild[i].selected=false;
          }
         })

         if(event.checked){
           
              if(this.selectedChild[this.childCount].subChild[index]['updatedResponse'] && this.data.data.type==='APPROVE'){
                delete this.selectedChild[this.childCount].subChild[index]['updatedResponse']
              }
           this.finalButtonStatus="MAP"

         }else{
           if( this.selectedChild[this.childCount].subChild[index].response.dataInteger && this.data.data.type==='APPROVE'){
            this.selectedChild[this.childCount].subChild[index]['updatedResponse']=Object.assign({},this.selectedChild[this.childCount].subChild[index].response)
            this.selectedChild[this.childCount].subChild[index]['updatedResponse'].id=this.selectedChild[this.childCount].subChild[index].response.id+'_n'
            this.selectedChild[this.childCount].subChild[index]['updatedResponse'].parentRequestId=this.selectedChild[this.childCount].subChild[index].response.id
            this.selectedChild[this.childCount].subChild[index]['updatedResponse'].operationType='DELETE';
            console.log( this.selectedChild[this.childCount].subChild[index]);
            
          }
          this.data.data.type=="MAP" && (this.finalButtonStatus="REQUEST_FOR_CREATION")
         }
       }else if(this.data.data.type==='APPROVE'){
        if(this.data.data.request==='APPROVE_MAP'){
          this.selectedChild[this.childCount].subChild.forEach((sub,i)=>{
            if(i!==index){
              if(this.selectedChild[this.childCount].subChild[i].selected){
                if( this.selectedChild[this.childCount].subChild[i].response.dataInteger && this.data.data.type==='APPROVE'){
                  this.selectedChild[this.childCount].subChild[i]['updatedResponse']=Object.assign({},this.selectedChild[this.childCount].subChild[i].response)
                  this.selectedChild[this.childCount].subChild[i]['updatedResponse'].id=this.selectedChild[this.childCount].subChild[i].response.id+'_n'
                  this.selectedChild[this.childCount].subChild[i]['updatedResponse'].parentRequestId=this.selectedChild[this.childCount].subChild[i].response.id
                  this.selectedChild[this.childCount].subChild[i]['updatedResponse'].operationType='DELETE';
                  
                }
              }
              this.selectedChild[this.childCount].subChild[i].selected=false;
            }
           })
        }
        

        if(event.checked){
           
          if(this.selectedChild[this.childCount].subChild[index]['updatedResponse'] && this.data.data.type==='APPROVE'){
            delete this.selectedChild[this.childCount].subChild[index]['updatedResponse']
          }
     

     }else{
       if( this.selectedChild[this.childCount].subChild[index].response.dataInteger && this.data.data.type==='APPROVE'){
        this.selectedChild[this.childCount].subChild[index]['updatedResponse']=Object.assign({},this.selectedChild[this.childCount].subChild[index].response)
        this.selectedChild[this.childCount].subChild[index]['updatedResponse'].id=this.selectedChild[this.childCount].subChild[index].response.id+'_n'
        this.selectedChild[this.childCount].subChild[index]['updatedResponse'].parentRequestId=this.selectedChild[this.childCount].subChild[index].response.id
        this.selectedChild[this.childCount].subChild[index]['updatedResponse'].operationType='DELETE';
        console.log( this.selectedChild[this.childCount].subChild[index]);
        
      }
      
     }
       }
        
}

clearAllChildFields(existing){

 if(!existing){
  this.activeChildCount=1
  this.childCount=0;
  this.selectedChild=[];
 }
 if(this.data.data.type=="MAP"){this.finalButtonStatus="REQUEST_FOR_CREATION"}


  this.selectedChild[this.childCount]={
    inpValue:{name:''},
    searchString:this.selectedChild[this.childCount]?this.selectedChild[this.childCount].searchString:"",
    loader:false,
    childStatusLive:false,
    edited:false,
    error:false,
    verifyResponse:Object.assign({},this.updateResp),
    response:{ id:this.selectedChild[this.childCount]?this.selectedChild[this.childCount].response.id:'CHILD_1',
      type:'',
      parentRequestId:null,
      dataInteger:null,
      dataString:'',
      operationType:'CREATE',
      entryType:'TEMP',
      timeStamp:null},
      compositions:{
        data:[],
        show:false,
        error:false,
        loader:false,
        searchString:'',
        verifyResponse:Object.assign({},this.updateResp),
        inpValue:{},
        edited:false,
        response:{ id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        },
      },
      superGroup:{
        data:[],
        filteredData:[],
        showOptions:false,
        edited:false,
        error:false,
        selectedSuperGroups:[],
        inpValue:[{
          "id": "",
            "superGroup": "",
            "t":'',
            group:{id:"",group:""}
        }],
        selectedValue:{  "id": "",
        "superGroup": "",
        "t":'',
        group:{id:"",group:""}
         }
      },
      groups:{
        data:[],
        inpValue:[{
          "id": "",
            "t": ""
        }],
        edited:false,
        verifyResponse:Object.assign({},this.updateResp),
        response:[{ id:null,
          type:'',
          superGroupId:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        }]
      },
      scheduleDrug:{
        data:[],
        edited:false,
        error:false,
        inpValue:{
          id:"",
          t:""
        },
        verifyResponse:Object.assign({},this.updateResp),
         response:{ id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        }
      },
      sideEffects:{
        data:[],
        edited:false,
        selectedData:[],
        loader:false,
        searchString:'',
        verifyResponse:Object.assign({},this.updateResp),
        response:[]
      },
      cautions:{
        data:[],
        edited:false,
        selectedData:[],
        loader:false,
        searchString:'',
        verifyResponse:Object.assign({},this.updateResp),
        response:[]
      },
    
      diseases:{
        data:[],
        edited:false,
        selectedData:[],
        loader:false,
        searchString:'',
        verifyResponse:Object.assign({},this.updateResp),
        response:[]
      },
      dpco:{
        inpValue:"",
        edited:false,
        error:false,
        verifyResponse:Object.assign({},this.updateResp),
        response:{ id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        }
      },
      atcn:{
        inpValue:"",
        edited:false,
        verifyResponse:Object.assign({},this.updateResp),
        response:{ id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        }
      },
      indication:{
        inpValue:"",
        edited:false,
        verifyResponse:Object.assign({},this.updateResp),
        response:{ id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        }
      },
      contradiction:{
        inpValue:"",
        edited:false,
        verifyResponse:Object.assign({},this.updateResp),
        response:{ id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        }
      },
      dosage:{
        inpValue:"",
        edited:false,
        verifyResponse:Object.assign({},this.updateResp),
        response:{ id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
        }
      },
      subChild:[{
        selected:true,
        edited:false,
        error:false,
        medicineId:null,
        response:{
           id:this.subchild_variable+this.subChildCreated,
          type:'PRODUCT_SUB_CHILD',
          parentRequestId:this.selectedChild[this.childCount]?this.selectedChild[this.childCount].response.id:'CHILD_1',
          dataInteger:null,
          dataString:'',
          operationType:'CREATE',
          entryType:'TEMP',
          timeStamp:new Date().getTime()
        },
        form:{
          inpValue:{id:'',t:''},
          edited:false,
          error:false,
          showOptions:false,
          verifyResponse:Object.assign({},this.updateResp),
          updatedResponse:null,
          response:{
            id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
          }
        },
        type:{ 
          inpValue:{id:'',t:''},
          edited:false,
          error:false,
          showOptions:false,
          verifyResponse:Object.assign({},this.updateResp),
          updatedResponse:null,
          response:{
          id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
          }
        },
        size:{
          inpValue:'',
          edited:false,
          verifyResponse:Object.assign({},this.updateResp),
          error:false,
          response:{
          id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
          }
        },
        unit:{
          inpValue:{id:'',t:''},
          edited:false,
          showOptions:false,
          verifyResponse:Object.assign({},this.updateResp),
          updatedResponse:null,
          error:false,
          response:{
          id:null,
          type:'',
          parentRequestId:null,
          dataInteger:null,
          dataString:'',
          operationType:'',
          entryType:'',
          timeStamp:null
          }
        },
    
      }],
    createdChild:false,
    newChild:'',
    childCount:this.childCount
  };

  
  this.selectedChild[0].subChild[0].response.id=this.subchild_variable+this.subChildCreated
  this.selectedChild[0].createdChild=false;
}
clearAllParentFields(){
  this.manufactures={
    data:[],
    show:false,
    loader:false,
    edited:false,
    error:false,
    searchString:'',
    inpValue:{},
    response:{ id:null,
      type:'',
      parentRequestId:null,
      dataInteger:null,
      dataString:'',
      operationType:'',
      entryType:'',
      timeStamp:null
    },
  }

  this.marketings={
    data:[],
    show:false,
    loader:false,
    edited:false,
    error:false,
    searchString:'',
    inpValue:{},
    response:{ id:null,
      type:'',
      parentRequestId:null,
      dataInteger:null,
      dataString:'',
      operationType:'',
      entryType:'',
      timeStamp:null
    }
  }

  this.description={
    inpValue:"",
    edited:false,
    error:false,
    response:{ id:null,
      type:'',
      parentRequestId:null,
      dataInteger:null,
      dataString:'',
      operationType:'',
      entryType:'',
      timeStamp:null
    }
  }

  this.category={
    data:[],
    edited:false,
    error:false,
    inpValue:{
      t:"",
      id:null
    },
    response:{ id:null,
      type:'',
      parentRequestId:null,
      dataInteger:null,
      dataString:'',
      operationType:'',
      entryType:'',
      timeStamp:null
    }
  }
}
}
