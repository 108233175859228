import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AdminService } from 'src/app/core/services/api/admin.service';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { DialogModelUploadWinnerList} from './model';
@Component({
  selector: 'app-upload-winner-list',
  templateUrl: './upload-winner-list.component.html',
  styleUrls: ['./upload-winner-list.component.scss']
})
export class UploadWinnerListComponent implements OnInit {
  retList = [];
  noGift = false;
  errorStat = null;

  constructor(public dialogRef: MatDialogRef<UploadWinnerListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelUploadWinnerList,
    private adminService: AdminService,
    private popUpService: PopupService) { }

  ngOnInit(): void {
    this.retList = this.data.data.body['retailersWhoCannotWinGift'];
    this.errorStat = this.data.data.body['retailersWhoCannotWinGift'].length + '/' + this.data.data.body['totalRetailers'];
    if(this.data.data.body['retailersWhoCannotWinGift'].length === this.data.data.body['totalRetailers']) {
      this.noGift = true;
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

  viewList() {
    const header = ['Retailers Code'];
    const csv = this.retList;
    // add headers
    csv.unshift(header.join(","));
    const csvArray = csv.join("\n");
    // generate file
    const a = document.createElement("a");
    const blob = new Blob([csvArray], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    // download file
    a.href = url;
    a.download = 'View Unfulfilled List';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  retry() {
    let obj = this.data.data.body;
    delete obj.noGiftInInventory;

    const body = {
      ...obj
    }

    const params = {
      campaignId: this.data.campaignId
    }
    
    this.adminService.retryUploadCampaignWinnersCSV(params, body).subscribe(res => {
      if(res) {
        this.dialogRef.close(true);
      }
    },
    error => {
      this.popUpService.openError(error.error.error);
    })
  }
}
