import { LogisticsComponent } from './logistics.component';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule, MatRippleModule, MatSidenavModule, MatToolbarModule } from "@angular/material";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "../../material.module";
import { TbWaterfallModule } from "../../tb/tb-waterfall/tb-waterfall.module";
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [LogisticsComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    MatRippleModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    TbWaterfallModule,
    RouterModule,
    FormsModule
  ],
  exports: [LogisticsComponent]
})
export class LogisticsModule { }
