import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelAdnUpload } from './model';
import { SupplyService } from "src/app/core/services/api/supply.service";
import { PopupService } from "src/app/core/services/popup/popup.service";
import { I } from '@angular/cdk/keycodes';
@Component({
  selector: 'app-adn-upload-csv',
  templateUrl: './adn-upload-csv.component.html',
  styleUrls: ['./adn-upload-csv.component.scss']
})
export class AdnUploadCsvComponent implements OnInit {
  panelOpenState=false;
  constructor(private supplyService: SupplyService,
    private popupService: PopupService,
    public dialogRef: MatDialogRef<AdnUploadCsvComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelAdnUpload,) { }
csvData=[];filterCSVData=[];
headers=[];
emptyCsvObject={};
fileData;
retailerCodeInput=''
sortByRetStatus={
  type:'down',
  status:false
}
  ngOnInit(): void {
    this.csvData=this.data.data.data;
    this.headers=this.data.data.headers;
    this.headers.forEach((x)=>{this.emptyCsvObject[x.toString()]={value:'',error:false,edit:true}});
    this.csvData=this.csvData.map((item,index)=>{
         this.headers.forEach((header,i)=>{
              
             item[header]={value:item[header]?item[header]:'',error:false,edit:false}
             
            

         })
         
         return item;
    });
   
    if(this.headers[0].toLowerCase()=='type'){
             this.csvData[0][this.headers[2]].edit=true    
             this.csvData[1][this.headers[2]].edit=true    
    }
    
    this.filterCSVData=[...this.csvData ]   
   
  }

  retailerCodeSearch(event){
      if(event.target.value?.length){
            this.csvData=this.filterCSVData.filter((data)=>data?.retailerCode.value.toLowerCase().includes(event.target.value.toLowerCase()))  
      }else{
        this.csvData=[...this.filterCSVData]
      }
  }

  sortByRet(type,event){
    event.stopPropagation();
    let key;
    type==='down'?key='up':key='down'
    this.sortByRetStatus={status:true,type:key}
      if(type==='up'){
       this.csvData=this.sortByKey(this.filterCSVData,'retailerCode','up')
       
      }else{
        this.csvData=this.sortByKey(this.filterCSVData,'retailerCode','down')

      }
  }
   sortByKey(array, key,type) {
     if(type==='down'){
      return array.sort(function(a, b) {
        var x = a[key].value; var y = b[key].value;
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
     } else{
      return array.sort(function(a, b) {
        var x = a[key].value; var y = b[key].value;
        return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
     }
   
}

removeSortByRetFilter(event){
    event.stopPropagation();
    this.sortByRetStatus.status=false
    this.csvData=[...this.filterCSVData]
  }

  addRow(){
    this.csvData.push(JSON.parse(JSON.stringify(this.emptyCsvObject)))
    
  }
  deleteRow(index){
    this.popupService.openCommonConfirmPopup("Do you want to delete row").afterClosed().subscribe(response=>{
       if(response){
        this.csvData.splice(index,1)
       }
    })
      
  }
  cancel(attachFile) {
    this.dialogRef.close({close:true,fileData:this.fileData,attachFile:attachFile});
  }

  enableInput(index,header,headerIndex){
    
    if(this.headers[0].toLowerCase()==='type' && index<2){
      if(headerIndex<3){
        this.csvData[index][header].edit=true

      }else{
        this.csvData[index][header].edit=false

      }

    }else{
      this.csvData[index][header].edit=true
    }

  }

  disableInput(index,header){
    this.csvData[index][header].value && (this.csvData[index][header].edit=false)
  }

  saveCSV(){
    let errorStatus=false;
    if(this.data?.data?.type!=='wholesaleBill'){
    this.addRow();
    }
    let data=this.csvData.map((val)=>{
          let item=JSON.parse(JSON.stringify(val))
            this.headers.forEach((header)=>{
              // if(item[header].value.length<1){
              //      errorStatus=true;
              //      val[header].error=true;
              // }else{
                item[header]=item[header].value
              // }
             
         })
         return item;
     })
    this.downloadFile(data,this.data.data.fileName);
    this.cancel(false)

  }

  attachCSV(){
    let errorStatus=false;
    let data=this.csvData.map((val,index)=>{
          let item=JSON.parse(JSON.stringify(val))
            this.headers.forEach((header,i)=>{
              if(this.headers[0].toLowerCase()=='type'&& i>2 && index<2){
               
               item[header]=item[header].value
             
            }else{
              if(item[header].value.length<1){
                errorStatus=true;
                val[header].error=true;
                if(this.data?.data?.type==='wholesaleBill'){
                  item[header]=''
                }
           }else{
             item[header]=item[header].value
           }
            }
       
             
         })
         return item;
     })
     
    if(this.data?.data?.type!=='wholesaleBill'){
      !errorStatus && this.convertCsvtoFile(data,this.data.data.fileName);
      !errorStatus && this.cancel(true)
    }else{
     
       this.convertCsvtoFile(data,this.data.data.fileName);
       this.cancel(true)
    }
  
  }

  convertCsvtoFile(data,filename){
    let arrHeader = Object.keys(data[0]);
    let csvData = this.ConvertToCSV(data, arrHeader);
   
    let blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' }); 
    this.fileData=blob
  }

  downloadFile(data, filename) {
    let arrHeader = Object.keys(data[0]);
    
    let csvData = this.ConvertToCSV(data, arrHeader);
   
    let blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });  
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", `${filename}.csv`);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    let newHeaders = headerList;
    if(this.data?.data?.showHeaders){
      newHeaders=this.data.data.showHeaders
    }
    for (let index in newHeaders) {
      row += newHeaders[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      // let line = (i + 1) + '';
      let line =  ''
      for (let index=0;index<headerList.length; index++) {
        let head = headerList[index];
        if(index < headerList.length-1){
          line +=this.strRep(array[i][head])+',' ;
        }else{
          line +=this.strRep(array[i][head]);

        }
      }
      str += line + '\r\n';
    }

    
    return str;
  }
  strRep(data) {
    if(typeof data == "string") {
      let newData = data.replace(/,/g, " ");
       return newData;
    }
    else if(typeof data == "undefined") {
      return "-";
    }
    else if(typeof data == "number") {
      return  data.toString();
    }
    else {
      return data;
    }
  }




  keyup(item){
    console.log(item);
    
  }
}
