import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogModelAddItemsFilter } from './model';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { WarehouseService } from "src/app/core/services/api/warehouse.service";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-items-filter',
  templateUrl: './add-items-filter.component.html',
  styleUrls: ['./add-items-filter.component.scss']
})
export class AddItemsFilterComponent implements OnInit {
  filters = {
    distributors: [],
    name: '',
    medicineTag: [],
    priceMapping: [],
    startTime: null,
    sortBy:[]
  };
  selectedFilters = {
    distributorFilter: [],
    medicineTag: [],
    name: '',
    priceMapping: [],
    startTime: null,
    sortBy:null
  }

  constructor(
    public dialogRef: MatDialogRef<AddItemsFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelAddItemsFilter,
    private warehouseService: WarehouseService,
    private popupService: PopupService,
    private activatedRoute: ActivatedRoute
  ) { 
    // console.log(this.data)
  }

  ngOnInit(): void {
    this.popupService.openProgress();
    if (this.data.currParams && this.data.currParams.distributorFilter) {
      this.selectedFilters.distributorFilter = this.data.currParams.distributorFilter;
    }
    if (this.data.currParams && this.data.currParams.name) {
      this.selectedFilters.name = this.data.currParams.name;
    }
    if (this.data.currParams && this.data.currParams.medicineTag) {
      this.selectedFilters.medicineTag = [this.data.currParams.medicineTag];
    }
    if (this.data.currParams && this.data.currParams.priceMapping) {
      this.selectedFilters.priceMapping = [this.data.currParams.priceMapping];
    }
    if (this.data.currParams && this.data.currParams.startTime) {
      var startTime = new Date(this.data.currParams.startTime);
      startTime.setDate(startTime.getDate()+1)
      this.selectedFilters.startTime = (new Date(startTime)).toISOString().split("T")[0];
    }

    if (this.data.currParams && this.data.currParams.sortBy) {
      this.selectedFilters.sortBy =this.data.currParams.sortBy;
    }
    this.getFilters();
  }

  // get filter options
  getFilters() {
    let url = ''
    if (this.data.data == 'Items from Decision Room') {
      url = "/saveoinventory/itemsToBeAdded/filters/decisionRoom";
    } else if (this.data.data == "Items Cancelled by Customer") {
      url = "/saveoinventory/itemsToBeAdded/filters/CancelledByCustomer";
    } else if (this.data.data == "Returns from Distributor") {
      url = "/saveoinventory/itemsToBeAdded/filters/returnFromDistributor";
    }
    this.warehouseService.getAddItemsFilters(url).subscribe(
      response => {
        this.filters = response.data;
        this.filters.sortBy=['medicineName asc','medicineName desc','createdTimestamp asc','createdTimestamp desc'];
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  // apply filters
  submit() {
    let returnFilter = {
      name: '',
      distributorFilter: [],
      priceMapping: null,
      medicineTag: null,
    };
    if (this.selectedFilters.priceMapping.length == 1) {
      returnFilter.priceMapping = this.selectedFilters.priceMapping[0];
    }
    if (this.selectedFilters.medicineTag.length == 1) {
      returnFilter.medicineTag = this.selectedFilters.medicineTag[0];
    }
    returnFilter.name = this.selectedFilters.name;
    returnFilter.distributorFilter = this.selectedFilters.distributorFilter;

    if(this.selectedFilters.sortBy){
      returnFilter['sortBy']=this.selectedFilters.sortBy
    }
  
    if (this.selectedFilters.startTime) {
      returnFilter["startTime"] = Date.parse((new Date(this.selectedFilters.startTime)).toString().replace("05:30:00", "00:00:00"));
    }
    this.dialogRef.close(returnFilter)
  }

  // close popup
  close() {
    this.dialogRef.close(false);
  }

}
