import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogWholesaleRedirect } from './model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wholesale-redirect-popup',
  templateUrl: './wholesale-redirect-popup.component.html',
  styleUrls: ['./wholesale-redirect-popup.component.scss']
})
export class WholesaleRedirectPopupComponent implements OnInit {

  constructor( private router: Router,  public dialogRef: MatDialogRef<WholesaleRedirectPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogWholesaleRedirect) { }

  ngOnInit(): void {

    setTimeout(()=>{
      if(this.data.data.redirect=="ready_to_ship"){
        this.router.navigate(['/oms/readyToShip']);
      }else if(this.data.data.redirect=='acceptedOrders'){
        this.router.navigate(['oms/acceptedOrders']);
      }else if(this.data.data.redirect=='past-orders'){
        this.router.navigate(['oms/past-orders']);
      }
      
      this.cancel()
    },1500)
  }


  cancel() {
    this.dialogRef.close(false);
  }
  
}
