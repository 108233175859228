import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule } from "@angular/forms";
import { MatButtonModule, MatIconModule, MatRippleModule, MatSidenavModule, MatToolbarModule } from "@angular/material";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "src/app/material.module";
import { GlobalSearchModule } from "src/app/shared/global-search/global-search.module";
import { TbWaterfallModule } from "src/app/tb/tb-waterfall/tb-waterfall.module";
import { DemandComponent } from "./demand.component";

@NgModule({
    declarations: [DemandComponent],
    imports: [
        CommonModule,
    MaterialModule,
    FlexLayoutModule,
    MatRippleModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    TbWaterfallModule,
    RouterModule,
    GlobalSearchModule,
    MatIconModule,
    MaterialModule,
    FormsModule,
    ], 
    exports: [DemandComponent]
})

export class DemandModule { }