import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelOutstandingPaymentHistory } from './model';
import { OutstandingService } from "src/app/core/services/api/outstanding.service";
import { PopupService } from "src/app/core/services/popup/popup.service";

@Component({
  selector: 'app-outstanding-payment-history',
  templateUrl: './outstanding-payment-history.component.html',
  styleUrls: ['./outstanding-payment-history.component.scss']
})
export class OutstandingPaymentHistoryComponent implements OnInit {
  paymentHistory = [];

  constructor(
    private outstandingService: OutstandingService,
    private popupService: PopupService,
    public dialogRef: MatDialogRef<OutstandingPaymentHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelOutstandingPaymentHistory,
    ) { }

  ngOnInit(): void {
    this.getPaymentHistory();
  }

  getPaymentHistory() {
    this.popupService.openProgress();
    this.outstandingService.getPaymentHistory({id: this.data.outstandingData.id}).subscribe(
      response => {
        this.paymentHistory = response.data;
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }
  
  closePopup() {
    this.dialogRef.close();
  }

}
