import { Injectable, EventEmitter } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class SideNavEventHandlerService {

    

    actionMainEmitter: EventEmitter<Boolean> = new EventEmitter<Boolean>();



    constructor() {
    }

    /**
     *  @deprecated
     */
    emitMainEvent(isOpening: Boolean) {
        this.actionMainEmitter.emit(isOpening);
    }

    /**
     *  @deprecated
     */
    getEmitter(): EventEmitter<Boolean> {
        return this.actionMainEmitter;
    }


    emitActionMainEvent(isOpening: Boolean) {
        this.actionMainEmitter.emit(isOpening);
    }

    getActionMainEmitter(): EventEmitter<Boolean> {
        return this.actionMainEmitter;
    }

    

}
