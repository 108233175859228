import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../../../common/api.service';

@Injectable({
  providedIn: 'root'
})
export class OutstandingService {
  API_URL: string = environment.adminUrl + '/admin';
  preprod_url = "https://preprod.saveo.in/admin";

  constructor(private http: HttpClient, private apiService: ApiService) { }

  getAllOutstandings(params): Observable<any> {
    const urlPath = this.API_URL + "/outstanding/home";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  collectPaymentForRetailerReason(params, body): Observable<any> {
    const urlPath = this.API_URL + "/outstanding/collect/Retailer";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  collectPaymentForDEReason(params, body): Observable<any> {
    const urlPath = this.API_URL + "/outstanding/collect/DE";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  saveBillingChanges(params, body): Observable<any> {
    const urlPath = this.API_URL + "/outstanding/updateStatus/BillingFinalize";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  changeOutstandingBillingToRetailer(params, body): Observable<any> {
    const urlPath = this.API_URL + "/outstanding/updateStatus/BillingToRetailer";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateRetailerIssue(params, body): Observable<any> {
    const urlPath = this.API_URL + "/outstanding/updateStatus/RetailerIssue";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getPaymentHistory(params): Observable<any> {
    const urlPath = this.API_URL + "/outstanding/viewPayments";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getOutstandingInvoiceData(params): Observable<any> {
    const urlPath = this.API_URL + "/outstanding/viewInvoice";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

}
