import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogModelDROpenItemsSpokeFilter } from './model';

@Component({
  selector: 'app-dr-open-items-spoke-filter',
  templateUrl: './dr-open-items-spoke-filter.component.html',
  styleUrls: ['./dr-open-items-spoke-filter.component.scss']
})
export class DrOpenItemsSpokeFilterComponent implements OnInit {
  spokes = [
    {
      spokeName: 'spoke 1',
      selected: false
    },
    {
      spokeName: 'spoke 2',
      selected: false
    },
    {
      spokeName: 'spoke 3',
      selected: false
    },
    {
      spokeName: 'spoke 4',
      selected: false
    }
  ];
  selectAllSpokes = false;
  disableConfirm = true;

  constructor(
    public dialogRef: MatDialogRef<DrOpenItemsSpokeFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public _mData: DialogModelDROpenItemsSpokeFilter
  ) {}

  ngOnInit():void {}

  checkDisableConfirm() {
    let count = 0;
    this.spokes.map(spoke => {
      if (spoke.selected) {
        count++;
      }
    });
    console.log(count);
    if (count > 0) {
      this.disableConfirm = false;
    } else {
      this.disableConfirm = true;
    }
  }

  toggleAllSpokes(e) {
    console.log(e)
    this.spokes.map(spoke => {
      spoke.selected = e;
    });
    this.checkDisableConfirm();
  }

  onSubmit() {
    const selectedspokes = [];
    this.spokes.map(spoke => {
      if (spoke.selected) {
        selectedspokes.push(spoke.spokeName);
      }
    })
    console.log(selectedspokes)
    this.dialogRef.close(selectedspokes);
  }

  closePopup() {
    this.dialogRef.close();
  }

}