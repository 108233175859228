import { AuthGuardService } from "src/app/core/auth/auth.guard";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { DashboardContainerComponent } from "./container/dashboard-container/dashboard-container.component";
import { RegistrationComponent } from "./components/registration/registration.component";
import { AdminComponent } from "./components/admin/admin.component";
import { DistributorComponent } from "./components/distributor/distributor.component";
import { RetailerContainerComponent } from "./container/retailer-container/retailer-container.component";
import { DispatchManagerComponent } from "./components/dispatch-manager/dispatch-manager.component";
import { DeliveryExecutiveComponent } from "./components/delivery-executive/delivery-executive.component";
import { AnalyticsComponent } from "./components/analytics/analytics.component";
import { LogisticsComponent } from "./components/logistics/logistics.component";
import { SupplyComponent } from "./components/supply/supply.component";
import { OutstandingComponent } from "./components/outstanding/outstanding.component";
import { WarehouseComponent } from "src/app/components/warehouse/warehouse.component";
import { HelpDeskComponent } from "./components/help-desk/help-desk.component";
import { CreditDashboardComponent } from "./components/credit/credit-dashboard/credit-dashboard.component";
import { FinanceComponent } from "./components/finance/finance.component";
import { OmsComponent } from "./components/oms/oms.component";

import { DemandComponent } from "./components/demand/demand.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "check",
    pathMatch: "full",
  },
  {
    path: "retailer",
    component: RetailerContainerComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./components/retailer/retailer.module").then(
            (m) => m.RetailerModule
          ),
      },
    ],
  },
  {
    path: "oms",
    component: OmsComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/oms/oms-container/oms-container.module"
          ).then(m => m.OmsContainerModule)
      }
    ]
  },
  {
    path: "admin",
    component: AdminComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/admin/admin-container/admin-container.module"
          ).then((m) => m.AdminContainerModule),
      },
    ],
  },
  {
    path: "finance",
    component: FinanceComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/finance/finance-container/finance-container.module"
          ).then((m) => m.FinanceContainerModule),
      },
    ],
  },
  {
    path: "help-desk",
    component: HelpDeskComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/help-desk/help-desk-container/help-desk-container.module"
          ).then((m) => m.HelpDeskContainerModule),
      },
    ],
  },
  {
    path: "outstanding",
    component: OutstandingComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/outstanding/outstanding-container/outstanding-container.module"
          ).then((m) => m.OutstandingContainerModule),
      },
    ],
  },
  {
    path: "warehouse",
    component: WarehouseComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/warehouse/warehouse-container/warehouse-container.module"
          ).then((m) => m.WarehouseContainerModule),
      },
    ],
  },
  {
    path: "delivery-exec",
    component: DeliveryExecutiveComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/delivery-executive/delivery-executive-container/delivery-executive-container.module"
          ).then((m) => m.DeliveryExecutiveContainerModule),
      },
    ],
  },
  {
    path: "dispatch-manager",
    component: DispatchManagerComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/dispatch-manager/dispatch-manager-container/dispatch-manager-container.module"
          ).then((m) => m.DispatchManagerContainerModule),
      },
    ],
  },
  {
    path: "dist",
    component: DistributorComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/distributor/distributor-container/distributor-container.module"
          ).then((m) => m.DistributorContainerModule),
      },
    ],
  },
  {
    path: "analysis",
    component: AnalyticsComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/analytics/analytics-container/analytics-container.module"
          ).then((m) => m.AnalyticsContainerModule),
      },
    ],
  },
  {
    path: "logistics",
    component: LogisticsComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/logistics/logistics-container/logistics-container.module"
          ).then((m) => m.LogisticsContainerModule),
      },
    ],
  },
  {
    path: "supply",
    component: SupplyComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/supply/supply-container/supply-container.module"
          ).then((m) => m.SupplyContainerModule),
      },
    ],
  },
  {
    path: "demand",
    component: DemandComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/demand/demand-container/demand-container.module"
          ).then((m) => m.DemandContainerModule),
      },
    ],
  },
  {
    path: "credit",
    component: CreditDashboardComponent,
    canActivate: [AuthGuardService],
    // children: [
    //   {
    //     path: "",
    //     loadChildren: () =>
    //       import(
    //         "./components/supply/supply-container/supply-container.module"
    //       ).then(m => m.SupplyContainerModule)
    //   }
    // ]
  },
  {
    path: "check",
    canActivate: [AuthGuardService],
    children: [],
  },
  {
    path: "authenticate",
    component: RegistrationComponent,

    children: [
      {
        path: "",
        loadChildren: () =>
          import("./components/registration/components/auth/auth.module").then(
            (m) => m.AuthModule
          ),
      },
      {
        path: "profile-setup",
        loadChildren: () =>
          import(
            "./components/registration/components/profile-setup/profile-setup.module"
          ).then((m) => m.ProfileSetupModule),
      },
      {
        path: "complete",
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import(
            "./components/registration/components/registration-complete/registration-complete.module"
          ).then((m) => m.RegistrationCompleteModule),
      },
      {
        path: "block",
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import(
            "./components/registration/components/block/block.module"
          ).then((m) => m.BlockModule),
      },
    ],
  },
  {
    path: "shop",
    component: DashboardContainerComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./components/customer/home/home.module").then(
            (m) => m.HomeModule
          ),
      },
      {
        path: "cart",
        loadChildren: () =>
          import("./components/customer/cart/cart.module").then(
            m => m.CartModule
          )
      },
      {
        path: "past-order",
        loadChildren: () =>
          import("./components/customer/past-order/past-order.module").then(
            m => m.PastOrderModule
          )
      },
      {
        path: "regular",
        loadChildren: () =>
          import(
            "./components/customer/regular-schemes/regular-schemes.module"
          ).then(m => m.RegularSchemesModule)
      },
      {
        path: "special-schemes",
        loadChildren: () =>
          import(
            "./components/customer/special-discount/special-discount.module"
          ).then(m => m.SpecialDiscountModule)
      },
      {
        path: "company-section",
        loadChildren: () =>
          import(
            "./components/customer/company-section/company-section.module"
          ).then(m => m.CompanySectionModule)
      },
      {
        path: "special",
        loadChildren: () =>
          import(
            "./components/customer/special-schemes/special-schemes.module"
          ).then(m => m.SpecialSchemesModule)
      },
      {
        path: "user-profile",
        loadChildren: () =>
          import("./components/customer/user-profile/user-profile.module").then(
            m => m.UserProfileModule
          )
      },
      {
        path: "catalogue",
        loadChildren: () =>
          import("./components/customer/product-catalog/product-catalog.module").then(
            m => m.ProductCatalogModule
          )
      },
      {
        path: "allopathy",
        loadChildren: () => 
        import("src/app/components/customer/allopathy/allopathy.module").then(
          m => m.AllopathyModule
        )
      },
      {
        path: "ayuervedic",
        loadChildren: () => 
        import("src/app/components/customer/ayurvedic/ayurvedic.module").then(
          m => m.AyurvedicModule
        )
      },
      {
        path: "generic",
        loadChildren: () => 
        import("src/app/components/customer/generic/generic.module").then(
          m => m.GenericModule
        )
      },
      {
        path: "exclusive",
        loadChildren: () => 
        import("src/app/components/customer/exclusive/exclusive.module").then(
          m => m.ExclusiveModule
        )
      },
      {
        path: "surgical",
        loadChildren: () =>
        import("src/app/components/customer/surgical/surgical.module").then(
          m => m.SurgicalModule
        )
      }
    ]
  },
  {
    path: "**",
    redirectTo: "shop"
  }
];

const rootRouting: ModuleWithProviders = RouterModule.forRoot(
  routes,

  {
    preloadingStrategy: PreloadAllModules,
    useHash: false,
    enableTracing: false,
    scrollPositionRestoration: "top",
    onSameUrlNavigation: "reload",
  }
);

@NgModule({
  imports: [rootRouting],
  exports: [RouterModule],
})
export class AppRoutingModule {}
