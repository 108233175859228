import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogModelPrintBillWarning } from "./model";
@Component({
  selector: "app-print-bill-warning",
  templateUrl: "./print-bill-warning.component.html",
  styleUrls: ["./print-bill-warning.component.scss"],
})
export class PrintBillWarningComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PrintBillWarningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelPrintBillWarning
  ) {}

  ngOnInit(): void {}

  submit(isFullFilled) {
    this.dialogRef.close({isFullFilled: isFullFilled});
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
