import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule } from "@angular/forms";
import { MatButtonModule, MatRippleModule, MatSidenavModule, MatToolbarModule } from "@angular/material";
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';
import { CommonSearchContainerModule } from "../shared/common-search-container/common-search-container.module";
import { CompanyNamesModule } from "../shared/company-names/company-names.module";
import { TbWaterfallModule } from "../tb/tb-waterfall/tb-waterfall.module";
import { BlankContainerComponent } from './blank-container/blank-container.component';
import { DashboardContainerComponent } from "./dashboard-container/dashboard-container.component";
import { DialogModule } from "src/app/shared/dialog/dialog.module";
import { RetailerContainerComponent } from './retailer-container/retailer-container.component';
import { SearchBarModule } from '../shared/search-bar/search-bar.module';



@NgModule({
  declarations: [
    DashboardContainerComponent,
    BlankContainerComponent,
    RetailerContainerComponent,
  ],

  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    MatRippleModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    TbWaterfallModule,
    DialogModule,
    SearchBarModule,
    FormsModule,
    CommonSearchContainerModule,
    CompanyNamesModule,
    RouterModule
  ],
  exports: [BlankContainerComponent, DashboardContainerComponent,RetailerContainerComponent],
})
export class ContainerModule {}
