import { CommonModule } from "@angular/common";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "src/app/material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatIconModule, MatRippleModule } from "@angular/material";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { SearchBarComponent } from "./search-bar.component";

@NgModule({
  declarations: [SearchBarComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    MatIconModule,
    MatRippleModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [SearchBarComponent]
})
export class SearchBarModule {}
