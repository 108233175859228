import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MedicineMappingMenuComponent } from './medicine-mapping-menu.component'
import { MatButtonModule, MatIconModule, MatTooltipModule, MatSidenavModule, MatToolbarModule, MatTableModule } from "@angular/material";
import { RouterModule } from "@angular/router";


@NgModule({
  declarations: [MedicineMappingMenuComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    RouterModule
  ],
  exports:[MedicineMappingMenuComponent]
})
export class MedicineMappingMenuModule { }
