import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { WarehouseService } from 'src/app/core/services/api/warehouse.service';
import { FilterService } from 'src/app/core/services/filter/filter.service';
import { DialogModelDROpenItemsSlotFilter } from './model';
import { PopupService } from "src/app/core/services/popup/popup.service";


@Component({
  selector: 'app-dr-open-items-slot-filter',
  templateUrl: './dr-open-items-slot-filter.component.html',
  styleUrls: ['./dr-open-items-slot-filter.component.scss']
})
export class DrOpenItemsSlotFilterComponent implements OnInit {
  currStartDate;
  currEndDate;
  today;
  minStartDate;
  maxStartDate;
  minEndDate;
  maxEndDate;
  slots = [];
  selectAllSlots = false;
  disableConfirm = true;
  filters = {
    credential: {
      uniqueValues: [],
      values: [],
      key: 'credential',
      active: false,
      searchString: ""
    },
    date: {
      uniqueValues: [],
      values: [],
      key: 'date',
      active: false,
      searchString: ""
    },
    time: {
      uniqueValues: [],
      values: [],
      key: 'time',
      active: false,
      searchString: ""
    }
  };

  startTime = "";
  endTime = "";

  filteredSlots = [];

  constructor(
    public dialogRef: MatDialogRef<DrOpenItemsSlotFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public _mData: DialogModelDROpenItemsSlotFilter,
    private warehouseService: WarehouseService,
    private filterService: FilterService,
    private popupService: PopupService,
  ) {}

  ngOnInit():void {
    // today's date setup
    this.today = new Date();
    this.getStartDateRange();
  }

  // Get start date range
  getStartDateRange() {
    const todayISO = this.today.toISOString().split("T")[0];
    this.maxStartDate = 
    (todayISO.split("-")[0]) +
    "-" +
    todayISO.split("-")[1] +
    "-" +
    todayISO.split("-")[2];
    this.minStartDate = "2019-09-14";
    this.currStartDate = this.maxStartDate;
    this.getEndDateRange();
  }

  // Get end date range
  getEndDateRange() {
    this.minEndDate = this.currStartDate;
    const todayISO = this.today.toISOString().split("T")[0];
    this.maxEndDate =
      (todayISO.split("-")[0]) +
      "-" +
      todayISO.split("-")[1] +
      "-" +
      todayISO.split("-")[2];
      // console.log(this.maxEndDate)
      this.currEndDate = this.maxEndDate;
      this.getSlots();
  }

  // change selected start date
  changeStartDate(e) {
    this.currStartDate = e.target.value;
    this.minEndDate = this.currStartDate;
    // this.getSlots();
  }

  // change selected end date
  changeEndDate(e) {
    this.currEndDate = e.target.value;
    this.maxStartDate = this.currEndDate;
    // this.getSlots();
  }

  submitDate(){
    this.getSlots();
    this.selectAllSlots=false;
    this.disableConfirm=true
  }

  getSlots() {
    this.popupService.openProgress()
    const params = {
      startDate: Date.parse(this.currStartDate + "T00:00:00.000+05:30"),
      endDate: Date.parse(this.currEndDate + "T23:59:59.000+05:30")
    };
    this.warehouseService.getDecisionRoomFilters(params).subscribe(
      response => {
        this.slots = response.data;
        this.slots.map(slot => {
          slot.selected = false;
          slot.date = slot.retrievalTime.split(',')[0];
          slot.time = slot.retrievalTime.split(', ')[1];
        });
        this.filteredSlots = this.slots;
        this.filters = this.filterService.getFiltersV2(this.filteredSlots, this.filters);
        this.popupService.closeProgress()
      },
      error => {
        console.log(error.error.error.error);
      }
    )
  }

  onSubmit() {
    const selectedSpokeSlots = [];
    this.filteredSlots.map(slot => {
      if (slot.selected) {
        selectedSpokeSlots.push(slot);
      }
    })
    console.log(selectedSpokeSlots)
    this.dialogRef.close(selectedSpokeSlots);
  }

  toggleAllSlots(e) {
    this.filteredSlots.map(slot => {
      slot.selected = this.selectAllSlots;
    });
    this.checkDisableConfirm();
  }

  checkDisableConfirm() {
    this.disableConfirm = true;
    this.filteredSlots.map(slot => {
      if (slot.selected) {
        this.disableConfirm = false;
      }
    })
  }

  closePopup() {
    this.dialogRef.close(false);
  }

  // add/remove any column filters
  toggleFilter(key) {
    this.filters[key].active = !this.filters[key].active;
    this.filterData();
  }

  // Filter data
  filterData() {
    this.filteredSlots.map(items => {
      items.selected = false;
    });
    this.selectAllSlots = false;
    this.filteredSlots = this.filterService.filterDataV2(this.slots, this.filters)
    if (this.filters['time'].active && this.startTime && this.endTime && this.startTime <= this.endTime) {
      const slotList = this.filteredSlots;
      this.filteredSlots = [];
      slotList.map(slot => {
        if (slot.time >= this.startTime && slot.time <= this.endTime) {
          this.filteredSlots.push(slot);
        }
      })
    }
  }

  // search in filters
  searchFilter(filter) {
    filter = this.filterService.searchInFilter(filter);
  }

  changeStartTime() {
    this.filterByTime();
  }

  changeEndTime() {
    this.filterByTime();
  }

  filterByTime() {
    if (this.startTime && this.endTime && this.startTime <= this.endTime) {
      this.filteredSlots.map(items => {
        items.selected = false;
      });
      this.selectAllSlots = false;
      this.filteredSlots = this.filterService.filterDataV2(this.slots, this.filters);
      const slotList = this.filteredSlots;
      this.filteredSlots = [];
      slotList.map(slot => {
        if (slot.time >= this.startTime && slot.time <= this.endTime) {
          this.filteredSlots.push(slot);
        }
      })
    }
  }

}