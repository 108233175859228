import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ApiService } from "../../../common/api.service";

@Injectable({
  providedIn: "root"
})
export class CartService {
  API_URL: string = environment.BASE_URL + "/home";

  constructor(private apiService: ApiService) {}

  cancelOrder(id) {
    const params = {
      orderId: id
    };
    const urlPath = this.API_URL + "/retailer/cancelOrder";
    return this.apiService.doUpdateForQuery(urlPath, params);
  }

  getMinCartValue(): Observable<any> {
    const urlPath = this.API_URL + "/getMinCartValue";
    return this.apiService.doGetObservable(urlPath);
  }

  addToCart(data): Observable<any> {
    const urlPath = this.API_URL + "/cart";
    return this.apiService.doPostObservable(urlPath, data);
    // .pipe(map(response => response.data.cartResponseList));
  }

  removeFromCart(params): Observable<any> {
    const urlPath = this.API_URL + "/deleteCart";
    return this.apiService.doDeleteForQuery(urlPath, params);
    // .pipe(map(response => response.data.cartResponseList));
  }

  getCartItems(retailer): Observable<any> {
    const urlPath = this.API_URL + "/viewCart/" + retailer;
    return this.apiService
      .doGetObservable(urlPath)
      .pipe(map(response => response.data));
  }

  getPlacedOrders(retailer, page): Observable<any> {
    const urlPath = this.API_URL + "/viewPlacedOrder/" + retailer + "?page=" + page;
    return this.apiService
      .doGetObservable(urlPath)
      .pipe(map(response => response));
  }

  placeOrder(data): Observable<any> {
    const urlPath = this.API_URL + "/v3/order";
    return this.apiService
      .doPostObservable(urlPath, data)
      .pipe(map(response => response.data));
  }

  getDeliverySlots(): Observable<any> {
    const urlPath = this.API_URL + "/getDeliverySlots";
    return this.apiService
      .doGetObservable(urlPath)
      .pipe(map(response => response.data));
  }

  getDeliveryCharge(params): Observable<any> {
    const urlPath = this.API_URL + "/getDeliveryCharge";
    return this.apiService.doGetObservableForQuery(urlPath, params).pipe(
      map(response => response.data.deliveryCharge));
  }
}
