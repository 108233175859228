import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { AuthService } from "src/app/core/services/api/api-auth.service";
import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  ElementRef,
  Output,
  EventEmitter
} from "@angular/core";
import {
  MatSidenav,
  MatSidenavContainer,
  MatBottomSheet
} from "@angular/material";
import { Subscription } from "rxjs";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { SideNavEventHandlerService } from "src/app/common/event/sidenav-event-handler.service";
import { ScrollEventService } from "src/app/common/event/scroll-event.service";
import { AppDataService } from "src/app/app-data/app-data.service";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"]
})
export class AdminComponent implements OnInit {
  showWholesale = false;
  showRetailer = false;
  showSupply = false;
  showAccount = false;
  showBanner = false;
  _drawerOpened: boolean = false;
  _slideMode = "over";
  adminHome=false;
  _mNavMode = "side";
  _mNavOpen = false;
  _mShowMenu = false;
  auth;
  isAuthenticated: boolean = false;
  panelOpenState = false;
  isExpanded = false;

  subscriptions: Subscription[] = [];
  mCurrentHeading = 1;
  showClub = false;
  showUser = false;
  showInvoice = false;
  showGraph = false;
  showLedger = false;
  showProdMasterSideMenu=false
  showCreditManagement= false

  constructor(
    private authService: AuthService,
    private appDataService: AppDataService,
    private location: Location,
    private router: Router,
    private scrollEventService: ScrollEventService,
    private media: MediaObserver,
    private sideNavEventHandlerService: SideNavEventHandlerService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.media.media$.subscribe((mediaChange: MediaChange) => {
      this._mNavMode = this.getMode(mediaChange);
      // console.log("sidena3:", this._mNavMode);
      this.updateSlideMode(mediaChange);
      // this.opened = this.getOpened(mediaChange);
      
      this.router.events.subscribe(val => {
        if(this.router.url.split('/')[2]){
             this.adminHome=true
        }else{
          this.adminHome=false
        }
        if (this.location.path() != "") {
          const routePath = this.location.path();

          return;
        } else {
        }
      });
    });
  }

  @ViewChild("fixedContent", { static: true }) public fixedContent: ElementRef;

  @ViewChild("mainDrawer", { static: true }) sidenav: MatSidenav;

  @ViewChild("sideNavContainer", { static: true })
  myScrollContainer: MatSidenavContainer;

  @ViewChild("contentScroller", { static: true }) contentScroller: ElementRef;

  @Output() public sidenavToggle = new EventEmitter();
  @Output() sidenavClose = new EventEmitter();

  toggleSupply() {
    this.showSupply = !this.showSupply;
  }

  toggleAccount() {
    this.showAccount = !this.showAccount;
  }

  toggleRet() {
    this.showRetailer = !this.showRetailer;
  }

  toggleClub() {
    this.showClub = !this.showClub;
  }

  toggleUser() {
    this.showUser = !this.showUser;
  }

  toggleLedger() {
    this.showLedger = !this.showLedger;
  }

  toggleInvoice() {
    this.showInvoice = !this.showInvoice;
  }

  toggleGraph() {
    this.showGraph = !this.showGraph;
  }

  toggleBanner() {
    this.showBanner = !this.showBanner;
  }

  toggleWholesale() {
    this.showWholesale = !this.showWholesale;
  }

  logout() {
    this.authService.logout().subscribe(
      value => {
        // console.log("logout: ", value);
        this.router.navigate(["/authenticate"]);
        this.appDataService.setData("IsUserExist", "false", "local");
      },
      error => {
        console.log("error", error);
      }
    );
  }

  ngOnInit() {
    if (this.router.url.includes("inventory") || this.router.url.includes("exception-medicines") || this.router.url.includes("deal-disable") || this.router.url.includes("wrongly-mapped-medicine") || this.router.url.includes("zipcode-mapping") || this.router.url.includes("addmeds") || this.router.url.includes("mapping") || this.router.url.includes("mfac-mapping") || this.router.url.includes("distributor-board") || this.router.url.includes("promote") || this.router.url.includes("handle-meds") || this.router.url.includes("image-upload") || this.router.url.includes("manufacturer-image-upload") || this.router.url.includes("stock-audit") || this.router.url.includes("distributor-promotion") || this.router.url.includes("enable-disable-medicines") || this.router.url.includes("map-prod-master-medicines") || this.router.url.includes("price-mapping") || this.router.url.includes("medicine-tagging")) {
      this.showSupply = true;
    } else if (this.router.url.includes("enable-newUser") || this.router.url.includes("disable-retailer") || this.router.url.includes("push-notification") || this.router.url.includes("sales-person-mapping") || this.router.url.includes("create-new-user")) {
      this.showUser = true;
    } else if (this.router.url.includes("consolidate-orders")) {
      this.showClub = true;
    } else if (this.router.url.includes("actual-inventory-upload") || this.router.url.includes("invoice-generator") || this.router.url.includes("seller-invoice") || this.router.url.includes("seller-past-invoice") || this.router.url.includes("buyer-invoice") || this.router.url.includes("buyer-past-invoice") || this.router.url.includes("upload-invoice") || this.router.url.includes("billing") || this.router.url.includes("coins") || this.router.url.includes("print-bill-logs") || this.router.url.includes("new-billing") || this.router.url.includes("print-selected-bill") || this.router.url.includes("generate-manual-bill")) {
      this.showInvoice = true;
    } else if (this.router.url.includes("debit-note") || this.router.url.includes("club-credit-items") || this.router.url.includes("account-buyer-invoice") || this.router.url.includes("account-buyer-past-invoice") || this.router.url.includes("partial-return-order") || this.router.url.includes("return-order") || this.router.url.includes("rate-difference-credit-note") || this.router.url.includes("upload-mis") || this.router.url.includes("upload-credit-payment") || this.router.url.includes("upload-credit-bill-payment") || this.router.url.includes("manual-loantap-posting")) {
      this.showAccount = true;
    } else if (this.router.url.includes("ledger-past-seller-invoice") || this.router.url.includes("ledger-payment") || this.router.url.includes("see-ledger")) {
      this.showLedger = true;
    } else if (this.router.url.includes("banner-management")) {
      this.showBanner = true;
    } else if (this.router.url.includes("wholesale-inventory")) {
      this.showWholesale = true;
    }
    
   
    this.subscriptions[
      this.subscriptions.length
    ] = this.scrollEventService
      .getActionMainEmitter()
      .subscribe((heading: Number) => {
        this.mCurrentHeading = heading as number;
        this.scrollToHeader(heading);
      });

      //hget userData
      let userData = JSON.parse(localStorage.getItem('userData'))
      this.showCreditManagement = userData.user.creditAdmin;

  }

  navigateTo(heading: Number) {
    this.scrollEventService.emitHeadingEvent(heading);
    // this.closeDrawer()
  }
  scrollToHeader(heading: Number) {
    const headerClass: String = this.getHeaderClass(heading);
  }

  getHeaderClass(heading: Number): String {
    // switch (heading) {
    //   case ScrollEventService.SUB_HEADING.home:
    //     return '#home'

    //   default:
    //     break;
    // }
    return "";
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private updateSlideMode(mediaChange: MediaChange) {
    if (this.media.isActive("lt-md")) {
      this._slideMode = "over";
      this._drawerOpened = false;
    } else {
      this._slideMode = "over";
      this._drawerOpened = false;
    }
  }
  onActionLaunch(event) {
    this._mNavOpen = true;
    const isOpened = this.sidenav.opened;
    this.sidenav.toggle();
    this.sideNavEventHandlerService.emitMainEvent(!isOpened);
    // console.log(event);
  }

  onActivate(e, outlet) {
    this.contentScroller.nativeElement.scrollTop = 0;
    // this.myScrollContainer.scrollable.getElementRef().nativeElement.scrollTop = 0;
  }

  getMode(mediaChange: MediaChange): string {
    if (this.media.isActive("lt-md")) {
      // this._mNavOpen = false;
      return "";
    } else {
      // this._mNavOpen = false;
      return "side";
    }
  }

  _backdropToggle(type) {
    if (type === "backdrop") {
      this._drawerOpened = !this._drawerOpened;
    }
  }

  _toggleSideNav(sideNav) {
    // console.log(sideNav.opened);
    // if (sideNav.opened == false) {
    this._drawerOpened = !this._drawerOpened;
    // }
  }
}
