import { Component, OnInit, Inject,ElementRef, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelNewDisease } from './model';
import { ProductMasterService } from 'src/app/core/services/api/product-master.service';
import { PopupService } from 'src/app/core/services/popup/popup.service';
@Component({
  selector: 'app-add-new-disease',
  templateUrl: './add-new-disease.component.html',
  styleUrls: ['./add-new-disease.component.scss']
})
export class AddNewDiseaseComponent implements OnInit {

  typesData=['CAUTIONS','DISEASES','SIDE_EFFECTS']
  manufacture=''
  selectedType=''
  dataObj={}

  constructor(public dialogRef: MatDialogRef<AddNewDiseaseComponent>,private productMaster: ProductMasterService,private popupService:PopupService,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelNewDisease,) { }


  ngOnInit(): void {
         if(this.data.data.request==="SUBCHILDS"){
           this.typesData=['FORM','PACK_TYPE','PACK_UNIT']
           this.dataObj={FORM:{value:''},PACK_TYPE:{value:''},PACK_UNIT:{value:''}}
           this.selectedType="FORM"
         }else{
          this.dataObj={CAUTIONS:{value:''},DISEASES:{value:''},SIDE_EFFECTS:{value:''}}
          this.selectedType="CAUTIONS"
         }
  }

  create(){
    this.popupService.openProgress()
    const body={
     "bridgeId":null,
     "productMappingRequestDTO":
       {
         "dataInteger":null,
         "dataString":this.dataObj[this.selectedType].value,
         "entryType": "TEMP",
         "id": "1",
         "operationType": "CREATE",
         "parentRequestId":null,
         "timeStamp": new Date().getTime(),
         "type":this.selectedType
       }
     ,
     "status": "CREATE"
   }
   
   this.productMaster.createEntity({},body).subscribe(response=>{
     this.popupService.closeProgress()
     this.cancel()
    this.popupService.openSuccess(`${this.selectedType} Submitted for Approve`)
   },error=>{
     this.popupService.closeProgress()
     this.popupService.openError(error.error.data.localizedMessage)
   })
  }


  onKeyup(){
    this.dataObj[this.selectedType].value=this.dataObj[this.selectedType].value.toUpperCase()
  }

  typeChange(){
    if(this.data.data.request==="SUBCHILDS"){
      this.typesData=['FORM','PACK_TYPE','PACK_UNIT']
      this.dataObj={FORM:{value:''},PACK_TYPE:{value:''},PACK_UNIT:{value:''}}
    }else{
     this.dataObj={CAUTIONS:{value:''},DISEASES:{value:''},SIDE_EFFECTS:{value:''}}
    }

  }
  cancel() {
    this.dialogRef.close(true);
    
  }

}
