import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FinanceDNDialogModel } from './model';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { FinanceService } from 'src/app/core/services/api/finance.service';

@Component({
  selector: 'app-finance-dn',
  templateUrl: './finance-dn.component.html',
  styleUrls: ['./finance-dn.component.scss']
})
export class FinanceDnComponent implements OnInit {
  debitNotes = [];

  constructor(
    public dialogRef: MatDialogRef<FinanceDnComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FinanceDNDialogModel,
    private financeService: FinanceService,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {
    this.popupService.openProgress();
    this.getDebitNotes();
  }

  getDebitNotes() {
    const params = {
      dnNumbers: this.data.dnNumbers,
      creditNoteValue: this.data.cnValue
    }
    this.financeService.getDebitNoteList(params).subscribe(
      response => {
        this.debitNotes = response.data;
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  close() {
    this.dialogRef.close();
  }

}
