import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blank-container',
  templateUrl: './blank-container.component.html',
  styleUrls: ['./blank-container.component.scss']
})
export class BlankContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
