import { Component, OnInit, Inject } from '@angular/core';
import {FormControl} from "@angular/forms";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelAddMed } from './model';
import { SupplyService } from "src/app/core/services/api/supply.service";
import { PopupService } from "src/app/core/services/popup/popup.service";
import { debounceTime } from 'rxjs/operators';
import { AdminService } from "src/app/core/services/api/admin.service";

// import { Observable, Subscription } from "rxjs";
@Component({
  selector: 'app-add-medicine-si',
  templateUrl: './add-medicine-si.component.html',
  styleUrls: ['./add-medicine-si.component.scss']
})
export class AddMedicineSiComponent implements OnInit {
  myControl = new FormControl();
  options=[];
  selectedOption={
    option:{},
    inpValue:"",
    id:'',
    schemeData:{editable:false,schemes:[]}
  }
  schemes=[];
  entryScheme={x:{value:'',error:false},y:{value:'',error:false}}
  orderedQuantity;
  quantityError=false;
  currentRoute=window.location.pathname.split('/').slice(-1).join("");

 timer
  constructor(private supplyService: SupplyService,
    private popupService: PopupService,
    public dialogRef: MatDialogRef<AddMedicineSiComponent>,
    private adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelAddMed,) { 
    
    }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close(null);
  }

  searchMed(event){
    if(!event.target.value.length){
      this.options=[];
    }
    clearInterval(this.timer)
    this.timer=setTimeout(()=>{
      const params={
        searchString:event.target.value,
        page:0
      }
      
      this.supplyService.searchMedicine(params).pipe(debounceTime(1000)).subscribe(
        response => {
             this.options=response.data
        },
        error => {
          this.popupService.closeProgress()
          this.popupService.openError(error.error.error.error);
        }
      )
    },500)
    
  }
  searchMedTest(event){
    if(!event.target.value.length){
      this.options=[];
    }
    clearInterval(this.timer)
    this.timer=setTimeout(()=>{
      const params={
        searchString:event.target.value,
        page:0,
        billingKey:this.data.data.billingKey

      }
      
      this.adminService.searchMedicinesTest2(params).pipe(debounceTime(1000)).subscribe(
        response => {
             this.options=response.data
        },
        error => {
          this.popupService.closeProgress()
          this.popupService.openError(error.error.error.error);
        }
      )
    },500)
    
  }


  searchMedSaveoInv(event){
    if(!event.target.value.length){
      this.options=[];
    }
    clearInterval(this.timer)
    this.timer=setTimeout(()=>{
      const params={
        name:event.target.value,
        billType:this.data.data.billType
      }
      
      this.supplyService.searchMedicineSaveoInv(params).pipe(debounceTime(1000)).subscribe(
        response => {
             this.options=response.data
        },
        error => {
          this.popupService.closeProgress()
          this.popupService.openError(error.error.error.error);
        }
      )
    },500)
    
  }

  addScheme(){
    if(this.entryScheme.x.value.length<1){
      this.entryScheme.x.error=true
    }
    if(this.entryScheme.y.value.length<1){
      this.entryScheme.y.error=true
    }
    if(this.entryScheme.x.value.length>0 && this.entryScheme.y.value.length>0){
      this.entryScheme.x.error=false;
      this.entryScheme.y.error=false;
      this.selectedOption.schemeData.schemes.push(this.entryScheme.x.value+'+'+this.entryScheme.y.value)
      this.entryScheme={x:{value:'',error:false},y:{value:'',error:false}}
    }
   
  }
  deleteScheme(index){
    this.selectedOption.schemeData.schemes.splice(index,1)
  }
  addMedicine(){
    // console.log( this._mFormGroup.controls['file'].value,this.option)
    this.popupService.openProgress()
    const data=[{
      edited: true,
      newMedicineId: {value:this.selectedOption.id},
      orderRetrieveId: {value: this.data.data.id}
      }]
      this.supplyService.updateBill(data).subscribe(
        response => {
          this.popupService.closeProgress()
          this.dialogRef.close(true);
        },
        error => {
          this.popupService.closeProgress()
          this.popupService.openError(error.error.error.error);
        }
      )
  }

  addMedicineTest(){
    // console.log( this._mFormGroup.controls['file'].value,this.option)
    this.popupService.openProgress()
    const data=[{
      edited: true,
      newMedicineId: {value:this.selectedOption.id},
      orderRetrieveId: {value: this.data.data.id}
      }]
      this.adminService.updateBillTest2(data).subscribe(
        response => {
          this.popupService.closeProgress()
          this.dialogRef.close(true);
        },
        error => {
          this.popupService.closeProgress()
          this.popupService.openError(error.error.error.error);
        }
      )
  }

  

  requestMedFromSaveoInv(){
    if(!this.orderedQuantity){
      this.quantityError=true
    }else{
      this.quantityError=false;
      this.popupService.openProgress()
      const data={
        "availableQuantity":this.selectedOption.option['quantity'],
        "businessType":this.selectedOption.option['businessType'],
        "itemCode": this.selectedOption.option['itemCode'],
        "manufacturer": this.selectedOption.option['manufacturer'],
        "medicineId": this.selectedOption.option['medicineId'],
        "medicineName": this.selectedOption.option['name'],
        "orderRetrieveId": this.data.data.id,
        "packaging": this.selectedOption.option['packaging'],
        "ptr": this.selectedOption.option['ptr'],
        "quantity": this.orderedQuantity,
        "schemeList": this.selectedOption.schemeData.schemes
      }
        this.supplyService.requestMedFromSaveoInv({},data).subscribe(
          response => {
            this.popupService.closeProgress()
            this.dialogRef.close(true);
          },
          error => {
            this.popupService.closeProgress()
            this.popupService.openError(error.error.error.error);
          }
        )
    }
   
  }

  selectMed(option){
   let inpstring=`${option.name} | PTR : ${option.ptr} | ${option.distributorName} | ${option.availableQuantity}`
    this.selectedOption={
      option:option,
      inpValue:inpstring,
      id:option.id,
      schemeData:{editable:false,schemes:[]}
    }
    this.options=[]
  }
  selectMedSaveoInv(option){
    let inpstring=`${option.name} | PTR : ${option.ptr} | ${option.quantity} | ${option.businessType}`
    this.selectedOption={
      option:option,
      inpValue:inpstring,
      id:option.id,
      schemeData:{editable:false,schemes:[]}
    }

    this.options=[];
    const params={
      orderRetrieveId:this.data.data.id,
      medicineId:option.medicineId
    }
    this.supplyService.getSchemeBillModification(params).subscribe(
      response => {
        this.popupService.closeProgress()
        this.selectedOption.schemeData.editable=response.data.schemeEditable
        this.selectedOption.schemeData.schemes=response.data.availableScheme
      },
      error => {
        this.popupService.closeProgress()
        this.popupService.openError(error.error.error.error);
      }
    )
  }

}
