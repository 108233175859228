import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// import { stat } from 'fs';
import { DialogWholesalePickSlip } from './model';
import { PopupService} from 'src/app/core/services/popup/popup.service';
import { WholesaleService } from 'src/app/core/services/api/wholesale.service';

@Component({
  selector: 'app-wholesale-pick-slip',
  templateUrl: './wholesale-pick-slip.component.html',
  styleUrls: ['./wholesale-pick-slip.component.scss']
})
export class WholesalePickSlipComponent implements OnInit {

  constructor(
    private popupService: PopupService,
    private wholesaleService: WholesaleService,
    public dialogRef: MatDialogRef<WholesalePickSlipComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogWholesalePickSlip) { }

    ordersData={
      orderDetailWholesaleDistributorList:[]
    }

    copyOrdersData={
      orderDetailWholesaleDistributorList:[]
    }
    showEditNote=true 
    showEditBtn=false
  ngOnInit(): void {
    this.getOrdersData()
  }

  getOrdersData(){
    this.popupService.openProgress()

    this.wholesaleService.getOrderDetails({orderId:this.data.data.order.orderId}).subscribe((res)=>{
           this.ordersData=res.data
           this.copyOrdersData=JSON.parse(JSON.stringify(res.data))
           this.popupService.closeProgress()
    },
    error => {
      this.popupService.closeProgress()
      this.popupService.openError(error.error.error.error);
    }
    )
  }

  enableInvoice(){
    this.popupService.openProgress()
   this.wholesaleService.enableInvoice({orderId:this.data.data.order.orderId}).subscribe((res)=>{
    this.popupService.closeProgress()
    this.submit()
   },
    error => {
      this.popupService.closeProgress()
      this.popupService.openError(error.error.error.error);
    })
  }

  submit() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  enableEdit(status){
 this.showEditNote=false;
 status && (this.showEditBtn=true)
  }

  itemEdit(index,item){
     
     this.ordersData['orderDetailWholesaleDistributorList'][index].edit=true
  }

  itemSave(index,item){
    if(item.quantity>this.copyOrdersData.orderDetailWholesaleDistributorList[index].orderedQuantity){
      this.popupService.openError('Quantity shouldnt be greater than ordered quantity ')
      item.quantity=this.copyOrdersData.orderDetailWholesaleDistributorList[index].quantity
   }else if(item.quantity==0){
    this.popupService.openError('Quantity shouldnt be zero ')
    item.quantity=this.copyOrdersData.orderDetailWholesaleDistributorList[index].quantity
   }else{
    this.ordersData['orderDetailWholesaleDistributorList'][index].edit=false
    this.popupService.openProgress()
    const params={
      orderDetailId:item.idOfOrderMedicineDetail,
      orderId:this.data.data.order.orderId,
      editQuantity:item.quantity
    }
    this.wholesaleService.editInvoice(params,{}).subscribe((res)=>{
      this.popupService.closeProgress()
      this.getOrdersData()
    },
    error => {
      this.popupService.closeProgress()
      this.popupService.openError(error.error.error.error);
      this.getOrdersData()
    })
   }
   
 }



 openLiveSo(){
  
  let data={
      order:this.data.data.order
  }
    this.popupService.openWholesaleSo(data).afterClosed().subscribe(
      resposne=>{
        this.dialogRef.close(true)
      }
    )

 }

}
