import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiDataService } from "src/app/core/services/api/api.data.service";
import { MedicinesResponsesList } from "src/app/shared/common-item-card/model";
import { AuthService } from "../services/api/api-auth.service";
import { PopupService } from "src/app/core/services/popup/popup.service";

@Injectable({
  providedIn: "root"
})
export class StoreService {
  private loadMoreDataSubject = new BehaviorSubject<boolean>(false);
  loadMoreData$: Observable<any> = this.loadMoreDataSubject.asObservable();

  private specialSchemeSubject = new BehaviorSubject<MedicinesResponsesList[]>(
    []
  );
  specialScheme$: Observable<
    MedicinesResponsesList[]
  > = this.specialSchemeSubject.asObservable();

  private regularSchemeSubject = new BehaviorSubject<MedicinesResponsesList[]>(
    []
  );
  regularScheme$: Observable<
    MedicinesResponsesList[]
  > = this.regularSchemeSubject.asObservable();

  private popularSchemeSubject = new BehaviorSubject<MedicinesResponsesList[]>(
    []
  );
  popularScheme$: Observable<
    MedicinesResponsesList[]
  > = this.popularSchemeSubject.asObservable();

  private profileSubject = new BehaviorSubject<any>([]);
  profile$: Observable<any> = this.profileSubject.asObservable();

  private productPageSubject = new BehaviorSubject<any>([]);
  productPage$: Observable<any> = this.productPageSubject.asObservable();

  constructor(
    private apiDataService: ApiDataService,
    private authService: AuthService,
    private popupService: PopupService
  ) {}

  initPopularScheme() {
    this.apiDataService.getPopularScheme().subscribe(data => {
      this.popularSchemeSubject.next(data);
    });
  }

  initProfile() {
    this.authService.getProfileData().subscribe(data => {
      this.profileSubject.next(data.data);
      // console.log(data);
    });
  }

  initSpecialScheme() {
    const page = 0;
    this.apiDataService.getSpecialScheme(page, "").subscribe(data => {
      this.specialSchemeSubject.next(data.medicinesResponsesList);
      this.checkLoadMoreCondition(data);
    });
  }

  loadMoreSpecialScheme(page) {
    this.apiDataService.getSpecialScheme(page, "").subscribe(data => {
      this.specialSchemeSubject.next(
        this.specialSchemeSubject.getValue().concat(data.medicinesResponsesList)
      );
      this.checkLoadMoreCondition(data);
    });
  }

  initRegularScheme() {
    const page = 0;
    this.apiDataService.getRegularScheme(page, "").subscribe(data => {
      this.regularSchemeSubject.next(data.medicinesResponsesList);
      this.checkLoadMoreCondition(data);
    });
  }

  loadMoreRegularScheme(page) {
    this.apiDataService.getRegularScheme(page, "").subscribe(data => {
      this.regularSchemeSubject.next(
        this.regularSchemeSubject.getValue().concat(data.medicinesResponsesList)
      );
      this.checkLoadMoreCondition(data);
    });
  }

  checkLoadMoreCondition(data) {
    if (data.size === 0) {
      this.popupService.openError("No more Medicines Available")
      this.loadMoreDataSubject.next(false);
    } else {
      this.loadMoreDataSubject.next(true);
    }
  }

  initProductPage(queryParams) {
    this.apiDataService.getProductPage(queryParams).subscribe(data => {
      this.productPageSubject.next(data.data);
    });
  }
}
