import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { AuthService } from "src/app/core/services/api/api-auth.service";
import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  ElementRef,
  Output,
  EventEmitter
} from "@angular/core";
import {
  MatSidenav,
  MatSidenavContainer,
  MatBottomSheet
} from "@angular/material";
import { Subscription } from "rxjs";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { SideNavEventHandlerService } from "src/app/common/event/sidenav-event-handler.service";
import { ScrollEventService } from "src/app/common/event/scroll-event.service";
import { AppDataService } from "src/app/app-data/app-data.service";
import { DistributorService } from "src/app/core/services/api/distributor.service";

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  _drawerOpened: boolean = false;
  _slideMode = "over";

  _mNavMode = "side";
  _mNavOpen = false;
  _mShowMenu = false;
  auth;
  isAuthenticated: boolean = false;
  panelOpenState = false;
  isExpanded = false;
  isAdmin = false;

  subscriptions: Subscription[] = [];
  mCurrentHeading = 1;
  showGraph = false;

  constructor(
    private authService: AuthService,
    private appDataService: AppDataService,
    private location: Location,
    private router: Router,
    private scrollEventService: ScrollEventService,
    private media: MediaObserver,
    private sideNavEventHandlerService: SideNavEventHandlerService,
    private distributorService: DistributorService,
    @Inject(DOCUMENT) private document: any) {
      this.media.media$.subscribe((mediaChange: MediaChange) => {
        this._mNavMode = this.getMode(mediaChange);
        // console.log("sidena3:", this._mNavMode);
        this.updateSlideMode(mediaChange);
        // this.opened = this.getOpened(mediaChange);
        this.router.events.subscribe(val => {
          if (this.location.path() != "") {
            const routePath = this.location.path();
  
            return;
          } else {
          }
        });
      });
    }

    @ViewChild("fixedContent", { static: true }) public fixedContent: ElementRef;

    @ViewChild("mainDrawer", { static: true }) sidenav: MatSidenav;
  
    @ViewChild("sideNavContainer", { static: true })
    myScrollContainer: MatSidenavContainer;
  
    @ViewChild("contentScroller", { static: true }) contentScroller: ElementRef;
  
    @Output() public sidenavToggle = new EventEmitter();
    @Output() sidenavClose = new EventEmitter();

  toggleGraph() {
    this.showGraph = !this.showGraph;
  }

  logout() {
    this.authService.logout().subscribe(
      value => {
        // console.log("logout: ", value);
        this.router.navigate(["/authenticate"]);
        this.appDataService.setData("IsUserExist", "false", "local");
      },
      error => {
        console.log("error", error);
      }
    );
  }

  ngOnInit() {
    this.authService.getUserPhoneNumber().subscribe(num => {
      const phone = num;
      console.log(num)
      this.authService.subscribeUserData(phone).subscribe(response => {
        this.isAdmin = response.data.user.admin;
      });
    });
    if (this.router.url.includes("weekly-active-users") || this.router.url.includes("new-user-graph") || this.router.url.includes("weekly-repeat-users") || this.router.url.includes("lost-user-graph") || this.router.url.includes("cohort-graph")) {
      this.showGraph = true;
    }
    this.subscriptions[
      this.subscriptions.length
    ] = this.scrollEventService
      .getActionMainEmitter()
      .subscribe((heading: Number) => {
        this.mCurrentHeading = heading as number;
        this.scrollToHeader(heading);
      });
  }navigateTo(heading: Number) {
    this.scrollEventService.emitHeadingEvent(heading);
    // this.closeDrawer()
  }
  scrollToHeader(heading: Number) {
    const headerClass: String = this.getHeaderClass(heading);
  }

  getHeaderClass(heading: Number): String {
    // switch (heading) {
    //   case ScrollEventService.SUB_HEADING.home:
    //     return '#home'

    //   default:
    //     break;
    // }
    return "";
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private updateSlideMode(mediaChange: MediaChange) {
    if (this.media.isActive("lt-md")) {
      this._slideMode = "over";
      this._drawerOpened = false;
    } else {
      this._slideMode = "over";
      this._drawerOpened = false;
    }
  }

  onActionLaunch(event) {
    this._mNavOpen = true;
    const isOpened = this.sidenav.opened;
    this.sidenav.toggle();
    this.sideNavEventHandlerService.emitMainEvent(!isOpened);
    // console.log(event);
  }

  onActivate(e, outlet) {
    this.contentScroller.nativeElement.scrollTop = 0;
    // this.myScrollContainer.scrollable.getElementRef().nativeElement.scrollTop = 0;
  }

  getMode(mediaChange: MediaChange): string {
    if (this.media.isActive("lt-md")) {
      // this._mNavOpen = false;
      return "";
    } else {
      // this._mNavOpen = false;
      return "side";
    }
  }

  _backdropToggle(type) {
    if (type === "backdrop") {
      this._drawerOpened = !this._drawerOpened;
    }
  }

  _toggleSideNav(sideNav) {
    // console.log(sideNav.opened);
    // if (sideNav.opened == false) {
    this._drawerOpened = !this._drawerOpened;
    // }
  }

}
