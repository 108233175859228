import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ApiService } from "../../../common/api.service";
import { unwrapResolvedMetadata } from "@angular/compiler";

@Injectable({
  providedIn: "root",
})
export class LogisticsService {
  API_URL: string = environment.adminUrl + "/admin";
  preprod_url = "https://preprod.saveo.in/admin";
  constructor(private http: HttpClient, private apiService: ApiService) {}

  getCurrentDeliveryExecutives(): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/getAllDeliveryExecutives";
    return this.apiService.doGetObservable(urlPath);
  }

  getAllDeliveryExecutives(params): Observable<any> {
    const urlPath = this.API_URL + "/logistics/delivery/allDEListing";
    return this.apiService.doGetObservableForQuery(urlPath,params);
  }

  getInvoiceForCollection(params): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/collection/getInvoicesForCollection";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getCollectionSummary(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/collection/getCollectionSummary";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateInvoiceForCollection(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/collection/updateBillPayment";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateDeliveryExecutivePayment(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/collection/updateDEPayment";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getPastPendingPayments(params): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/pastAccount/getPendingPayments";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getPastShortageOrders(params): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/pastAccount/viewShortage";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getPastOnlineNotRecievedOrders(params): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/pastAccount/viewOnlineNotReceived";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  checkPastshortage(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/pastAccount/checkShortage";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updatePastDePayment(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/pastAccount/updateDEPayment";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updatePastInvoicePayment(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/pastAccount/updateInvoicePayment";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getReAttemptInvoices(params): Observable<any> {
    const urlPath = this.API_URL + "/logistics/delivery/reattempt/viewInvoices";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getReAttemptStatus(): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/reattempt/getChangeStatus";
    return this.apiService.doGetObservable(urlPath);
  }

  getReAttemptReasons(): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/reattempt/getReattemptReasons";
    return this.apiService.doGetObservable(urlPath);
  }

  getCancelReasons(): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/reattempt/getCancelReasons";
    return this.apiService.doGetObservable(urlPath);
  }

  updateReAttemptStatus(params, body): Observable<any> {
    const urlPath = this.API_URL + "/logistics/delivery/reattempt/updateStatus";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getSalesReturnRetailers(params): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/salesReturn/getRetailers";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  searchSalesReturnInvoice(params): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/salesReturn/getSearchedInvoice";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getSalesReturnMedicineSuggestions(params): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/salesReturn/getMedicineSuggestion";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  searchMedicineInInvoices(params): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/salesReturn/searchMedicineInInvoices";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  placeSalesReturn(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/salesReturn/placeSalesReturn";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getAdjustInvoiceNumbers(params): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/salesReturn/getAdjustInvoiceNumbers";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getReasonList(): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/salesReturn/getReturnReasons";
    return this.apiService.doGetObservable(urlPath);
  }

  getAmountDetails(): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/account/current/getAmountDetails";
    return this.apiService.doGetObservable(urlPath);
  }

  sendOTP(params): Observable<any> {
    const urlPath = this.API_URL + "/logistics/account/sendOTP";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  verifyOTP(params): Observable<any> {
    const urlPath = this.API_URL + "/logistics/account/verifyOtp";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  sendCashToAccount(params, body): Observable<any> {
    const urlPath = this.API_URL + "/logistics/account/sendCashToAccounts";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getOnlineUR(): Observable<any> {
    const urlPath = this.API_URL + "/logistics/account/current/getOnlineUR";
    return this.apiService.doGetObservable(urlPath);
  }

  getCashShortage(): Observable<any> {
    const urlPath = this.API_URL + "/logistics/account/current/getCashShortage";
    return this.apiService.doGetObservable(urlPath);
  }

  getDeficitReasons(): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/collection/getDeficitReasons";
    return this.apiService.doGetObservable(urlPath);
  }

  getReturnValue(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/salesReturn/getReturnValue";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  advanceDeliveryPaymentReceived(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/collection/markAdvancePayment";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  sendDetailsToCreditor(params, body): Observable<any> {
    const urlPath =
      environment.adminUrl +
      "/credit/admin/initiateOnholdOpenAndOnholdWithdrawForCreditUsers";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getCaActiveCreditNoteRetailer(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/credit/user/getActiveCreditNoteForRetailerAtCA";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAssignedCreditNote(params): Observable<any> {
    const urlPath =
      environment.adminUrl +
      "/credit/user/getUsedCreditNoteByCreditBillPaymentIdAtCA";

    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getExpiryCollectedOrders(params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/expiry/getCollectedOrders";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getExpiryOrderMedicines(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/admin/expiry/getAvailableItemsOfOrder";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateExpiryCoinValue(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/expiry/getReturnValue";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  generateExpiryCreditNote(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/expiry/generateCN";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  rejectExpiryCreditNote(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/expiry/rejectItems";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateExpiryOrderItems(params, body): Observable<any> {
    const urlPath =
      environment.adminUrl + "/admin/expiry/editRetrieveOrderDetails";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getRejectSignedSlipReasons(): Observable<any> {
    const urlPath =
      environment.adminUrl + "/saveo-med/signSlip/getSignSlipReasons";
    return this.apiService.doGetObservable(urlPath);
  }

  uploadSignedSlip(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/saveo-med/signSlip/uploadSignSlipLogistics";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateSignedSlip(params, body): Observable<any> {
    const urlPath =
      environment.adminUrl + "/saveo-med/signSlip/updateSignedSlip";
    return this.apiService.doUpdateWithBody(urlPath, body, params);
  }

  checkSignedSlipUploaded(params): Observable<any> {
    const urlPath =
      environment.adminUrl +
      "/saveo-med/signSlip/isSignSlipCollectedByLogistics";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getCreditCollectionInvoices(params): Observable<any> {
    const urlPath =
      this.API_URL +
      "/logistics/delivery/collection/getInvoicesForCollectionCredit";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getCollectionAgents(page): Observable<any> {
    const urlPath =
      environment.adminUrl +
      "/saveo-med/signSlip/getDeliveryExecutiveListForCollection/" +
      page;
    return this.apiService.doGetObservable(urlPath);
  }

  getCaCollectionInvoices(mobile, page): Observable<any> {
    const urlPath =
      environment.adminUrl +
      "/saveo-med/signSlip/getInvoicesByDeliveryExecutiveAtLogistics/" +
      mobile +
      "/" +
      page;
    return this.apiService.doGetObservable(urlPath);
  }

  getCaCollectionInvoiceDetails(invoiceNo, signedSlipNo): Observable<any> {
    const urlPath =
      environment.adminUrl +
      "/saveo-med/signSlip/getBillPaymentsForInvoice/" +
      invoiceNo +
      "/" +
      signedSlipNo;
    return this.apiService.doGetObservable(urlPath);
  }

  collectCaInvoice(params, body): Observable<any> {
    const urlPath =
      environment.adminUrl +
      "/credit/user/uploadVerifiedCreditBillPaymentDataList";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  searchCa(ca): Observable<any> {
    const urlPath =
      environment.adminUrl +
      "/saveo-med/signSlip/searchDeliveryExecutiveListForCollection/" +
      ca;
    return this.apiService.doGetObservable(urlPath);
  }

  searchInvoice(caMobile, ca, page): Observable<any> {
    const urlPath =
      environment.adminUrl +
      "/saveo-med/signSlip/searchInvoicesByDeliveryExecutiveAtLogistics/" +
      caMobile +
      "/" +
      ca +
      "/" +
      page;
    return this.apiService.doGetObservable(urlPath);
  }

  searchInvoicesAtLogistics(params): Observable<any> {
    const urlPath = environment.adminUrl + "/saveo-med/signSlip/searchInvoicesAtLogistics";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getRDCNInvoiceItems(params): Observable<any> {
    const urlPath = this.API_URL + "/rateDifferenceCN/viewInvoiceItems";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  generateRDCN(params, body): Observable<any> {
    const urlPath = this.API_URL + "/rateDifferenceCN/generateRDCN";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getManualBills(params): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/delivery/salesReturn/checkManualBills";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getPaymentModes(): Observable<any> {
    const urlPath =
      environment.adminUrl + "/credit/user/getPaymentModesCreditBillPayment";
    return this.apiService.doGetObservable(urlPath);
  }

  getAllBankStatementWithUTRNo(params): Observable<any>{
    const urlPath = environment.adminUrl + '/saveo-med/bankstetement/getAllBankStatementWithUTRNo';
    return this.apiService.doGetObservableForQuery(urlPath, params)
  }

  getUtrSearchFilters() : Observable<any>{
    const urlPath = environment.adminUrl + '/saveo-med/bankstetement/user/getUtrSearchFilters';
    return this.apiService.doGetObservable(urlPath)
  }

  getBankAccounts() : Observable<any>{
    const urlPath = environment.adminUrl + '/saveo-med/bankstetement/user/getSupportedBanksForBankStatements';
    return this.apiService.doGetObservable(urlPath)
  }

}
