import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogModelCollectSignedSlip } from './model';
import { LogisticsService } from "src/app/core/services/api/logistics.service";
import { PopupService } from "src/app/core/services/popup/popup.service";

@Component({
  selector: 'app-collect-signed-slip',
  templateUrl: './collect-signed-slip.component.html',
  styleUrls: ['./collect-signed-slip.component.scss']
})
export class CollectSignedSlipComponent implements OnInit {
  isSignedSlipUpdate;
  rejectReasons = [];
  currRejectReason;
  approveSlip = true;
  rejectSlip = false;
  otherReason = "";

  constructor(
    private logisticsService: LogisticsService,
    private popupService: PopupService,
    public dialogRef: MatDialogRef<CollectSignedSlipComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelCollectSignedSlip,
    ) {
      console.log(data);
     }

  ngOnInit(): void {
    this.popupService.openProgress();
    this.getRejectReasons(true);
  }

  // get reject reasons
  getRejectReasons(closeProgress) {
    this.logisticsService.getRejectSignedSlipReasons().subscribe(
      response => {
        this.rejectReasons = response.data;
        if (this.rejectReasons && this.rejectReasons.length > 0) {
          this.currRejectReason = this.rejectReasons[0];
        }
        this.rejectReasons.push("Others");
        this.checkSignedSlipCollected(closeProgress);
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  checkSignedSlipCollected(closeProgress) {
    const params = {
      buyerInvoiceNo: this.data.invoiceData.invoiceData.invoiceNumber
    }
    this.logisticsService.checkSignedSlipUploaded(params).subscribe(
      response => {
        this.isSignedSlipUpdate = response.data;
        if (this.isSignedSlipUpdate.flag == true) {
          this.approveSlip = true;
          this.rejectSlip = false;
        } else if (this.isSignedSlipUpdate.flag == false) {
          this.currRejectReason = this.isSignedSlipUpdate.reason;
          this.approveSlip = false;
          this.rejectSlip = true;
        }
        if (closeProgress) {
          this.popupService.closeProgress();
        }
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  // check approve checkbox
  checkApprove() {
    this.rejectSlip = false;
    this.approveSlip = true;
  }

  // check reject checkbox
  checkReject() {
    this.rejectSlip = true;
    this.approveSlip = false;
  }

  // confirm submit
  confirm() {
    this.popupService.openProgress();
    const body = {
      action: "",
      invoiceNumber: this.data.invoiceData.invoiceData.invoiceNumber,
      remarks: ""
    };
    if (this.rejectSlip) {
      body.action = "Rejected_By_Logistics";
      if (this.currRejectReason == 'Others') {
        body.remarks = this.otherReason;
      } else {
        body.remarks = this.currRejectReason;
      }
    } else {
      body.action = "VERIFIED_BY_LOGISTICS";
    }
    if (body.action == "Rejected_By_Logistics" && !body.remarks) {
      this.popupService.closeProgress();
      this.popupService.openError("Invalid Reason.")
    } else {
      this.logisticsService.updateSignedSlip({}, body).subscribe(
        response => {
          this.popupService.closeProgress();
          this.popupService.openSuccess("Success");
          this.dialogRef.close();
        },
        error => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
          this.dialogRef.close();
        }
      )
    }
  }

  // close popup
  cancel() {
    this.dialogRef.close();
  }

}
