import {HttpEvent, HttpHandler, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { EventService } from "../services/event/event.service";


@Injectable({
  providedIn: 'root'
})
export class InterceptorEventService {
  constructor(private eventService: EventService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let url = request.url;
    let endpoint =url.split("/").pop();
    const startTime: Date = new Date();
   
    return next.handle(request).pipe(
          tap((response:HttpResponse<any>) => {
              let resTime=(new Date().valueOf() - startTime.valueOf())
              
               if(endpoint!=='app_events' && resTime>10 && !url.includes("assets/") && !url.includes("firebasestorage.googleapis.com")){

                     let body={
                        eventName:'api_success_time',
                        load_time:resTime,
                        url:url,
                        request:request,
                        timeStamp:new Date().getTime()

                     }
                 this.eventService.apiSuccessTime(body)
               }
          })
    )
  }
}