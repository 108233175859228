import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MissingDialogModel } from './model';
import { SupplyService } from 'src/app/core/services/api/supply.service';
import { PopupService } from 'src/app/core/services/popup/popup.service';

@Component({
  selector: 'app-missing-popup',
  templateUrl: './missing-popup.component.html',
  styleUrls: ['./missing-popup.component.scss']
})
export class MissingPopupComponent implements OnInit {
  view = "landingView"
  returnOptions = [];

  constructor(
    public dialogRef: MatDialogRef<MissingPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MissingDialogModel,
    private supplyService: SupplyService,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {
    console.log(this.data)
  }

  found() {
    this.getReturnOptions();
    this.view = "foundView";
  }

  notFound() {
    this.view = "notFoundView";
  }

  closeIt() {
    this.dialogRef.close(null);
  }

  // Get return options list
  getReturnOptions() {
    this.supplyService.getReturnOptions({}, this.data.item).subscribe(
      response => {
        this.returnOptions = response.data;
        console.log(this.returnOptions)
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  // Handle actions
  sendItem(action) {
    if (action == 'Damaged') {
      this.sendToDamaged();
    } else if (action.includes(':')) {
      this.sendForDn();
    } else {
      this.sendToInventory();
    }
  }

  missing() {
    this.popupService.openProgress();
    const params = {
      missingType: 'Missing from Supplier'
    };
    const body = this.data.item;
    this.supplyService.setNotFound(params, body).subscribe(
      response => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data);
        this.dialogRef.close(true);
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
        this.dialogRef.close(true);
      }
    )
  }

  lost() {
    this.popupService.openProgress();
    const params = {
      missingType: 'Lost'
    };
    const body = this.data.item;
    this.supplyService.setNotFound(params, body).subscribe(
      response => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data);
        this.dialogRef.close(true);
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
        this.dialogRef.close(true);
      }
    )
  }

  sendForDn() {
    const body = [];
    body.push(this.data.item)
    this.supplyService.sendForDebitNote({}, body).subscribe(
      response => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data);
        this.dialogRef.close(true);        
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
        this.dialogRef.close(true);
      }
    )
  }

  sendToInventory() {
    const body = [];
    body.push(this.data.item)
    console.log(body)
    this.supplyService.sendToInventory({}, body).subscribe(
      response => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data);  
        this.dialogRef.close(true);      
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
        this.dialogRef.close(true);
      }
    )
  }

  sendToDamaged() {
    const body = [];
    body.push(this.data.item)
    this.supplyService.setDamaged({}, body).subscribe(
      response => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data); 
        this.dialogRef.close(true);       
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
        this.dialogRef.close(true);
      }
    )
  }

}
