import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UserModel } from "src/app/core/services/params/user.model";
import { CartRequestList } from "src/app/core/services/params/cart.model";
import { MatDialog, MatDialogRef } from "@angular/material";
import { CartService } from "src/app/core/services/api/cart.service";
import { CartStoreService } from "src/app/core/store/cart-store.service";
import { DialogErrorComponent } from "src/app/shared/dialog/dialog-error/dialog-error.component";
import { DialogModelError } from "src/app/shared/dialog/dialog-error/model";
import { DialogProgressComponent } from "src/app/shared/dialog/dialog-progress/dialog-progress.component";
import { DialogModelProgress } from "src/app/shared/dialog/dialog-progress/model";
import { DialogSuccessComponent } from "src/app/shared/dialog/dialog-success/dialog-success.component";
import { DialogModelSuccess } from "src/app/shared/dialog/dialog-success/model";
import { EventService } from "src/app/core/services/event/event.service";

@Component({
  selector: "app-common-search-container",
  templateUrl: "./common-search-container.component.html",
  styleUrls: ["./common-search-container.component.scss"]
})
export class CommonSearchContainerComponent implements OnInit {
  screenName;
  _overCard: boolean;
  quantity: number = 1;
  showInput = false;
  retailer: UserModel = JSON.parse(localStorage.getItem("userData"));
  mDialogRef: MatDialogRef<DialogErrorComponent, any>;
  _mInProgress = false;

  _mModel: any;
  _mSchemeType: string;
  @Input("model") set val(value: any) {
    if (value) {
      this._mModel = value;
      // console.log(value);
    }
  }

  @Input() set schemeType(value: string) {
    if (value) { this._mSchemeType = value; }
    // console.log(value);
  }


  @Input() set screen_name(value: string) {
    if (value) { this.screenName = value; }
    // console.log(value);
  }

  @Output() clearSearch: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private cartService: CartService,
    private cartStoreService: CartStoreService,
    public dialog: MatDialog,
    private eventService: EventService
  ) {}

  ngOnInit() {}

  increment() {
    this.quantity += 1;
  }

  decrement() {
    if (this.quantity > 1) {
      this.quantity -= 1;
    } else {
      this.showInput = false;
    }
  }

  _toggleAddToCart() {
    this.showInput = true;
    // this.eventService.addToCart(this._mModel.ptr, this._mModel.id, this._mModel.name, this.screenName);
  }

  cartRequestList: CartRequestList[] = [];

  confirmAddingToCart(itemId: string, quantity: number) {
    this.showInput = false;
    this.cartRequestList.push({ medicineId: itemId, quantity: quantity });
    let retailer = this.retailer.user.id;
    let size = this.cartRequestList.length;
    let dataToSend = {
      cartRequestList: this.cartRequestList,
      retailerId: retailer,
      size: size
    };
    this.PostToCartService(dataToSend);
  }

  PostToCartService(data) {
    // console.log(this.cartRequestList);
    this.openProgress();
    this.cartService.addToCart(data).subscribe(
      () => {
        this.cartStoreService.initCartItems();
        this.closeProgress();
        this.openSuccess("Success");
        this.clearSearch.emit("discard");
        // this.eventService.confirmAddToCart(this._mModel.ptr, this._mModel.id, this._mModel.name, this.screenName);
      },
      error => {
        this.closeProgress();
        this.openError(error.error.error.error);
      }
    );
    this.cartRequestList = [];
  }

  /***************************************************************************************************************
   * 											Progress
   */

  autoClose() {
    setTimeout(() => {
      if (this.mDialogRef) this.mDialogRef.close();
    }, 3000);
  }

  openProgress() {
    this._mInProgress = true;
    let alert: DialogModelProgress = new DialogModelProgress(
      "Please Wait...",
      "",
      null,
      null
    );

    this.mDialogRef = this.dialog.open(DialogProgressComponent, {
      data: alert,
      disableClose: true
    });
  }

  closeProgress() {
    this._mInProgress = false;
    if (this.mDialogRef) this.mDialogRef.close();
  }

  openSuccess(response: string) {
    this._mInProgress = true;
    let alert: DialogModelSuccess = new DialogModelSuccess(
      response,
      "",
      null,
      null
    );

    this.mDialogRef = this.dialog.open(DialogSuccessComponent, {
      data: alert,
      disableClose: false
    });
    this.autoClose();
  }

  closeSuccess() {
    this._mInProgress = false;
    if (this.mDialogRef) this.mDialogRef.close();
  }

  openError(response: string) {
    this._mInProgress = true;
    let alert: DialogModelError = new DialogModelError(
      response,
      "",
      null,
      null
    );

    this.mDialogRef = this.dialog.open(DialogErrorComponent, {
      data: alert,
      disableClose: false
    });
    this.autoClose();
  }

  closeError() {
    this._mInProgress = false;
    if (this.mDialogRef) this.mDialogRef.close();
  }
}
