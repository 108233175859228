import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelMultipleImages } from './model';

@Component({
  selector: 'app-multiple-images',
  templateUrl: './multiple-images.component.html',
  styleUrls: ['./multiple-images.component.scss']
})
export class MultipleImagesComponent implements OnInit {
  currImage = 0;
  currImageUrl = "";
  length = 0;
  constructor(
    public dialogRef: MatDialogRef<MultipleImagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelMultipleImages,
    ) {
      if (this.data && this.data.imageUrls) {
      this.currImage = 0;
      this.currImageUrl = this.data.imageUrls[this.currImage];
      this.length = this.data.imageUrls.length;
    }}

  ngOnInit(): void {
  }

  getNextImage() {
    if (this.currImage < this.data.imageUrls.length - 1) {
      this.currImage++;
      this.currImageUrl = this.data.imageUrls[this.currImage];
    }
  }

  getPreviousImage() {
    if (this.currImage > 0) {
      this.currImage--;
      this.currImageUrl = this.data.imageUrls[this.currImage];
    }
  }

  closeIt() {
    this.dialogRef.close();
  }

}
