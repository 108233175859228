import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelGSTResponse } from './model';

@Component({
  selector: 'app-gst-response',
  templateUrl: './gst-response.component.html',
  styleUrls: ['./gst-response.component.scss']
})
export class GstResponseComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<GstResponseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelGSTResponse) { }

  ngOnInit(): void {
    
  }

  cancel() {
    
      this.dialogRef.close(false);
   
  }

}
