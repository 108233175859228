import { Location } from "@angular/common";
import { UserModel } from "./../services/params/user.model";
import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs/internal/Observable";
import { AppDataService } from "../../app-data/app-data.service";
import { RouteConfig } from "../configs/route.config";
import { AuthService } from "../services/api/api-auth.service";
import { AuthStoreService } from "../store/auth-store.service";
import { map } from "rxjs/operators";
import { PopupService } from "../services/popup/popup.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate, CanActivateChild {
  _authUser: UserModel;
  count = 0;
  constructor(
    private router: Router,
    private authService: AuthService,
    private ngZone: NgZone,
    private routeConfig: RouteConfig,
    private location: Location,
    private authStoreService: AuthStoreService,
    private appDataService: AppDataService,
    public popupService: PopupService,
  
  ) {
    
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    const url: string = state.url;
    // console.log("Url:" + url);
    return this.checkForActiveRoute(route, state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    const url: string = state.url;
    // console.log('Url1:' + url);
    return this.checkForActiveRoute(childRoute, state);
  }

  checkBlocked() {
    this.authService.getUserPhoneNumber().subscribe((number) => {
      this.authService.subscribeUserData(number).subscribe((response) => {
        const currentUser = response.data;
        if (currentUser.user.blocked) {
          this.router.navigate(["authenticate/block"]);
        }
      });
    });
  }

  checkForActiveRoute(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    const url: string = state.url;
    // alert(url)
    return new Observable<boolean>((observer) => {
      const isUser: UserModel = JSON.parse(
        this.appDataService.getData("IsUserExist")
      );
      if (!isUser) {
        // ------------------ user visits first time / user not loggedin -----------------
        // console.log("user not logged in");
        this.ngZone.run(() => {
          this.router.navigate(["authenticate"]);
        });
        observer.next(false);
        observer.complete();
      } else if (state.url.includes("/check")) {
        this.popupService.openSaveoLoader();
        // this.popupService.openSaveoLoader();
        // this.checkBlocked();
        this.authService.getUserPhoneNumber().subscribe((number) => {
          this.authService.subscribeUserData(number).subscribe(
            (response) => {
              this.popupService.closeSaveoLoader();
              const currentUser = response.data;
              if (currentUser.user.blocked) {
                this.router.navigate(["authenticate/block"]);
              }else{
                if (currentUser.user.enabled) {
                  if (currentUser.user.admin) {
                    this.router.navigate(["admin"]);
                  } else if (currentUser.user.distributor) {
                    this.router.navigate(["dist"]);
                  } else if (currentUser.user.deliveryExecutive) {
                    this.router.navigate(["delivery-exec"]);
                  } else if (currentUser.user.dispatchManager) {
                    this.router.navigate(["dispatch-manager"]);
                  } else if (currentUser.user.analyticsUser) {
                    this.router.navigate(["analysis"]);
                  } else if (currentUser.user.admin) {
                    this.router.navigate(["logistics"]);
                  } else if (currentUser.user.admin) {
                    this.router.navigate(["outstanding"]);
                  } else {
                    this.router.navigate(["shop"]);
                  }
                } else {
                  this.router.navigate(["shop"]);
                }
              }
               
             
            },
            (error) => {
              if (error.status == 401) {
                this.authService.refreshAccessToken();
              }
              this.popupService.closeSaveoLoader();
            }
          );
        });
      } else if (state.url.includes("/admin")) {
        this.popupService.openSaveoLoader();
        // this.checkBlocked();
        // ------------------ check if user is admin ---------------------
        // console.log("route to admin check permissions");
        this.authService.getUserPhoneNumber().subscribe((number) => {
          this.authService.subscribeUserData(number).subscribe(
            (response) => {
              this.popupService.closeSaveoLoader();
              const currentUser = response.data;
              if (currentUser.user.blocked) {
                this.router.navigate(["authenticate/block"]);
              }else{
              if (currentUser.user.enabled) {
                if (currentUser.user.admin) {
                  // console.log("Access granted your are admin user");
                  const subscription = this.authService
                    .subscribeAccessToken()
                    .subscribe(
                      (modelToken) => {
                        if (modelToken) {
                          observer.next(true);
                        } else {
                          this.router.navigate(["authenticate"]);
                          observer.next(false);
                        }
                        // subscription.unsubscribe();
                        observer.complete();
                      },
                      (error) => {
                        this.router.navigate(["authenticate"]);
                        observer.next(false);
                        // subscription.unsubscribe();
                        observer.complete();
                      }
                    );
                } else {
                  // console.log("Access Denied your are not admin user");
                  this.location.back();
                }
              } else {
                this.router.navigate(["authenticate/complete"]);
              }
            }
            },
            (error) => {
              if (error.status == 401) {
                this.authService.refreshAccessToken();
              }
              this.popupService.closeSaveoLoader();
            }
          );
        });
      } else if (state.url.includes("/supply")) {
        this.popupService.openSaveoLoader();
        // this.checkBlocked();
        // ------------------ check if user is admin ---------------------
        // console.log("route to admin check permissions");
        this.authService.getUserPhoneNumber().subscribe((number) => {
          this.authService.subscribeUserData(number).subscribe(
            (response) => {
              this.popupService.closeSaveoLoader();
              const currentUser = response.data;
              if (currentUser.user.blocked) {
                this.router.navigate(["authenticate/block"]);
              }else{
              if (currentUser.user.enabled) {
                if (currentUser.user.admin) {
                  // console.log("Access granted your are admin user");
                  const subscription = this.authService
                    .subscribeAccessToken()
                    .subscribe(
                      (modelToken) => {
                        if (modelToken) {
                          observer.next(true);
                        } else {
                          this.router.navigate(["authenticate"]);
                          observer.next(false);
                        }
                        // subscription.unsubscribe();
                        observer.complete();
                      },
                      (error) => {
                        this.router.navigate(["authenticate"]);
                        observer.next(false);
                        // subscription.unsubscribe();
                        observer.complete();
                      }
                    );
                } else {
                  // console.log("Access Denied your are not admin user");
                  this.location.back();
                }
              } else {
                this.router.navigate(["authenticate/complete"]);
              }
            }
            },
            (error) => {
              if (error.status == 401) {
                this.authService.refreshAccessToken();
              }
              this.popupService.closeSaveoLoader();
            }
          );
        });
      } else if (state.url.includes("/finance")) {
        this.popupService.openSaveoLoader();
        // this.checkBlocked();
        // ------------------ check if user is admin ---------------------
        // console.log("route to admin check permissions");
        this.authService.getUserPhoneNumber().subscribe((number) => {
          this.authService.subscribeUserData(number).subscribe(
            (response) => {
              this.popupService.closeSaveoLoader();
              const currentUser = response.data;
              if (currentUser.user.blocked) {
                this.router.navigate(["authenticate/block"]);
              }else{
              if (currentUser.user.enabled) {
                if (currentUser.user.admin) {
                  // console.log("Access granted your are admin user");
                  const subscription = this.authService
                    .subscribeAccessToken()
                    .subscribe(
                      (modelToken) => {
                        if (modelToken) {
                          observer.next(true);
                        } else {
                          this.router.navigate(["authenticate"]);
                          observer.next(false);
                        }
                        // subscription.unsubscribe();
                        observer.complete();
                      },
                      (error) => {
                        this.router.navigate(["authenticate"]);
                        observer.next(false);
                        // subscription.unsubscribe();
                        observer.complete();
                      }
                    );
                } else {
                  // console.log("Access Denied your are not admin user");
                  this.location.back();
                }
                
              } else {
                this.router.navigate(["authenticate/complete"]);
              }
            }
            },
            (error) => {
              if (error.status == 401) {
                this.authService.refreshAccessToken();
              }
              this.popupService.closeSaveoLoader();
            }
          );
        });
      } else if (state.url.includes("/help-desk")) {
        this.popupService.openSaveoLoader();
        this.checkBlocked();
        // ------------------ check if user is admin ---------------------
        // console.log("route to admin check permissions");
        this.authService.getUserPhoneNumber().subscribe((number) => {
          this.authService
            .subscribeUserData(number)
            .pipe(
              map((response) => {
                this.popupService.closeSaveoLoader();
                const currentUser = response.data;
                if (currentUser.user.enabled) {
                  if (currentUser.user.isCustomerExecutive) {
                    // console.log("Access granted your are admin user");
                    const subscription = this.authService
                      .subscribeAccessToken()
                      .subscribe(
                        (modelToken) => {
                          if (modelToken) {
                            observer.next(true);
                          } else {
                            this.router.navigate(["authenticate"]);
                            observer.next(false);
                          }
                          // subscription.unsubscribe();
                          observer.complete();
                        },
                        (error) => {
                          this.router.navigate(["authenticate"]);
                          observer.next(false);
                          // subscription.unsubscribe();
                          observer.complete();
                        }
                      );
                  } else {
                    // console.log("Access Denied your are not admin user");
                    this.location.back();
                  }
                } else {
                  this.router.navigate(["authenticate/complete"]);
                }
              })
            )
            .subscribe();
        });
      } else if (state.url.includes("/analysis")) {
        this.popupService.openSaveoLoader();
        this.checkBlocked();
        // ------------------ check if user is admin ---------------------
        // console.log("route to admin check permissions");
        this.authService.getUserPhoneNumber().subscribe((number) => {
          this.authService.subscribeUserData(number).subscribe(
            (response) => {
              this.popupService.closeSaveoLoader();
              const currentUser = response.data;
              if (currentUser.user.enabled) {
                if (currentUser.user.analyticsUser || currentUser.user.admin) {
                  // console.log("Access granted your are admin user");
                  const subscription = this.authService
                    .subscribeAccessToken()
                    .subscribe(
                      (modelToken) => {
                        if (modelToken) {
                          observer.next(true);
                        } else {
                          this.router.navigate(["authenticate"]);
                          observer.next(false);
                        }
                        // subscription.unsubscribe();
                        observer.complete();
                      },
                      (error) => {
                        this.router.navigate(["authenticate"]);
                        observer.next(false);
                        // subscription.unsubscribe();
                        observer.complete();
                      }
                    );
                } else {
                  // console.log("Access Denied your are not admin user");
                  this.location.back();
                }
              } else {
                this.router.navigate(["authenticate/complete"]);
              }
            },
            (error) => {
              if (error.status == 401) {
                this.authService.refreshAccessToken();
              }
              this.popupService.closeSaveoLoader();
            }
          );
        });
      } else if (state.url.includes("/logistics")) {
        this.popupService.openSaveoLoader();
        // this.checkBlocked();
        // ------------------ check if user is admin ---------------------
        // console.log("route to admin check permissions");
        this.authService.getUserPhoneNumber().subscribe((number) => {
          this.authService.subscribeUserData(number).subscribe(
            (response) => {
              this.popupService.closeSaveoLoader();
              const currentUser = response.data;
              if (currentUser.user.blocked) {
                this.router.navigate(["authenticate/block"]);
              }else{
              if (currentUser.user.enabled) {
                if (currentUser.user.admin) {
                  // console.log("Access granted your are admin user");
                  const subscription = this.authService
                    .subscribeAccessToken()
                    .subscribe(
                      (modelToken) => {
                        if (modelToken) {
                          observer.next(true);
                        } else {
                          this.router.navigate(["authenticate"]);
                          observer.next(false);
                        }
                        // subscription.unsubscribe();
                        observer.complete();
                      },
                      (error) => {
                        this.router.navigate(["authenticate"]);
                        observer.next(false);
                        // subscription.unsubscribe();
                        observer.complete();
                      }
                    );
                } else {
                  // console.log("Access Denied your are not admin user");
                  this.location.back();
                }
              } else {
                this.router.navigate(["authenticate/complete"]);
              }
            }
            },
            (error) => {
              if (error.status == 401) {
                this.authService.refreshAccessToken();
              }
              this.popupService.closeSaveoLoader();
            }
          );
        });
      } else if (state.url.includes("/outstanding")) {
        this.popupService.openSaveoLoader();
        this.checkBlocked();
        // ------------------ check if user is admin ---------------------
        // console.log("route to admin check permissions");
        this.authService.getUserPhoneNumber().subscribe((number) => {
          this.authService.subscribeUserData(number).subscribe(
            (response) => {
              this.popupService.closeSaveoLoader();
              const currentUser = response.data;
              if (currentUser.user.enabled) {
                if (currentUser.user.admin) {
                  // console.log("Access granted your are admin user");
                  const subscription = this.authService
                    .subscribeAccessToken()
                    .subscribe(
                      (modelToken) => {
                        if (modelToken) {
                          observer.next(true);
                        } else {
                          this.router.navigate(["authenticate"]);
                          observer.next(false);
                        }
                        // subscription.unsubscribe();
                        observer.complete();
                      },
                      (error) => {
                        this.router.navigate(["authenticate"]);
                        observer.next(false);
                        // subscription.unsubscribe();
                        observer.complete();
                      }
                    );
                } else {
                  // console.log("Access Denied your are not admin user");
                  this.location.back();
                }
              } else {
                this.router.navigate(["authenticate/complete"]);
              }
            },
            (error) => {
              if (error.status == 401) {
                this.authService.refreshAccessToken();
              }
            }
          );
        });
        this.popupService.closeSaveoLoader();
      } else if (state.url.includes("/warehouse")) {
        this.popupService.openSaveoLoader();
        // this.checkBlocked();
        // ------------------ check if user is admin ---------------------
        // console.log("route to admin check permissions");
        this.authService.getUserPhoneNumber().subscribe((number) => {
          this.authService.subscribeUserData(number).subscribe(
            (response) => {
              this.popupService.closeSaveoLoader();
              const currentUser = response.data;
              if (currentUser.user.blocked) {
                this.router.navigate(["authenticate/block"]);
              }else{
              if (currentUser.user.enabled) {
                if (currentUser.user.admin) {
                  // console.log("Access granted your are admin user");
                  const subscription = this.authService
                    .subscribeAccessToken()
                    .subscribe(
                      (modelToken) => {
                        if (modelToken) {
                          observer.next(true);
                        } else {
                          this.router.navigate(["authenticate"]);
                          observer.next(false);
                        }
                        // subscription.unsubscribe();
                        observer.complete();
                      },
                      (error) => {
                        this.router.navigate(["authenticate"]);
                        observer.next(false);
                        // subscription.unsubscribe();
                        observer.complete();
                      }
                    );
                } else {
                  // console.log("Access Denied your are not admin user");
                  this.location.back();
                }
              } else {
                this.router.navigate(["authenticate/complete"]);
              }
            }
            },
            (error) => {
              if (error.status == 401) {
                this.authService.refreshAccessToken();
              }
              this.popupService.closeSaveoLoader();
            }
          );
        });
      } /*else if (state.url.includes("/delivery-exec")) {
        this.checkBlocked();
        // ------------------ check if user is admin ---------------------
        // console.log("route to admin check permissions");
        this.authService.getUserPhoneNumber().subscribe(number => {
          this.authService
            .subscribeUserData(number)
            .pipe(
              map(currentUser => {
                if (currentUser.user.enabled) {
                  if (currentUser.user.admin || currentUser.user.deliveryExecutive) {
                    // console.log("Access granted your are admin user");
                    const subscription = this.authService
                      .subscribeAccessToken()
                      .subscribe(
                        modelToken => {
                          if (modelToken) {
                            observer.next(true);
                          } else {
                            this.router.navigate(["authenticate"]);
                            observer.next(false);
                          }
                          // subscription.unsubscribe();
                          observer.complete();
                        },
                        error => {
                          this.router.navigate(["authenticate"]);
                          observer.next(false);
                          // subscription.unsubscribe();
                          observer.complete();
                        }
                      );
                  } else {
                    // console.log("Access Denied your are not admin user");
                    this.location.back();
                  }
                } else {
                  this.router.navigate(["authenticate/complete"]);
                }
              })
            )
            .subscribe();
        });
      } else if (state.url.includes("/dispatch-manager")) {
        this.checkBlocked();
        // ------------------ check if user is admin ---------------------
        // console.log("route to admin check permissions");
        this.authService.getUserPhoneNumber().subscribe(number => {
          this.authService
            .subscribeUserData(number)
            .pipe(
              map(currentUser => {
                if (currentUser.user.enabled) {
                  if (currentUser.user.admin || currentUser.user.dispatchManager) {
                    // console.log("Access granted your are admin user");
                    const subscription = this.authService
                      .subscribeAccessToken()
                      .subscribe(
                        modelToken => {
                          if (modelToken) {
                            observer.next(true);
                          } else {
                            this.router.navigate(["authenticate"]);
                            observer.next(false);
                          }
                          // subscription.unsubscribe();
                          observer.complete();
                        },
                        error => {
                          this.router.navigate(["authenticate"]);
                          observer.next(false);
                          // subscription.unsubscribe();
                          observer.complete();
                        }
                      );
                  } else {
                    // console.log("Access Denied your are not admin user");
                    this.location.back();
                  }
                } else {
                  this.router.navigate(["authenticate/complete"]);
                }
              })
            )
            .subscribe();
        });
      }*/ else if (state.url.includes("/shop")) {
        this.popupService.openSaveoLoader();
        this.checkBlocked();
        //  --------------------  check if user is enabled ------------------
        this.authService.getUserPhoneNumber().subscribe((number) => {
          this.authService.subscribeUserData(number).subscribe(
            (response) => {
              this.popupService.closeSaveoLoader();
              const currentUser = response.data;

              // if (currentUser.isUserExists) {
              // if (currentUser.user.enabled) {
              // alert(JSON.stringify(currentUser.user))
              // if (currentUser.user.retailer || currentUser.user.admin || currentUser.user.salesPerson || currentUser.user.deliveryPerson) {
              // alert("Access granted user is enabled");
              const subscription = this.authService
                .subscribeAccessToken()
                .subscribe(
                  (modelToken) => {
                    if (modelToken) {
                      observer.next(true);
                    } else {
                      this.router.navigate(["authenticate"]);
                      observer.next(false);
                    }
                    // // subscription.unsubscribe();
                    observer.complete();
                  },
                  (error) => {
                    this.router.navigate(["authenticate"]);
                    observer.next(false);
                    // subscription.unsubscribe();
                    observer.complete();
                  }
                );
              // } else {
              //   // alert("access denied")
              //   observer.next(false);
              //   observer.complete();
              //   this.location.back();
              // }
              /*} else {
                    this.router.navigate(["authenticate/complete"]);
                  }
                } else {
                  const subscription = this.authService
                  .subscribeAccessToken()
                  .subscribe(
                    modelToken => {
                      if (modelToken) {
                        observer.next(true);
                      } else {
                        this.router.navigate(["authenticate"]);
                        observer.next(false);
                      }
                      // subscription.unsubscribe();
                      observer.complete();
                    },
                    error => {
                      this.router.navigate(["authenticate"]);
                      observer.next(false);
                      // subscription.unsubscribe();
                      observer.complete();
                    }
                  );
                }*/
            },
            (error) => {
              if (error.status == 401) {
                this.authService.refreshAccessToken();
              }
              this.popupService.closeSaveoLoader();
            }
          );
        });
      } else if (state.url.includes("/dist")) {
        this.popupService.openSaveoLoader();
        this.checkBlocked();
        // ------------------ check if user is admin ---------------------
        // console.log("route to admin check permissions");
        this.authService.getUserPhoneNumber().subscribe((number) => {
          // console.log(number);
          this.authService.subscribeUserData(number).subscribe(
            (response) => {
              this.popupService.closeSaveoLoader();
              this.popupService.closeSaveoLoader();
              const currentUser = response.data;
              if (currentUser.user.enabled) {
                if (currentUser.user.distributor || currentUser.user.admin) {
                  // console.log("Access granted your are admin user");
                  const subscription = this.authService
                    .subscribeAccessToken()
                    .subscribe(
                      (modelToken) => {
                        if (modelToken) {
                          observer.next(true);
                        } else {
                          this.router.navigate(["authenticate"]);
                          observer.next(false);
                        }
                        // subscription.unsubscribe();
                        observer.complete();
                      },
                      (error) => {
                        this.router.navigate(["authenticate"]);
                        observer.next(false);
                        // subscription.unsubscribe();
                        observer.complete();
                      }
                    );
                } else {
                  // console.log("Access Denied your are not admin user");
                  this.location.back();
                }
              } else {
                this.router.navigate(["authenticate/complete"]);
              }
            },
            (error) => {
              if (error.status == 401) {
                this.authService.refreshAccessToken();
              }
              this.popupService.closeSaveoLoader();
            }
          );
        });
      } else if (state.url.includes("/complete")) {
        this.popupService.openSaveoLoader();
        this.checkBlocked();
        this.authService.getUserPhoneNumber().subscribe((number) => {
          this.authService.subscribeUserData(number).subscribe(
            (response) => {
              this.popupService.closeSaveoLoader();
              const currentUser = response.data;
              if (currentUser.user.enabled) {
                const subscription = this.authService
                  .subscribeAccessToken()
                  .subscribe(
                    (modelToken) => {
                      if (modelToken) {
                        observer.next(true);
                        this.ngZone.run(() => {
                          this.router.navigate(["/check"]);
                        });
                      } else {
                        this.router.navigate(["authenticate"]);
                        observer.next(false);
                      }
                      // subscription.unsubscribe();
                      observer.complete();
                    },
                    (error) => {
                      observer.next(false);
                      // subscription.unsubscribe();
                      observer.complete();
                    }
                  );
              } else {
                observer.next(true);
                observer.complete();
              }
            },
            (error) => {
              if (error.status == 401) {
                this.authService.refreshAccessToken();
              }
              this.popupService.closeSaveoLoader();
            }
          );
        });
      } else {
        this.popupService.openSaveoLoader();
        // console.log("grt it " + state.url.includes("/dist"));
        // console.log("all ok");
        const subscription = this.authService.subscribeAccessToken().subscribe(
          (modelToken) => {
            this.popupService.closeSaveoLoader();
            if (modelToken) {
              observer.next(true);
            } else {
              this.router.navigate(["authenticate"]);
              observer.next(false);
            }
            // subscription.unsubscribe();
            observer.complete();
          },
          (error) => {
            this.router.navigate(["authenticate"]);
            observer.next(false);
            // subscription.unsubscribe();
            observer.complete();
            this.popupService.closeSaveoLoader();
          }
        );
      }
    }).toPromise();
  }
}
