import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelConfirmDeleteMed } from './model';
import { AdminService } from "src/app/core/services/api/admin.service";
import { PopupService } from "src/app/core/services/popup/popup.service";

@Component({
  selector: 'app-confirm-medicine-delete',
  templateUrl: './confirm-medicine-delete.component.html',
  styleUrls: ['./confirm-medicine-delete.component.scss']
})
export class ConfirmMedicineDeleteComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<ConfirmMedicineDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelConfirmDeleteMed,
    private adminService: AdminService,
    private popupService: PopupService) { }

  ngOnInit(): void {
  }

  submit() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
