import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogWholesaleBill } from './model';
import { PopupService} from 'src/app/core/services/popup/popup.service';
import { WholesaleService } from 'src/app/core/services/api/wholesale.service';
@Component({
  selector: 'app-wholesale-bill',
  templateUrl: './wholesale-bill.component.html',
  styleUrls: ['./wholesale-bill.component.scss']
})
export class WholesaleBillComponent implements OnInit {

  wholeDetails=[
    {
      "invoiceAmount": {
        "subTotal": 0,
        "discountAmount": 0,
        "tcsAmount": null,
        "gstAmount": 0,
        "invoiceValue": null,
        "referralAmount": null,
        "creditAmount": null,
        "roundOffAmount": null
      },
      "gstAmountMap": {
        "gstInfoMap": {
          "0": {
            "sgst": 0,
            "total": 0,
            "totalQuantity": 0,
            "taxableValue": 0,
            "subTotal": 0,
            "cgst": 0
          },
          "5": {
            "sgst": 0,
            "total": 0,
            "totalQuantity": 0,
            "taxableValue": 0,
            "subTotal": 0,
            "cgst": 0
          },
          "12": {
            "sgst": 0,
            "total": 0,
            "totalQuantity": 0,
            "taxableValue": 0,
            "subTotal": 0,
            "cgst": 0
          },
          "18": {
            "sgst": 0,
            "total": 0,
            "totalQuantity": 0,
            "taxableValue": 0,
            "subTotal": 0,
            "cgst": 0
          },
          "28": {
            "sgst": 0,
            "total": 0,
            "totalQuantity": 0,
            "taxableValue": 0,
            "subTotal": 0,
            "cgst": 0
          }
        }
      },
      "invoiceDetails": {
        "invoiceNumber": "",
        "invoiceDate": '',
        "deliverySlot": null,
        "deliveryTime": null,
        "orderPlacedDate": '',
        "orderId": ''
      },
      "shippingAddress": {
        "retailerCode": null,
        "retailerShopName": null,
        "retailerShopAddressLine1": null,
        "retailerShopAddressLine2": null,
        "retailerShopAddress": null,
        "retailerShopPinCode": null,
        "retailerDrugLicence": null,
        "retailerGstin": null
      },
      "billingAddress": {
        "distributorId": '',
        "distributorShopName": null,
        "distributorShopAddress": "",
        "distributorShopPinCode": "",
        "distributorDrugLicenceNumber": "",
        "distributorGstin": ""
      },
      "invoiceItemDetailList": [
        {
          "itemName": "",
          "manufacturerName": "",
          "billedQuantity":0,
          "freeQuantity": 0,
          "totalQuantity": 0,
          "packaging": "",
          "batch": null,
          "expiryDate": null,
          "mrp":0,
          "ptr":0,
          "schemeDiscountAmount": 0,
          "cashDiscount": 0,
          "taxableAmount":0,
          "hsnCode": "",
          "gstPercent": 0,
          "sgstAmount": null,
          "cgstAmount":0,
          "igstAmount": 0,
          "netValue": 0
        }
      
      ]}];
  retailerInfo=null
  saveoInfo=null
  dlNo=[]
  medSizeArray = [0,1,2,3,4,5,6,7,8,9,10,11];
  termsAndCondition=["E & O.E. Subject to Bengaluru Jurisdiction","All items to be checked while receiving it. We are not responsible for discrepancies later.","Payment against tax invoice should be made by Cash, UPI, NEFT in Favour of  'Saveo Healthtech Private Limited', only"]
  constructor(  private popupService: PopupService,
    private wholesaleService: WholesaleService,  public dialogRef: MatDialogRef<WholesaleBillComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogWholesaleBill) { }

  ngOnInit(): void {

  

   
    this.getBill()
  }


  getBill(){
    this.popupService.openProgress()
    const params={
      buyerInvoiceId:this.data.data.invoiceId,
      orderId:this.data.data.orderId
      // buyerInvoiceId:224,
      // orderId:34
    }
    this.wholesaleService.getPrintBill(params).subscribe(res=>{
      this.wholeDetails=res.data
      this.popupService.closeProgress()

    },
      error => {
        this.popupService.closeProgress()
        this.popupService.openError(error.error.error.error);   
      })
  }

  cancel() {
    this.dialogRef.close(false);
  }

  submit() {
    this.dialogRef.close(true);
  }



  printBill(){


  this.data.data?.checkList &&  this.popupService.openWholesalePrintCheckList({invoiceId:this.data.data.invoiceId}).afterClosed().subscribe((res)=>{
      if(res){
        
        let params={
          buyerInvoiceId:this.data.data.invoiceId
        }
       
          // var winHead = document.getElementsByTagName('head')[0];
          // var style = document.createElement('style');
          // style.type = 'text/css';
          // style.media = 'print';
          // style.appendChild(document.createTextNode(
          //   '@page {size: A4 landscape; margin:3mm;} #section-to-print{-webkit-print-color-adjust: exact;}  img {-webkit-print-color-adjust: exact;}'));
          //   winHead.appendChild(style);
          //   window.print()
            // window.onafterprint =()=>{
              this.openPrintPopup()
              this.popupService.openProgress()
              this.wholesaleService.updatePrintStatus(params).subscribe((res)=>{
                this.popupService.closeProgress()
               this.submit()
               this.openRedirect();
              },
                error => {
                  this.popupService.closeProgress()
                  this.popupService.openError(error.error.error.error);   
                })
             
            // }
           
           
     
      }
 })

 
 !this.data.data?.checkList && this.openPrintPopup()



   
   
      
  }

  openPrintPopup(){
    // var winHead = document.getElementsByTagName('head')[0];
    // var style = document.createElement('style');
    // style.type = 'text/css';
    // style.media = 'print';
    // style.appendChild(document.createTextNode(
    //   '@page {size: A4 landscape; margin:3mm;} #section-to-print{-webkit-print-color-adjust: exact;}  img {-webkit-print-color-adjust: exact;}'));
    //   winHead.appendChild(style);
    let popupWinindow
    var winHead = document.getElementsByTagName('head')[0];
    var style = document.createElement('style');
    style.type = 'text/css';
    style.media = 'print';
     style.appendChild(document.createTextNode(
      '@page {size: A4 landscape; margin:1mm 5mm;} #section-to-print{-webkit-print-color-adjust: exact;}  img {-webkit-print-color-adjust: exact;}'));
      winHead.appendChild(style);

      let script5=document.createElement('script');
      script5.appendChild(document.createTextNode(`
      var downloadPDFOf = function() {
          window.print()
          window.close()
          
      };
      window.onload=()=>{
        downloadPDFOf()
      }
      `
      ))

      winHead.appendChild(script5)
    let page = document.getElementById('print_div').innerHTML
    popupWinindow = window.open('', '_blank', 'width=auto,height=auto,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    popupWinindow.document.write('<html id="html">' + winHead.innerHTML + '<body  class="body"><div id="full-page1" class="full-page table-height">' + page + '</div></body></html>');
    popupWinindow.document.close();
   
  }


  openRedirect(){
    this.popupService.openWholesaleRedirect({title:'Invoice Print Success',redirectTitle:'Redirecting to Ready to Ship Orders',redirect:'ready_to_ship'}).afterClosed().subscribe((res)=>{

    })
  }

}
