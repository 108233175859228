import { InjectionToken, Injectable } from "@angular/core";
import { UserModel } from '../services/params/user.model';
// import { ParamUser } from "../api/params/auth-param";



export let APP_CONFIG = new InjectionToken('app.config');

@Injectable({ providedIn: 'root' })
export class RouteConfig {
    getGuardNavigation(user: UserModel): string {
        //console.log("routeconfig: ", user);
        if (user.isUserExists) {
            return '/complete'
        }
        if (user.user.admin) {
            return '/admin'
        }
        //console.log("routeconfig qwqw: ", user.name);
        if (user.user.enabled) {
            return '/shop'
        }
        if(!user.isUserExists){

        }
        //console.log("routeconfig 2: ", user);

        return ''


    }


    shouldIgnoreGuardNavigation(guardedRoute: string, currentStateUrl: string, user_role_company: boolean = false): boolean {

        if (guardedRoute) {
            if (currentStateUrl.includes('company/create') && !currentStateUrl.includes(guardedRoute)) {
                return true
            }

            if (currentStateUrl.includes('applicant/create') && !currentStateUrl.includes(guardedRoute)) {
                return true
            }

            if (currentStateUrl.includes('landing-page')) {
                return true
            }

            if (!currentStateUrl.includes(guardedRoute)) {
                if (user_role_company && guardedRoute.includes('applicant')) {
                    return true
                }
                if (!user_role_company && guardedRoute.includes('company')) {
                    return true
                }
                return false
            }


        }

        return true
    }

}