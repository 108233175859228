import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelSendExpiryItemToDn } from './model';
import { SupplyService } from 'src/app/core/services/api/supply.service';
import { PopupService } from 'src/app/core/services/popup/popup.service';

@Component({
  selector: 'app-send-expiry-itmes-to-dn',
  templateUrl: './send-expiry-itmes-to-dn.component.html',
  styleUrls: ['./send-expiry-itmes-to-dn.component.scss']
})
export class SendExpiryItmesToDnComponent implements OnInit {
  returnOptions = [];
  itemToBeSent;

  constructor(
    public dialogRef: MatDialogRef<SendExpiryItmesToDnComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelSendExpiryItemToDn,
    private supplyService: SupplyService,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {
    console.log(this.data)
    this.getReturnOptions();
  }

  // Get return options list
  getReturnOptions() {
    if (this.data.item.batchCheck == false) {
      this.data.item.batchCheck = 0;
    } else if (this.data.item.batchCheck == true) {
      this.data.item.batchCheck = 1;
    }
    if (this.data.item.packCheck == false) {
      this.data.item.packCheck = 0;
    } else if (this.data.item.packCheck == true) {
      this.data.item.packCheck = 1;
    }
    if (this.data.item.mrpCheck == false) {
      this.data.item.mrpCheck = 0;
    } else if (this.data.item.mrpCheck == true) {
      this.data.item.mrpCheck = 1;
    }
    this.supplyService.getExpiryReturnOptions({}, this.data.item).subscribe(
      response => {
        this.returnOptions = response.data;
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  // Handle actions
  sendItem(action) {
    if (action.optionName.includes('Debit Note')) {
      this.sendForDn();
    }
  }

  // Send item for debit note
  sendForDn() {
    const body = {
      expiryReturnRetrieveOrderId: this.data.item.orderRetrieveId,
      id: this.data.item.parentId,
      retailerCode: this.data.item.retailerCode,
      itemsForDN: [this.data.item]
    }
    console.log(body)
    this.supplyService.sendExpiryItemsForDn({}, body).subscribe(
      response => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data);
        this.dialogRef.close(true);        
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
        this.dialogRef.close(true);
      }
    )
  }

  // close popup
  closeIt() {
    this.dialogRef.close(null);
  }

}
