import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AdminService } from 'src/app/core/services/api/admin.service';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { DialogViewPincodeMappingModel } from './model';

@Component({
  selector: 'app-view-pincode-mapping',
  templateUrl: './view-pincode-mapping.component.html',
  styleUrls: ['./view-pincode-mapping.component.scss']
})
export class ViewPincodeMappingComponent implements OnInit {
  filteredOptions: Observable<string[]>;
  hsrControl = new FormControl('');
  routeControl = new FormControl('');
  allHsrId = [];
  allRouteCodes = [];
  allHubs = [];
  pincodeMappingData;
  enableSave: boolean = false;
  addNewRoute: boolean = false;


  constructor(
    public dialogRef: MatDialogRef<ViewPincodeMappingComponent>,
    @Inject(MAT_DIALOG_DATA) public data : DialogViewPincodeMappingModel,
    private adminService: AdminService,
    private popupService: PopupService,
  ) { }

  ngOnInit(): void {
    this.getPinCodeMapping()
  }

  // api to get pincode mapping data
  getPinCodeMapping(){
    const params = {
      pinCode : this.data.data.pincode,
    }
    this.popupService.openProgress()
    this.adminService.getPinCodeMapping(params).subscribe(
      (response) => {
        this.pincodeMappingData = response.data
        this.getRoutesByHsrId()
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  // api to get route codes based on hubId
  getRoutesByHsrId(closeProgress : boolean = false){
    const params = {
      hubSpokeRouteId : this.pincodeMappingData.hubSpokeRouteId,
    }
    // if(closeProgress) this.popupService.openProgress()

    this.adminService.getRoutesByHsrId(params).subscribe(
      (response) => {
        this.allRouteCodes = response.data;
        if(closeProgress){
          // this.popupService.closeProgress();
          return
        } 
        else this.getAllHubs(closeProgress)
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  // api to get all hubs
  getAllHubs(closeProgress){
    this.adminService.getAllServicingHub().subscribe(
      (response) => {
        this.allHubs = response.data;
        this.getAllHsrId()
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  // api to get all available hsrID
  getAllHsrId(){
    this.adminService.getAllAvailableSpokes().subscribe(
      (response) =>{
        this.allHsrId = response.data;
        this.popupService.closeProgress();
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }

    )
  }

  // handler to run side effects on hsr id focus
  handleHSRInputFocus(){
    let options = [...this.allHsrId]
    this.filteredOptions = this.hsrControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterHsrId(value || '', options)),
    );
  }

  // filter thr items
  private filterHsrId(value: string, options): string[] {
    const filterValue = value.toLowerCase();
    return options.filter(option => option.spokeName.toLowerCase().includes(filterValue));
  }

  // handler on hsr selectz
  handleHsrSelect(event){
    // update the hsr id as well on option change
    this.allHsrId.map((hsr, index) => {
      if(hsr.spokeName === event.option.value){
        this.pincodeMappingData.hubSpokeRouteId = hsr.id
      }
    })
    // remove the route code from the form
    this.pincodeMappingData.routeCode = '';
    // update the available routes
    this.getRoutesByHsrId(true)

  }

  // handler to run side effects on route code focus
  handleRouteInputFocus(){
    let options = [...this.allRouteCodes]
    this.filteredOptions = this.routeControl.valueChanges.pipe(
      startWith(''),
      map(value  =>  this.filterRoutes(value || '', options)),
    );
  }

  private filterRoutes(value: string, options): string[]{
    const filterValue = value.toLowerCase()
    return options.filter(option => option.toLowerCase().includes(filterValue))
  }

  // handler for hub selection change
  handleHubChange(event){
    let selectedHubId = event.value;
    // update the hub name in the pincode mapping object
    this.allHubs.forEach((hub, index) => {
      if(hub.id === selectedHubId){
        this.pincodeMappingData.hubCode = hub.hubCode
      }
    })
  }

  // handler to run post api on save button click
  handleSave(){
    if(this.checkEnableSave()) return
    const body = {
      ...this.pincodeMappingData
    }
    this.adminService.updatePinCodeMapping(body).subscribe(
      (response) => {
        this.popupService.openSuccess('Success')
        this.dialogRef.close()
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  //  checks on the save button enable
  checkEnableSave(){
    // transform routeCode, hsrId to uppercase
    this.pincodeMappingData.routeCode = this.pincodeMappingData.routeCode.toUpperCase()
    this.pincodeMappingData.hubSpokeRouteName = this.pincodeMappingData.hubSpokeRouteName.toUpperCase()
    let flag = false;
    // check hsr id 
    let hsrNameList = this.allHsrId.map((hsr) => hsr.spokeName)
    if(!hsrNameList.includes(this.pincodeMappingData.hubSpokeRouteName)) flag = true;
    // get the hsr id of the entered hsrSpokeName and match it with the current hsr id
    let selectedHsr = this.allHsrId.filter(hsr => hsr.spokeName === this.pincodeMappingData.hubSpokeRouteName)
    if(selectedHsr.length > 0 && selectedHsr[0]?.id !== this.pincodeMappingData.hubSpokeRouteId) flag = true;
    // check route codes 
    if((!this.allRouteCodes.includes(this.pincodeMappingData.routeCode) && !this.addNewRoute) || !this.pincodeMappingData.routeCode) flag =  true
    // check hub id
    let hubIdsList = this.allHubs.map((hub) => hub.id)
    if(!this.pincodeMappingData.hubId) flag = true

    this.enableSave = !flag;
    return flag;
  }



}
