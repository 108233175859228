import { SupplyService } from 'src/app/core/services/api/supply.service';
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { PopupService } from "src/app/core/services/popup/popup.service";
import { FormGroup } from "@angular/forms";
import { AdminService } from "src/app/core/services/api/admin.service";
import { FormControl } from "@angular/forms";
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-print-bill-new',
  templateUrl: './print-bill-new.component.html',
  styleUrls: ['./print-bill-new.component.scss']
})
export class PrintBillNewComponent implements OnInit {
  id;
  printInfo={id:'',status:''}
  allPrintBills = [];
  showAllPrintBill=false;
  spanLen = 5;
  _mFormGroup: FormGroup;
  retailerInfo = {
    vpinCode: '',
    vshopName: "",
    vaddress: "",
    vaddress1: "",
    vaddress2: "",
    vaddress3: "",
    vpanNo: "",
    panNo: "",
    addressLine1: "",
    addressLine2: "",
    retailerCode: "",
    varea: "",
    vmobile: "",
    vdrugLicence: "",
    vgstNo: "",
    address: "",
    area: "",
    drugLicence: "",
    mobileNo: "",
    shopName: "",
    gstNo: "",
    zip: '',
    isCounterCustomer:null
  };
  dlNo=[];
  saveoInfo = {
    companyName: "",
    oldCompanyName: "",
    address: "",
    phoneNumber: "",
    website: "",
    email: "",
    dlNo: "",
    gstNo: "",
    termsAndCondition: "",
    creditUserTermsAndCondition:"",
    cinNumber: "",
    fssai:'',
    bankName:'',
    branch:'',
    accountNo:'',
    ifscCode:'',
    upiId:'',
    upiQRCodeUrl:''
  };
  invoices = [];
  showPast = false;
  isSearchActive = false;
  currIndex;
  currMedKeys;
  showNextBtn = false;
  showPreviousBtn = false;
  retailerCodes = [];
  showUpdate = false;
  showRetailerCodeList = false;  
  currRetailerCode = new FormControl();
  currSearchMed = new FormControl();
  filteredOptions: Observable<string[]>;
  searchSuggestions: Observable<string[]>;
  emptyMed = {
    reasons: [],
    reason: '',
    batchId: {value: null, edit: true},
    expiryDate: {value: null, edit: true},
    hsnCode: {value: null, edit: true},
    id: {value: null, edit: true},
    itemCode: {value: null, edit: true},
    itemName: {value: null, edit: true},
    manufacturerName: {value: null, edit: true},
    mrp: {value: null, edit: true},
    netValue: {value: null, edit: true},
    packaging: {value: null, edit: true},
    productDiscount: {value: null, edit: true},
    ptr: {value: null, edit: true},
    quantity: {value: null, edit: true},
    schemeDiscount: {value: null, edit: true},
    tax: {value: null, edit: true},
    taxableValue: {value: null, edit: true},
    uniqueCode: {value: null, edit: false},
    isNew: true,
    edited: true
  };
  searchResult = [];
  detailPage = 0;
  pageDetailLimit = 0;
  wholeDetails = [];
  medSizeArray = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14];
  showCross = true;
  updateReasons = ['Select Reason'];

  searchStr = "";
  searchedMedicines = [];
  selectedMedicine;
  currMedToAdd;
  currMedId;
  showMedList = false;

  showSplitPdf = false;
  pdfBills;
  splitBills = [];
  pagedBills = [[], [], [], [], [], [], [], [], [], []];
  showSplitBill = [false, false, false, false, false, false, false, false, false, false];
  showBills = false;

  @Input("printInfo") set val(value: any) {
    if (value) {
      this.printInfo = value;
      this.id=+value.id;
    }
  }
  @Output() statusClose = new EventEmitter<Boolean>();

    
  constructor(private supplyService: SupplyService,  public popupService: PopupService, private adminService: AdminService) { }

  ngOnInit(): void {
    this.getDetails()
  }

  // ------------------------------------------------------------------------------------------------
  // print bill ui functionalities ->
  // -------------------------------------------------------------------------------------------------------
  getSaveoInfo() {
    const params = {
      orderRetrieveId: +this.printInfo.id
    }
    this.adminService.getV2SaveoInfo(params).subscribe(
      response => {
        this.saveoInfo = response.data;
        this.dlNo[0]=this.saveoInfo.dlNo.split(' ')[0];
        this.dlNo[1]=this.saveoInfo.dlNo.split(' ')[1];
        this.dlNo[2]=this.saveoInfo.dlNo.split(' ')[2];
        this.popupService.closeProgress();
        if(this.printInfo.status){
          this.printBill(true)
        }else{
          this.printBill(false)
        }
        
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  getDetails() {
    this.popupService.openProgress()
    this.showUpdate = false;
    const params = {
      retrieveOrderId: +this.printInfo.id
    };
    this.adminService.getBillDetails(params).subscribe(
      response => {
        // console.log(response.data);
        this.wholeDetails = response.data;
        this.wholeDetails.map(detail => {
          if (!detail.buyerInvoiceDetailResponseList) {
            detail.buyerInvoiceDetailResponseList = [];
          }
          if (!detail.buyerInvoiceDetailBounceResponseList) {
            detail.buyerInvoiceDetailBounceResponseList = [];
          }
          detail.spanning = 21;
          detail.bounceCount = 0;
          detail.noBounceCount = 0;
          detail.returnableCount = 0;
          detail.nonReturnableCount = 0;
          detail.buyerInvoiceDetailResponseList.map(med => {
            // med.reasons = this.updateReasons;
            // med.reason = this.updateReasons[0];
            if (med.bounce) {
              detail.bounceCount = detail.bounceCount + 1;
            } else {
              if (med.returnable.value) {
                detail.returnableCount = detail.returnableCount + 1;
              } else {
                detail.nonReturnableCount = detail.nonReturnableCount + 1;
              }
            }
            Object.keys(med).map((key) => {
              if (typeof med[key] == 'object' && med[key]!== null) {
                // console.log(med[key])
                med[key].oldValue = med[key].value;
              }
            })
          })
          detail.buyerInvoiceDetailBounceResponseList.map(med => {
            // med.reasons = this.updateReasons;
            // med.reason = this.updateReasons[0];
            Object.keys(med).map((key) => {
              if (typeof med[key] == 'object' && med[key]!== null) {
                // console.log(med[key])
                med[key].oldValue = med[key].value;
              }
            })
          })
          detail.noBounceCount = detail.buyerInvoiceDetailResponseList.length - detail.bounceCount;
          /*
          if (detail.returnableCount > 0) {
            detail.spanning = detail.spanning + 1;
          }
          if (detail.nonReturnableCount > 0) {
            detail.spanning = detail.spanning + 1;
          }
          */
          // if (detail.nonReturnableCount > 0) {
          //   detail.spanning = detail.spanning + 2;
          // }
          
          // console.log(detail);
        })
        this.wholeDetails.map(detail => {
          detail.show = false;
        })
        this.getRetailerInfo(response.data[this.detailPage].invoiceDetail.value.retailerCode);

        this.wholeDetails[0].show = true;

        this.setDetailPageData();
        this.popupService.closeProgress()
      },
      error => {
        this.statusClose.emit(false)
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }


  setDetailPageData() {
    this.wholeDetails.map(detail => {
      detail.show = false;
      if (this.wholeDetails[this.detailPage].buyerInvoiceDetailResponseList.length > 0) {
        this.currMedKeys = Object.keys(this.wholeDetails[this.detailPage].buyerInvoiceDetailResponseList[0]);
      }
      this.wholeDetails[this.detailPage].buyerInvoiceDetailResponseList.map(med => {
        this.currMedKeys.map(key => {
          if(typeof med[key] == 'object' && med[key]!==null){
            med[key].edit = false;
          }
        })
        med.isNew = false;
        med.edited = false;
      })
    })
    this.wholeDetails[this.detailPage].show = true;
    this.pageDetailLimit = this.wholeDetails.length;
    
    
  }

  getRetailerInfo(code) {
    const params = {
      retailerCode: code
    };
    this.adminService.getBuyerInfo(params).subscribe(
      response => {
        this.retailerInfo = response.data;
        this.getSaveoInfo();
        
      },
      error => {
        this.statusClose.emit(false)
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
        // console.log(error.error.error.error);
      }
    )
  }

  
  printBill(print) {
    
    this.adminService.updatePrintStatus({orderRetrieveId: this.printInfo.id}).subscribe(
      response => { },
      error => { }
    );
    this.showCross = false;
    this.wholeDetails.map(detail => {
      detail.show = true;
    })
    setTimeout(() => {
      let popupWinindow
      var winHead = document.getElementsByTagName('head')[0];
      var style = document.createElement('style');
      style.type = 'text/css';
      style.media = 'print';
      if(print){
        style.appendChild(document.createTextNode(
          `@page {size: A4 landscape; margin:3mm 6mm;} 
          .bg-highlight {
            background-color: #232324c5 !important;
            color: white !important;
            margin: 0px 5px !important;
            -webkit-print-color-adjust: exact; 
          }`));
      }else{
        style.appendChild(document.createTextNode(
          `@page {size: A4 landscape; margin:3mm;} 
          .bg-highlight {
            background-color: #232324c5 !important;
            color: white !important;
            margin: 0px 5px !important;
            -webkit-print-color-adjust: exact; 
          }`));
      }
      
       
        // style.appendChild(document.createTextNode(
        //   '.body {transform:scale(0.83,0.5); transform-origin:0% 0%;}'));
        //   style.appendChild(document.createTextNode(
        //     '.rescale-td {transform:scale(0.85,1.15);transform-origin:0% 0%;}'));
        //     style.appendChild(document.createTextNode(
        //       '.rescale-th {transform:scale(0.85,1.15);transform-origin:0% 0%; }'));
              
        //     style.appendChild(document.createTextNode(
        //       '.re-scale {transform:scale(1,1);}'));
        //       // style.appendChild(document.createTextNode(
        //       //   '.item-scale {}'));
        //       style.appendChild(document.createTextNode(
        //         '.re-re-scale {transform:scale(1.1,1);}'));
        //         style.appendChild(document.createTextNode(
        //           ' .image{transform:scale(0.7,1) !important;}'));
        //           style.appendChild(document.createTextNode(
        //             ' .qr-code{transform:scale(0.7,1) !important;}'));
    
      winHead.appendChild(style);
      if(document.getElementById('fullpage4') && print) {

        // var script3 = '<script>function getPDF() { const pages = $(".printIt").length; var images = []; var HTML_Width = $(".printIt").eq(0).width(); var HTML_Height = 0; for (let counter = 0; counter < pages; counter++) {  HTML_Height = HTML_Height + $(".printIt").eq(counter).height();}   for (let counter = 0; counter < pages; counter++) { images.push({index: counter, photo: "", height: HTML_Width - 20, width: ((HTML_Width * 1.44) - 200)}); } var top_left_margin = 2; var PDF_Height = (HTML_Width); var PDF_Width = PDF_Height * 1.4; var pdf = new jsPDF("l", "pt", [PDF_Width, PDF_Height]); let total = 0; for (let counter = 0; counter < pages; counter++) { var canvas_image_width = $(".printIt").eq(counter).width(); var canvas_image_height = $(".printIt").eq(counter).height(); html2canvas($(".printIt")[counter], { allowTaint: true,background:"#ffffff" }).then(function(canvas) { total = total + 1; canvas.getContext("2d"); console.log(canvas.height + "  " + canvas.width); var imgData = canvas.toDataURL("image/jpeg", 1); images[counter].photo = imgData; if (total == pages) { for(let i = 0; i < pages; i++){pdf.addImage(images[i].photo, "JPG", 75, 10, images[i].width, images[i].height);if (i != pages - 1) { pdf.addPage("l",[PDF_Width, PDF_Height]);} }  pdf.autoPrint();pdf.output("bloburl"); window.close(); window.open(pdf.output("bloburl")); } }); } } </script>';

        // var script1 = '<script>function getPDF() { const pages = $(".printIt").length; alert(pages); var HTML_Width = $("#printIt").width(); var HTML_Height = $("#printIt").height();var top_left_margin = 2;var PDF_Width = HTML_Height+20;var PDF_Height = HTML_Width+20;var canvas_image_width = HTML_Height; var canvas_image_height = HTML_Width;var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;html2canvas($("#printIt")[0],{allowTaint:true}).then(function(canvas) {canvas.getContext("2d");console.log(canvas.height+"  "+canvas.width);var imgData = canvas.toDataURL("image/jpeg", 0.6); var pdf = new jsPDF("l", "pt",  [PDF_Width, PDF_Height]); pdf.addImage(imgData, "JPG", 10, 10,canvas_image_width,canvas_image_height);for (var i = 1; i <= 6; i++) {  pdf.addPage("l",[PDF_Width, PDF_Height]); pdf.addImage(imgData, "JPG", top_left_margin, -(PDF_Height*i)+(top_left_margin*4),canvas_image_width,canvas_image_height); } pdf.save("HTML-Document.pdf"); });};</script>'

        // var script2='<script src="https://code.jquery.com/jquery-1.12.4.js"></script> <script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.3.3/jspdf.min.js"></script><script src="https://html2canvas.hertzen.com/dist/html2canvas.js"></script>'

        // var script4='<script> function perform() { getPDF();  } </script>'
        let findChild = (idOfElement) => {
          let elements = document.getElementsByClassName(idOfElement);
          for (var i = 0; i < elements.length; i++) {
                let element =elements.item(i)
                let parentSvg=element.querySelector(`svg`)
           let childSvg= parentSvg.querySelector(`svg`)
           const attr = childSvg.getAttributeNode("shape-rendering");
           childSvg.removeAttributeNode(attr);
            }
        }
        findChild('irnSvg')
        let page = document.getElementById('fullpage4').innerHTML
        popupWinindow = window.open('', '_blank', 'width=auto,height=auto,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        popupWinindow.document.open();
        popupWinindow.document.write('<html id="html">' + winHead.innerHTML + '<body onload="window.print()" class="body"><div id="full-page1" class="full-page table-height">' + page + '</div></body></html>');
        popupWinindow.document.close();
      } else if(document.getElementById('fullpage5') && !print) {
       
        let page='<!DOCTYPE html><html id="html">' + winHead.innerHTML + '<body class="body"><div id="full-page1" class="full-page table-height">' + document.getElementById('fullpage5').innerHTML + '</div></body></html>'
        // var compressor = new LZUTF8.Compressor();
        
        let body={
          "action":"pdfBills",
          "content":page
      }
       
        this.popupService.openProgress()
         this.supplyService.getPDFBill(body).subscribe((res)=>{
          const url = window.URL.createObjectURL(new Blob([new Uint8Array(res.data.data).buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${this.printInfo.id}.pdf`);
          document.body.appendChild(link);
          link.click();
          this.popupService.closeProgress();
         },
         error => {
         
          this.popupService.closeProgress();
          this.popupService.openError('Download Bill Failed');
        })
      }
      this.showCross = true;
      this.statusClose.emit(false)
      this.wholeDetails.map(detail => {
        detail.show = false;
      })
      this.wholeDetails[this.detailPage].show = true;

    }, 1);
    
  }

  saveAsPdf() {
    this.adminService.updatePrintStatus({orderRetrieveId:this.printInfo.id}).subscribe(
      response => { },
      error => { }
    );
    this.showCross = false;
    this.wholeDetails.map(detail => {
      detail.show = true;
    })
    setTimeout(() => {
      let popupWinindow
      var winHead = document.getElementsByTagName('head')[0];
      var style = document.createElement('style');
      style.type = 'text/css';
      style.media = 'print';
      style.appendChild(document.createTextNode(
        '@page {size: A4 landscape; margin:3mm;}'));
       
        // style.appendChild(document.createTextNode(
        //   '.body {transform:scale(0.83,0.5); transform-origin:0% 0%;}'));
        //   style.appendChild(document.createTextNode(
        //     '.rescale-td {transform:scale(0.85,1.15);transform-origin:0% 0%;}'));
        //     style.appendChild(document.createTextNode(
        //       '.rescale-th {transform:scale(0.85,1.15);transform-origin:0% 0%; }'));
              
        //     style.appendChild(document.createTextNode(
        //       '.re-scale {transform:scale(1,1);}'));
        //       // style.appendChild(document.createTextNode(
        //       //   '.item-scale {}'));
        //       style.appendChild(document.createTextNode(
        //         '.re-re-scale {transform:scale(1.1,1);}'));
        //         style.appendChild(document.createTextNode(
        //           ' .image{transform:scale(0.7,1) !important;}'));
        //           style.appendChild(document.createTextNode(
        //             ' .qr-code{transform:scale(0.7,1) !important;}'));
    
      winHead.appendChild(style);
      if(document.getElementById('fullpagePrint')) {
    
        var script3 = '<script>function getPDF() { const pages = $(".printIt").length; var images = []; var HTML_Width = $(".printIt").eq(0).width(); var HTML_Height = 0; for (let counter = 0; counter < pages; counter++) {  HTML_Height = HTML_Height + $(".printIt").eq(counter).height();}   for (let counter = 0; counter < pages; counter++) { images.push({index: counter, photo: "", height: HTML_Width - 20, width: ((HTML_Width * 1.44) - 200)}); } var top_left_margin = 2; var PDF_Height = (HTML_Width); var PDF_Width = PDF_Height * 1.4; var pdf = new jsPDF("l", "pt", [PDF_Width, PDF_Height]); let total = 0; for (let counter = 0; counter < pages; counter++) { var canvas_image_width = $(".printIt").eq(counter).width(); var canvas_image_height = $(".printIt").eq(counter).height(); html2canvas($(".printIt")[counter], { allowTaint: true,background:"#ffffff" }).then(function(canvas) { total = total + 1; canvas.getContext("2d"); console.log(canvas.height + "  " + canvas.width); var imgData = canvas.toDataURL("image/jpeg", 1); images[counter].photo = imgData; if (total == pages) { for(let i = 0; i < pages; i++){pdf.addImage(images[i].photo, "JPG", 75, 10, images[i].width, images[i].height);if (i != pages - 1) { pdf.addPage("l",[PDF_Width, PDF_Height]);} }  pdf.save("' + this.id + '.pdf"); } }); } } </script>';

        var script1 = '<script>function getPDF() { const pages = $(".printIt").length; alert(pages); var HTML_Width = $("#printIt").width(); var HTML_Height = $("#printIt").height();var top_left_margin = 2;var PDF_Width = HTML_Height+20;var PDF_Height = HTML_Width+20;var canvas_image_width = HTML_Height; var canvas_image_height = HTML_Width;var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;html2canvas($("#printIt")[0],{allowTaint:true}).then(function(canvas) {canvas.getContext("2d");console.log(canvas.height+"  "+canvas.width);var imgData = canvas.toDataURL("image/jpeg", 0.6); var pdf = new jsPDF("l", "pt",  [PDF_Width, PDF_Height]); pdf.addImage(imgData, "JPG", 10, 10,canvas_image_width,canvas_image_height);for (var i = 1; i <= 6; i++) {  pdf.addPage("l",[PDF_Width, PDF_Height]); pdf.addImage(imgData, "JPG", top_left_margin, -(PDF_Height*i)+(top_left_margin*4),canvas_image_width,canvas_image_height); } pdf.save("HTML-Document.pdf"); });};</script>'

        var script2='<script src="https://code.jquery.com/jquery-1.12.4.js"></script> <script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.3.3/jspdf.min.js"></script><script src="https://html2canvas.hertzen.com/dist/html2canvas.js"></script>'
        var script4='<script> function perform() { getPDF(); setTimeout(function(){ window.close(); }, 2500); } </script>'

        let page = document.getElementById('fullpagePrint').innerHTML;

        popupWinindow = window.open('', '_blank', 'width=auto,height=auto,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        popupWinindow.document.open();

        popupWinindow.document.write('<html style="width: fit-content;">' + winHead.innerHTML + '<body onload="perform()" class="body"><div class="full-page table-height">' + page + '</div>' + script2 + script3 + script4 + '</body></html>');
        popupWinindow.document.close();
      } else {
      }
      this.showCross = true;
      this.wholeDetails.map(detail => {
        detail.show = false;
      })
      this.wholeDetails[this.detailPage].show = true;

    }, 1);
    
  }

}
