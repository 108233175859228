import { Component, OnInit } from '@angular/core';
import { AuthService } from "src/app/core/services/api/api-auth.service";
import { AppDataService } from "src/app/app-data/app-data.service";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import {
  ViewChild,
  Inject,
  ElementRef,
  Output,
  EventEmitter
} from "@angular/core";
import {
  MatSidenav,
  MatSidenavContainer,
  MatBottomSheet
} from "@angular/material";
import { Subscription } from "rxjs";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { SideNavEventHandlerService } from "src/app/common/event/sidenav-event-handler.service";
import { ScrollEventService } from "src/app/common/event/scroll-event.service";
import { DistributorService } from 'src/app/core/services/api/distributor.service';


@Component({
  selector: 'app-delivery-executive',
  templateUrl: './delivery-executive.component.html',
  styleUrls: ['./delivery-executive.component.scss']
})
export class DeliveryExecutiveComponent implements OnInit {
  _drawerOpened = false;
  _slideMode = "over";

  _mNavMode = "side";
  _mNavOpen = false;
  _mShowMenu = false;
  auth;
  isAuthenticated = false;
  panelOpenState = false;
  isExpanded = false;

  subscriptions: Subscription[] = [];
  mCurrentHeading = 1;
  isAdmin = false;

  constructor(
    private location: Location,
    private router: Router,
    private scrollEventService: ScrollEventService,
    private media: MediaObserver,
    private sideNavEventHandlerService: SideNavEventHandlerService,
    private distributorService: DistributorService,
    private authService: AuthService,
    private appDataService: AppDataService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.media.media$.subscribe((mediaChange: MediaChange) => {
      this._mNavMode = this.getMode(mediaChange);
      // console.log("sidena3:", this._mNavMode);
      this.updateSlideMode(mediaChange);
      // this.opened = this.getOpened(mediaChange);
      this.router.events.subscribe(val => {
        if (this.location.path() !== "") {
          const routePath = this.location.path();

          return;
        } else {
        }
      });
    });
  }

  @ViewChild("fixedContent", { static: true }) public fixedContent: ElementRef;

  @ViewChild("mainDrawer", { static: true }) sidenav: MatSidenav;

  @ViewChild("sideNavContainer", { static: true })
  myScrollContainer: MatSidenavContainer;

  @ViewChild("contentScroller", { static: true }) contentScroller: ElementRef;

  @Output() public sidenavToggle = new EventEmitter();
  @Output() sidenavClose = new EventEmitter();

  ngOnInit() {
    this.distributorService.getPhNo().subscribe(num => {
      const phone = num;
      this.distributorService.checkAdmin(phone).subscribe(response => {
        this.isAdmin = response.data.user.admin;
      });
    });

    this.subscriptions[
      this.subscriptions.length
    ] = this.scrollEventService
      .getActionMainEmitter()
      .subscribe((heading: Number) => {
        this.mCurrentHeading = heading as number;
        this.scrollToHeader(heading);
      });
  }

  logout() {
    this.authService.logout().subscribe(
      value => {
        // console.log("logout: ", value);
        this.router.navigate(["/authenticate"]);
        this.appDataService.setData("IsUserExist", "false", "local");
      },
      error => {
        console.log("error", error);
      }
    );
  }


  navigateTo(heading: Number) {
    this.scrollEventService.emitHeadingEvent(heading);
    // this.closeDrawer()
  }
  scrollToHeader(heading: Number) {
    const headerClass: String = this.getHeaderClass(heading);
  }

  getHeaderClass(heading: Number): String {
    // switch (heading) {
    //   case ScrollEventService.SUB_HEADING.home:
    //     return '#home'

    //   default:
    //     break;
    // }
    return "";
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private updateSlideMode(mediaChange: MediaChange) {
    if (this.media.isActive("lt-md")) {
      this._slideMode = "over";
      this._drawerOpened = false;
    } else {
      this._slideMode = "over";
      this._drawerOpened = false;
    }
  }
  onActionLaunch(event) {
    this._mNavOpen = true;
    const isOpened = this.sidenav.opened;
    this.sidenav.toggle();
    this.sideNavEventHandlerService.emitMainEvent(!isOpened);
    // console.log(event);
  }

  onActivate(e, outlet) {
    this.contentScroller.nativeElement.scrollTop = 0;
    // this.myScrollContainer.scrollable.getElementRef().nativeElement.scrollTop = 0;
  }

  getMode(mediaChange: MediaChange): string {
    if (this.media.isActive("lt-md")) {
      // this._mNavOpen = false;
      return "";
    } else {
      // this._mNavOpen = false;
      return "side";
    }
  }

  _backdropToggle(type) {
    if (type === "backdrop") {
      this._drawerOpened = !this._drawerOpened;
    }
  }

  _toggleSideNav() {
    // console.log(sideNav.opened);
    // if (sideNav.opened == false) {
    this._drawerOpened = !this._drawerOpened;
    // }
  }
}
