import { Component, OnInit, Inject,ElementRef, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelNewManufacture } from './model';
import { ProductMasterService } from 'src/app/core/services/api/product-master.service';
import { PopupService } from 'src/app/core/services/popup/popup.service';
@Component({
  selector: 'app-add-new-manufacture',
  templateUrl: './add-new-manufacture.component.html',
  styleUrls: ['./add-new-manufacture.component.scss']
})
export class AddNewManufactureComponent implements OnInit {

manufacture='';
operationType='CREATE';
inputDisabled=false;
status="CREATE";
enableSave=false;
id=null;
  constructor(public dialogRef: MatDialogRef<AddNewManufactureComponent>,private productMaster: ProductMasterService,private popupService:PopupService,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelNewManufacture,) { }


  ngOnInit(): void {
    if(this.data.data.type==='approve' && this.data.data.value.length){
      this.status='APPROVE'
      this.manufacture=this.data.data.value
    }else if(this.data.data.type==='verified' && this.data.data.value.length){
      this.status='VERIFIED'
      this.manufacture=this.data.data.value
      this.inputDisabled=true
    }


    if(this.data.data.bridgeId){
      this.getCreationData()
    }

  }

  getCreationData(){
    const params={
      bridgeId:this.data.data.bridgeId,
      name:this.data.data.value
    }
    this.productMaster.getEntityCreation(params).subscribe(response=>{
      
      this.id=response.data.id
      this.popupService.closeProgress()
    },error=>{
      this.popupService.closeProgress()
      this.popupService.openError(error.error.data.localizedMessage)
    })
  }
onKeyup(){
  this.enableSave=true;
  this.manufacture=this.manufacture.toUpperCase()

}
  createManf(){
    this.popupService.openProgress()
    const body={
     "bridgeId":null,
     "productMappingRequestDTO": 
       {
         "dataInteger":null,
         "dataString":this.manufacture.trim(),
         "entryType": "TEMP",
         "id": "1",
         "operationType":this.operationType,
         "parentRequestId":null,
         "timeStamp": new Date().getTime(),
         "type":this.data.data.request.toUpperCase()
       }
     ,
     "status":'CREATE'
   }
   
   this.productMaster.createEntity({},body).subscribe(response=>{
     this.popupService.closeProgress()
     this.cancel()
    this.popupService.openSuccess(`${this.data.data.request} Submitted for Approve`)
   },error=>{
     this.popupService.closeProgress()
     this.popupService.openError(error.error.data.localizedMessage)
   })
  }

  valueChange(event){
    if(this.data.data.type==='approve'){
      this.operationType='UPDATE'
    }
  }

  approveManf(){
    this.popupService.openProgress()
    const body={
     "bridgeId":this.data.data.bridgeId,
     "productMappingRequestDTO": 
       {
         "dataInteger":this.id,
         "dataString":this.manufacture.trim(),
         "entryType": "TEMP",
         "id": "1",
         "operationType":this.operationType,
         "parentRequestId":null,
         "timeStamp": new Date().getTime(),
         "type":this.data.data.request.toUpperCase()
       }
     ,
     "status":'APPROVED'
   }
   
   this.productMaster.approveEntity({},body).subscribe(response=>{
     this.popupService.closeProgress()
     this.cancel()
    this.popupService.openSuccess(`${this.data.data.request} Approved Successfully`)
   },error=>{
     this.popupService.closeProgress()
     this.popupService.openError(error.error.data.localizedMessage)
     this.manufacture=this.data.data.value
   })
  }


  updateEntity(){
    this.popupService.openProgress()
    const body={
     
        "dataInteger":this.data.data.dataInteger,
        "dataString": this.manufacture,
        "operationType": "UPDATE",
        "parentNodeId":null,
        "productClassType": this.data.data.request.toUpperCase()
    
   }
   
   this.productMaster.updateEntity({},body).subscribe(response=>{
     this.popupService.closeProgress()
     this.cancel()
    this.popupService.openSuccess(`${this.manufacture} Updated Successfully`)
   },error=>{
     this.popupService.closeProgress()
     this.popupService.openError(error.error.data.localizedMessage)
     this.manufacture=this.data.data.value
   })
  }

  
  cancel() {
    this.dialogRef.close(true);
    
  }
}
