import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: "app-search-bar",
  templateUrl: "./search-bar.component.html",
  styleUrls: ["./search-bar.component.scss"]
})
export class SearchBarComponent implements OnInit {
  // ----search variables
  _mSearchType: string;
  _mSearchString: string;
  query;
  _mModel;
  _mFormGroup: FormGroup;

  @Input("model") set val(value: any) {
    if (value) {
      this._mModel = value;
      // console.log(value);
    }
  }

  @Input() set searchType(value: string) {
    if (value) {
      this._mSearchType = value;
      if (value === "products") {
        this.clearSearchValues();
      } else {
        this.clearSearchValues();
      }
    }
  }

  @Input() set searchStatus(value: string) {
    if (value) {
        this.clearSearchValues();
    }
  }

  @Output() SearchEvent: EventEmitter<string> = new EventEmitter<string>();

  @Output() ClearSearchEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor(private router: Router, private formBuilder: FormBuilder) {
    this._mFormGroup = this.formBuilder.group({
      name: [""]
    });
  }

  ngOnInit() {
    // console.log(this.router.events, "=====eevevev");
  }

  onKeyUp(e) {
    this.checkAfter(e.target.value);
  }

  checkAfter(val) {
    setTimeout( () => {
      if (val === this._mFormGroup.value.name) {
      this.SearchEvent.emit(this._mFormGroup.value.name);
      }
    }, 1000);
  }

  _searchEventEmitter($event) {
    this.query = $event.target.value;
    if (this.query.length > 2) {
      this.SearchEvent.emit(this.query);
    }
  }

  _searchTypeChanged($event) {
    // console.log("clear search field", $event);
  }

  clearSearchValues() {
    this._mSearchString = "";
    this.ClearSearchEvent.emit("discard");
  }
}
