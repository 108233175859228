import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelConfirm } from './model';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent implements OnInit {
  
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelConfirm
  ) {}

  ngOnInit(): void {
  }

  adjustInCurrentBill() {
    this.dialogRef.close(true);
  }

  adjustInNextBill() {
    this.dialogRef.close(false);
  }

  close() {
    this.dialogRef.close(null);
  }

}
