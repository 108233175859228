import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { AuthService } from '../services/api/api-auth.service';
import { PopupService } from "../services/popup/popup.service";


@Injectable({
  providedIn: 'root'
})
export class InterceptorHelpDeskService {
  constructor(private router: Router, private authService: AuthService, private popupService: PopupService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let url = request.url;
    return next.handle(request).pipe(
      catchError((err) => {
        if (url.includes("/helpNSupport") && err.status == 492) {
          setTimeout(() => {
            this.popupService.openCreateHelpExecutive();
          }, 3000)
        }
        return throwError(err);
        
      })
    )
  }
}