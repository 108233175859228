import { DOCUMENT, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatSidenav, MatSidenavContainer } from '@angular/material';
import { Router } from '@angular/router';
import { AppDataService } from 'src/app/app-data/app-data.service';
import { ScrollEventService } from 'src/app/common/event/scroll-event.service';
import { SideNavEventHandlerService } from 'src/app/common/event/sidenav-event-handler.service';
import { AdminService } from 'src/app/core/services/api/admin.service';
import { AuthService } from 'src/app/core/services/api/api-auth.service';
import { HubService } from 'src/app/core/services/api/hub.service';
import { PopupService } from 'src/app/core/services/popup/popup.service';

@Component({
  selector: 'app-demand',
  templateUrl: './demand.component.html',
  styleUrls: ['./demand.component.scss']
})
export class DemandComponent implements OnInit {

  _mNavMode = "side";
  _slideMode = "over";
  _mNavOpen = false;
  _mShowMenu = false;
  _drawerOpened: boolean = false;
  showDemandPlanning = true;
  invoiceNumber;
  orderedBilledData = [];
  showDetails = false;




  constructor(
    private authService: AuthService,
    private appDataService: AppDataService,
    private location: Location,
    private router: Router,
    private scrollEventService: ScrollEventService,
    private media: MediaObserver,
    private sideNavEventHandlerService: SideNavEventHandlerService,
    private adminService: AdminService,
    private popupService: PopupService,
    private hubService: HubService,
    private http: HttpClient,
    @Inject(DOCUMENT) private document: any
  ) {
    this.media.media$.subscribe((mediaChange: MediaChange) => {
      this._mNavMode = this.getMode(mediaChange);
      // console.log("sidena3:", this._mNavMode);
      this.updateSlideMode(mediaChange);
      // this.opened = this.getOpened(mediaChange);
      this.router.events.subscribe((val) => {
        if (this.location.path() != "") {
          const routePath = this.location.path();

          return;
        } else {
        }
      });
    });
   }

   @ViewChild("fixedContent", { static: true }) public fixedContent: ElementRef;

   @ViewChild("mainDrawer", { static: true }) sidenav: MatSidenav;
 
   @ViewChild("sideNavContainer", { static: true })
   myScrollContainer: MatSidenavContainer;
 
   @ViewChild("contentScroller", { static: true }) contentScroller: ElementRef;
 
   @Output() public sidenavToggle = new EventEmitter();
   @Output() sidenavClose = new EventEmitter();

  ngOnInit(): void {
  }

  private updateSlideMode(mediaChange: MediaChange) {
    if (this.media.isActive("lt-md")) {
      this._slideMode = "over";
      this._drawerOpened = false;
    } else {
      this._slideMode = "over";
      this._drawerOpened = false;
    }
  }

  getMode(mediaChange: MediaChange): string {
    if (this.media.isActive("lt-md")) {
      // this._mNavOpen = false;
      return "";
    } else {
      // this._mNavOpen = false;
      return "side";
    }
  }

  //can be included in the fututre releases
  toggleDemandPlanning(){
    this.showDemandPlanning = !this.showDemandPlanning;
  }

  // logout logic
  logout() {
    this.authService.logout().subscribe(
      (value) => {
        // console.log("logout: ", value);
        this.router.navigate(["/authenticate"]);
        this.appDataService.setData("IsUserExist", "false", "local");
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  // toggle side nav logic
  _toggleSideNav(sideNav) {
    this._drawerOpened = !this._drawerOpened;
  }

  openSideNav(invNo) {
    this.popupService.openProgress();
    const params = {
      invoiceNumber: invNo,
    };
    this.invoiceNumber = invNo;
    this.adminService.globalSearchGetOrderedBilledData(params).subscribe(
      (response) => {
        this.orderedBilledData = response.data;
        this.orderedBilledData.map((item) => {
          if (!item.orderCompareDetails) {
            item.orderCompareDetails = {};
          }
          if (!item.purchaseCompareDetails) {
            item.purchaseCompareDetails = {};
          }
          if (!item.billCompareDetails) {
            item.billCompareDetails = {};
          }
        });
        this.showDetails = true;
        this.popupService.closeProgress();
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  onActivate(e, outlet) {
    this.contentScroller.nativeElement.scrollTop = 0;
    // this.myScrollContainer.scrollable.getElementRef().nativeElement.scrollTop = 0;
  }

  _backdropToggle(type) {
    if (type === "backdrop") {
      this._drawerOpened = !this._drawerOpened;
    }
  }

}
