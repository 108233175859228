import { InjectionToken } from '@angular/core';
import { environment } from 'src/environments/environment';


export let POSTMAN = new InjectionToken('app.postman');

export class Postman {
         // static API_BASE_URL: string;
         public static API_URL: string = environment.BASE_URL;

         getBaseUrl(): string {
           return Postman.API_URL;
         }

         auth() {
           return {
             checkAuth: "/auth/checkAuth",
             getUser: "/auth/user",
             addUser: "/auth/user",
             updateUser:"/auth/user/update"
           };
         }

         admin() {
           return {};
         }

         home() {
           return {};
         }


       }