import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelGenerateDn } from './model';
import { SupplyService } from 'src/app/core/services/api/supply.service';
import { PopupService } from 'src/app/core/services/popup/popup.service';

@Component({
  selector: 'app-generate-dn',
  templateUrl: './generate-dn.component.html',
  styleUrls: ['./generate-dn.component.scss']
})
export class GenerateDnComponent implements OnInit {
  returnOptions = [];
  itemToBeSent;

  constructor(
    public dialogRef: MatDialogRef<GenerateDnComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelGenerateDn,
    private supplyService: SupplyService,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {
    console.log(this.data)
    this.getReturnOptions();
  }

  // Get return options list
  getReturnOptions() {
    this.data.invoice.items.map(item => {
      if (item.confirmationItem) {
        this.itemToBeSent = item;
      }
    })
    this.supplyService.getReturnOptionsV2({}, this.itemToBeSent).subscribe(
      response => {
        this.returnOptions = response.data;
        console.log(this.returnOptions)
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  // Handle actions
  sendItem(action) {
    if (action == 'Damaged') {
      this.sendToDamaged();
    } else if (action.includes(':')) {
      this.sendForDn();
    } else {
      this.sendToInventory();
    }
  }

  // Send item for debit note
  sendForDn() {
    const body = [];
    this.data.invoice.items.map(item => {
      if (item.selected) {
        body.push(item);
      }
    })
    this.supplyService.sendForDebitNote({}, body).subscribe(
      response => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data);
        this.dialogRef.close(true);        
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
        this.dialogRef.close(true);
      }
    )
  }

  // send item to inventory
  sendToInventory() {
    const body = [];
    this.data.invoice.items.map(item => {
      if (item.selected) {
        body.push(item);
      }
    })
    body.map(item => {
      item.sentToOurInventory = true;
    })
    this.supplyService.sendToInventory({}, body).subscribe(
      response => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data);  
        this.dialogRef.close(true);      
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
        this.dialogRef.close(true);
      }
    )
  }

  // send item to damaged
  sendToDamaged() {
    const body = [];
    this.data.invoice.items.map(item => {
      if (item.selected) {
        body.push(item);
      }
    })
    this.supplyService.setDamaged({}, body).subscribe(
      response => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data); 
        this.dialogRef.close(true);       
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
        this.dialogRef.close(true);
      }
    )
  }

  // close popup
  closeIt() {
    this.dialogRef.close(null);
  }

}
