import { HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { AuthService } from '../services/api/api-auth.service';

@Injectable({
  providedIn: "root"
})
export class InterceptorAuthService {
  constructor(private router: Router, private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let url = request.url;
    if (
      url.includes("assets/") ||
      url.includes("authenticate") ||
      url.includes("firebasestorage.googleapis.com")
    ) {
      return next.handle(request);
    } else if (url.includes("/checkAuth")) {
      return this.authService.getAccessToken().pipe(
        mergeMap((token: String) => {
          if (token) {
            // console.log("tokennnnnnnnn", token);
            // clone and modify the request
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`
              }
            });
          }

          return next.handle(request);
        })
      );
    } else {
      return this.authService.getAccessToken().pipe(
        mergeMap((token: String, htt) => {
          if (token) {
            // clone and modify the request
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`,
                // 'Content-Type': undefined
              }
            });
          }
          return next.handle(request);
        })
      );
    }
  }
}
