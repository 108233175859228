import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SupplyService } from 'src/app/core/services/api/supply.service';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { DialogRevertCnModel } from './model';

@Component({
  selector: 'app-revert-cn',
  templateUrl: './revert-cn.component.html',
  styleUrls: ['./revert-cn.component.scss']
})
export class RevertCNComponent implements OnInit {
  cnNumber = null;
  reason = null;

  constructor(
    public dialogRef: MatDialogRef<RevertCNComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogRevertCnModel,
    private supplyService: SupplyService,
    public popUpService: PopupService
  ) { }

  ngOnInit(): void {
    this.cnNumber = this.data.data
  }

  cancel(body) {
    this.dialogRef.close(body);
  }

  confirmRevert() {
    this.popUpService.openProgress();

    const params = {
      cnNumber: this.cnNumber,
      reason: this.reason
    }

    this.supplyService.revertCreditNote(params).subscribe(
      response => {
      this.popUpService.closeProgress();
      this.cancel(response.data);
      },
      error => {
        this.popUpService.closeProgress();
        this.popUpService.openError(error.error.error.error);
      })
  }
}
