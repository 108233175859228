import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SupplyService } from 'src/app/core/services/api/supply.service';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { DialogReverify } from './model';
@Component({
  selector: 'app-reverify',
  templateUrl: './reverify.component.html',
  styleUrls: ['./reverify.component.scss']
})
export class ReverifyComponent implements OnInit {
  
  retailerCodes=''

  constructor(public dialogRef: MatDialogRef<ReverifyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogReverify,
    private supplyService: SupplyService,
    public popUpService: PopupService) { }

  ngOnInit(): void {
  }
  cancel() {
    this.dialogRef.close();
  }
  submit(){
    let arr=this.retailerCodes.split(",")
    this.dialogRef.close(arr);
   
  }
}
