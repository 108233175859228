import { AnalyticsContainerComponent } from './analytics-container/analytics-container.component';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule, MatRippleModule, MatSidenavModule, MatToolbarModule } from "@angular/material";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "../../material.module";
import { TbWaterfallModule } from "../../tb/tb-waterfall/tb-waterfall.module";
import { AnalyticsComponent } from "./analytics.component";
import { AnalyticsContainerModule } from './analytics-container/analytics-container.module';

@NgModule({
  declarations: [AnalyticsComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    MatRippleModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    TbWaterfallModule,
    RouterModule
  ],
  exports: [AnalyticsComponent]
})
export class AnalyticsModule { }
