import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelConfirm } from '../dialog-confirm/model';
import { DialogModelBlockUser } from './model';
import { AdminService } from "src/app/core/services/api/admin.service";
import { PopupService } from "src/app/core/services/popup/popup.service";

@Component({
  selector: 'app-block-user',
  templateUrl: './block-user.component.html',
  styleUrls: ['./block-user.component.scss']
})
export class BlockUserComponent implements OnInit {
  reasons = [];
  currReason = "";
  comment = "";
  selectedCount = 0;

  constructor(
    public dialogRef: MatDialogRef<BlockUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelBlockUser,
    private adminService: AdminService,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {
    this.popupService.openProgress();
    this.adminService.getBlockReasons().subscribe(
      response => {
        this.reasons = response.data;
        this.reasons.map(reason => {
          reason.selected = false;
        })
        if (this.reasons.length > 0) {
          this.currReason = this.reasons[0];
        }
        this.selectedCount = 0;
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.dialogRef.close(false);
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  selectReason(e) {
    this.selectedCount = 0;
    this.reasons.map(reason => {
      if (reason.selected) {
        this.selectedCount = this.selectedCount + 1;
      }
    })
  }

  block() {
    const body = {
      reasons: [],
      comment: this.comment
    };
    this.reasons.map(reason => {
      if (reason.selected) {
        body.reasons.push(reason.reason)
      }
    })
    this.dialogRef.close(body);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
