import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogModelStoreDetails } from './model';
import { PopupService } from "src/app/core/services/popup/popup.service";

@Component({
  selector: 'app-oms-store-details',
  templateUrl: './oms-store-details.component.html',
  styleUrls: ['./oms-store-details.component.scss']
})
export class OmsStoreDetailsComponent implements OnInit {

  constructor(
    private popupService: PopupService,
    public dialogRef: MatDialogRef<OmsStoreDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelStoreDetails,
    ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

}
