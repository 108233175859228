import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AdminService } from "src/app/core/services/api/admin.service";
import { FileUploadService } from "src/app/core/services/api/file-upload.service";
import { HubService } from "src/app/core/services/api/hub.service";
import { PopupService } from "src/app/core/services/popup/popup.service";
import { DialogModelAddBanner } from "./model";
import { v4 as uuidv4 } from "uuid";
@Component({
  selector: "app-add-banner",
  templateUrl: "./add-banner.component.html",
  styleUrls: ["./add-banner.component.scss"],
})
export class AddBannerComponent implements OnInit {
  hubs = [];
  currentAttachment;
  maxStartTime;
  minEndTime;
  // originalStartTime = '00:00';
  startTime = "00:00";
  endTime = "00:00";
  startDate;
  endDate;
  retrievalCodes = [];
  gamificationConstants = {};
  gamificationSubConstants = [];
  searchFactor = "";
  medSearch = null;
  targets = [];
  sampleTarget = {
    gift: [],
    targetsList: [],
    targetInputs: {
      targetValue: null,
      targetType: [],
      subFactor: null,
      targetQuantityValue: null,
    },
    giftInputs: {
      items: null,
      quantity: null,
      selectedItem: { medicineName: "" },
    },
  };
  targetsCount = 0;
  maxTargetValue = 0;
  currentGamificationStep = 1;
  cohortFile = null;
  cohortList = [];
  gamificationData = {
    campaignCohortFileUrl: null,
    cohortFileName: null,
    campaignImageUrl: null,
    campaignDescription: null,
    campaignName: null,
    campaignType: null,
    targetCompletionType: null,
    durationType: "Delayed",
    factorType: null,
    subFactorType: [],
    endTime: 0,
    giftType: "",
    hub: null,
    isCohort: false,
    retrievalSpoke: [],
    scheduled: true,
    startTime: 0,
    active: false,
  };
  distributorId = null;
  winnersList = null;
  wrongWinnersList = null;
  wrongWinnersListHeader = null;
  removable = true;
  timer;
  disableCampaignIdList = [
    17, 24, 25, 26, 27, 28, 29, 30, 31, 33, 34, 35, 36, 38, 39, 42, 43,
  ];
  disableCampaignId = null;
  selectedHubs = [
    { hub: { hubCode: null, id: null }, selectRsp: [], allRsps: [] },
  ];
  selectedHubArray = [];
  filteredHubs = [];
  constructor(
    public dialogRef: MatDialogRef<AddBannerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelAddBanner,
    private popupService: PopupService,
    private fileUploadService: FileUploadService,
    private adminService: AdminService,
    private hubService: HubService
  ) {}

  ngOnInit(): void {
    this.popupService.openProgress();
    // Setting the campaign id for which the winner section to be disabled
    this.disableCampaignIdList.map((ele) => {
      if (ele === this.data.data.bannerId) {
        this.disableCampaignId = ele;
      }
    });
    // setup start and end date format
    if (this.data.data.currSection !== "rewards") {
      this.startDate = new Date(this.data.data.bannerDetails.startTime)
        .toLocaleString()
        .split(", ")[0];
      this.startDate =
        this.startDate.split("/")[2] +
        "-" +
        this.startDate.split("/")[1] +
        "-" +
        this.startDate.split("/")[0];
      this.startTime = new Date(this.data.data.bannerDetails.startTime)
        .toLocaleString()
        .split(", ")[1];
    } else {
      this.startDate = new Date(this.data.data.bannerDetails.campaignStartTime)
        .toLocaleString()
        .split(", ")[0];
      this.startDate =
        this.startDate.split("/")[2] +
        "-" +
        this.startDate.split("/")[1] +
        "-" +
        this.startDate.split("/")[0];
      this.startTime = new Date(this.data.data.bannerDetails.campaignStartTime)
        .toLocaleString()
        .split(", ")[1];
    }

    this.endDate = new Date(this.data.data.bannerDetails.endTime)
      .toLocaleString()
      .split(", ")[0];
    this.endDate =
      this.endDate.split("/")[2] +
      "-" +
      this.endDate.split("/")[1] +
      "-" +
      this.endDate.split("/")[0];
    this.endTime = new Date(this.data.data.bannerDetails.endTime)
      .toLocaleString()
      .split(", ")[1];
    // if(this.data.data.gamificationState === 'TO_BE_LIVE')
    // {
    //   this.originalStartTime = this.startTime;
    // }
    if (this.data.data.currSection == "rewards" && !this.data.data.isNew) {
      this.gamificationData.hub = { id: this.data.data.bannerDetails.hubId };
    }

    this.getHubs(true);

    if (this.data.data.currSection === "rewards") {
      this.getAllConstants();
    }

    if (this.data.data.isNew) {
      // if (this.data.data.currSection === "rewards") {
      this.startDate = null;
      this.endDate = null;
      // }
      this.targets.push(JSON.parse(JSON.stringify(this.sampleTarget)));
    }
  }

  setCampaignData() {
    this.targets = [];
    let res = this.data.data.bannerDetails;
    this.gamificationData.campaignName = res.campaignName;
    this.gamificationData.targetCompletionType = res.targetCompletionType;
    this.gamificationData.durationType = res.durationType;
    this.gamificationData.campaignDescription = res.campaignDescription;
    this.gamificationData.cohortFileName = res.cohortFileName;
    this.gamificationData.campaignImageUrl = res.campaignImageUrl;
    this.gamificationData.factorType = res.factorType;
    let StDate = new Date(res.campaignStartTime);
    let StDateString =
      StDate.getFullYear() +
      "-" +
      ("0" + (StDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + StDate.getDate()).slice(-2) +
      "T" +
      ("0" + StDate.getHours()).slice(-2) +
      ":" +
      ("0" + StDate.getMinutes()).slice(-2);
    this.startDate = StDateString;
    let EnDate = new Date(res.campaignEndTime);
    let EnDateString =
      EnDate.getFullYear() +
      "-" +
      ("0" + (EnDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + EnDate.getDate()).slice(-2) +
      "T" +
      ("0" + EnDate.getHours()).slice(-2) +
      ":" +
      ("0" + EnDate.getMinutes()).slice(-2);
    this.endDate = EnDateString;

    this.hubs.forEach((hub) => {
      if (res.hubId === hub.id) {
        this.gamificationData.hub = hub;
      }
    });

    res.campaignRspIdCodeMapList.map((rsp) => {
      this.retrievalCodes.map((rspTotal) => {
        if (rspTotal.id === rsp.retrievalSpokeId) {
          this.gamificationData.retrievalSpoke.push(rspTotal);
        }
      });
    });

    res.targetGiftList.forEach((target) => {
      let obj = {
        gift: [],
        targetsList: [],
        targetInputs: { targetValue: null, targetType: [], subFactor: null },
        giftInputs: {
          items: null,
          quantity: null,
          selectedItem: { medicineName: "" },
        },
      };

      target.giftItemList.forEach((gift) => {
        obj.gift.push({
          medItem: gift,
          quantity: gift.quantity,
        });
      });
      obj.targetsList = target.targetList;
      this.targets.push(obj);
      this.targetsCount++;
    });
  }
  hubFocusOut(index) {
    (<HTMLInputElement>document.getElementById(`hub${index}`)).value =
      this.selectedHubs[index].hub.hubCode;
  }
  hubsFocus(index) {
    this.filteredHubs = [...this.hubs];
  }

  searchHub(event) {
    let data = [...this.hubs];
    this.filteredHubs = data.filter((item) =>
      item.hubCode.toLowerCase().includes(event.target.value.toLowerCase())
    );
  }
  searchRsp(event, index) {
    this.selectedHubs[index].allRsps.forEach((item, i) => {
      if (
        !item.retrievalSpokeCode
          .toUpperCase()
          .includes(event.target.value.toUpperCase())
      ) {
        item.show = false;
      } else {
        item.show = true;
      }
    });
  }

  // get hubs
  getHubs(closeProgress) {
    this.hubService.getHubs().subscribe(
      (response) => {
        this.hubs = response.body.data;
        this.filteredHubs = [...response.body.data];
        if (this.data.data.currSection !== "rewards") {
          if (this.data.data.bannerDetails.hubId) {
            this.getRetrievalCodes(closeProgress);
          } else {
            this.popupService.closeProgress();
          }
        } else {
          if (this.gamificationData.hub) {
            this.getRetrievalCodesForCampaign(true);
          }
        }
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  changeHub() {
    this.popupService.openProgress();
    this.getRetrievalCodes(true);
  }
  rspChange(item) {
    const found = item.selectRsp.some((el) => el.id === "all");
    if (found) {
      item.selectRsp = [];
      item.allRsps.forEach((rsp) => {
        item.selectRsp.push(rsp);
      });
    } else {
      item.selectRsp = [];
    }
  }

  addHub() {
    this.selectedHubs.push({
      hub: { hubCode: null, id: null },
      selectRsp: [],
      allRsps: [],
    });
  }

  deleteHub(index) {
    this.selectedHubArray.splice(index, 1);
    this.selectedHubs.splice(index, 1);
  }
  changeCampaignHub() {
    this.popupService.openProgress();

    this.adminService
      .getHubDistributorId({ hubId: this.gamificationData.hub.id })
      .subscribe(
        (response) => {
          this.distributorId = response.data;
        },
        (error) => {
          this.popupService.openError(error.error.error.error);
        }
      );
    this.getRetrievalCodesForCampaign(true);
  }

  changeHubBanners(index, event) {
    this.selectedHubs[index].hub = event;
    this.selectedHubArray[index] = event.hubCode;
    this.getRetrievalCodesForBanner(index);
  }
  getRetrievalCodesForBanner(index) {
    let params = {
      hubId: this.selectedHubs[index].hub.id,
    };

    this.hubService.getRetrievalCodes(params).subscribe(
      (response) => {
        this.retrievalCodes = response.data;
        this.selectedHubs[index].allRsps = response.data;
        this.selectedHubs[index].selectRsp = [];
        this.selectedHubs[index].allRsps.unshift({
          retrievalSpokeCode: "Select_All",
          id: "all",
        });
        this.popupService.closeProgress();
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }
  logItems(index) {
    console.log(this.selectedHubs[index]);
  }
  getRetrievalCodes(closeProgress) {
    let params = {
      hubId: this.data.data.bannerDetails.hubId,
    };

    if (this.data.data.currSection == "rewards") {
      params.hubId = this.gamificationData.hub.id;
    }

    this.hubService.getRetrievalCodes(params).subscribe(
      (response) => {
        this.retrievalCodes = response.data;
        if (closeProgress) {
          this.popupService.closeProgress();
        }
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  getRetrievalCodesForCampaign(closeProgress) {
    let params = {
      hubId: this.gamificationData.hub.id,
    };

    if (this.data.data.currSection === "rewards") {
      params.hubId = this.gamificationData.hub.id;
    }

    this.hubService.getRetrievalCodes(params).subscribe(
      (response) => {
        this.retrievalCodes = response.data;

        !this.data.data.isNew && this.setCampaignData();

        if (closeProgress) {
          this.popupService.closeProgress();
        }
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  // Select Sub Factors
  getSubFactor(event) {
    this.popupService.openProgress();

    this.searchFactor = "";
    this.gamificationSubConstants = [];
    this.gamificationData.subFactorType = [];
    this.targets = [];
    this.targetsCount = 0;

    const params = {
      factor: this.gamificationData.factorType,
    };

    this.adminService.getTargetType(params).subscribe(
      (response) => {
        this.gamificationConstants["TARGETS"] = response.data;

        if (event.value.toLowerCase() === "category") {
          this.adminService
            .getAllMedicinesCategoriesForGamification()
            .subscribe(
              (response) => {
                this.gamificationSubConstants = response.data;
                this.popupService.closeProgress();
              },
              (error) => {
                this.popupService.closeProgress();
                this.popupService.openError(error.error.error.error);
              }
            );
        } else {
          this.popupService.closeProgress();
        }
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  // Search Sub Factor
  searchSubFactor(event) {
    this.searchFactor = event.target.value;

    if (this.gamificationData.factorType.toLowerCase() === "company") {
      const params = {
        searchKey: this.searchFactor,
        page: 0,
      };

      this.adminService.getAllCompanyBySearch(params).subscribe(
        (response) => {
          this.gamificationSubConstants = response.data;
        },
        (error) => {}
      );
    } else if (this.gamificationData.factorType.toLowerCase() === "medicine") {
      const params = {
        searchKey: this.searchFactor,
        page: 0,
      };

      this.adminService.getAllMedicineBySearch(params).subscribe(
        (response) => {
          this.gamificationSubConstants = response.data;
        },
        (error) => {}
      );
    }
  }

  // Select Sub Factor
  selectSubFactor(event) {
    let str;
    if (this.gamificationData.factorType.toLowerCase() === "category") {
      str = event.source.value;
      this.gamificationData.subFactorType = str;
    } else if (this.gamificationData.factorType.toLowerCase() === "medicine") {
      if (
        this.gamificationData.subFactorType.some(
          (ele) => ele.medicineId === event.medicineId
        )
      ) {
        this.popupService.openError("Item is already selected");
      } else {
        str = {
          medicineId: event.medicineId,
          medicineName: event.name,
        };
        this.gamificationData.subFactorType.push(str);
      }
    } else {
      if (this.gamificationData.subFactorType.some((ele) => ele === event)) {
        this.popupService.openError("Item is already selected");
      } else {
        str = event;
        this.gamificationData.subFactorType.push(str);
      }
    }

    this.searchFactor = "";

    if (this.gamificationData.factorType.toLowerCase() !== "category") {
      this.gamificationSubConstants = [];
    }
  }

  // select image/gif/video
  selectOnlineFile(e) {
    this.currentAttachment = e.target.files[0];
    const formData = new FormData();
    formData.append(
      "file",
      this.currentAttachment,
      this.currentAttachment.name
    );

    this.fileUploadService.uploadBannerImage(formData).subscribe(
      (response) => {
        this.data.data.bannerDetails.imageUrl = response.data;
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  uploadWinnerList(e) {
    this.popupService.openProgress();

    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append(
        "multipartFile",
        e.target.files[0],
        e.target.files[0].name
      );
      const params = {
        campaignId: this.data.data.bannerId,
      };
      this.adminService.uploadCampaignWinnersList(formData, params).subscribe(
        (response) => {
          var reader = new FileReader();

          reader.readAsText(response.body);
          reader.onload = () => {
            const result = String(reader.result);
            const obj = JSON.parse(result);

            if (obj.data.body.Reason) {
              this.wrongWinnersList = obj.data.body.errorList;
              this.wrongWinnersListHeader = obj.data.body.errorHeader;
              this.popupService.closeProgress();
              this.popupService.openError(obj.data.body.Reason);
            } else {
              if (obj.data.body.WinnersUploaded) {
                this.popupService.closeProgress();
                this.popupService.openSuccess(obj.data.body.SUCCESS_MESSAGE);
              } else {
                this.popupService.closeProgress();
                this.popupService
                  .openUploadWinnerList(obj.data, this.data.data.bannerId)
                  .afterClosed()
                  .subscribe((res) => {
                    if (res) {
                      this.popupService.openSuccess("Success");
                    }
                  });
              }
            }
          };
        },
        (error) => {
          var reader = new FileReader();

          reader.readAsText(error.error);
          reader.onload = () => {
            const result = String(reader.result);
            const obj = JSON.parse(result);
            this.popupService.closeProgress();
            this.popupService.openError(obj.error.error);
          };
        }
      );
    }
  }

  selectCohertFile(e) {
    this.cohortFile = e.target.files[0];

    if (this.cohortFile) {
      this.popupService.openProgress();
      let formData = new FormData();
      formData.append("cohortFile", this.cohortFile);

      this.adminService.uploadCohortFile(formData).subscribe(
        (res) => {
          if (res.data.Reason) {
            this.wrongWinnersList = res.data?.RetailerCodeList;
            this.popupService.closeProgress();
            this.popupService.openError(res.data.Reason);
          } else {
            this.wrongWinnersList = null;
            this.gamificationData.campaignCohortFileUrl =
              res.data?.campaignCohortFileUrl;
            this.cohortList = res.data?.retailerList;
            this.gamificationData.isCohort = true;
            this.gamificationData.hub = null;
            this.gamificationData.retrievalSpoke = null;
            this.popupService.closeProgress();
          }
        },
        (error) => {
          this.popupService.closeProgress();
          this.gamificationData.isCohort = false;
          this.popupService.openError(error.error.error.error);
        }
      );
    } else {
      this.gamificationData.isCohort = false;
    }
  }

  selectGamificationBannerFile(e) {
    this.currentAttachment = e.target.files[0];
    const formData = new FormData();
    formData.append(
      "file",
      this.currentAttachment,
      this.currentAttachment.name
    );
    this.fileUploadService.uploadBannerImage(formData).subscribe(
      (response) => {
        this.gamificationData.campaignImageUrl = response.data;
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  // update start time
  updateStartTime() {
    this.minEndTime = this.startDate;
  }

  // update end time
  updateEndTime() {
    this.maxStartTime = this.endDate;
  }

  // add/update banner
  addBanner() {
    this.popupService.openProgress();
    if (this.checkBannerValidity()) {
      const body = JSON.parse(JSON.stringify(this.data.data.bannerDetails));
      body.startTime = new Date(
        this.startDate + " " + this.startTime
      ).getTime();
      body.endTime = new Date(this.endDate + " " + this.endTime).getTime();
      if (body.startTime < 1) {
        body.startTime = null;
      }
      if (body.endTime < 1) {
        body.endTime = null;
      }
      let hubConfigList = [];
      this.selectedHubs.forEach((data) => {
        if (data.hub?.id) {
          let obj = {
            hubId: data.hub?.id,
            retrievalCodeDataList: [],
          };
          if (data.selectRsp?.length) {
            let arr = [];
            data.selectRsp.every((item) => {
              if (item.id === "all") {
                return false;
              } else {
                arr.push({ retrievalCodeId: item.id });
                return true;
              }
            });
            obj["retrievalCodeDataList"] = arr;
          }
          hubConfigList.push(obj);
        }
      });

      body.bannerHubAndRetrievalList = hubConfigList;
      this.adminService.updateBanner({}, body).subscribe(
        (response) => {
          this.popupService.closeProgress();
          this.popupService.openSuccess(response.data);
          this.dialogRef.close();
        },
        (error) => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      );
    }
  }

  editBanner() {
    this.popupService.openProgress();
    if (this.checkBannerValidity()) {
      const body = JSON.parse(JSON.stringify(this.data.data.bannerDetails));
      body.startTime = new Date(
        this.startDate + " " + this.startTime
      ).getTime();
      body.endTime = new Date(this.endDate + " " + this.endTime).getTime();
      if (body.startTime < 1) {
        body.startTime = null;
      }
      if (body.endTime < 1) {
        body.endTime = null;
      }

      body.bannerHubAndRetrievalList = [];
      this.adminService.editBanner({}, body).subscribe(
        (response) => {
          this.popupService.closeProgress();
          this.popupService.openSuccess(response.data);
          this.dialogRef.close();
        },
        (error) => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      );
    }
  }

  // check banner details validity
  checkBannerValidity() {
    if (!this.data.data.bannerDetails.imageUrl) {
      this.popupService.closeProgress();
      this.popupService.openError("Please add Image/GIF/Video");
      return false;
    }
    if (
      !this.data.data.bannerDetails.position ||
      this.data.data.bannerDetails.position < 0
    ) {
      this.popupService.closeProgress();
      this.popupService.openError("Please add valid position");
      return false;
    }
    if (!this.data.data.bannerDetails.caption) {
      this.popupService.closeProgress();
      this.popupService.openError("Please add caption");
      return false;
    }
    if (!this.data.data.bannerDetails.fileName) {
      this.popupService.closeProgress();
      this.popupService.openError("Please add analytics id");
      return false;
    }
    if (!this.data.data.bannerDetails.redirectUrl) {
      this.popupService.closeProgress();
      this.popupService.openError("Please add deeplink");
      return false;
    }

    if (!this.startDate || !this.startTime || !this.endDate || !this.endTime) {
      this.popupService.closeProgress();
      this.popupService.openError("Please add start and end timestamps");
      return false;
    }
    return true;
  }

  // close popup
  closePopup() {
    this.dialogRef.close();
  }

  prevStep() {
    if (this.currentGamificationStep > 1) {
      this.currentGamificationStep--;
    }
  }

  nextStep() {
    let errorStatus = false;

    if (
      this.data.data.isNew ||
      this.data.data.gamificationState === "TO_BE_LIVE"
    ) {
      if (!this.gamificationData.campaignType) {
        errorStatus = true;
        this.popupService.openError("Please select the campaign type");
      } else if (
        this.data.data.isNew &&
        !this.gamificationData.targetCompletionType
      ) {
        errorStatus = true;
        this.popupService.openError("Please select the target completion type");
      } else if (
        this.data.data.isNew &&
        this.gamificationData.campaignType.toLowerCase() === "cohort" &&
        !this.gamificationData.isCohort
      ) {
        errorStatus = true;
        this.popupService.openError("Please upload the cohort file");
      } else if (
        this.data.data.isNew &&
        this.gamificationData.isCohort &&
        this.gamificationData.campaignCohortFileUrl === null
      ) {
        errorStatus = true;
        this.popupService.openError("Please upload the cohort file");
      } else if (!this.gamificationData.campaignImageUrl) {
        errorStatus = true;
        this.popupService.openError("Please upload the campaign image");
      } else if (!this.startDate || !this.endDate) {
        errorStatus = true;
        this.popupService.openError(
          "Please set the start and the end date for the campaign"
        );
      } else if (new Date(this.startDate) < new Date()) {
        errorStatus = true;
        this.popupService.openError(
          "Start date cannot be less than the current date"
        );
      } else if (this.startDate > this.endDate) {
        errorStatus = true;
        this.popupService.openError(
          "Start date & time cannot be greater than the end date & time"
        );
      } else if (
        (this.gamificationData.campaignType.toLowerCase() === "rsp" &&
          !this.gamificationData.hub) ||
        (this.gamificationData.campaignType.toLowerCase() === "hub" &&
          !this.gamificationData.hub)
      ) {
        errorStatus = true;
        this.popupService.openError("Please select the campaign hub");
      } else if (
        this.gamificationData.campaignType.toLowerCase() === "rsp" &&
        this.gamificationData.retrievalSpoke.length < 1
      ) {
        errorStatus = true;
        this.popupService.openError("Please select the campaign rsp");
      } else if (!this.gamificationData.campaignName) {
        errorStatus = true;
        this.popupService.openError("Please enter the campaign name");
      } else if (!this.gamificationData.campaignDescription) {
        errorStatus = true;
        this.popupService.openError("Please enter the campaign description");
      }
      // else if(this.data.data.isNew && (new Date(this.startDate) < new Date())){
      //   errorStatus=true
      //   this.popupService.openError("Start date and time cannot be less than the current date")
      // }else if((!this.data.data.isNew && this.data.data.gamificationState === 'TO_BE_LIVE') && ((new Date(this.originalStartTime)) > new Date()) && (new Date() > new Date(this.startTime))){
      //   errorStatus=true
      //   this.popupService.openError("Start date and time cannot be less than the current date")
      // }else if((!this.data.data.isNew && this.data.data.gamificationState === 'TO_BE_LIVE') && (new Date(this.originalStartTime) < new Date(this.startTime))){
      //   errorStatus=true
      //   this.popupService.openError("This campaign is already live")
      // }
    }

    if (!errorStatus) {
      this.currentGamificationStep++;
    }
  }

  // get all constants for gamification dropdowns
  getAllConstants() {
    this.popupService.openProgress();
    this.adminService.getAllConstantsForGamification().subscribe(
      (res) => {
        this.gamificationConstants = res.data;
        // this.gamificationConstants['DURATIONS'] = this.gamificationConstants['DURATIONS'].filter(ele => ele.toLowerCase() !== 'instant')
        this.gamificationConstants["DURATIONS"] = "Delayed";
        this.gamificationConstants["TARGETS"] = "GMV";
        this.gamificationData.targetCompletionType =
          this.gamificationConstants["TARGETSCOMPLETION"].length === 1
            ? this.gamificationConstants["TARGETSCOMPLETION"][0]
            : null;
        this.gamificationData.giftType = res.data.GIFTS[0];
        this.gamificationData.campaignType =
          this.data.data.bannerDetails.campaignType?.toUpperCase();
        this.popupService.closeProgress();
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  addParentTarget() {
    if (this.targetsCount === 0) {
      this.targets = [];
      this.targets.push(JSON.parse(JSON.stringify(this.sampleTarget)));
    } else {
      let prevTarget = this.targets[this.targets.length - 1];

      if (prevTarget.targetsList.length === 0 || prevTarget.gift.length === 0) {
        this.popupService.openError(
          "Fill select the details for the current target."
        );
      } else {
        this.targets.push(JSON.parse(JSON.stringify(this.sampleTarget)));
      }
    }
    this.targetsCount++;
  }

  changeMedSearch(event) {
    this.gamificationData.subFactorType = [];
    this.medSearch = event;
  }

  // Search by multiple medicine id's
  searchMedicineId(event) {
    if (event.keyCode === 13) {
      this.popupService.openProgress();

      this.gamificationData.subFactorType = [];
      const body = event.target.value.split(/[ ,]+/);

      this.adminService.getAllMedicineByMedicineIds(body).subscribe(
        (response) => {
          for (let i = 0; i < response.data.length; i++) {
            let str = {
              medicineId: response.data[i].medicineId,
              medicineName: response.data[i].name,
            };

            this.gamificationData.subFactorType.push(str);
          }
          event.target.value = null;
          this.popupService.closeProgress();
        },
        (error) => {
          this.popupService.closeProgress();
          this.popupService.openError(error?.error?.error?.error);
        }
      );
    }
  }

  addTarget(index) {
    if (
      (this.targets[index].targetInputs.targetValue &&
        this.targets[index].targetInputs.subFactor !== null &&
        this.targets[index].targetInputs.subFactor.length > 0 &&
        this.targets[index].targetInputs.targetType !== null &&
        this.targets[index].targetInputs.targetType.length > 0) ||
      this.gamificationData.factorType.toLowerCase() === "overall"
    ) {
      let isExist = this.targets[index].targetsList.some(
        (ele) => ele.subFactor === this.targets[index].targetInputs.subFactor
      );

      if (
        !isExist ||
        this.gamificationData.factorType.toLowerCase() === "overall"
      ) {
        if (
          this.targets[index].targetsList.some(
            (ele) =>
              ele.targetType === this.targets[index].targetInputs.targetType
          )
        ) {
          this.popupService.openError(
            `${this.targets[
              index
            ].targetInputs.targetType.toUpperCase()} is already selected.`
          );
        } else {
          if (
            this.targets[index].targetInputs.targetType.toUpperCase() ===
              "GMV" &&
            this.targets[index].targetInputs.targetValue <= this.maxTargetValue
          ) {
            this.popupService.openError(
              "Current GMV should be greater than the previous GMVs."
            );
          } else {
            let isTargetExist = false;

            // Check if the same sub-target is selected in the current target
            if (this.targets[index].targetsList.length > 0) {
              for (let i = 0; i < this.targets[index].targetsList.length; i++) {
                if (Array.isArray(this.targets[index].targetInputs.subFactor)) {
                  for (
                    let j = 0;
                    j < this.targets[index].targetInputs.subFactor.length;
                    j++
                  ) {
                    if (
                      this.targets[index].targetsList[i].subFactor ===
                      this.targets[index].targetInputs.subFactor[j]
                    ) {
                      isTargetExist = true;
                      break;
                    }
                  }
                } else {
                  if (
                    this.targets[index].targetsList[i].subFactor ===
                    this.targets[index].targetInputs.subFactor
                  ) {
                    isTargetExist = true;
                  }
                }
                if (isTargetExist) {
                  break;
                }
              }
            }

            if (isTargetExist) {
              this.popupService.openError(
                "Same target cannot be selected again!"
              );
            } else {
              if (
                this.targets[index].targetInputs.targetType.toUpperCase() ===
                "GMV"
              ) {
                this.maxTargetValue =
                  this.targets[index].targetInputs.targetValue;
              }

              if (Array.isArray(this.targets[index].targetInputs.subFactor)) {
                this.targets[index].targetInputs.subFactor.map((ele) => {
                  let newList = { ...this.targets[index].targetInputs };
                  newList.subFactor = ele;
                  this.targets[index].targetsList.push(newList);
                });
              } else {
                let list = this.targets[index].targetInputs;

                this.targets[index].targetsList.push(list);
              }

              // Object.assign(this.targets[index].targetInputs, {
              //   targetValue:null, subFactor:null
              // })

              this.targets[index].targetInputs = {
                targetValue: null,
                targetType: [],
                subFactor: null,
                targetQuantityValue: null,
              };
            }
          }
        }
      } else {
        this.popupService.openError(
          `${this.targets[
            index
          ].targetInputs.subFactor.toUpperCase()} is already selected.`
        );
      }
    } else {
      this.popupService.openError("Please enter the required target fields.");
    }
  }

  removeChild(childIndex, parentIndex) {
    this.targets[parentIndex].targetsList.splice(childIndex, 1);

    if (parentIndex !== 0) {
      let prevTarget = this.targets[parentIndex - 1].targetsList;
      this.maxTargetValue = prevTarget[prevTarget.length - 1].targetValue;
    } else {
      this.maxTargetValue = null;
    }
  }

  addMed(index) {
    if (
      this.targets[index].giftInputs.selectedItem.medicineId > 0 &&
      this.targets[index].giftInputs.selectedItem.medicineName &&
      this.targets[index].giftInputs.quantity
    ) {
      this.targets[index].gift.push({
        medItem: this.targets[index].giftInputs.selectedItem,
        quantity: this.targets[index].giftInputs.quantity,
      });
      this.targets[index].giftInputs.quantity = null;
      this.targets[index].giftInputs.selectedItem = { medicineName: "" };
    } else {
      this.popupService.openError("Please enter the required gift fields.");
    }
  }

  removeMed(childIndex, parentIndex) {
    this.targets[parentIndex].gift.splice(childIndex, 1);
  }

  getMedicines(parentIndex, event) {
    clearInterval(this.timer);
    this.timer = setTimeout(() => {
      const params = {
        // searchKey:event.target.value,
        searchString: event.target.value,
        page: 0,
      };

      //  this.adminService.getAllGiftMedicines(params).subscribe(
      //   res=>{
      //          this.targets[parentIndex].giftInputs.items=res.data
      //   },error=>{
      //      this.popupService.openError(error.error.error.error)
      //  }
      // )

      if (!event.target.value) {
        this.targets[parentIndex].giftInputs.selectedItem = {
          medicineId: 0,
          medicineName: "",
        };
        this.targets[parentIndex].giftInputs.items = null;
        this.targets[parentIndex].giftInputs.quantity = null;
      }

      this.adminService.searchEnableDisableMedicines(params).subscribe(
        (response) => {
          let giftSearchList = [];

          response.data.medicines.map((med) => {
            let tempObj = {
              medicineId: med.id,
              medicineName: med.name,
            };
            giftSearchList.push(tempObj);
          });

          this.targets[parentIndex].giftInputs.items = giftSearchList;
        },
        (error) => {
          this.popupService.openError(error.error.error.error);
        }
      );
    }, 500);
  }

  checkGiftQty(index, giftSelected, event) {
    if (event.target.value > 0) {
      this.targets[index].giftInputs.quantity = event.target.value;
    } else {
      this.popupService.openError(`Invalid quantity.`);
      this.targets[index].giftInputs.quantity = null;
      event.target.value = null;
    }
  }

  medSelect(index, event) {
    // let isExist  = false;

    // for(let i = 0; i < this.targets.length; i++) {
    // isExist = this.targets[index].gift.some(item => {
    //   if(item.medItem.medicineId === event.option.value.medicineId && item.medItem.distributorId === this.targets[index].giftInputs.selectedItem.distributorId)
    //   {
    //     return true;
    //   }

    //   return false;
    // })

    // if (isExist) {
    //   break;
    // }
    // }

    //  this.targets[index].giftInputs.selectedItem = {
    //   medicineId: event.option.value.medicineId,
    //   medicineName: event.option.value.medicineName,
    //  }

    // if(!isExist) {
    Object.assign(this.targets[index].giftInputs.selectedItem, {
      medicineId: event.option.value.medicineId,
      medicineName: event.option.value.medicineName,
    });
    // } else {
    //   event.option.value = "";
    //   this.targets[index].giftInputs.selectedItem = {medicineId:'', medicineName:''};
    //   this.targets[index].giftInputs.items = [];
    //   this.popupService.openError('Combination of this medicine & distributor is already added.');
    // }
  }

  addCampaign(updateCampaign) {
    let errorStatus = false;
    if (!this.gamificationData.durationType) {
      errorStatus = true;
      this.popupService.openError("Please select the duration type");
    } else if (!this.gamificationData.factorType) {
      errorStatus = true;
      this.popupService.openError("Please select the factor type");
    } else if (
      this.gamificationData.factorType &&
      this.gamificationData.factorType.toLowerCase() !== "overall" &&
      this.gamificationData.subFactorType.length === 0
    ) {
      errorStatus = true;
      this.popupService.openError("Please select the sub factor type");
    } else if (this.targets.length === 0) {
      errorStatus = true;
      this.popupService.openError("Please set the target");
    } else if (this.targets.length > 0) {
      this.targets.map((ele) => {
        if (ele.targetsList.length === 0) {
          errorStatus = true;
          this.popupService.openError("Please set the target");
        } else if (ele.gift.length === 0) {
          errorStatus = true;
          this.popupService.openError("Please set the gift");
        }
      });
    }

    if (!errorStatus) {
      let body = {
        ...(this.gamificationData.isCohort && {
          retailerCodeList: this.cohortList,
        }),
        allSubFactorList: [],
        campaignCohortFileUrl: this.gamificationData.isCohort
          ? this.gamificationData.campaignCohortFileUrl
          : null,
        campaignDescription: this.gamificationData.campaignDescription,
        campaignImageUrl: this.gamificationData.campaignImageUrl,
        campaignName: this.gamificationData.campaignName,
        campaignPairList: [],
        campaignType: this.gamificationData.campaignType,
        targetCompletionType: this.gamificationData.targetCompletionType,
        durationType: this.gamificationData.durationType,
        endTime: new Date(this.endDate).getTime(),
        factorType: this.gamificationData.factorType,
        giftType: this.gamificationData.giftType,
        hubId: this.gamificationData.hub ? this.gamificationData.hub.id : null,
        isCohort: this.gamificationData.isCohort,
        campaignRspIdCodeMapList: [],
        scheduled: true,
        startTime: new Date(this.startDate).getTime(),
      };

      if (this.gamificationData.factorType.toLowerCase() === "medicine") {
        this.gamificationData.subFactorType.map((val) => {
          return body.allSubFactorList.push(val.medicineName);
        });
      } else {
        body.allSubFactorList = this.gamificationData.subFactorType;
      }

      if (this.gamificationData.retrievalSpoke) {
        this.gamificationData.retrievalSpoke.map((rsp) => {
          let rspObj = {
            retrievalSpokeCode: rsp.retrievalSpokeCode,
            retrievalSpokeId: rsp.id,
          };

          body.campaignRspIdCodeMapList.push(rspObj);
        });
      }

      this.targets.forEach((data) => {
        let targetsMessage = [];
        let giftsMessage = [];
        let obj = {
          gift: {
            giftId: "",
            giftItemList: [],
          },
          subFactorTargetUniqueKey: "",
          subFactorsList: [],
          targetGiftMap: [],
          targetList: [],
          toGroup: false,
        };
        if (data.gift?.length) {
          data.gift.forEach((gift) => {
            obj.gift.giftItemList.push({
              distributorId: this.distributorId,
              medicineId: gift.medItem["medicineId"],
              medicineName: gift.medItem["medicineName"],
              quantity: gift.quantity,
            });
            giftsMessage.push(
              gift.quantity + " " + gift.medItem["medicineName"]
            );
          });
        } else {
          errorStatus = true;
          this.popupService.openError(
            "Please select atleast one gift for all targets"
          );
        }

        if (data.targetsList?.length) {
          let groupCheck = [];

          let subFactorVal = [];

          if (this.gamificationData.factorType.toLowerCase() === "medicine") {
            subFactorVal = data.targetsList.map(
              ({ subFactor }) => subFactor.medicineName
            );
          } else {
            subFactorVal = data.targetsList.map(({ subFactor }) => subFactor);
          }

          data.targetsList.forEach((item) => {
            targetsMessage.push(item.targetType + "- " + item.targetValue);
            obj.targetList.push({
              companyName:
                this.gamificationData.factorType.toLowerCase() === "company"
                  ? item.subFactor
                  : "",
              distributorId: 0,
              medicineId:
                this.gamificationData.factorType.toLowerCase() === "medicine"
                  ? item.subFactor.medicineId
                  : 0,
              medicineName:
                this.gamificationData.factorType.toLowerCase() === "medicine"
                  ? item.subFactor.medicineName
                  : "",
              subFactorType:
                this.gamificationData.factorType.toLowerCase() === "medicine"
                  ? item.subFactor.medicineName
                  : item.subFactor,
              targetType: item.targetType,
              targetValue: item.targetValue,
              giftDescription: `Buy ${
                item.targetType.toUpperCase() === "QUANTITY"
                  ? item.targetValue + " " + item.targetQuantityValue
                  : `₹${item.targetValue}`
              } worth of ${
                this.gamificationData.factorType.toLowerCase() === "overall"
                  ? "items"
                  : this.gamificationData.factorType.toLowerCase() ===
                    "medicine"
                  ? subFactorVal.join(", ")
                  : subFactorVal.join(", ") + " items"
              } to get ${giftsMessage}`,
              giftDescriptionUniqueId: uuidv4(),
            });

            if (Array.isArray(subFactorVal)) {
              subFactorVal.map((ele) => obj.subFactorsList.push(ele));
            } else {
              obj.subFactorsList.push(subFactorVal);
            }
            groupCheck.push(subFactorVal);
          });

          if (!groupCheck.every((val, i, arr) => val === arr[0])) {
            obj.toGroup = true;
          }
        } else {
          this.popupService.openError(
            "Please select atleast one target for all targets"
          );
          errorStatus = true;
        }
        obj["targetGiftMap"].push({
          gift: obj.gift,
          targetList: obj.targetList,
        });
        body.campaignPairList.push(obj);
      });

      if (!errorStatus) {
        this.popupService.openProgress();

        if (!updateCampaign) {
          this.adminService
            .createCampaign(body, { isCohort: this.gamificationData.isCohort })
            .subscribe(
              (res) => {
                this.popupService.closeProgress();
                this.popupService.openSuccess(res.data.body);
                this.dialogRef.close(true);
              },
              (error) => {
                this.popupService.closeProgress();
                this.popupService.openError(error?.error?.error?.error);
              }
            );
        } else {
          this.adminService
            .updateCampaign(body, {
              status: this.data.data.gamificationState,
              campaignId: this.data.data.bannerId,
              isCohort: this.gamificationData.isCohort,
            })
            .subscribe(
              (res) => {
                this.popupService.closeProgress();
                this.popupService.openSuccess(res.data.Status);
                this.dialogRef.close(true);
              },
              (error) => {
                this.popupService.closeProgress();
                this.popupService.openError(error?.error?.error?.error);
              }
            );
        }
      } else {
        this.popupService.closeProgress();
      }
    }
  }

  removeParentTarget(index) {
    let msg = "Do you want to delete target";
    this.popupService
      .openCommonConfirmPopup(msg)
      .afterClosed()
      .subscribe((resp) => {
        if (resp) {
          this.targets.splice(index, 1);
          this.targetsCount--;

          if (index !== 0) {
            let prevTarget = this.targets[index - 1].targetsList;
            this.maxTargetValue = prevTarget[prevTarget.length - 1].targetValue;
          } else {
            this.maxTargetValue = null;
          }
        }
      });
  }

  deleteCampaign() {
    let msg = "Do you want to delete Campaign";
    this.popupService
      .openCommonConfirmPopup(msg)
      .afterClosed()
      .subscribe((resp) => {
        if (resp) {
          this.popupService.openProgress();
          let params = {
            campaignId: this.data.data.bannerId,
          };
          this.adminService.deleteCampaign(params).subscribe(
            (res) => {
              this.popupService.closeProgress();
              this.popupService.openSuccess(res.data.body);
              this.dialogRef.close(true);
            },
            (error) => {
              this.popupService.closeProgress();
              this.popupService.openError(error.error.error.error);
            }
          );
        }
      });
  }

  downloadCohort() {
    const a = document.createElement("a");
    a.setAttribute("style", "display:none;");
    document.body.appendChild(a);
    const url = this.data.data.isNew
      ? this.gamificationData.campaignCohortFileUrl
      : this.gamificationData.cohortFileName;
    a.href = url;
    a.download = this.data.data.isNew
      ? this.gamificationData.campaignCohortFileUrl.split("/").pop()
      : this.gamificationData.cohortFileName.split("/").pop();
    a.click();
  }

  // Download the error list after the rsp check is done
  downloadSample = () => {
    const header = ["Retailers Code"];
    const csv = [];

    // add headers
    csv.unshift(header.join(","));
    const csvArray = csv.join("\n");

    // generate file
    const a = document.createElement("a");
    const blob = new Blob([csvArray], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    // download file
    a.href = url;
    a.download = "Sample Cohort";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };

  downloadWinnersCSV(fileHeader, winners, data) {
    const header = [fileHeader];
    const csv = winners ? data : this.wrongWinnersList;
    // add headers
    csv.unshift(header.join(","));
    const csvArray = csv.join("\n");
    // generate file
    const a = document.createElement("a");
    const blob = new Blob([csvArray], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    // download file
    a.href = url;
    a.download = winners ? "Winners List" : "Error List";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  // Download winners list
  downloadWinners() {
    this.popupService.openProgress();

    let params = {
      campaignId: this.data.data.bannerId,
    };

    this.adminService.downloadCampaignWinnersList(params).subscribe(
      (res) => {
        this.downloadWinnersCSV("Retailers Code", true, res);
        this.popupService.closeProgress();
        this.popupService.openSuccess("Download Successful");
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  toggleEnable(event) {
    if (!event.checked) {
      let msg = "Do you want to disable banner";
      this.popupService
        .openCommonConfirmPopup(msg)
        .afterClosed()
        .subscribe((resp) => {
          if (resp) {
            this.popupService.openProgress();
            this.adminService
              .disableBanner({ bannerId: this.data.data.bannerDetails?.id })
              .subscribe(
                (res) => {
                  this.popupService.closeProgress();
                  this.popupService.openSuccess("Success");
                  this.dialogRef.close(true);
                },
                (error) => {
                  this.popupService.closeProgress();
                  this.popupService.openError(error.error.error.error);
                }
              );
          }
        });
    }
  }
}
