import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelConfirmReason } from './model';
import { PopupService } from "src/app/core/services/popup/popup.service";

@Component({
  selector: 'app-confirm-reason',
  templateUrl: './confirm-reason.component.html',
  styleUrls: ['./confirm-reason.component.scss']
})
export class ConfirmReasonComponent implements OnInit {
  reasonForDeleteMed="";
  filteredReasons=[];
  constructor(
    private popupService: PopupService,
    public dialogRef: MatDialogRef<ConfirmReasonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelConfirmReason) { }

  ngOnInit(): void {
    this.filteredReasons=this.data.message.reasons
    console.log(this.filteredReasons);
    
  }

  searchReason(event){
    this.filteredReasons=this.data.message.reasons.filter((ele)=>ele.toLowerCase().includes(event.target.value.toLowerCase()))
  }

  submit() {
   
    if(this.data.message.reasons && this.reasonForDeleteMed.length<1){
       this.popupService.openError("Please select reason for deleting medicine")
    }else{
      if(this.reasonForDeleteMed.length>0){
        this.dialogRef.close({status:true,reason:this.reasonForDeleteMed});
      }else{
        this.dialogRef.close(true);
      }
    }
   
   
  }

  cancel() {
    if(this.reasonForDeleteMed.length>0){
      this.dialogRef.close({status:false,reason:this.reasonForDeleteMed});
    }else{
      this.dialogRef.close(false);
    }
   
  }

}
