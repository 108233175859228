import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogModelSaveoLoader } from './model';

@Component({
  selector: 'app-saveo-loader',
  templateUrl: './saveo-loader.component.html',
  styleUrls: ['./saveo-loader.component.scss']
})
export class SaveoLoaderComponent implements OnInit {

  constructor(
    public saveoLoaderRef: MatDialogRef<SaveoLoaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelSaveoLoader) { }

  ngOnInit(): void {
  }

  cancel() {  
    this.saveoLoaderRef.close(false);
  }

}
