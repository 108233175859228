import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { DialogModelCommonConfirm } from "./model";

@Component({
  selector: "app-common-confirm",
  templateUrl: "./common-confirm.component.html",
  styleUrls: ["./common-confirm.component.scss"],
})
export class CommonConfirmComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CommonConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelCommonConfirm
  ) {}

  ngOnInit(): void {}

  submit() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
