import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelWarehouseGenericConfirm } from './model';


@Component({
  selector: 'app-warehouse-generic-confirm',
  templateUrl: './warehouse-generic-confirm.component.html',
  styleUrls: ['./warehouse-generic-confirm.component.scss']
})
export class WarehouseGenericConfirmComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<WarehouseGenericConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelWarehouseGenericConfirm) { }

  ngOnInit(): void {
    
  }

  submit() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
