import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogModelTicketInstructions } from "./model";

@Component({
  selector: 'app-ticket-instrutions',
  templateUrl: './ticket-instrutions.component.html',
  styleUrls: ['./ticket-instrutions.component.scss']
})
export class TicketInstrutionsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TicketInstrutionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelTicketInstructions,) { }

  ngOnInit(): void {
    document.getElementById('instructionId').innerHTML = this.data.instructions;
  }

  closePopup() {
    this.dialogRef.close();
  }

}
