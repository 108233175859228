import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ExpiryDialogModel } from './model';
import { SupplyService } from 'src/app/core/services/api/supply.service';
import { PopupService } from 'src/app/core/services/popup/popup.service';

@Component({
  selector: 'app-expiry',
  templateUrl: './expiry.component.html',
  styleUrls: ['./expiry.component.scss']
})
export class ExpiryComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ExpiryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExpiryDialogModel,
    private supplyService: SupplyService,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {
  }

  closeIt() {
    this.dialogRef.close(null);
  }

  submit() {
    const params = {
      missingType: "Near Expiry"
    };
    this.supplyService.solveNearExpiry(params, this.data.item).subscribe(
      response => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data);
        this.dialogRef.close(true);        
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
        this.dialogRef.close(true);
      }
    )
  }

}
