import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiService } from "../../../common/api.service";

@Injectable({
  providedIn: "root",
})
export class FinanceService {
  API_URL: string = environment.adminUrl + "/admin";

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getVendors(): Observable<any> {
    const urlPath = this.API_URL + "/finance/getDistributors";
    return this.apiService.doGetObservable(urlPath);
  }

  getInvoices(params): Observable<any> {
    const urlPath = this.API_URL + "/finance/purchaseInvoiceSummary";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getInvoiceDetails(params): Observable<any> {
    const urlPath = this.API_URL + "/finance/purchaseInvoiceSummary/PODetails";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getDistributorDetails(params): Observable<any> {
    const urlPath = this.API_URL + "/finance/distributorDetails";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateDisputedBy(params, body): Observable<any> {
    const urlPath = this.API_URL + "/finance/updateDispute";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getDebitNotes(params): Observable<any> {
    const urlPath = this.API_URL + "/finance/debitNoteSummary";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getPayments(params): Observable<any> {
    const urlPath = this.API_URL + "/finance/payment/summary";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  downloadPaymentCSV(params, body): Observable<any> {
    const urlPath = this.API_URL + "/finance/payment/download";
    return this.apiService.doPostObservableForCSV(urlPath, body, params);
  }

  downloadFilteredPaymentCSV(params, body): Observable<any> {
    const urlPath = this.API_URL + "/finance/payment/download/filtered";
    return this.apiService.doPostObservableForCSV(urlPath, body, params);
  }

  uploadPaymentCSV(file, params): Observable<any> {
    const urlPath = this.API_URL + "/finance/payment/upload";
    return this.apiService.doPostObservableForCSV(urlPath, file, params);
  }

  getCreditNotes(params): Observable<any> {
    const urlPath = this.API_URL + "/finance/creditNoteSummary";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getDebitNoteList(params): Observable<any> {
    const urlPath = this.API_URL + "/finance/creditNoteSummary/DN";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getSummaries(params): Observable<any> {
    const urlPath = this.API_URL + "/finance/vendorSummary";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getLedgers(params): Observable<any> {
    const urlPath = this.API_URL + "/finance/vendorLedger";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getPaymentHistory(params): Observable<any> {
    const urlPath = this.API_URL + "/finance/vendorSummary/paymentHistory";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getPiImages(params): Observable<any> {
    const urlPath = this.API_URL + "/finance/purchaseInvoiceSummary/PIImages";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getBankAccounts(params): Observable<any> {
    const urlPath = environment.adminUrl + "/file/bank-statement/getBankAccounts";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  checkBankStatement(file, params): Observable<any> {
    const urlPath = environment.adminUrl + "/file/bank-statement/checkBankStatement";
    return this.apiService.doPostObservableForCSV(urlPath, file, params);
  }
  getBankAccountUploadLogs(params): Observable<any> {
    const urlPath = environment.adminUrl + "/file/bank-statement/getAllBankStatementUploadOutputDetails";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  uploadBankStatement(file, params): Observable<any> {
    const urlPath = environment.adminUrl + "/file/bank-statement/uploadBankStatement";
    return this.apiService.doPostObservableForCSV(urlPath, file, params);
  }
  uploadBSStatus(params): Observable<any> {
    const urlPath = environment.adminUrl + "/file/bank-statement/getBankStatementUploadStatus";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getBulkMapingTypes(params): Observable<any> {
    const urlPath = environment.adminUrl + "/file/bulk-mapping/upload/mappingType";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  uploadMappingStatus(params): Observable<any> {
    const urlPath = environment.adminUrl + "/file/bulk-mapping/upload/bulkMappingUploadStatus";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }
  uploadBulkMapping(file, params): Observable<any> {
    const urlPath = environment.adminUrl + "/file/bulk-mapping/upload/invoiceBulkMapping";
    return this.apiService.doPostObservableForCSV(urlPath, file, params);
  }
  getBulkMappingUploadLogs(params): Observable<any> {
    const urlPath = environment.adminUrl + "/file/bulk-mapping/upload/getAllBulkMappingUploadStatus";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getUploadFile(params): Observable<any> {
    const urlPath =  environment.adminUrl+"/file/bank-statement/getBankStatementRawFile";
    return this.apiService.doGetObservableForFile(urlPath, params);
  }
  getSalarySettlementLogs(params): Observable<any> {
    const urlPath =environment.adminUrl+ "/file/salary-adjustment/getAllSalaryAdjustmentUploadStatus";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  uploadSalarySettlement(file, params): Observable<any> {
    const urlPath = environment.adminUrl + "/file/salary-adjustment/uploadSalaryAdjustment";
    return this.apiService.doPostObservableForCSV(urlPath, file, params);
  }
  getSalarySettlementStatus(params): Observable<any> {
    const urlPath = environment.adminUrl+ "/file/salary-adjustment/uploadSalaryAdjustmentStatus";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }
  getCnAdjustmentUploadLogs(params): Observable<any> {
    const urlPath =environment.adminUrl+ "/file/cn-adjustment/getAllCNAdjustmentUploadStatus";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  uploadCnAdjustment(file, params): Observable<any> {
    const urlPath = environment.adminUrl + "/file/cn-adjustment/cnAdjustmentUpload";
    return this.apiService.doPostObservableForCSV(urlPath, file, params);
  }
  
  getCnAdjustmentUploadStatus(params): Observable<any> {
    const urlPath = environment.adminUrl+ "/file/cn-adjustment/uploadCNAdjustmentStatus";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

}

