import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelBillingOutstandingInvoice } from './model';
import { OutstandingService } from "src/app/core/services/api/outstanding.service";
import { PopupService } from "src/app/core/services/popup/popup.service";

@Component({
  selector: 'app-outstanding-billing-invoice',
  templateUrl: './outstanding-billing-invoice.component.html',
  styleUrls: ['./outstanding-billing-invoice.component.scss']
})
export class OutstandingBillingInvoiceComponent implements OnInit {
  invoiceData = {
    invoiceValue: 0,
    returnValue: 0,
    itemList: []
  };
  osAmount = 0;
  deficitAmount = 0;
  comments = "";
  disableSaveChanges = true;


  constructor(
    private outstandingService: OutstandingService,
    private popupService: PopupService,
    public dialogRef: MatDialogRef<OutstandingBillingInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelBillingOutstandingInvoice,
    ) { }

    ngOnInit(): void {
      this.popupService.openProgress();
      this.getData(true);
    }
  
    getData(closeProgress) {
      const params = {
        id: this.data.outstandingData.id
      };
      this.outstandingService.getOutstandingInvoiceData(params).subscribe(
        response => {
          this.invoiceData = response.data;
          this.invoiceData.itemList.map(medicine => {
            if (medicine.osId) {
              this.deficitAmount = medicine.deficitAmount;
            }
          })
          console.log(response.data);
          if (closeProgress) {
            this.popupService.closeProgress();
          }
        },
        error => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
          this.dialogRef.close(false);
        }
      )
    }

    changeAmount() {
      if ((this.deficitAmount - this.osAmount) > 0) {
        this.disableSaveChanges = true;
      } else {
        this.disableSaveChanges = false;
      }
    }

    sendToRetailer() {
      this.popupService.openProgress();
      const params = {
        amount: this.deficitAmount - this.osAmount,
        id: this.data.outstandingData.id,
        comment: this.comments
      };
      this.outstandingService.changeOutstandingBillingToRetailer(params, {}).subscribe(
        response => {
          this.disableSaveChanges = false;
          this.popupService.closeProgress();
          this.popupService.openSuccess(response.data);
        },
        error => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      )
    }

    saveChanges() {
      this.popupService.openProgress();
      const params = {
        amount: this.osAmount,
        id: this.data.outstandingData.id
      };
      this.outstandingService.saveBillingChanges(params, {}).subscribe(
        response => {
          this.popupService.closeProgress();
          this.popupService.openSuccess(response.data);
          this.dialogRef.close(true);
        },
        error => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      )
    }

    closePopup() {
      this.dialogRef.close(false);
    }

}
