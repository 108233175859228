import * as React from 'react';
import { Component }  from 'react';
import "./CCHeader.css";
import {
    CreditCardOutlined
  } from '@ant-design/icons';
interface Props {
    
}
interface State {
    
}

export default class CCHeader extends Component<Props, State> {
    state = {}

    render() {
        return (
            <div style={{backgroundColor:"#5f58a0",height:"65px"}} className="cc-header">
                <div style={{paddingTop: "14px",paddingLeft:"20px"}}>
                <span className="header-cc-icon"><CreditCardOutlined style={{ fontSize: '24px', color:"white", marginRight:"20px" }}/></span>
                    <span style={{fontSize:"21px",fontWeight:"bolder",color:"#ffff"}}>
                        Credit Dashboard
                    </span>
                </div>
               
            </div>
        )
    }
}
