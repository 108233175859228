import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelUploadCreditError } from "./model"

@Component({
  selector: 'app-upload-credit-error',
  templateUrl: './upload-credit-error.component.html',
  styleUrls: ['./upload-credit-error.component.scss']
})
export class UploadCreditErrorComponent implements OnInit {
  view = "table";
  wrongItems = [];
  productName = "";
  wrongItemName = "";
  
  constructor(
    public dialogRef: MatDialogRef<UploadCreditErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelUploadCreditError
  ) {}

  ngOnInit(): void {
    console.log(this.data)
  }

  closeIt() {
    this.dialogRef.close(null);
  }

}
