import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { WrongBilledDialogModel } from './model';
import { SupplyService } from 'src/app/core/services/api/supply.service';
import { PopupService } from 'src/app/core/services/popup/popup.service';

@Component({
  selector: 'app-wrong-billed',
  templateUrl: './wrong-billed.component.html',
  styleUrls: ['./wrong-billed.component.scss']
})
export class WrongBilledComponent implements OnInit {
  view = "table";
  wrongItems = [];
  productName = "";
  wrongItemName = "";
  
  constructor(
    public dialogRef: MatDialogRef<WrongBilledComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WrongBilledDialogModel,
    private supplyService: SupplyService,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {
    console.log(this.data)
    this.getData();
  }

  getData() {
    this.popupService.openProgress();
    const params = {};
    this.supplyService.getWronglyMappedMedicine(params, this.data.item).subscribe(
      response => {
        this.wrongItems = response.data;
        this.wrongItems.map(item => {
          this.wrongItemName = item.itemName;
        })
        console.log(response.data)
        this.popupService.closeProgress();
      }
    )
  }

  submit() {
    
  }

  closeIt() {
    this.dialogRef.close(null);
  }

}
