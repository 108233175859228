import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HelpDeskService } from 'src/app/core/services/api/help-desk.service';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { DialogModelCreateHelpExecutive } from "./model";

@Component({
  selector: 'app-create-help-executive',
  templateUrl: './create-help-executive.component.html',
  styleUrls: ['./create-help-executive.component.scss']
})
export class CreateHelpExecutiveComponent implements OnInit {
  _mForm: FormGroup;
  departments = [];
  
  constructor(
    public dialogRef: MatDialogRef<CreateHelpExecutiveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelCreateHelpExecutive,
    private popupService: PopupService,
    private formBuilder: FormBuilder,
    private helpDeskService: HelpDeskService) { 

      this._mForm = this.formBuilder.group({
        mobileNo: ["", [Validators.required, Validators.pattern("[6-9]\\d{9}")]],
        executiveName: ["", [Validators.required]],
        department: ["", [Validators.required]],
        key: ["", [Validators.required]]
      });
    }

  ngOnInit(): void {
    this.popupService.openProgress();
    this.getDepartments();
  }

  getDepartments() {
    this.helpDeskService.getAllDepartments().subscribe(
      response => {
        this.departments = response.data;
        if (response && response.data && response.data.length > 0) {
          let formValue = this._mForm.value;
          formValue.department = response.data[0].param;
          this._mForm.setValue(formValue);
        }
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.message);
      }
    )
  }

  createTicket() {
    this.popupService.openProgress();
    this.helpDeskService.createHelpExecutive(this._mForm.value).subscribe(
      response => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data);
        this.closePopup();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.message);
      }
    )
  }

  closePopup() {
    this.dialogRef.close();
  }

}
