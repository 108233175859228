import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../../../common/api.service';
import { AuthService } from './api-auth.service';
import { AdminService } from './admin.service';

@Injectable({
  providedIn: 'root'
})
export class DistributorService {
  isAdmin = false;
  API_URL: string = environment.adminUrl + '/distributor';
  preprod_url = "https://preprod.saveo.in/distributor";

  constructor(private apiService: ApiService, private authService: AuthService, private adminService: AdminService) { }

  getAllERP(): Observable<any> {
    const urlPath = this.API_URL + "/home/getAllErp";
    return this.apiService.doGetObservable(urlPath);
  }

  getInvoiceColumns(dist, ERP): Observable<any> {
    const urlPath = this.API_URL + "/home/sellerInvoiceColumnMapping";
    const params = {
      distributor: dist,
      erp: ERP
    };
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  mapInvoice(data, params): Observable<any> {
    const urlPath = this.API_URL + "/home/updateSellerInvoiceColumnMapping";
    return this.apiService.doPostObservableForQuery(urlPath, data, params);
  }

  getPastOrders(params): Observable<any> {
    const urlPath = this.API_URL + '/home/viewPastOrdersByDistributor';
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getPastOrdersByDate(params): Observable<any> {
    const urlPath = this.API_URL + '/home/viewPastOrdersByDateAndDistributor';
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getLiveOrders(params): Observable<any> {
    const urlPath = this.API_URL + '/home/viewLiveOrdersByDistributor';
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateDeliveryStatus(params): Observable<any> {
    const data = {};
    const urlPath = this.API_URL + '/home/UpdateDeliveryStatusAsShipped';
    return this.apiService.doPostObservableForQuery(urlPath, data, params);
  }

  clubOrders(params): Observable<any> {
    const data = {};
    const urlPath = this.API_URL + '/home/distributorClubOrders';
    const dat =  this.apiService.doPostObservableForCSV(urlPath, data, params);
    // console.log(dat)
    return dat;
  }

  getPhNo(): Observable<any> {
    return this.authService.getUserPhoneNumber();
  }

  checkAdmin(phone): Observable<any> {
  return this.authService.checkAuth(phone);
  }

  getDistributors() {
    console.log(this.adminService.getDistributors())
    return this.adminService.getDistributors();
  }

  getdistributorId(dist): Observable<any> {
    const params = {
      distributorName: dist
    };
    const urlPath = this.API_URL + "/home/getDistributorId";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getMapMeds(name): Observable<any> {
    const params = {
      distributorName: name
    };
    const urlPath = this.API_URL + "/home/distributorColumnMapping";
    return  this.apiService.doGetObservableForQuery(urlPath, params);
  }

  postMap(data, params): Observable<any> {
    const urlPath = this.API_URL + "/home/updateDistributorColumnMapping";
    return this.apiService.doPostObservableForQuery(urlPath, data, params);
  }
}
