
import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EventService } from 'src/app/core/services/event/event.service';
import { DialogModelCustomizeConfirm } from './model';

@Component({
  selector: 'app-customize-confirm',
  templateUrl: './customize-confirm.component.html',
  styleUrls: ['./customize-confirm.component.scss']
})
export class CustomizeConfirmComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CustomizeConfirmComponent>,
    private eventService:EventService,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelCustomizeConfirm) { }

  ngOnInit(): void {
    
  }

  submit() {
   
       if(this.data.obj?.downloadBtnStyle=='caution'){
        this.eventService.trackEvent('bs_cautionFile_download',{cautionFile:this.data.obj?.cautionUrl})
        window.open(this.data.obj?.cautionUrl)

       }else{
        this.dialogRef.close(true);
       }
        
    
  }

  upload(){
    this.dialogRef.close(true);
  }

  cancel() {
    
      this.dialogRef.close(false);
   
  }
}
