import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { RemoveBounceMedDialogModel } from './model';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { AdminService } from 'src/app/core/services/api/admin.service';

@Component({
  selector: 'app-remove-bounce-med',
  templateUrl: './remove-bounce-med.component.html',
  styleUrls: ['./remove-bounce-med.component.scss']
})
export class RemoveBounceMedComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RemoveBounceMedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RemoveBounceMedDialogModel,
    private popupService: PopupService,
    private adminService: AdminService
    ) { }

  ngOnInit(): void {
    console.log(this.data)
  }

  remove() {
    this.popupService.openProgress();
    if (this.data.item.reason == 'Select Reason') {
      this.popupService.closeProgress();
      this.popupService.openError("Please Select Some Reason");
    } else {
      this.adminService.remveMedicineFromBill({}, this.data.item).subscribe(
        response => {
          this.popupService.closeProgress();
          this.dialogRef.close(true);
        },
        error => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
          this.dialogRef.close(false);
        }
      )
    }
    // this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
