import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CompanySectionModule } from 'src/app/components/customer/company-section/company-section.module';
import { SupplyService } from 'src/app/core/services/api/supply.service';
import { PopupService } from 'src/app/core/services/popup/popup.service';

@Component({
  selector: 'app-assign-expiry',
  templateUrl: './assign-expiry.component.html',
  styleUrls: ['./assign-expiry.component.scss']
})
export class AssignExpiryComponent implements OnInit {
  assignees = [];
  currAssignee;
  enableSave = false;

  constructor(public dialogRef: MatDialogRef<AssignExpiryComponent>, private supplyService: SupplyService, private popupService: PopupService) {
  }

  ngOnInit(): void {
    this.getAssignee();
  }

  getAssignee() {
    this.supplyService.getAssignee().subscribe(
      response => {
        this.assignees = response.data;
        this.assignees.map(assignee => {
          assignee.selected = false;
        })
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  selectAssignee(e, index) {
    this.enableSave = false;
    this.assignees.map(assignee => {
      assignee.selected = false;
    });
    if (e) {
      this.assignees[index].selected = true;
      this.enableSave = true;
    }
  }

  confirm() {
    this.assignees.map(assignee => {
      if (assignee.selected) {
        this.currAssignee = assignee;
      }
    })
    this.dialogRef.close(this.currAssignee);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
