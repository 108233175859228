import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { DialogModelLogisticsCollect } from "./model";
import { AdminService } from "src/app/core/services/api/admin.service";
import { LogisticsService } from "src/app/core/services/api/logistics.service";
import { PopupService } from "src/app/core/services/popup/popup.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { FileUploadService } from "src/app/core/services/api/file-upload.service";
import { EventService } from "src/app/core/services/event/event.service";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "app-logistics-collect",
  templateUrl: "./logistics-collect.component.html",
  styleUrls: ["./logistics-collect.component.scss"],
  // encapsulation : ViewEncapsulation.None,  //used to control the padding for the mat-form-field
})
export class LogisticsCollectComponent implements OnInit {
  currentAttachment;
  attachmentUrls = [];
  deAmount = 0;
  deComment = "";
  otherAmount = 0;
  otherComment = "";
  retailerAmount = 0;
  retailerComment = "";
  billingAmount = 0;
  billingComment = "";
  emptyOnlineObj = {
    notReceived: false,
    onlineAmount: 0,
    referenceNumber: "",
  };
  showInvoices = false;
  medicines = [];
  totalDeficitAmount = 0;
  isSignedSlipUpdate = {
    reason: "",
    flag: false,
  };

  // newer states
  paymentModes = [];
  payments = [];
  PAYMENT_OBJECT = {
    notReceived: false,
    amountEntered: null,
    searchString: "",
    utrSuggestionList: [],
    page: 1,
    totalSearchCount: "",
    totalPages: "",
    selectedUTRNumber: "",
    selectedUTRID: "",
    showChangeMOPSelect: false,
    confirmDisabled: true,
    paymentConfirmed: false,
    mappedUTRObject: {
      tableColumns: ["Date", "MOP", "UTR Amount", "Bank", "UTR No", ""],
      date: "",
      MOP: "",
      utrAmount: "",
      bank: "",
      utrNumber: "",
    },
    resultString: "",
    cashDepositAmount: 0,
    cashDepositDate: "",
    showUTREdit: false,
    onlineAmount: 0,
    paymentMode: "",
    onlinePaymentMode: "",
    selectedFilters: [],
    selectedFilterName: "",
    selectedFilterValue: "",
    filteredOptions: "",
  };
  utrExclusions = ["cash", "credit note", "post dated cheque", "cheque"];
  totalAmountReconciled = 0;
  collectEnable = false;
  deficitAmount = 0;
  deficitReason = "DE";
  paymentModeExclusions = ["credit note", "payment to lender", "other"];
  totalOutstandingAmount = 0;
  totalOutstanding = [
    {
      amount: null,
      reason: "DE",
      comment: "",
      osConfirmed: false,
    },
    {
      amount: null,
      reason: "RETAILER",
      comment: "",
      osConfirmed: false,
    },
    {
      amount: null,
      reason: "OTHER",
      comment: "",
      osConfirmed: false,
    },
    {
      amount: this.totalDeficitAmount,
      reason: "BILLING",
      comment: "",
      osConfirmed: false,
      buyerInvoiceMedicineList: [],
      showInvoices: false,
      buyerInvoiceItemsHeaderList: [
        "Name",
        "Packaging",
        "Quantity",
        "Value",
        "Deficit Amount",
        "Remarks",
        "Confirm",
      ],
    },
  ];
  MAPPED_UTR_OBJECT = {
    tableColumns: ["Date", "MOP", "UTR Amount", "Bank", "UTR No", ""],
    date: "",
    MOP: "",
    utrAmount: "",
    bank: "",
    utrNumber: "",
  };
  tableColumns = [
    "Date",
    "MOP",
    "Amount",
    "Avl. to Adjust",
    "Bank",
    "UTR No",
    "Select",
  ];
  osSubmissionSuccessful = false;
  onlinePaymentObject = {
    amountCollectedCash: 0,
    collectAmount: 0,
    deficitAmount: 0,
    invoiceNumber: "string",
    invoiceValue: 0,
    retailerCode: "string",
    returnValue: 0,
    onlinePaymentCollectionList: [],
  };
  onlinePaymentCollectionObject = {
    notReceived: false,
    onlineAmount: 0,
    referenceNumber: "string",
    utrNoId: "string",
    modeOfPayment: "",
  };
  showCollectConfirm = false;
  revisedPaymentModes: string[] = ["cash", "online payment", "cash deposit"];
  onlinePaymentModes: string[] = ["upi", "imps", "neft", "rtgs", "pos"];
  showShakeAnimation: boolean = false;
  availableSearchFilters = []; //filters for the utr search
  availableBankAccounts = [];
  filteredOptions: Observable<string[]>;
  myControl = new FormControl("");

  constructor(
    private adminService: AdminService,
    private logisticsService: LogisticsService,
    private popupService: PopupService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<LogisticsCollectComponent>,
    public fileUploadService: FileUploadService,
    private eventService: EventService,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelLogisticsCollect
  ) {
    this.data.invoiceData.amountCollectedCredit =
      this.data.invoiceData.amountPaidCredit;
    // this.data.invoiceData.amountCollectedCash = this.data.invoiceData.amountPaidCash;
  }

  ngOnInit(): void {
    this.triggerEvent("de_collect_popup_open");

    if (this.data.invoiceData.creditCustomer) {
      this.checkSignedSlipUpdate();
    } else {
      // add each initial payment row on load
      this.revisedPaymentModes.forEach((paymentMode, index) => {
        // push an empty payment object
        this.payments.push(JSON.parse(JSON.stringify(this.PAYMENT_OBJECT)));
        // integrate the payment mode
        this.payments[index].paymentMode = paymentMode;
      });

      // get all the payment modes and hit other subchained apis for the initial data
      this.getPaymentModes(true);
    }
  }

  // get all the available payment modes
  getPaymentModes(closeProgress) {
    this.logisticsService.getPaymentModes().subscribe(
      (response) => {
        this.paymentModes = response.data;
        // remove the paymentmodes object with items from payment mode exclusions
        this.paymentModes.forEach((payMode, index) => {
          if (this.paymentModeExclusions.includes(payMode)) {
            this.paymentModes.splice(index, 1);
          }
        });

        this.getAvailableBankAccounts(closeProgress);
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  // get all available bank accounts
  getAvailableBankAccounts(closeProgress) {
    this.logisticsService.getBankAccounts().subscribe(
      (response) => {
        this.availableBankAccounts = response.data;
        this.getAvailableUtrSearchFilters(closeProgress);
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  // get all filters available for the utr search
  getAvailableUtrSearchFilters(closeProgress) {
    this.logisticsService.getUtrSearchFilters().subscribe(
      (response) => {
        this.availableSearchFilters = response.data;
        if (closeProgress) this.popupService.closeProgress();
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  // add online collection field
  addOnlineCollection() {
    this.data.invoiceData.onlinePaymentCollectionList.push(
      JSON.parse(JSON.stringify(this.emptyOnlineObj))
    );
  }

  // Calculate total deficit amount
  calculateTotalDeficitAmount() {
    this.totalDeficitAmount = 0;
    this.totalOutstanding[3].buyerInvoiceMedicineList.map((medicine) => {
      // only add the amount in the confirmed state
      if (medicine?.confirmed) {
        this.totalDeficitAmount += medicine.deficitAmount;
        this.totalOutstanding[3].amount += medicine.deficitAmount;
      }
    });
  }

  // submit deficit amount
  submitDeficit(amount, reason, comment) {
    this.popupService.openProgress();
    const body = this.data.invoiceData;
    body.deficitReason = reason === "DE" ? "DELIVERY_EXECUTIVE" : reason;
    body.deficitAmount = amount;
    body.comment = comment;
    body.source = "DE_TO_WAREHOUSE";
    if (reason == "RETAILER") {
      this.storeRetailerReason(body);
    } else if (reason == "DE") {
      this.storeDEReason(body);
    } else if (reason == "OTHER") {
      this.storeOtherReason(body);
    }
  }

  // Submit deficit amount for other reason
  storeOtherReason(body) {
    this.adminService.storeOtherReasonSelection({}, body).subscribe(
      (response) => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data);
        this.osSubmissionSuccessful = true;
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
        this.osSubmissionSuccessful = false;
      }
    );
  }

  // Submit deficit amount for retailer reason
  storeRetailerReason(body) {
    this.adminService.storeRetailerReasonSelection({}, body).subscribe(
      (response) => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data);
        this.osSubmissionSuccessful = true;
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
        this.osSubmissionSuccessful = false;
      }
    );
  }

  // Submit deficit amount for DE reason
  storeDEReason(body) {
    this.adminService.storeDEReasonSelection({}, body).subscribe(
      (response) => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data);
        this.osSubmissionSuccessful = true;
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
        this.osSubmissionSuccessful = false;
      }
    );
  }

  // Submit deficit amount for billing reason
  storeBillingReason() {
    this.popupService.openProgress();
    const body = this.data.invoiceData;
    body.deficitReason = "BILLING";
    body.source = "DE_TO_WAREHOUSE";
    body.deficitAmount = this.totalOutstanding[3].amount;
    body.comment = this.totalOutstanding[3].comment;
    body.deficitItemDetailList = [];
    this.totalOutstanding[3].buyerInvoiceMedicineList.map((medicine) => {
      if (medicine.deficitAmount > 0 && medicine?.confirmed) {
        body.deficitItemDetailList.push(medicine);
      }
    });
    this.adminService.storeBillingReasonSelection({}, body).subscribe(
      (response) => {
        this.showInvoices = false;
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data);
      },
      (error) => {
        this.showInvoices = false;
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  // Get Invoices
  getInvoices() {
    this.popupService.openProgress();
    this.adminService
      .getInvoiceItems({ invoiceNumber: this.data.invoiceData.invoiceNumber })
      .subscribe(
        (response) => {
          // this.medicines = response.data;
          // this.showInvoices = true;
          this.totalOutstanding[3].buyerInvoiceMedicineList = response.data;
          this.totalOutstanding[3].showInvoices = true;
          this.popupService.closeProgress();
        },
        (error) => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      );
  }

  // Collect Amount
  collect() {
    // de_collect_click event
 
    this.triggerEvent("de_collect_click");
    this.showCollectConfirm=false 
    if (!this.data.invoiceData.creditCustomer) {
      // hit all the outstanding object apis
      this.totalOutstanding.forEach((os, _) => {
        if (os.reason !== "BILLING" && os.amount > 0 && os.osConfirmed) {
          this.submitDeficit(os.amount, os.reason, os.comment);
        } else if (os.reason === "BILLING" && os.amount > 0 && os.osConfirmed) {
          this.storeBillingReason();
        }
      });

      // build the request body for the final api
      let body = JSON.parse(JSON.stringify(this.data.invoiceData));
      // amountCollectedCash :  sum of amount if payment mode is cash
      body.amountCollectedCash = 0;
      this.payments
        .filter((payment) => payment.paymentMode === "cash")
        .forEach((item) => (body.amountCollectedCash += item.amountEntered));
      // deficitAmount : total amount of all the os added
      body.deficitAmount = 0;
      this.totalOutstanding.forEach(
        (os, _) => (body.deficitAmount += os.amount)
      );
      // push the online payment collection list
      body.onlinePaymentCollectionList = [];
      this.payments
        .filter((payment) => payment.paymentMode !== "cash")
        .forEach((payment) => {
          if (payment.paymentConfirmed && payment.amountEntered > 0) {
            payment.onlineAmount = payment.amountEntered;
            payment.utrNoId = payment?.selectedUTRID;
            payment.referenceNumber = payment?.selectedUTRNumber;
            body.onlinePaymentCollectionList.push(payment);
          }
        });
      // update the invoice data
      // this.data.invoiceData = body

      this.logisticsService.updateInvoiceForCollection({}, body).subscribe(
        (response) => {
          this.popupService.closeProgress();
          this.popupService.openSuccess("Success");
          // de_collect_popup_close event
          this.triggerEvent("de_collect_popup_close");
          this.dialogRef.close(body);
        },
        (error) => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
          // de_collect_popup_close event
          this.triggerEvent("de_collect_popup_close");
          this.dialogRef.close(false);
        }
      );
    } else {
      this.popupService.openProgress();
      const body = {
        action: "Signed_slip_collected_by_logistics",
        invoiceNumber: this.data.invoiceData.invoiceNumber,
      };
      this.logisticsService.updateSignedSlip({}, body).subscribe(
        (response) => {
          this.popupService.closeProgress();
          this.popupService.openSuccess("Success");
          this.dialogRef.close(this.data.invoiceData);
        },
        (error) => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
          this.dialogRef.close(false);
        }
      );
    }
    
  }

  // advance payment received
  advanceDeliveryPaymentReceived() {
    this.popupService.openProgress();
    this.logisticsService
      .advanceDeliveryPaymentReceived(
        { invoiceNumber: this.data.invoiceData.invoiceNumber },
        {}
      )
      .subscribe(
        (response) => {
          this.popupService.closeProgress();
          this.dialogRef.close();
          this.popupService.openSuccess(response.data);
        },
        (error) => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      );
  }

  // Send Details To Creditor
  sendDetailsToCreditor() {
    this.popupService.openProgress();
    const body = {
      actionAmount: this.data.invoiceData.collectAmount,
      orderRetrieveId: this.data.invoiceData.orderRetrieveId,
    };
    this.logisticsService.sendDetailsToCreditor({}, body).subscribe(
      (response) => {
        this.popupService.closeProgress();
        if (response.data[0].status == "success") {
          this.popupService.openSuccess(response.data[0].message);
        } else {
          this.popupService.openError(response.data[0].message);
        }
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  // Clode collection popup
  closePopup() {
    // de_collect_popup_close event
    if (!this.data.invoiceData.creditCustomer)
      this.triggerEvent("de_collect_popup_close");

    if (this.data.invoiceData.submitted) {
      this.dialogRef.close(this.data.invoiceData);
    } else {
      this.dialogRef.close();
    }
  }

  //clearInputState
  clearStateOnFocus(id) {
    (document.getElementById(id) as HTMLInputElement).value =
      (document.getElementById(id) as HTMLInputElement).value == "0"
        ? ""
        : (document.getElementById(id) as HTMLInputElement).value;
  }

  //check State Out Focus for again 0 is value is not changed at all
  clearStateOutFocus(id) {
    (document.getElementById(id) as HTMLInputElement).value =
      (document.getElementById(id) as HTMLInputElement).value.length == 0
        ? "0"
        : (document.getElementById(id) as HTMLInputElement).value;
  }

  // open signed slip
  openSignedSlip(src) {
    this.popupService.openImagePopup(src);
  }

  // open collect signed slip popup
  openCollectSignedSlip() {
    this.popupService
      .openCollectSignedSlip(this.data)
      .afterClosed()
      .subscribe((response) => {
        this.checkSignedSlipUpdate();
      });
  }

  // check signed slip updated
  checkSignedSlipUpdate() {
    const params = {
      buyerInvoiceNo: this.data.invoiceData.invoiceNumber,
    };
    this.logisticsService.checkSignedSlipUploaded(params).subscribe(
      (response) => {
        this.isSignedSlipUpdate = response.data;
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  selectOnlineFile(e) {
    if (e.target.files.length > 0) {
      this.popupService.openProgress();
    }
    let imagesCount = e.target.files.length;
    for (let index = 0; index < e.target.files.length; index++) {
      this.currentAttachment = e.target.files[index];
      const formData = new FormData();
      formData.append(
        "file",
        this.currentAttachment,
        this.currentAttachment.name
      );
      this.fileUploadService.uploadOrderImage(formData).subscribe(
        (response) => {
          this.attachmentUrls.push(response.data);
          if (this.attachmentUrls.length == imagesCount) {
            this.popupService.closeProgress();
          }
        },
        (error) => {
          imagesCount--;
          if (this.attachmentUrls.length == imagesCount) {
            this.popupService.closeProgress();
          }
          this.popupService.openError(error.error.error.error);
        }
      );
    }
  }

  uploadSignedSlip() {
    this.popupService.openProgress();
    if (this.attachmentUrls && this.attachmentUrls.length > 0) {
      const params = {
        buyerInvoice: this.data.invoiceData.invoiceNumber,
        url: this.attachmentUrls[0],
      };
      this.logisticsService.uploadSignedSlip(params).subscribe(
        (response) => {
          this.data.invoiceData.signSlipUrl = params.url;
          this.popupService.closeProgress();
          this.popupService.openSuccess(response.data);
        },
        (error) => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      );
    } else {
      this.popupService.closeProgress();
      this.popupService.openError("No signed slip selected.");
    }
  }

  // newer code - hemanth

  // get utr data for a certain payment
  getUtrDataForPayment(index: number, type = "default", showProgress = true) {
    let payment = this.payments[index];

    // de_collect_utr_search event
    let data = {
      mop: payment.paymentMode,
      utrSearchString:
        type === "manual" && payment.searchString
          ? payment.searchString
          : payment.instrumentNumber,
      paymentId: index,
    };
    this.triggerEvent("de_collect_utr_search", data);

    // donot hit the api if no filters are present
    if (payment.selectedFilters.length === 0) {
      payment.utrSuggestionList = [];
      return;
    }

    // update the search type
    payment.searchString.length > 0 || payment.selectedFilters.length > 0
      ? (type = "manual")
      : (type = "default"); // open loader
    if (showProgress) this.popupService.openProgress();

    let params = {};
    // build the query params
    if (type === "default") {
      params = {
        utrno: payment.instrumentNumber || "",
        page: payment.page,
      };
    } else {
      let filters = {
        modeOfPayment: "",
        amount: "",
        bankName: "",
        narration: "",
        utrno: "",
        date: "",
      };
      // update the filters object
      this.payments[index].selectedFilters.forEach((filter, _) => {
        if (filter.filterName === "PaymentMode")
          filters.modeOfPayment = filter.filterValue;
        else if (filter.filterName === "Amount")
          filters.amount = filter.filterValue;
        else if (filter.filterName === "BankName")
          filters.bankName = filter.filterValue;
        else if (filter.filterName === "Narration")
          filters.narration = filter.filterValue;
        else if (filter.filterName === "UTR")
          filters.utrno = filter.filterValue;
        else if (filter.filterName === "DepositDate")
          filters.date = filter.filterValue;
      });

      params = {
        page: payment.page,
        ...filters,
      };

      Object.keys(params).forEach((key) => {
        if (!params[key]) delete params[key];
      });
    }
    // empty the responses list
    payment.utrSuggestionList = [];
    this.logisticsService.getAllBankStatementWithUTRNo(params).subscribe(
      (response) => {
        // update the result search string to show in the table
        type === "manual"
          ? (payment.resultString = payment.searchString)
          : (payment.resultString = "");
        // update the utr suggestions list
        this.payments[index].utrSuggestionList =
          response.data.bankStatementResponseList;

        // calculate the pages available
        let pages = Math.floor(response.data.count / 5);
        response.data.count % 5 > 0 ? (pages += 1) : "";
        this.payments[index].totalPages = pages;
        // update the total search count
        this.payments[index].totalSearchCount = response.data.count;

        // close the progress for the last item
        if (showProgress) this.popupService.closeProgress();
        // reset the search string for manual search
        if (type === "manual") {
          this.payments[index].selectedFilterName = "";
          this.payments[index].searchString = "";
        }
      },
      (error) => {
        if (showProgress) this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  // handler to change the MOP for a certain payment item
  handleChangeMOP(index: number) {
    //  update the show mop container key
    this.payments[index].showChangeMOPSelect = true;
  }

  // handler to close the change MOP option
  handleMOPBlur(index: number) {
    this.payments[index].showChangeMOPSelect = false;
  }

  // handler to run side effects on changing MOP
  handleMOPSelectionChange(index: number, type: string = "mop") {
    // de_collect_mop_select event
    if (type === "mop") {
      let data = {
        mop: this.payments[index].paymentMode,
        paymentId: index,
      };

      this.triggerEvent("de_collect_mop_select", data);
    } else if (type === "online") {
      let data = {
        mop: this.payments[index].onlinePaymentMode,
        paymentId: index,
      };
      this.triggerEvent("de_collect_mop_change", data);
    }

    // mop maybe changed when an existing utr suggestion list is present, in this case empty the utr suggestion list; reset the search string, result string; refresh the utr suggestion list
    this.payments[index].utrSuggestionList = [];
    this.payments[index].searchString = "";
    this.payments[index].resultString = "";
    this.handleManualUTRSearch(index);

    this.payments[index].showChangeMOPSelect = false;

    // edge case where the confirm button doesn't enable cus the entered amount doesn't trigger the onChange handler
    // check for the confirm button enable manually in this scenario
    this.checkConfirmEnable(index);
    // the user might change the MOP after mapping is done without clicking on the edit button
    // in this case reset all the data during the edit button click and proceed
    this.handleMappedEditClick(index);
    // update the confirmed payment state
    this.payments[index].paymentConfirmed = false;
    // recalculate outstanding and reconciled and collect button enable
    this.checkCollectButtonEnable();
  }

  // handler to run side effects on mapped edit button click
  handleMappedEditClick(index: number) {
    // reset the payment confirmed state
    this.payments[index].paymentConfirmed = false;
    // remove the mapped object in the payment item
    this.payments[index].mappedUTRObject = JSON.parse(
      JSON.stringify(this.MAPPED_UTR_OBJECT)
    );
    // also remove selected utr and utr id fields
    this.payments[index].selectedUTRNumber = "";
    this.payments[index].selectedUTRID = "";

    // check confirm button enable status
    this.checkConfirmEnable(index);

    // recalculate the os and recon amount
    this.checkCollectButtonEnable();

    // focus on the amount input element
    document.getElementById(`amountInput${index}`).focus();
  }

  // check confirm enable for a certain payment
  checkConfirmEnable(index: number) {
    let payment = this.payments[index];

    if (this.utrExclusions.includes(payment.paymentMode)) {
      // dont check if any utr is selected
      // we donot show the search utr table for this type of payment
      // check only if amount > 0
      // amount can't be 0 for any payment in de2w
      if (payment.amountEntered > 0) payment.confirmDisabled = false;
      else payment.confirmDisabled = true;
      // can be null when the input is completely cleared
      if (payment.amountEntered === null) payment.confirmDisabled = true;
    } else {
      if (payment.amountEntered > 0 && payment.selectedUTRNumber) {
        payment.confirmDisabled = false;
      } else {
        payment.confirmDisabled = true;
      }
    }
  }

  // calculates outstanding amount
  calculateOutstandingAmount() {
    let totalAmountConfirmed = 0;
    // add the amount entered in all payments
    this.payments.forEach((payment, _) => {
      // amount entered can be zero when no utr is selected
      // add the amount paid to outstanding
      totalAmountConfirmed +=
        payment.amountEntered === null ? 0 : payment.amountEntered;
    });

    let totalOs = 0;
    this.totalOutstanding.forEach((os, _) => {
      totalOs += os.amount === null ? 0 : os.amount;
    });
    // add the amount entered in outstanding
    totalAmountConfirmed += totalOs;
    // calculate the total outstanding
    this.totalOutstandingAmount =
      this.data.invoiceData.collectAmount - totalAmountConfirmed;
  }

  // calculate reconciled amount
  calculateReconciledAmount() {
    let totalAmountRecon = 0;
    // add the confirmed amount in all payments
    this.payments.forEach((payment, _) => {
      if (payment.paymentConfirmed) totalAmountRecon += payment.amountEntered;
    });
    // add the amount in the outstanding object
    // map throught the items of outstanding object and increase the totalAmountRecon
    this.totalOutstanding.forEach((item, _) => {
      if (item.amount > 0 && item.osConfirmed) {
        totalAmountRecon += item.amount;
      }
    });
    // update the reconciled amount
    this.totalAmountReconciled = totalAmountRecon;
  }

  // calculate total os entered
  calculateTotalOutstandingEntered() {
    let totalOsEntered = 0;
    this.totalOutstanding.forEach((os, _) => {
      totalOsEntered += os.amount === null ? 0 : os.amount;
    });
  }

  // handler to run side effects on confirm button click
  handleConfirmClick(index: number) {
    let payment = this.payments[index];
    payment.paymentConfirmed = true;
    // calculate outstanding and amount reconciled and collect button enable
    this.checkCollectButtonEnable();

    // de_collect_payment_confirm event
    let data = {
      mop: payment.paymentMode,
      amount: payment.amountEntered,
      paymentId: index,
      utr: payment.selectedUTRNumber,
      onlinePaymentMode: payment.onlinePaymentMode,
      dateSelected: payment.cashDepositDate,
    };
    // remove empty keys from the payload
    Object.keys(data).forEach((key, _) => {
      if (!data[key]) delete data[key];
    });
    this.triggerEvent("de_collect_payment_confirm", data);

    if (this.payments[index].paymentMode === "online payment") {
      this.payments[index].onlinePaymentMode =
        this.payments[index].mappedUTRObject.MOP;
    }
  }
  // handler to reset any negative values entered in input fields
  handleNegativeValueInput(event, index: number) {
    if (event.target.value < 0) {
      event.target.value = Math.abs(event.target.value);
      this.payments[index].cashDepositAmount = Math.abs(event.target.value);
    }
  }

  // handler to update the UTR item selection
  updateUTRselect(event, suggestionIndex, paymentIndex) {
    this.payments[paymentIndex].selectedUTRNumber = event.value;

    this.payments[paymentIndex].selectedUTRID =
      this.payments[paymentIndex].utrSuggestionList[suggestionIndex].utrNoId;

    let selectedUTR =
      this.payments[paymentIndex].utrSuggestionList[suggestionIndex];

    // de_collect_utr_selected event
    let data = {
      mop: this.payments[paymentIndex].paymentMode,
      utr: this.payments[paymentIndex].selectedUTRNumber,
      amount: this.payments[paymentIndex].amountEntered,
      paymentId: paymentIndex,
    };
    this.triggerEvent("de_collect_utr_selected", data);

    // checks to engforce on utr selection
    if (
      this.checkUTRBalance(
        selectedUTR,
        this.payments[paymentIndex].amountEntered
      )
    ) {
      // above method returns a flag that indicates, if an error is raised
      // if error raised, reset the selectedUTR data and return
      this.payments[paymentIndex].selectedUTRNumber = "";
      this.payments[paymentIndex].selectedUTRID = "";
      this.handleManualUTRSearch(paymentIndex, false);
      // refresh the utr table when the error is thrown above
      this.checkConfirmEnable(paymentIndex);
      return;
    }

    // this method returns a flag if the utr has been totally consumed in any other payments
    // in this scenario, reset the utr selection
    if (this.checkUtrSelection(paymentIndex)) {
      this.payments[paymentIndex].selectedUTRNumber = "";
      this.payments[paymentIndex].selectedUTRID = "";
      this.handleManualUTRSearch(paymentIndex, false);
      this.checkConfirmEnable(paymentIndex);
      return;
    }

    // update the mapped utr object
    this.payments[paymentIndex].mappedUTRObject.date = selectedUTR.valueDate;
    this.payments[paymentIndex].mappedUTRObject.MOP = selectedUTR.modeOfPayment;
    this.payments[paymentIndex].mappedUTRObject.utrAmount =
      selectedUTR.unsettledAmount;
    this.payments[paymentIndex].mappedUTRObject.bank = selectedUTR.bankName;
    this.payments[paymentIndex].mappedUTRObject.utrNumber =
      selectedUTR.utrnumber;
    this.payments[paymentIndex].mappedUTRObject.narration =
      selectedUTR.narration;

    // check the confirm button enable case post update
    this.checkConfirmEnable(paymentIndex);
  }

  // handler for manual utr search button
  handleManualUTRSearch(index: number, showProgress = true) {
    // reset the page number to 1
    this.payments[index].page = 1;

    // donont hit the api if the character count for the search string is < 5 chars
    this.getUtrDataForPayment(index, "manual", showProgress);
  }

  // handler to navigate the pages in table
  handlePageNav(type, index: number) {
    // handle the first page and last page edge cases
    type === "next"
      ? (this.payments[index].page += 1)
      : type === "prev"
      ? (this.payments[index].page -= 1)
      : "";
    // hit the search utr api with updated page number
    this.getUtrDataForPayment(index);
  }

  // key up handler for the os amount input
  handleOSAmountKeyUp(event, index) {
    if (event.keyCode === 13) this.handleOsAmountChange(event, index);
    // reset the confirmed state
    this.totalOutstanding[index].osConfirmed = false;
    // check for the negative values
    if (event.target.value < 0) {
      event.target.value = Math.abs(event.target.value);
      this.totalOutstanding[index].amount = Math.abs(event.target.value);
    } else if (event.target.value === null) {
      event.target.value = 0;
      this.totalOutstanding[index].amount = 0;
    }

    // trigger the confirm button click on retrun key press
    if (event.keyCode === 13) this.handleOSConfirmClick(index);
  }

  // handler to run side effects on os amount change
  handleOsAmountChange(event, index) {
    // de_collect_os_amount_entered event
    let data = {
      osAmount: this.totalOutstanding[index].amount,
      osReason: this.totalOutstanding[index].reason,
    };
    this.triggerEvent("de_collect_os_amount_entered", data);

    if (event.target.value === null) {
      event.target.value = 0;
      this.totalOutstanding[index].amount = 0;
    }
    // on os amount change, recalculate the outstanding and reconciled amount and collect button enable
    this.checkCollectButtonEnable();
  }

  // handler to run side effects on os comment change
  handleOsCommentChange(event, index) {
    // de_collect_os_comments_entered event
    let data = {
      osAmount: this.totalOutstanding[index].amount,
      osReason: this.totalOutstanding[index].reason,
      osComments: this.totalOutstanding[index].comment,
    };
    this.triggerEvent("de_collect_os_comments_entered", data);
  }

  // handler to run side effects on os confirm button
  handleOSConfirmClick(index) {
    // de_collect_os_amount_confirm event
    let data = {
      osAmount: this.totalOutstanding[index].amount,
      osReason: this.totalOutstanding[index].reason,
      osComments: this.totalOutstanding[index].comment,
    };
    this.triggerEvent("de_collect_os_amount_confirm", data);

    this.totalOutstanding[index].osConfirmed = true;
    // update outstanding and reconcilation amount and collect button enable
    this.checkCollectButtonEnable();
  }

  // check if we need to enable the final collect button
  checkCollectButtonEnable() {
    // update the total os calculation
    // this.calculateTotalOutstandingEntered()
    // update the o/s and recon amount
    this.calculateOutstandingAmount();
    this.calculateReconciledAmount();

    // all payments are optional and doesn't have to be in a confirmed state
    // let allPaymentsConfirmed = this.payments.every(
    //   (payment) => payment.paymentConfirmed
    // );
    this.totalAmountReconciled.toFixed(2) ==
    this.data.invoiceData.collectAmount.toFixed(2)
      ? (this.collectEnable = true)
      : (this.collectEnable = false);
  }

  // handler to check for the negative values on enter amount
  handleAmountInput(event, index: number) {
    if (event.target.value < 0) {
      event.target.value = Math.abs(event.target.value);
      this.payments[index].amountEntered = Math.abs(event.target.value);
    } else if (event.target.value > this.data.invoiceData.collectAmount) {
      // donont allow the user to enter the amount greater than the total recon amount
      event.target.value = this.data.invoiceData.collectAmount;
      this.payments[index].amountEntered = this.data.invoiceData.collectAmount;
    }

    // enforce checks based on selected utr
    if (this.checkUTRBalanceAmountChange(index)) {
      // above method throws an error for the enforced checks and returns a boolean
      // reset the selected utr if error is raised
      this.payments[index].selectedUTRNumber = "";
      this.payments[index].selectedUTRID = "";

      // WHEN THE ERROR IS THROWN, REFRESH THE SEARCH TABLE
      this.handleManualUTRSearch(index, false);

      this.checkConfirmEnable(index);
    }

    // this method returns a flag if the utr has been totally consumed in any other payments
    // in this scenario, reset the utr selection
    if (this.checkUtrSelection(index)) {
      this.payments[index].selectedUTRNumber = "";
      this.payments[index].selectedUTRID = "";
      this.handleManualUTRSearch(index, false);
      this.checkConfirmEnable(index);
      return;
    }

    // hit amount change on enter press
    if (event.keycode === 13) {
      this.handleAmountChange(index);
    }

    // reset the confirmed state on change
    if (this.payments[index].paymentConfirmed) {
      this.payments[index].paymentConfirmed = false;
    }

    // check confirm button enable
    this.checkConfirmEnable(index);

    // calculate o/s and reconciled amount and collect button enable
    this.checkCollectButtonEnable();

    // on enter click run the side effect to confirm the button
    if (event.keyCode === 13) {
      // if confirm button is enabled, hit the confirm button handler
      if (this.utrExclusions.includes(this.payments[index].paymentMode)) {
        this.handleConfirmClick(index);
      }
    }
  }

  // handler to run side effects on amount enter change
  handleAmountChange(index: number) {
    // de_collect_amount_entered event
    let data = {
      mop: this.payments[index].paymentMode,
      amount: this.payments[index].amountEntered,
      paymentId: index,
    };
    this.triggerEvent("de_collect_amount_entered", data);
    // check confirm button enable
    this.checkConfirmEnable(index);
    // calculate o/s and reconciled amount and collect button enable
    this.checkCollectButtonEnable();
  }

  // handler to run side effects on add payment button click
  handleAddPayment() {
    // de_collect_add_payment_click event
    let data = {
      paymentId: this.payments.length,
    };
    this.triggerEvent("de_collect_add_payment_click", data);

    // push another payment object into the payments object
    this.payments.push(JSON.parse(JSON.stringify(this.PAYMENT_OBJECT)));
  }

  // calculates the max values allowed for each line item
  calculateMaxAmountValues() {}

  // handler to view invoices
  handleViewInvoices() {
    // de_collect_os_viewInvoice_click event
    let data = {
      osReason: this.totalOutstanding[3].reason,
      osComments: this.totalOutstanding[3].comment,
    };
    this.triggerEvent("de_collect_os_viewInvoice_click", data);

    // if invoice table is already open, close the table and return
    if (this.totalOutstanding[3].showInvoices) {
      this.totalOutstanding[3].showInvoices = false;
      return;
    }
    // hit the api to get the data
    this.getInvoices();
  }

  // billing item confirm click
  handleBillingConfirmClick(index) {
    // de_collect_os_deficitAmount_confirm event
    let data = {
      invoiceNumber: this.data.invoiceData.invoiceNumber,
      deficitAmount:
        this.totalOutstanding[3].buyerInvoiceMedicineList[index].deficitAmount,
      remarks: this.totalOutstanding[3].buyerInvoiceMedicineList[index].comment,
    };
    this.triggerEvent("de_collect_os_deficitAmount_confirm", data);

    // update the billing item confirmed state
    this.totalOutstanding[3].buyerInvoiceMedicineList[index].confirmed = true;
    // calculate the total deficit amount
    this.calculateTotalDeficitAmount();
  }

  // key up handler for the billing amount input field
  handleBillingAmountkeyUp(event, index) {
    this.totalOutstanding[3].buyerInvoiceMedicineList[index].confirmed
      ? (this.totalOutstanding[3].buyerInvoiceMedicineList[index].confirmed =
          false)
      : undefined;
    // reset the total calculated amount
    this.totalOutstanding[3].amount = 0;
    // if return key is pressed, handle the confirm click
    if (event.keyCode === 13) this.handleBillingConfirmClick(index);
  }

  // check utr available balance checks
  checkUTRBalance(selectedUTR, amountEntered) {
    let flag = false;
    if (amountEntered > 0 && amountEntered > selectedUTR.unsettledAmount) {
      this.popupService.openError(
        "Amount entered cannot be greater than the amount available to adjust"
      );
      flag = true;
    }
    return flag;
  }

  // check utr balance on amount change
  checkUTRBalanceAmountChange(index: number) {
    if (!this.payments[index].selectedUTRID) return;
    let flag = false;
    // get the selected utr object
    let selectedUTR = null;
    this.payments[index].utrSuggestionList.forEach((utr) => {
      if (utr.utrNoId === this.payments[index].selectedUTRID) {
        selectedUTR = utr;
        return;
      }
    });
    // compare if the amount entered is greater than that of selected utr
    if (this.payments[index].amountEntered > selectedUTR.unsettledAmount) {
      this.popupService.openError(
        "Amount entered cannot be greater than the amount available to adjust"
      );
      flag = true;
    }
    return flag;
  }

  // toggler for the final collect button
  toggleFinalConfirmation() {
    this.showCollectConfirm = !this.showCollectConfirm;
  }

  handleShake() {
    this.showShakeAnimation = true;
    setTimeout(() => {
      this.showShakeAnimation = false;
    }, 300);
  }

  // triggers the events available
  triggerEvent(eventName, data = {}) {
    // common data for the events in this screen
    let body = {
      eventName: eventName,
      screen_name: "de_to_warehouse_popup",
      invoiceNumber: this.data.invoiceData.invoiceNumber,
      invoiceAmount: this.data.invoiceData.invoiceValue,
      reconciliationAmount: this.data.invoiceData.collectAmount,
    };

    body = { ...body, ...data };
    // hit the final track api
    this.eventService.trackEvent(eventName, body);
  }

  // handler to run side effects on the deficit amount change
  handleDeficitAmountChange(event, index) {
    // de_collect_os_deficitAmount_entered event
    let data = {
      invoiceNumber: this.data.invoiceData.invoiceNumber,
      deficitAmount:
        this.totalOutstanding[3].buyerInvoiceMedicineList[index].deficitAmount,
      remarks: this.totalOutstanding[3].buyerInvoiceMedicineList[index].comment,
    };
    this.triggerEvent("de_collect_os_deficitAmount_entered", data);
  }

  // enforce check on amount available to adjust in utr selection
  checkUtrSelection(paymentIndex) {
    if (!this.payments[paymentIndex].selectedUTRNumber) return;
    let selectedUTR = null;
    this.payments[paymentIndex].utrSuggestionList.forEach((utr) => {
      if (utr.utrNoId === this.payments[paymentIndex].selectedUTRID) {
        selectedUTR = utr;
        return;
      }
    });
    let flag = false;
    // loop through the payments and summate the amount consumed
    let amountConsumed = 0;
    this.payments.forEach((payment, _) => {
      // find the payments with the same utr id and summate
      if (payment.selectedUTRID === selectedUTR.utrNoId) {
        amountConsumed += payment.amountEntered;
      }
    });
    // if total consumed > avl. to adjust for that utr, throw error
    if (amountConsumed > selectedUTR.unsettledAmount) {
      let message;
      this.popupService.openError(
        `This utr has been exhausted. Amount available to adjust : ${Math.abs(
          selectedUTR.unsettledAmount -
            (amountConsumed - this.payments[paymentIndex].amountEntered)
        )} `
      );
      flag = true;
    }
    return flag;
  }

  // handler to add the selected filter
  handleAddFilter(index) {
    let payment = this.payments[index];
    // checks on autocomplete option select
    if(payment.selectedFilterName === "BankName" && !this.availableBankAccounts.includes(payment.searchString)) return;
    if(payment.selectedFilterName === "PaymentMode" && !this.paymentModes.includes(payment.searchString)) return;
    if (
      !(
        payment.selectedFilterName === "BankName" ||
        payment.selectedFilterName === "PaymentMode" ||
        payment.selectedFilterName === "Amount"
      ) &&
      payment.searchString.length < 5
    ) {
      this.handleShake();
      return;
    }

    // checks
    if (!payment.selectedFilterName || !payment.searchString) {
      // dont hit the api + add user cta
      this.popupService.openError(
        "Please select filter and enter search string"
      );
      return;
    }

    // check the deposit date string format
    if (payment.selectedFilterName === "DepositDate") {
      let dateRegex =
        /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
      if (!payment.searchString.match(dateRegex)) {
        this.popupService.openError("invalid date format");
        payment.selectedFilterName = "";
        payment.searchString = "";
        return;
      }
    }
    payment.selectedFilters.push({
      filterName: payment.selectedFilterName,
      filterValue: payment.searchString,
    });
    // reset the selection and input field for the filter
    // payment.selectedFilterName = null;
    // payment.searchString = null;
    // hit the manual utr search api
    // this is handled inside the api call
    this.handleManualUTRSearch(index);
  }

  // handler for filter remove
  handleFilterRemove(index: number, filterIndex: number) {
    // remove the filter obj from the selected filters arr
    this.payments[index].selectedFilters.splice(filterIndex, 1);
    // reload the suggestion list data
    this.handleManualUTRSearch(index);
  }

  // handler to run side effects on filter selection change
  handleFilterSelectionChange(event, index) {
    let payment = this.payments[index];
    // check if the filter has already benn added
    payment.selectedFilters.forEach((filter, _) => {
      if (filter.filterName === event.value) {
        //  throw an error to the user and reset the fields
        this.popupService.openError("This filter has already been applied");
        // reset the selection and input field for the filter
        payment.selectedFilterName = "";
        payment.searchString = "";
      }
    });
  }

  // key up handler for the filter input
  handleFilterInputKeyUp(event, index) {
    if (event.keyCode === 13) {
      if (
        this.payments[index].selectedFilterName === "BankName" ||
        this.payments[index].selectedFilterName === "PaymentMode" ||
        this.payments[index].selectedFilterName === "Amount"
      ) {
        this.handleAddFilter(index);
      } else {
        if (this.payments[index].searchString.length < 5) {
          this.handleShake();
        } else {
          this.handleAddFilter(index);
        }
      }
    }
  }

  // actual filter function for the mat autocomplete
  private _filter(value: string, options: string[]): string[] {
    const filterValue = value.toLowerCase();
    return options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  // onFocus handler for thr filter input
  handleSearchInputFocus(index) {
    // update the filtered options
    let options = [];
    if (this.payments[index].selectedFilterName === "PaymentMode") {
      options = [...this.paymentModes];
      // remove cash and credit note from the list
      options.splice(options.indexOf("cash"), 1);
      options.splice(options.indexOf("credit note"), 1);
    } else if (this.payments[index].selectedFilterName === "BankName") {
      options = [...this.availableBankAccounts];
    }
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value, options))
    );
  }
}
