export const environment = {
  version: 1.0,
  production: true,
  // BASE_URL: "http://saveomedpreprod.ap-south-1.elasticbeanstalk.com",
  // BASE_URL: "http://saveomeddev-env.cnaetytsju.ap-south-1.elasticbeanstalk.com", //devapi
  BASE_URL: "https://gatewaydev.clinn.in/api",
  adminUrl: "https://gatewaydev.clinn.in/api",
  helpDeskUrl: "https://gatewaydev.clinn.in/api",
  hubUrl: "https://gatewaydev.clinn.in/api",
  eagleUrl : 'https://eagledev.clinn.in/api',
  bannerUrl: "https://gatewaydev.clinn.in/api",
  docraptor:{key:"dc8n3N38toneWIGm0qsr",test:true},
  firebase: {
   apiKey: "AIzaSyCzWPhfLm9SgJ5d3eUPwcTn3vMkq9EcLIs",
   authDomain: "saveo-medical-dev.firebaseapp.com",
   databaseURL: "https://saveo-medical-dev.firebaseio.com",
   projectId: "saveo-medical-dev",
   storageBucket: "saveo-medical-dev.appspot.com",
   messagingSenderId: "976257538432",
   appId: "1:976257538432:web:ae04199b13e3af8c95c965",
   measurementId: "G-K33WELCQYT"
}
};
