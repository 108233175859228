import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogModelAddOpenMedicineWarehouse } from './model';
import { FormGroup, FormBuilder } from "@angular/forms";
import { FormControl } from "@angular/forms";
import {Observable} from 'rxjs';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { map, startWith } from 'rxjs/operators';
import { WarehouseService } from 'src/app/core/services/api/warehouse.service';

@Component({
  selector: 'app-add-open-medicine-warehouse',
  templateUrl: './add-open-medicine-warehouse.component.html',
  styleUrls: ['./add-open-medicine-warehouse.component.scss']
})
export class AddOpenMedicineWarehouseComponent implements OnInit {
  _mFormGroup: FormGroup;
  currSearchMed = new FormControl();
  searchSuggestions: Observable<string[]>;
  currMedToAdd;
  searchResult = [];
  enableAddMedicine = false;

  constructor(
    public dialogRef: MatDialogRef<AddOpenMedicineWarehouseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelAddOpenMedicineWarehouse,
    private formBuilder: FormBuilder,
    private popupService: PopupService,
    private warehouseService: WarehouseService
  ) {}

  ngOnInit():void {
    this.getSearchSuggestions();
  }
  
  getSearchSuggestions() {
    console.log(this.data["billingKeys"].billingKeys)
    const params = {
      billingKeys: this.data["billingKeys"].billingKeys,
      medicineName: "",
    }
    this.warehouseService.searchItem(params).subscribe(
      response => {
        let sample = [];
        response.data.map(med => {
          sample.push(med)
        })

        this.searchResult = sample;
    
        this.searchSuggestions = this.currSearchMed.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filterSuggestions(value))
          );
      },
      error=> {
        let sample = [];

        this.searchResult = sample;
    
        this.searchSuggestions = this.currSearchMed.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filterSuggestions(value))
          );
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  private filterSuggestions(value: string): string[] {
    let sample = [];
    if (value.length > 2) {
      const params = {
        billingKeys: this.data["billingKeys"].billingKeys,
        medicineName: value
      }
      this.warehouseService.searchItem(params).subscribe(
        response => {
          response.data.map(med => {
            sample.push(med)
          })
        }
      );
    }
    return sample;
  }

  selectNewMed(e, opt) {
    console.log(e, opt)
    this.currMedToAdd = opt;
    this.checkAddMedicine();
  }

  checkAddMedicine() {
    if (this.currMedToAdd) {
      this.enableAddMedicine = true;
    } else {
      this.enableAddMedicine = false;
    }
  }

  addMedicine() {
    this.popupService.openProgress();
    const params= {
      billingKeys: this.data["billingKeys"].billingKeys,
      medicineId:this.currMedToAdd.medicineId,
      medicineName:this.currMedToAdd.medicineName
    };
    this.warehouseService.decisionRoomAddItem(params,{}).subscribe(
      response => {
        this.popupService.closeProgress();
        this.popupService.openSuccess("Item added successfully!!!");
        this.cancel();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  cancel() {
    this.dialogRef.close();
  }

}