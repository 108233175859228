import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogPrintCheckList } from './model';
import { PopupService} from 'src/app/core/services/popup/popup.service';
import { WholesaleService } from 'src/app/core/services/api/wholesale.service';
import {
  FormGroup,
  FormBuilder,
  Validators
} from "@angular/forms";
@Component({
  selector: 'app-print-checklist',
  templateUrl: './print-checklist.component.html',
  styleUrls: ['./print-checklist.component.scss']
})
export class PrintChecklistComponent implements OnInit {
  manualChecked=false;
  packSlipChecked=false;
  shippingChecked=false;
  _mFormGroup: FormGroup;
  csvFiles=[]
  constructor(  private popupService: PopupService,
    private wholesaleService: WholesaleService,
    public dialogRef: MatDialogRef<PrintChecklistComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogPrintCheckList,
    ) { 
      this._mFormGroup = this.formBuilder.group({
        file: [""]
      });
    }

  ngOnInit(): void {
  }


  submit() {
    this.popupService.openProgress()
    const params={
      buyerInvoiceId:this.data.data.invoiceId
    }
    const body={
      "ewayBillAttached": this.manualChecked,
      "ewayBillAwsUrl": "",
      "pickAndPackSlipAttached": this.packSlipChecked,
      "pickAndPackSlipAwsUrl": "",
      "shippingLabelAttached": this.shippingChecked,
      "shippingLabelAwsUrl": "string"
    }
    this.wholesaleService.printCheckList(params,body).subscribe((res)=>{
      this.dialogRef.close(true);
    },
    error => {
      this.popupService.closeProgress()
      this.dialogRef.close(true);
      // this.popupService.openError(error?.error?.error?.error?error?.error?.error?.error:'Something went wrong');   
    })
  }

  cancel() {
    this.dialogRef.close(false);
  }

  getUploadFiles(event){

  }
  

}
