
import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ɵINTERNAL_BROWSER_DYNAMIC_PLATFORM_PROVIDERS } from '@angular/platform-browser-dynamic';
import { DialogApproveConfirm } from './model';
import { ProductMasterService } from 'src/app/core/services/api/product-master.service';
import { PopupService } from "src/app/core/services/popup/popup.service";

@Component({
  selector: 'app-mapping-approve-confirm',
  templateUrl: './mapping-approve-confirm.component.html',
  styleUrls: ['./mapping-approve-confirm.component.scss']
})
export class MappingApproveConfirmComponent implements OnInit {
  constructor(
    private popupService: PopupService,
    private productMaster: ProductMasterService,
    public dialogRef: MatDialogRef<MappingApproveConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogApproveConfirm) { }

    masterMed=null
 
  ngOnInit(): void {
    this.data.data.subChild.medicineId && this.getData()
    console.log(this.data.data.subChild);
    
  }

  getData(){
        this.popupService.openProgress()
         const params={
           medicineId:this.data.data.subChild.medicineId
         }

         this.productMaster.getMasterMedicineById(params).subscribe(response=>{
            this.masterMed=response.data.t
          this.popupService.closeProgress()
         },error=>{
          this.popupService.closeProgress()
          this.popupService.openError(error.error.error.error)
         })
  }
  submit() {
   
   
        this.dialogRef.close(true);
    
  }

  cancel() {
    
      this.dialogRef.close(false);
   
  }
}
