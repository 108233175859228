import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-credit-dashboard',
  templateUrl: './credit-dashboard.component.html',
  styleUrls: ['./credit-dashboard.component.scss']
})
export class CreditDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
