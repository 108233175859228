import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";
import { AdminService } from "src/app/core/services/api/admin.service";
import { SupplyService } from "src/app/core/services/api/supply.service";
import { PopupService } from "src/app/core/services/popup/popup.service";
import { DialogManualCN } from "./model";

@Component({
  selector: "app-manual-cn-popup",
  templateUrl: "./manual-cn-popup.component.html",
  styleUrls: ["./manual-cn-popup.component.scss"],
})
export class ManualCnPopupComponent implements OnInit {
  cnData = [];
  totalCNValue = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogManualCN,
    public popupService: PopupService,
    private supplyService: SupplyService,
    private adminService: AdminService
  ) {}

  ngOnInit(): void {
    this.getCnData();
  }

  getCnData() {
    console.log(this.data.data)
    this.popupService.openProgress();
    let params = {
      creationId: this.data.data.cnData.creationId,
    };
    this.supplyService.getManualCNsByCreationId(params).subscribe(
      (response) => {
        this.popupService.closeProgress();
        // map and calculate the total cn value
        let total = null;
        response.data.map((item, _) => {
          item.crDrNote *= -1;
          total += item.crDrNote;
        });
        // update the cnData
        this.cnData = response.data;
        // update the total cn
        this.totalCNValue = total;
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  // handler to print the current screen
  handlePrintScreen(){
    setTimeout(() => {
      let popupWindow
      var winHead = document.getElementsByTagName('head')[0];
      var style = document.createElement('style');
      style.type = 'text/css';
      style.media = 'print';
      let page = document.getElementById('printable').innerHTML
      popupWindow = window.open('', '_blank', 'width=auto,height=auto,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
      popupWindow.document.open();
      popupWindow.document.write('<html style="width: 100%;s">' + winHead.innerHTML + '<body onload="window.print()" class="body"><div class="full-page table-height">' + page + '</div></body></html>');
      popupWindow.document.close();
    },1) 
  }
}
