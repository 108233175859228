import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { PopupService } from "src/app/core/services/popup/popup.service";
import { BinService } from "src/app/core/services/api/bin.service";
import { FoundQuantityDialogModel } from "./model";

@Component({
  selector: "app-found-quantity",
  templateUrl: "./found-quantity.component.html",
  styleUrls: ["./found-quantity.component.scss"],
})
export class FoundQuantityComponent implements OnInit {
  parentId;
  adnMedicineId;
  quantity;
  summationQty = 0;
  selectQuantity;
  remQuantity;
  currBinToAdd;
  currBin = "";
  searchKey;
  searchSuggestions: [];
  searchResult = [];
  enableAddBin = false;
  binTbl = [];
  binErr = false;

  constructor(
    public dialogRef: MatDialogRef<FoundQuantityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FoundQuantityDialogModel,
    private binService: BinService,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {
    this.getOrderData();
  }

  getOrderData() {
    this.quantity = this.data.invoices.quantity;
    this.selectQuantity = this.data.invoices.quantity;
    this.data.invoices.binFoundQuantityList.map((ele) => {
      this.summationQty += ele.foundQty;
    });
    this.remQuantity = this.data.invoices.quantity - this.summationQty;
    this.parentId = this.data.invoices.parentId;
    this.adnMedicineId = this.data.invoices.adnMedicineId;
    this.binTbl = [...this.data.invoices.binFoundQuantityList];
  }

  cancel(body) {
    this.dialogRef.close(body);
  }

  searchBinTag(event) {
    this.searchKey = event.target.value;

    if(this.data.currDataType === "Manual PO")
    {
      this.getAdnIdSearchSuggestions();
    }
    else {
      this.getParentIdSearchSuggestions();
    }
  }

  getAdnIdSearchSuggestions() {
    const params = {
      name: this.searchKey,
      adnId: this.data.adnId
    };

    this.binService.adnSearchBin(params).subscribe(
      (response) => {
        this.searchSuggestions = response.body.data;
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  getParentIdSearchSuggestions() {
    const params = {
      name: this.searchKey,
      parentId: this.parentId
    };

    this.binService.parentSearchBin(params).subscribe(
      (response) => {
        this.searchSuggestions = response.body.data;
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  selectNewBin(e) {
    this.searchKey = "";
    let opt = e.option.value;
    this.currBin = opt.binName;
    this.currBinToAdd = opt;
    this.checkAddBin();
  }

  checkAddBin() {
    if (this.currBinToAdd) {
      this.enableAddBin = true;
    } else {
      this.enableAddBin = false;
    }
  }

  addBin() {
    if (this.binTbl.some((bin) => bin.binId === this.currBinToAdd.id)) {
      this.binErr = true;
    } else {
      if (this.remQuantity < this.quantity) {
        this.remQuantity = this.remQuantity - this.selectQuantity;
      } else {
        this.remQuantity = this.quantity - this.selectQuantity;
      }

      let tempTbl = {
        binId: this.currBinToAdd.id,
        binName: this.currBinToAdd.binName,
        foundQty: this.selectQuantity,
      };

      this.binTbl.push(tempTbl);
      if (this.binErr) {
        this.binErr = false;
      }

      this.currBinToAdd = "";
      this.currBin = "";
      this.searchKey = "";
    }
  }

  removeBin(binId) {
    let binIndex = this.binTbl.findIndex((bin) => bin.binId === binId);
    this.remQuantity = +this.remQuantity + +this.binTbl[binIndex].foundQty;

    this.binTbl = this.binTbl.filter((bin) => bin.binId !== binId);

    if (this.binErr) {
      this.binErr = false;
    }
  }

  submitQuantity() {
    this.popupService.openProgress();
    this.binErr = false;
    let body = this.binTbl;
    let bins = this.binTbl.map((ele) => ele.binName);

    if (this.adnMedicineId) {
      let params = { adnMedicineId: this.adnMedicineId };

      this.binService.submitManualPOBin(params, body).subscribe(
        (response) => {
          let adnBody = {
            adnMedicineId: this.adnMedicineId,
            foundQuantity: response.body.data,
            suggestedBinNames: bins.toString(),
            binFoundQuantityList: this.binTbl,
          };
          this.popupService.closeProgress();
          this.cancel(adnBody);
        },
        (error) => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      );
    } else {
      let params = { parentSaveoInventoryId: this.parentId };

      this.binService.submitBin(params, body).subscribe(
        (response) => {
          let body = {
            parentId: this.parentId,
            foundQuantity: response.body.data,
            suggestedBinNames: bins.toString(),
            binFoundQuantityList: this.binTbl,
          };
          this.popupService.closeProgress();
          this.cancel(body);
        },
        (error) => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      );
    }
  }
}
