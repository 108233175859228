import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogModelConfirmDelete } from './model';
import { PopupService } from "src/app/core/services/popup/popup.service";
import { WholesaleService } from 'src/app/core/services/api/wholesale.service';

@Component({
  selector: 'app-oms-confirm-delete',
  templateUrl: './oms-confirm-delete.component.html',
  styleUrls: ['./oms-confirm-delete.component.scss']
})
export class OmsConfirmDeleteComponent implements OnInit {

  constructor(
    private popupService: PopupService,
    public dialogRef: MatDialogRef<OmsConfirmDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelConfirmDelete,
    private wholesaleService: WholesaleService
    ) { }

  ngOnInit(): void {
  }

  deleteItem() {
    this.popupService.openProgress();
    const params = {
      orderDetailId: this.data.item.idOfOrderMedicineDetail,
      orderId: this.data.orderId
    };
    this.wholesaleService.deleteItem(params).subscribe(
      response => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data);
        this.close()
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
        this.close();
      }
    )
  }

  close() {
    this.dialogRef.close(false);
  }

  change() {
    this.dialogRef.close(true);
  }

}
