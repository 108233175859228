import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogModelErrorList } from './model';

@Component({
  selector: 'app-error-list',
  templateUrl: './error-list.component.html',
  styleUrls: ['./error-list.component.scss']
})
export class ErrorListComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogModelErrorList>,
    @Inject(MAT_DIALOG_DATA) public _mData
  ) {}

  ngOnInit():void {
    console.log(this._mData)
  }

  cancel(){
    this.dialogRef.close()
  }

  onSubmit() {
    this.dialogRef.close(this._mData);
  }
}