import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// import { stat } from 'fs';
import { DialogWholesaleLiveSo } from './model';
import { PopupService} from 'src/app/core/services/popup/popup.service';
import { WholesaleService } from 'src/app/core/services/api/wholesale.service';
import { ItemsFromDecisionRoomComponent } from 'src/app/components/supply/supply-container/items-from-decision-room/items-from-decision-room.component';
import { ItemContent } from 'semantic-ui-react';

@Component({
  selector: 'app-wholesale-live-so',
  templateUrl: './wholesale-live-so.component.html',
  styleUrls: ['./wholesale-live-so.component.scss']
})
export class WholesaleLiveSoComponent implements OnInit {

  constructor(
    private popupService: PopupService,
    private wholesaleService: WholesaleService,
    public dialogRef: MatDialogRef<WholesaleLiveSoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogWholesaleLiveSo) { }

    ordersData={
      orderId:'',
      buyerInvoiceId:'',
      netTotalAmount:'',
      generatedBuyerInvoiceDetailList:[],
      paymentStatus:'',
      invoiceNumber:''
    }

    showEditNote=true 
    showEditBtn=false
    todayDate=new Date();
  ngOnInit(): void {
    this.getBill()
  }

  getBill(){
    this.popupService.openProgress()
    if(this.data.data.order.buyerInvoiceGenerated){
      this.wholesaleService.getInvoiceById({buyerInvoiceId:this.data.data?.order?.buyerInvoiceId}).subscribe((res)=>{
        this.ordersData=res.data
        this.ordersData?.generatedBuyerInvoiceDetailList.map(item=>{
          item.expiryDate &&  (item.expiryDate=new Date(item.expiryDate))
        })
        this.popupService.closeProgress()
  },
  error => {
    this.popupService.closeProgress()
    this.popupService.openError(error.error.error.error);
   
  })
    }else{
      this.wholesaleService.generateBill({orderId:this.data.data?.order?.orderId}).subscribe((res)=>{
        this.ordersData=res.data
        this.popupService.closeProgress()
  },
  error => {
    this.popupService.closeProgress()
    this.popupService.openError(error.error.error.error);
   
  })
    }
   
    
  }
  submit() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  

  openBill(){
    let printState={
      status:true,
      items:[]
    };
    this.ordersData?.generatedBuyerInvoiceDetailList.forEach((item)=>{
             if(!item.batch?.length || !item.expiryDate || !item.hsnCode?.length ){ 
              printState.items.push(item.itemName)
              printState.status=false
             }else if(item?.editBatch==true || item?.editExpiry==true || item?.editHsn==true){
              printState.items.push(item.itemName)
              printState.status=false
            }
            console.log(item)
            console.log(!item.batch?.length, !item.expiryDate, item?.editBatch==true,item?.editExpiry==true)
    })

    if(printState.status){
this.popupService.openWholesaleBill({invoiceId:this.ordersData.buyerInvoiceId,orderId:this.data.data?.order?.orderId,checkList:true}).afterClosed().subscribe((res)=>{
                  if(res){
                    
                    this.submit()
                  }
                  console.log(res)
         })
    }else{
      this.popupService.openError(`Please fill Batch, Expiry date and HSN Code for items ${printState.items}`)
    }
         
  }

  openSplit(item){
     this.popupService.openWholesaleSplit({item:item,orderData:this.ordersData}).afterClosed().subscribe((res)=>{
           if(res){
            this.ordersData=res.data
           }
     })
  }

  editBill(item,type){
    if(type=="batch"){
      item.editBatch=true
    }else if(type=="expiry"){
      item.editExpiry=true
    }
    else if(type=="hsn"){
      item.editHsn=true
    }
  }
  

  saveBill(item,type){
 let params={
  buyerInvoiceDetailId:item._id
 }
 type==="batch" && (params['batch']=item.batch)
 type==="expiry" && (params['expiryDate']=new Date(item.expiryDate).getTime())
 type==="hsn" && (params['hsnCode']=item.hsnCode)
 this.popupService.openProgress()
    this.wholesaleService.updateBill(params).subscribe((res)=>{
      if(type=="batch"){
        item.editBatch=false
      }else if(type=="expiry"){
        item.editExpiry=false
      }
      else if(type=="hsn"){
        item.editHsn=false
      }
      this.popupService.closeProgress()
    }
    ,
    error => {
      this.popupService.closeProgress()
      this.popupService.openError(error?.error?.error?.error?error?.error?.error?.error:'Something went wrong');
     
    })
  }

}
