import { Component, OnInit, Input } from '@angular/core';
import { database } from 'firebase';

@Component({
  selector: "app-company-names",
  templateUrl: "./company-names.component.html",
  styleUrls: ["./company-names.component.scss"]
})
export class CompanyNamesComponent implements OnInit {
  _mHover:boolean =false;
  _mModel: any;
  @Input("model") set val(value: any) {
    if (value) {
      this._mModel = value;
      // console.log(value);
    }
  }
     
  constructor() {}

  ngOnInit() {
  }
}
