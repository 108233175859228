
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule, MatIconModule, MatRippleModule, MatSidenavModule, MatToolbarModule, MatTableModule } from "@angular/material";
import { MaterialFileInputModule } from "ngx-material-file-input";
import { MaterialModule } from "../../material.module";
import { TbWaterfallModule } from "../../tb/tb-waterfall/tb-waterfall.module";
import { DialogModule } from "src/app/shared/dialog/dialog.module";
import { MatPaginatorModule } from "@angular/material/paginator";
import { GlobalSearchComponent } from './global-search.component';


@NgModule({
  declarations: [GlobalSearchComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    MatRippleModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    TbWaterfallModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialFileInputModule,
    DialogModule,
  ],
  exports: [GlobalSearchComponent]
})
export class GlobalSearchModule { }
