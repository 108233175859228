import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelDERetailerOutstandingInvoice } from './model';
import { OutstandingService } from "src/app/core/services/api/outstanding.service";
import { PopupService } from "src/app/core/services/popup/popup.service";

@Component({
  selector: 'app-outstanding-de-retailer-invoice',
  templateUrl: './outstanding-de-retailer-invoice.component.html',
  styleUrls: ['./outstanding-de-retailer-invoice.component.scss']
})
export class OutstandingDeRetailerInvoiceComponent implements OnInit {
  invoiceData = {
    invoiceValue: 0,
    returnValue: 0,
    itemList: []
  };

  constructor(
    private outstandingService: OutstandingService,
    private popupService: PopupService,
    public dialogRef: MatDialogRef<OutstandingDeRetailerInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelDERetailerOutstandingInvoice,
    ) { }

  ngOnInit(): void {
    this.popupService.openProgress();
    this.getData(true);
  }

  getData(closeProgress) {
    const params = {
      id: this.data.outstandingData.id
    };
    this.outstandingService.getOutstandingInvoiceData(params).subscribe(
      response => {
        this.invoiceData = response.data;
        if (closeProgress) {
          this.popupService.closeProgress();
        }
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
        this.dialogRef.close(false);
      }
    )
  }

  makePayment() {
    this.popupService.openCollectRetailerOutstandingPopup(this.data.outstandingData)
  }

  closePopup() {
    this.dialogRef.close(false);
  }

}
