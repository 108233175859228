import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelAdnMapConfirm } from './model';
@Component({
  selector: 'app-adn-map-confirm',
  templateUrl: './adn-map-confirm.component.html',
  styleUrls: ['./adn-map-confirm.component.scss']
})
export class AdnMapConfirmComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AdnMapConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelAdnMapConfirm) { }

  ngOnInit(): void {
    
  }

  submit(existing) {
   
     let obj={
         useExisting:existing,
         action:true
     }
        this.dialogRef.close(obj);
    
  }

  cancel() {
    
      this.dialogRef.close(false);
   
  }

}
