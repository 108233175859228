import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelConfirmSingleSOCancel } from './model';
import { AdminService } from "src/app/core/services/api/admin.service";
import { PopupService } from "src/app/core/services/popup/popup.service";


@Component({
  selector: 'app-confirm-single-so-cancel',
  templateUrl: './confirm-single-so-cancel.component.html',
  styleUrls: ['./confirm-single-so-cancel.component.scss']
})
export class ConfirmSingleSoCancelComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmSingleSoCancelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelConfirmSingleSOCancel,
    private adminService: AdminService,
    private popupService: PopupService) { }

  ngOnInit(): void {
  }

  submit() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
