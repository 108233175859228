import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { ApiService } from "../../../common/api.service";

@Injectable({
  providedIn: 'root'
})
export class WholesaleService {
  API_URL: string = environment.adminUrl + "/wholesale";
  // https://ashish-test.loca.lt/

  constructor(private apiService: ApiService) {}

  getOrderRequests(params): Observable<any> {
    const urlPath = this.API_URL + "/sellerDashboard/order/requests";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getOrderDetails(params): Observable<any> {
    const urlPath = this.API_URL + "/sellerDashboard/view/orderDetails";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateItemQuantity(params, body): Observable<any> {
    const urlPath = this.API_URL + "/sellerDashboard/editQuantity/orderDetails";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  deleteItem(params): Observable<any> {
    const urlPath = this.API_URL + "/sellerDashboard/deleteMedicine/beforeOrderAcceptedBySeller";
    return this.apiService.doDeleteForQuery(urlPath, params);
  }

  acceptOrder(params): Observable<any> {
    const urlPath = this.API_URL + "/sellerDashboard/accept/order";
    return this.apiService.doUpdateForQuery(urlPath, params);
  }

  
  getAcceptedOrders(params): Observable<any> {
    const urlPath = this.API_URL + "/sellerDashboard/getAcceptedOrders/lastDay";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }
 
  enableInvoice(params): Observable<any> {
    const urlPath = this.API_URL + "/sellerDashboard/enable/invoice";
    return this.apiService.doUpdateForQuery(urlPath, params);
  }

  editInvoice(params, body): Observable<any> {
    const urlPath = this.API_URL + "/sellerDashboard/editQuantity/orderDetails";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }
  
  generateBill(params): Observable<any> {
    const urlPath = this.API_URL + "/sellerDashboard/generate/buyerInvoice";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }
  

  getReadyToShipOrders(params): Observable<any> {
    const urlPath = this.API_URL + "/sellerDashboard/getOrderForReadyToShip";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  splitQuantity(params, body): Observable<any> {
    const urlPath = this.API_URL + "/sellerDashboard/split/batchInBuyerInvoice";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }
  
  updateBill(params): Observable<any> {
    const urlPath = this.API_URL + "/sellerDashboard/update/batchOrExpiryOrHsnCode";
    return this.apiService.doUpdateForQuery(urlPath, params);
  }

  
  getInvoiceById(params): Observable<any> {
    const urlPath = this.API_URL + "/sellerDashboard/getBuyerInvoiceById";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  
  getReadyToShipDeliverySlot(params): Observable<any> {
    const urlPath = this.API_URL + "/sellerDashboard/getReadyToShipDeliverySlot";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }
  
  
  confirmPickUp(params): Observable<any> {
    const urlPath = this.API_URL + "/sellerDashboard/updatePickUpTiming";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updatePrintStatus(params): Observable<any> {
    const urlPath = this.API_URL + "/sellerDashboard/printInvoice/bySeller";
    return this.apiService.doUpdateForQuery(urlPath, params);
  }

  
  getPrintBill(params): Observable<any> {
    const urlPath = this.API_URL + "/sellerDashboard/print/bill";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  printCheckList(params, body): Observable<any> {
    const urlPath = this.API_URL + "/sellerDashboard/checkList/beforeReadyToShip";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }
  
  getShippedOrders(params): Observable<any> {
    const urlPath = this.API_URL + "/sellerDashboard/getShippedOrders";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getDistributors(): Observable<any> {
    const urlPath = this.API_URL + "/distributor/getWholesaleDistributors";
    return this.apiService.doGetObservable(urlPath);
  }

  getCategories(): Observable<any> {
    const urlPath = this.API_URL + "/distributor/getAllMedicineCategory";
    return this.apiService.doGetObservable(urlPath);
  }

  getSubCategories(): Observable<any> {
    const urlPath = this.API_URL + "/distributor/getAllMedicineSubCategory";
    return this.apiService.doGetObservable(urlPath);
  }

  getLogs(): Observable<any> {
    const urlPath = this.API_URL + "/inventory/getRecentInventoryUpdateLogs";
    return this.apiService.doGetObservable(urlPath);
  }

  getInventoryLogDetails(params): Observable<any> {
    const urlPath = this.API_URL + "/inventory/getUploadedInventoryData";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getDistributotMapping(params): Observable<any> {
    const urlPath = environment.adminUrl + "/distributor/home/distributorColumnMapping";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

}
