import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelADN } from './model';
import { SupplyService } from "src/app/core/services/api/supply.service";
import { PopupService } from "src/app/core/services/popup/popup.service";


@Component({
  selector: 'app-adn-upload-errors',
  templateUrl: './adn-upload-errors.component.html',
  styleUrls: ['./adn-upload-errors.component.scss']
})
export class AdnUploadErrorsComponent implements OnInit {

  panelOpenState=false;
  constructor(private supplyService: SupplyService,
    private popupService: PopupService,
    public dialogRef: MatDialogRef<AdnUploadErrorsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelADN,) { }

  ngOnInit(): void {
    
  }


  cancel() {
    this.dialogRef.close(true);
  }

}
