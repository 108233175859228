import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileUploadService } from 'src/app/core/services/api/file-upload.service';
import { SupplyService } from 'src/app/core/services/api/supply.service';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { DialogModelCollectAcknowledgementSlip } from './model';

@Component({
  selector: 'app-collect-acknowledgement-slip',
  templateUrl: './collect-acknowledgement-slip.component.html',
  styleUrls: ['./collect-acknowledgement-slip.component.scss']
})
export class CollectAcknowledgementSlipComponent implements OnInit {
  disableUpdate = true;
  currentAttachment;

  constructor(
    public dialogRef: MatDialogRef<CollectAcknowledgementSlipComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelCollectAcknowledgementSlip,
    private supplyService: SupplyService,
    private popupService: PopupService,
    private fileUploadService: FileUploadService
    ) { 
      console.log(this.data);
    }

  ngOnInit(): void {
  }

  selectOnlineFile(e) {
    if (e.target.files.length > 0) {
      this.popupService.openProgress();
      this.currentAttachment = e.target.files[0];
      const formData = new FormData();
      formData.append("file", this.currentAttachment, this.currentAttachment.name);
      this.fileUploadService.uploadAcknowledgementSlip(formData, {debitNoteId: this.data.debitNote.debitNoteId}).subscribe(
        response => {
          this.popupService.closeProgress();
          this.updateState();
        },
        error => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      )
    }
  }

  // update state
  updateState() {
    const params = {
      debitNoteId: this.data.debitNote.debitNoteId,
      updateState: this.data.debitNote.correctState
    };
    this.supplyService.updateState(params, {}).subscribe(
      response => {
        this.popupService.closeProgress();
        this.popupService.openSuccess("Acknowledgement Slip Updated.");
        this.closePopup();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  // close popup
  closePopup() {
    this.dialogRef.close();
  }
}
