import { QRCodeModule } from 'angularx-qrcode';
import { CommonModule } from "@angular/common";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "src/app/material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatIconModule, MatRippleModule } from "@angular/material";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { PrintBillNewComponent } from './print-bill-new.component';



@NgModule({
  declarations: [PrintBillNewComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    MatIconModule,
    MatRippleModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    QRCodeModule
  ],
  exports: [PrintBillNewComponent]
})
export class PrintBillNewModule { }
