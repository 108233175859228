import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AuthService } from '../services/api/api-auth.service';
import { UserModel } from '../services/params/user.model';

@Injectable({
  providedIn: "root"
})
export class AuthStoreService {
  private userSubject = new BehaviorSubject<UserModel[]>([]);
  authUser$: Observable<UserModel[]> = this.userSubject.asObservable();

  constructor(private authService: AuthService) {}

  // initAuthUser(number) {
  //   this.authService.checkAuth(number).subscribe(data => {
  //     this.userSubject.next(data);
  //   });
  // }

  initRetailerId(){
    
  }
}
