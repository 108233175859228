
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule, MatIconModule, MatRippleModule, MatSidenavModule, MatToolbarModule } from "@angular/material";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "../../material.module";
import { TbWaterfallModule } from "../../tb/tb-waterfall/tb-waterfall.module";
import { SupplyComponent } from './supply.component';
import { GlobalSearchModule } from "src/app/shared/global-search/global-search.module";
import { FormsModule } from "@angular/forms";



@NgModule({
  declarations: [SupplyComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    MatRippleModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    TbWaterfallModule,
    RouterModule,
    GlobalSearchModule,
    MatIconModule,
    MaterialModule,
    FormsModule,
  ],
  exports: [SupplyComponent]
})
export class SupplyModule { }
