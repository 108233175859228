import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable, of, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ApiService } from "../../../common/api.service";
// import { url } from "inspector";

@Injectable({
  providedIn: "root"
})
export class FileUploadService {
  private cache: any;
  API_URL: string = environment.adminUrl;
  preprod_url = "https://preprod.saveo.in";
  Eagle_URL:string=environment.eagleUrl

  constructor(private http: HttpClient, private apiService: ApiService) {}

  downloadAccountBuyerInvoice(body): Observable<any> {
    const urlPath = this.API_URL + '/admin/accounts/downloadPaymentDetails';
    return this.apiService.doPostObservableForCSV(urlPath, body, {});
  }

  downloadStock(body): Observable<any> {
    const urlPath = this.API_URL + "/admin/stock/downloadStockFile";
    return this.apiService.doPostObservableForCSV(urlPath, body, {});
  }

  uploadMultipleInvoices(file: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = this.API_URL + "/file/multipleInvoiceUpload";
    return this.http.post<any>(urlPath, file);
  }

  uploadCSV(params, file: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl + "/file/uploadCSV";
    return this.http.post<any>(urlPath, file, {params: params});
  }

  uploadCsvV2(params, file: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl + "/file/uploadCSV";
    return this.http.post<any>(urlPath, file, {params: params});
  }

  uploadActualInventory(file: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = this.API_URL + "/admin/invoice/uploadActualInventory";
    return this.http.post<any>(urlPath, file);
  }

  uploadMis(file: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl + "/home/uploadMisData";
    return this.http.post<any>(urlPath, file);
  }

  pushNotification(file: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = this.API_URL + "/admin/pushNotificationSPUser";
    return this.http.post<any>(urlPath, file);
  }

  convertInvoice(file: any, params): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = this.API_URL + "/admin/file/invoiceConvertor";
    return this.apiService.doPostObservableForCSV(urlPath, file, params)
  }

  uploadInvoice(file: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = this.API_URL + "/file/uploadInvoice";
    return this.http.post<any>(urlPath, file);
  }

  uploadImage(file: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = this.API_URL + "/admin/uploadImage";
    return this.http.post<any>(urlPath, file);
  }

  uploadMedicineImage(file: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = this.API_URL + "/v2/admin/uploadImage";
    return this.http.post<any>(urlPath, file);
  }

  uploadManufacturerImage(file: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = this.API_URL + "/admin/uploadManufacturerImage";
    return this.http.post<any>(urlPath, file);
  }

  uploadSellerInvoiceImage(file: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl + "/admin/upload/purchaseImportImage";
    return this.http.post<any>(urlPath, file);
  }

  uploadDocument(file: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.BASE_URL + "/home/uploadDocuments";
    return this.http.post<any>(urlPath, file);
  }

  private formatErrors(error: any) {
    console.log(error);
    return throwError(error.error);
  }

  
  adnUpload(file: any,params): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl +"/admin/ADN/UploadADN";
    return this.apiService.doPostObservableForCSV(urlPath,file,params); 
  }

  adnUploadTest(file: any,params): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl +"/admin/v2/ADN/UploadADN";
    return this.apiService.doPostObservableForCSV(urlPath,file,params); 
  }

  
  grnUpload(file: any,params): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl +"/admin/GRN/uploadGRN";
    return this.apiService.doPostObservableForCSV(urlPath,file,params); 
  }

  uploadCreditPaymentFile(file: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl + "/home/uploadCreditPaymentData";
    return this.http.post<any>(urlPath, file);
  }

  uploadBDEMappingCSV(file: any,params): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl +"/home/uploadCsvBdeRetMapping";
    return this.apiService.doPostObservableForCSV(urlPath,file,params); 
  }
  
  uploadPriceMapping(params, file: any): Observable<any> {
    const urlPath = environment.adminUrl + "/file/priceMapping/priceMappingUpload";
    return this.http.post<any>(urlPath, file, {params: params});
  }

  uploadGRNInvoice(file: any,params): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl + "/admin/GRN/uploadImage";
    return this.http.post<any>(urlPath, file,{params:params});
  }

  uploadOrderImage(file: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = this.API_URL + "/admin/uploadOrderImage";
    return this.http.post<any>(urlPath, file);
  }
  
  uploadManualPO(file: any, params): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl + "/v2/admin/generatePO/uploadCsv/v2";
    return this.apiService.doPostObservableForCSV(urlPath, file, params);
  }
  
  uploadCreditBillPayment(file: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl + "/credit/user/uploadCreditBillPaymentData";
    return this.http.post<any>(urlPath, file);
  }

  uploadManualLoantap(file, params): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl + "/credit/user/ManualLoantapPosting";
    return this.apiService.doPostObservableForCSV(urlPath, file, params);
  }

  getUrlForDoc(file: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.BASE_URL + "/home/uploadsSupportImage";
    return this.http.post<any>(urlPath, file);
  }

  uploadAcknowledgementSlip(file, params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/supply/dnPending/uploadAcknowledgement";
    return this.apiService.doPostObservableForCSV(urlPath, file, params);
  }

  uploadPIWithADN(file: any,params): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl + "/admin/PI/uploadPI";
    return this.http.post<any>(urlPath, file,{params:params});
  }
  uploadPIWithOutADN(file: any,params): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl + "/admin/PI/uploadPI/withoutAdnId";
    return this.http.post<any>(urlPath, file,{params:params});
  }

  submitGRN(file: any,params): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl + "/admin/GRN/submitGRN";
    return this.http.post<any>(urlPath, file,{params:params});
  }

  
  uploadInventoryNew(file): Observable<any> {
    const urlPath = environment.adminUrl + "/file/uploadInventory";
    return this.http.post(urlPath, file);
  }
  uploadOrderStatus(file: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.eagleUrl + "/uploadCsv";
    return this.http.post<any>(urlPath, file, { headers, responseType : 'text' as 'json'});
  }
  uploadOrderReassign(file: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.eagleUrl + "/uploadCsvExecutive";
    return this.http.post<any>(urlPath, file, { headers, responseType : 'text' as 'json'});
  }

  uploadWholesaleInventory(file): Observable<any> {
  const urlPath = environment.adminUrl + "/wholesale/inventory/uploadInventory";
  return this.http.post<any>(urlPath, file);
  }
  
  uploadBannerImage(file: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = this.API_URL + "/admin/banner/uploadBannerImage";
    return this.http.post<any>(urlPath, file);
  }


  addAdnUpload(file: any,params): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl +"/admin/uploadADNToSaveoInventory";
    return this.apiService.doPostObservableForCSV(urlPath,file,params); 
  }

  generateWholesaleBillByCSV(file: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl +"/admin/invoice/createManualBillForWholesale";
    return this.apiService.doPostObservableForCSV(urlPath,file,{}); 
  }

  getWholesaleBillUpload(file: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl +"/admin/invoice/addMedicineInRequestFile";
    return this.apiService.doPostObservableForCSV(urlPath,file,{}); 
  }


}
