import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { AdminService } from "src/app/core/services/api/admin.service";
import { HelpDeskService } from "src/app/core/services/api/help-desk.service";
import { PopupService } from "src/app/core/services/popup/popup.service";

@Component({
  selector: "app-global-search",
  templateUrl: "./global-search.component.html",
  styleUrls: ["./global-search.component.scss"],
})
export class GlobalSearchComponent implements OnInit {
  options = [];
  currOption = "";
  searchString = "";
  orderIdData = [];
  invoiceNumberData = [];
  retailerCodeData = [];
  showDetails = true;
  retSuggestions = [];
  timer;
  @Output() openSideNav: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private helpDeskService: HelpDeskService,
    private adminService: AdminService,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {
    this.adminService.getGlobalSearchOptions().subscribe((response) => {
      this.options = response.data;
      if (this.options && this.options.length > 0) {
        this.currOption = this.options[0];
      }
    });
  }

  enterSearch(event) {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  search() {
    if (this.currOption == "Invoice Number") {
      this.searchByInvoiceNumber();
    } else if (this.currOption == "Order Id") {
      this.searchByOrderId();
    } else if (this.currOption == "Retailer Code") {
      this.searchByRetailerCode();
    }
  }

  searchByOrderId() {
    this.popupService
      .openSearchByOrderPopup(this.searchString)
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          this.openSideNav.emit(response);
        }
      });
  }

  searchByInvoiceNumber() {
    this.popupService
      .openSearchByInvoicePopup(this.searchString)
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          this.openSideNav.emit(response);
        }
      });
  }

  searchByRetailerCode() {
    // open paginated popup
    this.popupService
      .openSearchByRetailerPopup(this.searchString)
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          this.openSideNav.emit(response);
        }
      });
  }

  searchFromList(event) {
    clearInterval(this.timer);
    this.timer = setTimeout(() => {
      this.helpDeskService
        .getRetCodes({ searchKeyword: event.target.value.trim() })
        .subscribe(
          (res) => {
            this.retSuggestions = res.data;
          },
          (error) => {
            this.popupService.openError("Something went wrong");
          }
        );
    }, 500);
  }

  optionSelect(event) {
    this.searchString = event.option.value.retailerCode;
    this.search();
  }

  displayFn(data) {
    return data && data.retailerCode ? data.retailerCode : "";
  }
}
