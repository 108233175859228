import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelConfirmMultipleSOCancel } from './model';
import { AdminService } from "src/app/core/services/api/admin.service";
import { PopupService } from "src/app/core/services/popup/popup.service";

@Component({
  selector: 'app-confirm-multiple-so-cancel',
  templateUrl: './confirm-multiple-so-cancel.component.html',
  styleUrls: ['./confirm-multiple-so-cancel.component.scss']
})
export class ConfirmMultipleSoCancelComponent implements OnInit {
  orderNumbers = "";
  constructor(
    public dialogRef: MatDialogRef<ConfirmMultipleSoCancelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelConfirmMultipleSOCancel,
    private adminService: AdminService,
    private popupService: PopupService) { }

  ngOnInit(): void {
    for(let counter = 0; counter < this.data.orders.length; counter++) {
      if (counter == this.data.orders.length - 1) {
        this.orderNumbers = this.orderNumbers + this.data.orders[counter];
      } else {
        this.orderNumbers = this.orderNumbers + this.data.orders[counter] + ", ";
      }
    }
  }

  submit() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
