import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ApiService } from "../../../common/api.service";

@Injectable({
  providedIn: "root"
})
export class ApiDataService {
  API_URL: string = environment.BASE_URL + "/home";
  preprod_url = "https://preprod.saveo.in/home";

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getMedicineInfo(params): Observable<any> {
    const urlPath = this.API_URL + "/v2/getMedicinePage";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getSpecialSchemes(params): Observable<any> {
    const urlPath = this.API_URL + "/v2/getMedicineByCategory";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getRegularSchemes(params): Observable<any> {
    const urlPath = this.API_URL + "/v2/getRegularSchemeMedicines";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getSupportDetails(): Observable<any> {
    const urlPath = this.API_URL + "/getSupport";
    return this.apiService.doGetObservable(urlPath);
  }

  searchMedicineByCategory(params): Observable<any> {
    const urlPath = this.API_URL + "/search/medicineByCategory";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAllMedicinesByCategory(params): Observable<any> {
    const urlPath = this.API_URL + "/v2/getMedicineByCategory";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getActiveSections(): Observable<any> {
    const urlPath = this.API_URL + "/getActiveAppSections";
    return this.apiService.doGetObservable(urlPath);
  }

  getBannerItems(): Observable<any> {
    const urlPath = this.API_URL + "/v2/getBannerObjectList";
    return this.apiService.doGetObservable(urlPath);
  }

  getBanners(params): Observable<any> {
    const urlPath = environment.bannerUrl + "/home/getBanners";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }
  
  searchSpecialDiscounts(params): Observable<any> {
    const urlPath = this.API_URL + "/search/specialOffers";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getSpecialDiscounts(params): Observable<any> {
    const urlPath = this.API_URL + "/getAllSpecialOffers";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAllCompanies(params): Observable<any> {
    const urlPath = this.API_URL + "/company/viewAllCompanies";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getMedsCount(): Observable<any> {
    const urlPath = this.API_URL + "/company/liveMedicines";
    return this.apiService.doGetObservable(urlPath);
  }

  searchMfac(params): Observable<any> {
    const urlPath = this.API_URL + "/search/manufacturerName";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  searchMed(params): Observable<any> {
    const urlPath = this.API_URL + "/search/medicine";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getMeds(params): Observable<any> {
    const urlPath = this.API_URL + "/company/viewMedicinesPaginated";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getCorona(params): Observable<any> {
    const urlPath = this.API_URL + "/v2/getCoronaSafetyMedicines";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getPopularScheme() {
    return this.getPopularMedicine();
  }

  getSpecialScheme(pageno, searchString) {
    return this.getSpecialSchemeItems({ page: pageno, searchString: searchString });
  }

  getRegularScheme(pageno, filterCondition) {
    return this.getRegularSchemeItems({ page: pageno, filterCondition: filterCondition });
  }

  getPopularMedicine(): Observable<any> {
    // const urlPath = this.API_URL + "/getPopularMedicine";
    const urlPath = this.API_URL + "/v2/getMedicineByCategory?category=ALLOPATHY&filterCondition=popular&page=0"
    return this.apiService
      .doGetObservable(urlPath)
      .pipe(map(response => response.data.medicinesResponsesList));
  }

  getSpecialSchemeItems(params): Observable<any> {
    const urlPath = this.API_URL + "/v2/getSpecialSchemeMedicines";
    return this.apiService
      .doGetObservableForQuery(urlPath, params)
      .pipe(map(response => response.data));
  }

  getRegularSchemeItems(params): Observable<any> {
    const urlPath = this.API_URL + "/v2/getRegularSchemeMedicines";
    return this.apiService
      .doGetObservableForQuery(urlPath, params)
      .pipe(map(response => response.data));
  }
  // by matching character
  fetchCompanyNames(params): Observable<any> {
    const urlPath = this.API_URL + "/search/manufacturerName";
    return this.apiService
      .doGetObservableForQuery(urlPath, params)
      .pipe(map(response => response.data));
  }

  // by full name
  getSearchByCompany(params): Observable<any> {
    const urlPath = this.API_URL + "/search/medByCompanyName";
    return this.apiService
      .doGetObservableForQuery(urlPath, params)
      .pipe(map(response => response.data));
  }
  // by character for products
  getSearchByProduct(data, params): Observable<any> {
    const urlPath = this.API_URL + "/search/medicine";
    return this.apiService
      .doPostObservableForQuery(urlPath, data, params)
      .pipe(map(response => response));
  }

  // search for regular scheme items
  SearchRegularScheme(params): Observable<any> {
    const urlPath = this.API_URL + "/search/scheme";
    return this.apiService
      .doGetObservableForQuery(urlPath, params)
      .pipe(map(response => response.data));
  }

  // search for special scheme items
  SearchSpecialScheme(params): Observable<any> {
    const urlPath = this.API_URL + "/search/topScheme";
    return this.apiService
      .doGetObservableForQuery(urlPath, params)
      .pipe(map(response => response.data));
  }

  // get product page for add to cart
  getProductPage(params): Observable<any> {
    const urlPath = this.API_URL + "/getProductPage";
    return this.apiService
      .doPostObservableForQuery(urlPath, null, params)
      .pipe(map(response => response.data));
  }

  getShopCategories(): Observable<any> {
    const urlPath = this.API_URL + "/v2/getShopCategory";
    return this.apiService.doGetObservable(urlPath);
  }

  getStateAndDistrict(params): Observable<any> {
    const urlPath = this.API_URL + "/v2/getStateAndDistrict";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

}
