import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelCollectRetailerOutstanding } from './model';
import { OutstandingService } from "src/app/core/services/api/outstanding.service";
import { PopupService } from "src/app/core/services/popup/popup.service";

@Component({
  selector: 'app-collect-retailer-outstanding',
  templateUrl: './collect-retailer-outstanding.component.html',
  styleUrls: ['./collect-retailer-outstanding.component.scss']
})
export class CollectRetailerOutstandingComponent implements OnInit {

  body = {
    amountInCash: 0,
    collectionDate: '',
    comment: "",
    onlinePaymentCollectionList: [
      {
        notReceived: false,
        onlineAmount: 0,
        referenceNumber: ""
      }
    ],
    outstandingId: ""
  }
  
  emptyOnlineObj = {
    notReceived: false,
    onlineAmount: 0,
    referenceNumber: ''
  };

  constructor(
    private outstandingService: OutstandingService,
    private popupService: PopupService,
    public dialogRef: MatDialogRef<CollectRetailerOutstandingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelCollectRetailerOutstanding,
    ) { }

  ngOnInit(): void {
    console.log(this.data)
  }

  // add online collection field
  addOnlineCollection() {
    this.body.onlinePaymentCollectionList.push(JSON.parse(JSON.stringify(this.emptyOnlineObj)))
  }

  // collect outstanding amount
  collect() {
    this.popupService.openProgress();
    this.body.outstandingId = this.data.invoiceData.id;
    const body = JSON.parse(JSON.stringify(this.body));
    body.onlinePaymentCollectionList = [];
    this.body.onlinePaymentCollectionList.map(onlinePayment => {
      if (onlinePayment.onlineAmount > 0) {
        body.onlinePaymentCollectionList.push(onlinePayment);
      }
    })
    if (this.data.invoiceData.osReason == 'RETAILER') {
      this.outstandingService.collectPaymentForRetailerReason({}, body).subscribe(
        response => {
          this.popupService.closeProgress();
          this.popupService.openSuccess(response.data);
        },
        error => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      )
    } else if (this.data.invoiceData.osReason == 'DELIVERY_EXECUTIVE') {
      this.outstandingService.collectPaymentForDEReason({}, body).subscribe(
        response => {
          this.popupService.closeProgress();
          this.popupService.openSuccess(response.data);
        },
        error => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      )
    }
  }

  // close popup
  closePopup() {
    this.dialogRef.close();
  }
  
  //clearInputState
  clearStateOnFocus(id){
    (document.getElementById(id) as HTMLInputElement).value = (document.getElementById(id) as HTMLInputElement).value == '0' ? '' : (document.getElementById(id) as HTMLInputElement).value;;
  }

  //check State Out Focus for again 0 is value is not changed at all
  clearStateOutFocus(id){
    (document.getElementById(id) as HTMLInputElement).value = (document.getElementById(id) as HTMLInputElement).value.length==0 ? '0' : (document.getElementById(id) as HTMLInputElement).value;
  }



}
