import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { CreditReactComponent } from './CreditReactComponent';
import * as React from 'react';

import * as ReactDOM from 'react-dom';
import { AuthService } from 'src/app/core/services/api/api-auth.service';
import { mergeMap } from 'rxjs/operators';

const containerElementName = 'myReactComponentContainer';

@Component({
  selector: 'app-my-component',
  template: `<span #${containerElementName}></span>`,
  styleUrls: ['./CreditReactComponent.scss','./CreditBills/CreditBillsTable.scss','./CCHeader/CCHeader.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CreditReactComponentWrapperComponent implements OnChanges, OnDestroy, AfterViewInit {
  @ViewChild(containerElementName, {static: false}) containerRef: ElementRef;

  @Input() public counter = 10;
  @Output() public componentClick = new EventEmitter<void>();
  token:String=undefined;
  constructor(private authService: AuthService) {
    this.handleDivClicked = this.handleDivClicked.bind(this);

  }

  public handleDivClicked() {
    if (this.componentClick) {
      this.componentClick.emit();
      this.authService.getAccessToken().subscribe(response => {
      const auth = "Bearer " + response;
      console.log(auth);
      this.token = auth;
      this.render();
    });
    }
  }

  ngOnInit(): void {
   
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.authService.getAccessToken().subscribe(response => {
      const auth = "Bearer " + response;
      console.log(auth);
      this.token = auth;
      this.render();
    });
    
  }

  ngAfterViewInit() {
    this.authService.getAccessToken().subscribe(response => {
      const auth = "Bearer " + response;
      console.log(auth);
      this.token = auth;
      this.render();
    });
  }

  ngOnDestroy() {
    ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
  }

  private render() {
    const {counter} = this;

    ReactDOM.render(<div className={'i-am-classy'}>
      <CreditReactComponent token={this.token}/>
    </div>, this.containerRef.nativeElement);
  }
}
