import { Component, OnInit, Inject,ElementRef, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelNewComposition } from './model';
import { ProductMasterService } from 'src/app/core/services/api/product-master.service';
import { PopupService } from 'src/app/core/services/popup/popup.service';

@Component({
  selector: 'app-add-new-composition',
  templateUrl: './add-new-composition.component.html',
  styleUrls: ['./add-new-composition.component.scss']
})
export class AddNewCompositionComponent implements OnInit {
  saltsCount=1;
  salt='';
  salts={
    data:[],
    searchString:"",
    show:false,
    loader:false,
    error:false,
    values:[{salt:{value:{},error:false},strength:{value:'',error:false},show:false}]
  }
  timer;
  page=0;
  composition=''
  constructor(public dialogRef: MatDialogRef<AddNewCompositionComponent>,private productMaster: ProductMasterService,private popupService:PopupService,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelNewComposition,) { }

  ngOnInit(): void {
  }

  searchSalt(defaultPage,index){
    this.salts.values[index].show=true;
      clearInterval(this.timer);
     
    this.timer=setTimeout(()=>{
      if(defaultPage){
        this.page=0;
      }
      this.salts.loader=true
      const params={
        page:this.page,
        name:this.salts.searchString
      }
      this.productMaster.getSalts(params).subscribe(response=>{

          this.salts.data=response.data.t
          this.salts.loader=false
      })
    },300)
  }

  selectSalt(option,index){
    this.salts.values[index].salt.value=option
    this.salts.values[index].show=false;
    this.composition=''
    let salts='';let strengths=''
    this.salts.values.forEach((x,i)=>{
       i>0 && (salts=salts+" "+` + `+" "+x.salt.value['t'])
       i==0 && (salts=salts+" "+x.salt.value['t'])
       i>0 && (strengths=strengths+" "+` + `+" "+x.strength.value)
       i==0 && (strengths=strengths+" "+x.strength.value)
    })
    this.composition=salts+" "+strengths
  }

  strengthChange(index){
    this.composition=''
    let salts='';let strengths=''
    this.salts.values.forEach((x,i)=>{
       i>0 && (salts=salts+" "+` + `+" "+x.salt.value['t'])
       i==0 && (salts=salts+" "+x.salt.value['t'])
       i>0 && (strengths=strengths+" "+` + `+" "+x.strength.value)
       i==0 && (strengths=strengths+" "+x.strength.value)
    }) 
    this.composition=salts+" "+strengths
  }
  previousPage(index){
    this.page--;
    this.searchSalt(false,index)
  }
  nextPage(index){
    this.page++
    this.searchSalt(false,index)

  }
  pageChange(event,index){
    this.page=event.target.value
    this.searchSalt(false,index)

  }
  removeSalt(index){
    this.salts.values.splice(index,1)
    this.composition=''
    let salts='';let strengths=''
    this.salts.values.forEach((x,i)=>{
       i>0 && (salts=salts+" "+` + `+" "+x.salt.value['t'])
       i==0 && (salts=salts+" "+x.salt.value['t'])
       i>0 && (strengths=strengths+" "+` + `+" "+x.strength.value)
       i==0 && (strengths=strengths+" "+x.strength.value)
    }) 
    this.composition=salts+" "+strengths
  }
  addSalt(){
    this.salts.values.push({salt:{value:{},error:false},strength:{value:'',error:false},show:false})
  }

  createComposition(){
    this.popupService.openProgress()
     const body={
      "bridgeId":null,
      "compositionName": this.composition.trim(),
      "productMappingRequestDTOList": [],
      "status": "CREATE"
    }
    body.productMappingRequestDTOList.push( {
      "dataInteger":null,
      "dataString":this.composition.trim(),
      "entryType": "TEMP",
      "id": "COMP_1",
      "operationType": "CREATE",
      "parentRequestId":null,
      "timeStamp": new Date().getTime(),
      "type": "COMPOSITION"
    })

    let apiHit=true;
    this.salts.values.forEach((item,i)=>{
      if(!item.salt.value['id']){
         item.salt.error=true;
         apiHit=false;
         this.popupService.closeProgress()
      }
       if(item.strength.value.length<1){
        item.strength.error=true
        apiHit=false;
        this.popupService.closeProgress()
      }

      apiHit && body.productMappingRequestDTOList.push( {
          "dataInteger":item.salt.value['id'],
          "dataString":item.strength.value,
          "entryType": "TEMP",
          "id": `SALT_${i}`,
          "operationType": "CREATE",
          "parentRequestId":'COMP_1',
          "timeStamp": new Date().getTime(),
          "type": "SALT"
        })
     
       
    })
  apiHit &&  this.productMaster.createComposition({},body).subscribe(response=>{
      this.popupService.closeProgress()
      this.cancel()
     this.popupService.openSuccess("Composition Submitted for Approve")
    },error=>{
      this.popupService.closeProgress()
      this.popupService.openError(error.error.data.localizedMessage)
    })
  }


  
  cancel() {
    this.dialogRef.close(true);
  }
}
