import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));
platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
  if ('serviceWorker' in navigator && environment.production) {
    navigator.serviceWorker.register('/ngsw-worker.js')
    .then(function(registration) {
      console.log(registration)
      console.log("service worker registered")
    })
    .catch(function(error) {
      console.log("service worker registration failed.")
      console.log("Error is " + error);
    })
  }
}).catch(err => console.log(err));
