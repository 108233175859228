import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule } from "@angular/forms";
import { MatButtonModule,MatIconModule, MatRippleModule, MatSidenavModule, MatToolbarModule } from "@angular/material";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "../../material.module";
import { TbWaterfallModule } from "../../tb/tb-waterfall/tb-waterfall.module";
import { OmsComponent } from './oms.component';



@NgModule({
  declarations: [OmsComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    MatRippleModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    TbWaterfallModule,
    RouterModule,
    FormsModule,
    MatIconModule
  ],
  exports: [OmsComponent]
})
export class OmsModule { }
