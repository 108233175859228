import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogModelAddPickItem } from './model';
import { FormGroup, FormBuilder } from "@angular/forms";
import { FormControl } from "@angular/forms";
import { Observable } from 'rxjs';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { map, startWith } from 'rxjs/operators';
import { WarehouseService } from 'src/app/core/services/api/warehouse.service';

@Component({
  selector: 'app-add-pick-item',
  templateUrl: './add-pick-item.component.html',
  styleUrls: ['./add-pick-item.component.scss']
})
export class AddPickItemComponent implements OnInit {
  _mFormGroup: FormGroup;
  currSearchMed = new FormControl();
  searchSuggestions: Observable<string[]>;
  currMedToAdd;
  searchResult = [];
  enableAddMedicine = false;

  constructor(
    public dialogRef: MatDialogRef<AddPickItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelAddPickItem,
    private formBuilder: FormBuilder,
    private popupService: PopupService,
    private warehouseService: WarehouseService
  ) {}

  ngOnInit():void {
    this.getSearchSuggestions();
  }
  
  getSearchSuggestions() {
    const params = {
      pickId: this.data.pickId,
      keyword: "",
    }
    this.warehouseService.searchPickItem(params).subscribe(
      response => {
        let sample = [];
        response.data.map(med => {
          sample.push(med)
        })

        this.searchResult = sample;
    
        this.searchSuggestions = this.currSearchMed.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filterSuggestions(value))
          );
      },
      error=> {
        let sample = [];

        this.searchResult = sample;
    
        this.searchSuggestions = this.currSearchMed.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filterSuggestions(value))
          );
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  private filterSuggestions(value: string): string[] {
    let sample = [];
    if (value.length > 2) {
      const params = {
        pickId: this.data.pickId,
        keyword: value
      }
      this.warehouseService.searchPickItem(params).subscribe(
        response => {
          response.data.map(med => {
            sample.push(med)
          })
        }
      );
    }
    return sample;
  }

  selectNewMed(e, opt) {
    this.currMedToAdd = opt;
    this.checkAddMedicine();
  }

  checkAddMedicine() {
    if (this.currMedToAdd) {
      this.enableAddMedicine = true;
    } else {
      this.enableAddMedicine = false;
    }
  }

  addMedicine() {
    this.popupService.openProgress();
    this.warehouseService.addPickItem({}, this.currMedToAdd).subscribe(
      response => {
        this.popupService.closeProgress();
        this.popupService.openSuccess("Item added successfully!!!");
        this.dialogRef.close(response.data);
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  cancel() {
    this.dialogRef.close();
  }

}