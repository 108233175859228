import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelConfirmRetrieve } from './model';
import { AdminService } from "src/app/core/services/api/admin.service";
import { PopupService } from "src/app/core/services/popup/popup.service";

@Component({
  selector: 'app-confirm-retrieve',
  templateUrl: './confirm-retrieve.component.html',
  styleUrls: ['./confirm-retrieve.component.scss']
})
export class ConfirmRetrieveComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmRetrieveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelConfirmRetrieve,
    private adminService: AdminService,
    private popupService: PopupService) { }

  ngOnInit(): void {
  }

  submit() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}

