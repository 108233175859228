import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { CartService } from "../services/api/cart.service";
import { UserModel } from "../services/params/user.model";

@Injectable({
  providedIn: "root"
})
export class CartStoreService {
  public showButton = true;

  private loadMoreDataSubject = new BehaviorSubject<boolean>(false);
  loadMoreData$: Observable<any> = this.loadMoreDataSubject.asObservable();

  private cartItemsSubject = new BehaviorSubject<any>([]);
  cartItems$: Observable<any> = this.cartItemsSubject.asObservable();

  private hideCartSubject = new BehaviorSubject<boolean>(false);
  hideCart$: Observable<any> = this.hideCartSubject.asObservable();

  private placedOrderSubject = new BehaviorSubject<any>([]);
  placedOrder$: Observable<any> = this.placedOrderSubject.asObservable();

  private deliverySlotsSubject = new BehaviorSubject<any[]>([]);
  deliverySlots$: Observable<any[]> = this.deliverySlotsSubject.asObservable();

  private deliveryChargeSubject = new BehaviorSubject<any>(0);
  deliveryCharge$: Observable<any> = this.deliveryChargeSubject.asObservable();

  constructor(private cartService: CartService) {}

  initCartItems() {
    const userData: UserModel = JSON.parse(localStorage.getItem("userData"));
    const retailerId = userData.user.id;
    this.cartService.getCartItems(retailerId).subscribe(data => {
      this.cartItemsSubject.next(data);
      if (data.size === 0) {
        this.hideCartSubject.next(true);
      } else {
        this.hideCartSubject.next(false);
      }
    });
  }

  initOrderItems(page) {
    const userData: UserModel = JSON.parse(localStorage.getItem("userData"));
    const retailerId = userData.user.id;
    this.cartService.getPlacedOrders(retailerId, page).subscribe(data => {
      this.placedOrderSubject.next(data);
      this.checkLoadMoreCondition(data);
    });
  }

  loadMoreOrderItems(page) {
    console.log("page is " + page);
    const userData: UserModel = JSON.parse(localStorage.getItem("userData"));
    const retailerId = userData.user.id;
    this.cartService.getPlacedOrders(retailerId, page).subscribe(resp => {
      this.placedOrderSubject.next(
        resp.data.forEach(element => {
        this.placedOrderSubject.getValue().data.push(element);
        })
      );
      this.checkLoadMoreCondition(resp);
    });
  }


  checkLoadMoreCondition(data) {
    if (data.length === 20) {
      this.loadMoreDataSubject.next(true);
    } else {
      this.showButton = false;
      this.loadMoreDataSubject.next(false);
    }
  }

initDeliverySlots() {
    this.cartService.getDeliverySlots().subscribe(data => {
      this.deliverySlotsSubject.next(data);
    });
  }

initDeliveryCharge() {
    const userData: UserModel = JSON.parse(localStorage.getItem("userData"));
    const retailerId = userData.user.id;
    this.cartService.getDeliveryCharge({ retailerId }).subscribe(data => {
      this.deliveryChargeSubject.next(data);
    });
  }
}
