import { Component, OnInit,Input } from '@angular/core';
import {PreviousUrlService} from 'src/app/core/services/previousRoute/previous-url.service';
@Component({
  selector: 'app-medicine-mapping-menu',
  templateUrl: './medicine-mapping-menu.component.html',
  styleUrls: ['./medicine-mapping-menu.component.scss']
})
export class MedicineMappingMenuComponent implements OnInit {
   compositions="60px";
   medicines="0px";
   manufacture="120px";
   category="180px";
   fromValue="0px";
   cautions="235px";
   supergroups="295px";
   subchilds="355px";
    url=window.location.href
   wholesale=false
  constructor(private previousUrlService: PreviousUrlService) { }

  ngOnInit(
    
  ): void {
    let arr=this.url.split('/')[4]
    if(arr==="product-master-wholesale"){
       this.wholesale=true
    }else{
      this.wholesale=false
    }

    let previousRoute=this.previousUrlService.getPreviousUrl().split('/').slice(-1).join("")

    let currentRoute=window.location.pathname.split('/').slice(-1).join("")
    
    if(previousRoute==="medicines"){
      this.fromValue="0px"
      
    }else if(previousRoute==="compositions"){
      this.fromValue="60px";
    }else if(previousRoute==="manufacturer"){
      this.fromValue="120px";
    }else if(previousRoute==="category"){
      this.fromValue="180px";
    }else if(previousRoute==="cautions"){
      this.fromValue="235px";
    }else if(previousRoute==="supergroups"){
      this.fromValue="295px";
    }else if(previousRoute==="subchilds"){
      this.fromValue="355px";
    }else{
      this.fromValue="0px";
    }

    if(currentRoute=="compositions"){
    this.keyframesFunction(this.fromValue,this.compositions);
    document.getElementById("active").style.top="60px";
   }else if(currentRoute=="medicines"){
    this.keyframesFunction(this.fromValue,this.medicines)
    document.getElementById("active").style.top="0px";

   }else if(currentRoute=="manufacturer"){
    this.keyframesFunction(this.fromValue,this.manufacture)
    document.getElementById("active").style.top="120px";

    
   }else if(currentRoute=="category"){
    this.keyframesFunction(this.fromValue,this.category)
    document.getElementById("active").style.top="180px";

    
   }else if(currentRoute=="cautions"){
    this.keyframesFunction(this.fromValue,this.cautions)
    document.getElementById("active").style.top="235px";

    
   }else if(currentRoute=="supergroups"){
    this.keyframesFunction(this.fromValue,this.supergroups)
    document.getElementById("active").style.top="295px";

    
   }else if(currentRoute=="subchilds"){
    this.keyframesFunction(this.fromValue,this.subchilds)
    document.getElementById("active").style.top="355px";

    
   }
   
   
  }

  keyframesFunction(fromValue,toPosition){
    var cssAnimation = document.createElement('style');
cssAnimation.type = 'text/css';
var rules = document.createTextNode('@-webkit-keyframes sideActive {'+
`from {top:${fromValue};}`+
`to { top:${toPosition}; }`+
'}');
var top=document.createTextNode(`.active {top:${toPosition}}`)
cssAnimation.appendChild(rules);
cssAnimation.appendChild(top);
document.getElementsByTagName("head")[0].appendChild(cssAnimation);
  }
   
}
