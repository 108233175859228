import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelConfirmDisablePlatform } from './model';

@Component({
  selector: 'app-confirm-disable-platform',
  templateUrl: './confirm-disable-platform.component.html',
  styleUrls: ['./confirm-disable-platform.component.scss']
})
export class ConfirmDisablePlatformComponent implements OnInit {
  comment = "";

  constructor(
    public dialogRef: MatDialogRef<ConfirmDisablePlatformComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelConfirmDisablePlatform) { }

  ngOnInit(): void {
  }

  submit() {
    this.dialogRef.close({comment: this.comment});
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
