import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { AuthService } from "src/app/core/services/api/api-auth.service";
import { Component, OnInit, ViewChild, Inject, ElementRef } from "@angular/core";
import { MatSidenav } from "@angular/material";
import { Subscription } from "rxjs";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { SideNavEventHandlerService } from "src/app/common/event/sidenav-event-handler.service";
import { ScrollEventService } from "src/app/common/event/scroll-event.service";
import { AppDataService } from "src/app/app-data/app-data.service";
import { I } from "@angular/cdk/keycodes";

@Component({
  selector: 'app-oms',
  templateUrl: './oms.component.html',
  styleUrls: ['./oms.component.scss']
})
export class OmsComponent implements OnInit {
  _drawerOpened: boolean = false;
  _slideMode = "over";
  _mNavMode = "side";
  _mNavOpen = false;
  sideMenu={active:false,menu:'',subMenu:''}
  subscriptions: Subscription[] = [];
  mCurrentHeading = 1;
  
  constructor(
    private authService: AuthService,
    private appDataService: AppDataService,
    private location: Location,
    private router: Router,
    private scrollEventService: ScrollEventService,
    private media: MediaObserver,
    private sideNavEventHandlerService: SideNavEventHandlerService,
    @Inject(DOCUMENT) private document: any) {
      this.media.media$.subscribe((mediaChange: MediaChange) => {
        this._mNavMode = this.getMode(mediaChange);
        // console.log("sidena3:", this._mNavMode);
        this.updateSlideMode(mediaChange);
        // this.opened = this.getOpened(mediaChange);
        this.router.events.subscribe(val => {
          if (this.router.url.includes("order-requests")) {
            this.sideMenu.menu = 'orderRequests'
          } else if (this.router.url.includes("past-orders")) {
            this.sideMenu.menu = 'pastOrders'
          }else if (this.router.url.includes("acceptedOrders")) {
            this.sideMenu.menu = 'ordersInProgress'
            this.sideMenu.subMenu='accepted_orders'
          }else if (this.router.url.includes("readyToShip")) {
            this.sideMenu.menu = 'ordersInProgress'
            this.sideMenu.subMenu='ready_to_ship'
          }
          if (this.location.path() != "") {
            const routePath = this.location.path();
  
            return;
          } else {
          }

         
        });
      });
    }

    @ViewChild("mainDrawer", { static: true }) sidenav: MatSidenav;
  
    @ViewChild("contentScroller", { static: true }) contentScroller: ElementRef;

    logout() {
      this.authService.logout().subscribe(
        value => {
          // console.log("logout: ", value);
          this.router.navigate(["/authenticate"]);
          this.appDataService.setData("IsUserExist", "false", "local");
        },
        error => {
          console.log("error", error);
        }
      );
    }

    ngOnInit() {
      if (this.router.url.includes("order-requests")) {
        this.sideMenu.menu = 'orderRequests'
      } else if (this.router.url.includes("past-orders")) {
        this.sideMenu.menu = 'pastOrders'
      }else if (this.router.url.includes("acceptedOrders")) {
        this.sideMenu.menu = 'ordersInProgress'
        this.sideMenu.subMenu='accepted_orders'
      }else if (this.router.url.includes("readyToShip")) {
        this.sideMenu.menu = 'ordersInProgress'
        this.sideMenu.subMenu='ready_to_ship'
      }
      this.subscriptions[
        this.subscriptions.length
      ] = this.scrollEventService
        .getActionMainEmitter()
        .subscribe((heading: Number) => {
          this.mCurrentHeading = heading as number;
          this.scrollToHeader(heading);
        });
    }
    
    navigateTo(heading: Number) {
      this.scrollEventService.emitHeadingEvent(heading);
      // this.closeDrawer()
    }
    
    scrollToHeader(heading: Number) {
      const headerClass: String = this.getHeaderClass(heading);
    }
  
    getHeaderClass(heading: Number): String {
      // switch (heading) {
      //   case ScrollEventService.SUB_HEADING.home:
      //     return '#home'
  
      //   default:
      //     break;
      // }
      return "";
    }
  
    ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  
    private updateSlideMode(mediaChange: MediaChange) {
      if (this.media.isActive("lt-md")) {
        this._slideMode = "over";
        this._drawerOpened = false;
      } else {
        this._slideMode = "over";
        this._drawerOpened = false;
      }
    }
  
    onActionLaunch(event) {
      this._mNavOpen = true;
      const isOpened = this.sidenav.opened;
      this.sidenav.toggle();
      this.sideNavEventHandlerService.emitMainEvent(!isOpened);
      // console.log(event);
    }
  
    onActivate(e, outlet) {
      this.contentScroller.nativeElement.scrollTop = 0;
      // this.myScrollContainer.scrollable.getElementRef().nativeElement.scrollTop = 0;
    }
  
    getMode(mediaChange: MediaChange): string {
      if (this.media.isActive("lt-md")) {
        // this._mNavOpen = false;
        return "";
      } else {
        // this._mNavOpen = false;
        return "side";
      }
    }
  
    _backdropToggle(type) {
      if (type === "backdrop") {
        this._drawerOpened = !this._drawerOpened;
      }
    }
  
    _toggleSideNav(sideNav) {
      // console.log(sideNav.opened);
      // if (sideNav.opened == false) {
      this._drawerOpened = !this._drawerOpened;
      // }
    }


    toggleMenu(menu){
      this.sideMenu.active=true
      this.sideMenu.menu=menu
    }
    toggleSubMenu(subMenu){
      this.sideMenu.subMenu=subMenu
    }



}
