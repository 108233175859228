import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FinanceDisputeDialogModel } from './model';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { FinanceService } from 'src/app/core/services/api/finance.service';

@Component({
  selector: 'app-finance-dispute',
  templateUrl: './finance-dispute.component.html',
  styleUrls: ['./finance-dispute.component.scss']
})
export class FinanceDisputeComponent implements OnInit {
  remarks = "";
  undoDisputeBody = {
    disputeAmount: 0,
    disputedBy: null,
    id: null,
    remarks: this.remarks,
    markDispute: 0
  }
  disableFinance = false;
  disableOps = false;

  constructor(
    public dialogRef: MatDialogRef<FinanceDisputeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FinanceDisputeDialogModel,
    private financeService: FinanceService,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.undoDisputeBody.disputeAmount = this.data.disputedAmount;
    if (this.data.disputedByFinance) {
      this.undoDisputeBody.disputedBy = 'FINANCE';
    } else if (this.data.disputedByOps) {
      this.undoDisputeBody.disputedBy = 'OPERATIONS';
    }
    this.undoDisputeBody.id = this.data.id;
  }
  
  changeDisputedByOps() {
    this.disableFinance = true;
  }
  
  changeDisputedByFinance() {
    this.disableOps = true;
  }

  update() {
    this.popupService.openProgress();
    if (this.disableOps) {
      this.undoDisputeBody.disputedBy = 'FINANCE';
      if (this.data.disputedByFinance) {
        this.undoDisputeBody.markDispute = 1;
      } else {
        this.undoDisputeBody.markDispute = 0;
      }
    } else if (this.disableFinance) {
      this.undoDisputeBody.disputedBy = 'OPERATIONS';
      if (this.data.disputedByOps) {
        this.undoDisputeBody.markDispute = 1;
      } else {
        this.undoDisputeBody.markDispute = 0;
      }
    }
    if (this.data.disputedByOps && this.data.disputedAmount < 0) {
      this.popupService.closeProgress();
      this.popupService.openError("Amount can't be less than 0.");
    } else {
      const params = {};
      this.undoDisputeBody.disputeAmount = this.data.disputedAmount;
      this.undoDisputeBody.id = this.data.id;
      this.undoDisputeBody.remarks = this.remarks;
      
      this.financeService.updateDisputedBy(params, this.undoDisputeBody).subscribe(
        response => {
          this.popupService.closeProgress();
          this.popupService.openSuccess(response.data);
          this.dialogRef.close("Done");
        },
        error => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      )
    }
  }

  close() {
    this.dialogRef.close();
  }

}
