import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { DialogModelLiveSI } from "./model";
import { SupplyService } from "src/app/core/services/api/supply.service";
import { PopupService } from "src/app/core/services/popup/popup.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { FormControl } from "@angular/forms";
import { AdminService } from "src/app/core/services/api/admin.service";

@Component({
  selector: "app-live-si-order",
  templateUrl: "./live-si-order.component.html",
  styleUrls: ["./live-si-order.component.scss"],
})
export class LiveSiOrderComponent implements OnInit {
  quantityControl = new FormControl();
  batchIdControl = new FormControl();
  expiryDateControl = new FormControl();
  mrpControl = new FormControl();
  ptrControl = new FormControl();
  packagingControl = new FormControl();
  itemNameControl = new FormControl();
  manufacturerNameControl = new FormControl();
  schemeDiscountControl = new FormControl();
  productDiscountControl = new FormControl();
  additionalDiscountControl = new FormControl();
  totalDiscountControl = new FormControl();
  taxableValueControl = new FormControl();
  hsnCodeControl = new FormControl();
  taxControl = new FormControl();
  sgstControl = new FormControl();
  cgstControl = new FormControl();
  igstControl = new FormControl();
  netValueControl = new FormControl();
  page = 0;
  orderDetails = [];
  reasons = {
    itemName: {
      data: [],
      responseData: [],
      show: false,
    },
    manufacturerName: {
      data: [],
      responseData: [],
      show: false,
    },
    schemeDiscount: {
      data: [],
      responseData: [],
      show: false,
    },
    productDiscount: {
      data: [],
      responseData: [],
      show: false,
    },
    additionalDiscount: {
      data: [],
      responseData: [],
      show: false,
    },
    totalDiscount: {
      data: [],
      responseData: [],
      show: false,
    },
    taxableValue: {
      data: [],
      responseData: [],
      show: false,
    },
    hsnCode: {
      data: [],
      responseData: [],
      show: false,
    },
    tax: {
      data: [],
      responseData: [],
      show: false,
    },
    sgst: {
      data: [],
      responseData: [],
      show: false,
    },
    cgst: {
      data: [],
      responseData: [],
      show: false,
    },
    igst: {
      data: [],
      responseData: [],
      show: false,
    },
    netValue: {
      data: [],
      responseData: [],
      show: false,
    },
    batchId: {
      data: [],
      responseData: [],
      show: false,
    },
    expiryDate: {
      data: [],
      responseData: [],
      show: false,
    },
    mrp: {
      data: [],
      responseData: [],
      show: false,
    },
    packaging: {
      data: [],
      responseData: [],
      show: false,
    },
    quantity: {
      data: [],
      responseData: [],
      show: false,
    },
    ptr: {
      data: [],
      responseData: [],
      show: false,
    },
  };
  edit = { status: false, med: "" };

  invoiceData = null;
  invoiceDataReplica = null;
  timer;
  constructor(
    private supplyService: SupplyService,
    private popupService: PopupService,
    public dialogRef: MatDialogRef<LiveSiOrderComponent>,
    private adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelLiveSI
  ) {}

  ngOnInit(): void {
    this.getOrderDetails();
  }

  cancel() {
    this.popupService.closeProgress();
    this.dialogRef.close(null);
    this.popupService.closeProgress();
  }

  previousPage() {
    this.page--;
  }
  nextPage() {
    this.page++;
  }
  getOrderDetails() {
    this.invoiceData = this.data.data[0];
    //  this.invoiceData.buyerInvoiceDetailResponseList=[];
    //  this.invoiceData.buyerInvoiceDetailBounceResponseList=[];

    this.data.data.forEach((order, i) => {
      if (i > 0) {
        this.invoiceData.buyerInvoiceDetailResponseList.push(
          ...order.buyerInvoiceDetailResponseList
        );
        this.invoiceData.buyerInvoiceDetailBounceResponseList.push(
          ...order.buyerInvoiceDetailBounceResponseList
        );
      }
    });

    this.invoiceDataReplica = JSON.parse(JSON.stringify(this.invoiceData));
  }

  itemSearch(event) {
    clearInterval(this.timer);
    this.timer = setTimeout(() => {
      this.page = 0;
      if (event.target.value?.length) {
        this.invoiceData.buyerInvoiceDetailResponseList =
          this.invoiceDataReplica.buyerInvoiceDetailResponseList.filter(
            (item) =>
              item.itemName.value
                .toLowerCase()
                .includes(event.target.value.toLowerCase())
          );
      } else {
        this.invoiceData.buyerInvoiceDetailResponseList = [
          ...this.invoiceDataReplica.buyerInvoiceDetailResponseList,
        ];
      }
    }, 500);
  }

  getOrderDetailsFromApi() {
    this.popupService.openProgress();
    const params = {
      retrieveOrderId: this.invoiceData.orderId,
    };
    this.supplyService.getBuyerInvoiceDetail(params).subscribe(
      (response) => {
        this.invoiceData = response.data[0];
        response.data.forEach((order, i) => {
          if (i > 0) {
            this.invoiceData.buyerInvoiceDetailResponseList.push(
              ...order.buyerInvoiceDetailResponseList
            );
            this.invoiceData.buyerInvoiceDetailBounceResponseList.push(
              ...order.buyerInvoiceDetailBounceResponseList
            );
          }
        });

        this.invoiceDataReplica = JSON.parse(JSON.stringify(this.invoiceData));

        this.popupService.closeProgress();
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }
  // edit order item

  editOrderItem(uniquecode, item) {
    this.edit = { status: true, med: uniquecode };
    Object.keys(item).map((key) => {
      if (typeof item[key] == "object") {
        if (item[key] !== null) {
          item[key].oldValue = item[key].value;
        } else {
          item[key] = {
            oldValue: null,
            value: null,
          };
        }
      }
    });
  }

  // med input //
  medInputChange(event, index) {
    this.invoiceData.buyerInvoiceDetailResponseList[index];
  }
  // save order item

  saveOrderItemTest(itemData) {
    this.popupService.openProgress();

    let fields = Object.keys(this.reasons);
    for (let i = 0; i < fields.length; i++) {
      if (this.reasons[fields[i]].show) {
        // if(this[fields[i]+'Control']['value']===null || this[fields[i]+'Control']['value']===''){
        //   this.popupService.closeProgress();
        //   return this.popupService.openError(`Please give reason for editing medicine ${fields[i]}`)
        // }
        this.reasonSelect(fields[i], itemData);
      }
    }
    this.edit = { status: true, med: "" };

    itemData.edited = true;
    itemData.orderRetrieveId = {
      oldValue: this.invoiceData.orderId,
      value: this.invoiceData.orderId,
    };

    let validPrice = true;
    let errorItem = null;
    let item = itemData;
    if (item.mrp.value < item.ptr.value) {
      validPrice = false;
      errorItem = item;
    }
    if (validPrice) {
      this.adminService.updateBillTest2([item]).subscribe(
        (response) => {
          this.getOrderDetailsFromApi();
          this.popupService.closeProgress();
          this.popupService.openSuccess(response.data);
        },
        (error) => {
          let item = itemData;
          Object.keys(item).map((key) => {
            if (typeof item[key] == "object") {
              if (item[key] !== null) {
                item[key].oldValue = item[key].value;
              } else {
                item[key]["oldValue"] = null;
                item[key]["value"] = null;
              }
            }
          });

          this.getOrderDetailsFromApi();
          this.popupService.closeProgress();
          setTimeout(() => {
            this.popupService.openError(error.error.error.error);
          }, 1000);
        }
      );
      Object.keys(this.reasons).forEach((key) => {
        this.reasons[key].show = false;
        this[key + "Control"].setValue(null);
      });
    } else {
      this.popupService.closeProgress();
      this.popupService.openError(
        `Invalid Price. PTR (${errorItem.ptr.value.toFixed(
          2
        )}) cant be greater than MRP (${errorItem.mrp.value.toFixed(2)}). in ${
          errorItem.itemName.value
        }`
      );
      itemData.ptr.value = itemData.ptr.oldValue;
      itemData.mrp.value = itemData.mrp.oldValue;
    }
  }

  //  open add med dialog on click add medicine
  openAddMedDialog(test) {
    this.popupService
      .openAddMedicine({
        id: this.data.data[0].orderId,
        test: test,
        billingKey: this.data.data[0].billingKeyList,
        billType: this.data.data[0].billType,
      })
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          this.getOrderDetailsFromApi();
        }
      });
  }

  deleteRequestMedicine(med) {
    const confirmString = "Do you want to delete" + med.medName + "request ?";
    this.popupService
      .openConfirmReasonPopup(confirmString)
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          this.popupService.openProgress();
          const params = {
            requestId: med.id,
          };
          const body = {};

          this.supplyService.deleteRequestMedicine(params, body).subscribe(
            (response) => {
              this.getOrderDetailsFromApi();
              this.popupService.closeProgress();
              this.popupService.openSuccess(response.data);
            },
            (error) => {
              this.popupService.closeProgress();
              this.popupService.openError(error.error.error.error);
            }
          );
        }
      });
  }

  //  delete medicine form bill
  deleteMedicine(item, medName) {
    const confirmString = {
      string: "Do you want to delete" + medName + "?",
      reasons: [],
    };
    this.popupService
      .openConfirmReasonPopup(confirmString)
      .afterClosed()
      .subscribe((response) => {
        if (response.status) {
          this.popupService.openProgress();
          const params = {};
          const body = item;
          body.reason = response.reason;
          this.supplyService.deleteMedicineFromBill(params, body).subscribe(
            (response) => {
              this.getOrderDetailsFromApi();
              this.popupService.closeProgress();
              this.popupService.openSuccess(response.data);
            },
            (error) => {
              this.popupService.closeProgress();
              this.popupService.openError(error.error.error.error);
            }
          );
        }
      });
  }

  getReasons(type) {
    this.popupService.openProgress();
    const params = {
      fieldEdited: type,
    };
    this.supplyService.getBillUpdateReasons(params).subscribe(
      (response) => {
        this.popupService.closeProgress();
        this.reasons[type].data = response.data;
        this.reasons[type].responseData = response.data;
        this.reasons[type].show = true;
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  reasonSelect(type, item) {
    // // this.invoiceData.buyerInvoiceDetailResponseList[index][type].reason=event.value;
    let control = type + "Control";
    let value = this[control]["value"];
    let selectedValue;

    selectedValue = this.reasons[type].responseData.filter(
      (data) => data.dropdownReasons === value
    );
    if (selectedValue.length) {
      item[type].reason = selectedValue[0].dropdownReasons;
    } else {
      item[type].reason = value;
    }
  }

  reasonSearch(type, event) {
    this.reasons[type].data = this.reasons[type].responseData.filter((data) =>
      data.dropdownReasons
        .toLowerCase()
        .includes(event.target.value.toLowerCase())
    );
  }
}
