import * as React from 'react';
import { Divider } from 'antd';
import { Menu, Button } from 'antd';
import {
  AppstoreOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
  MailOutlined,
} from '@ant-design/icons';

const { SubMenu } = Menu;
export interface IAppProps {
    setSubmenuSelected:any,
    setLeftbar:any,
    token:String
}

export interface MainState {
    collapsed:boolean
  }

export default class App extends React.Component<IAppProps,MainState> {
    constructor(props: IAppProps | Readonly<IAppProps>){
        super(props);
        this.state = {
            collapsed: false,
          };
    }
    
    toggleCollapsed = () => {
        this.setState({
          collapsed: !this.state.collapsed,
        },()=>this.props.setLeftbar(this.state.collapsed));
      };
  public render() {
    return (
    //   <div>
    //      <Divider orientation="left">Invoices</Divider>
    //     <p style={{paddingLeft:"20px"}}>
    //     All Invoices
    //     </p>
    //     <p style={{paddingLeft:"20px"}}>
    //     Payment Initiated Invoices
    //     </p>
    //     <Divider orientation="left">Signed Slips</Divider>
    //     <p style={{paddingLeft:"20px"}}>
    //     All Signed slips
    //     </p>
    //   </div>

        <div style={{ width: "inherit", }}>
          {/* <Button type="primary" onClick={this.toggleCollapsed} style={{ marginBottom: 16 }}>
            {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
          </Button> */}
          <Menu
            defaultSelectedKeys={['all']}
            defaultOpenKeys={['invoices']}
            mode="inline"
            // theme="dark"
            inlineCollapsed={this.state.collapsed}
          >
            {/* <Menu.Item key="1" icon={<PieChartOutlined />}>
              Option 1
            </Menu.Item>
            <Menu.Item key="2" icon={<DesktopOutlined />}>
              Option 2
            </Menu.Item>
            <Menu.Item key="3" icon={<ContainerOutlined />}>
              Option 3
            </Menu.Item> */}
            <SubMenu style={{fontWeight:"bold"}} key="invoices" icon={<MailOutlined style={{color:"#4b4495"}} />} title="Invoices">
              <Menu.Item onClick={()=>this.props.setSubmenuSelected("all")} style={{fontWeight:"normal"}} key="all">All</Menu.Item>
              {/* <Menu.Item onClick={()=>this.props.setSubmenuSelected("cheque")} style={{fontWeight:"normal"}} key="cheque">Cheque Payment</Menu.Item> */}
              {/* <Menu.Item onClick={()=>this.props.setSubmenuSelected("postDatedCheque")} style={{fontWeight:"normal"}} key="postDatedCheque">Post Date Cheques</Menu.Item> */}
              {/* <Menu.Item onClick={()=>this.props.setSubmenuSelected("creditUserDetails")} style={{fontWeight:"normal"}} key="creditUserDetails">Credit User Details</Menu.Item> */}
              {/* <Menu.Item key="7"></Menu.Item>
              <Menu.Item key="8">Option 8</Menu.Item> */}
            </SubMenu>
            <SubMenu style={{fontWeight:"bold"}} key="creditusers" icon={<MailOutlined style={{color:"#4b4495"}} />} title="Credit User Details">
              <Menu.Item onClick={()=>this.props.setSubmenuSelected("livelimits")} style={{fontWeight:"normal"}} key="livelimits">Live Limits</Menu.Item>
              {/* <Menu.Item key="7"></Menu.Item>
              <Menu.Item key="8">Option 8</Menu.Item> */}
            </SubMenu>
            {/* <SubMenu key="sub2" icon={<AppstoreOutlined />} title="Navigation Two">
              <Menu.Item key="9">Option 9</Menu.Item>
              <Menu.Item key="10">Option 10</Menu.Item>
              <SubMenu key="sub3" title="Submenu">
                <Menu.Item key="11">Option 11</Menu.Item>
                <Menu.Item key="12">Option 12</Menu.Item>
              </SubMenu>
            </SubMenu> */}
          </Menu>
        </div>
    );
  }
}
