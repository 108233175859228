import { AppDataService } from "./app-data/app-data.service";
import { ApplicationRef, Component } from "@angular/core";
import { MatIconRegistry } from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import { RoutingStateService } from "./core/services/routingState/routingState.service";
import { first } from "rxjs/operators";
import { SwUpdate } from "@angular/service-worker";
import { ConnectionService } from "ng-connection-service";
import { PopupService } from "src/app/core/services/popup/popup.service";
import { concat, interval } from "rxjs";
import { MixpanelService } from "./core/services/event/mixpanel.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  showUpdate=false
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private appDataService: AppDataService,
    private routingState: RoutingStateService,
    private update: SwUpdate,
    private connectionService: ConnectionService,
    private popupService: PopupService,
    private appRef: ApplicationRef,
    public popService: PopupService,
    private mixPanel: MixpanelService,
  ) {
    this.checkInternetConnection();
    this.updateApp();
    this.checkUpdate();
    localStorage.removeItem("pdfCountPast")
    localStorage.removeItem("pdfLengthPast")
    localStorage.removeItem("pdfCountLive")
    localStorage.removeItem("pdfLengthLive")
    localStorage.removeItem("pdfCountBulk")
    localStorage.removeItem("pdfLengthBulk")
    if (!this.appDataService.getData("IsUserExist")) {
      this.appDataService.setData("IsUserExist", "false", "local");
    }
    this.routingState.loadRouting();
    this.addSvgIcons(iconRegistry, sanitizer);
    this.mixPanel.init() 
  }

  // Check Internet Conenction
  checkInternetConnection() {
    this.connectionService.monitor().subscribe((response) => {
      if (!response) {
        this.popupService.openConnectionLost();
      } else {
        this.popupService.closeConnectionLost();
        this.popupService.openSuccess("Internet Is Back.");
      }
    });
  }

  updateApp() {
    if (!this.update.isEnabled) {
      console.log("SW not enabled.");
      return;
    }

    this.update.available.subscribe((e) => {
      // console.log(e);
       this.showUpdate=true
    });

    this.update.activated.subscribe((e) => {
      // console.log("up-to-date");
      // console.log("previous: " + e.previous + " current: " + e.current);
    });
  }

  updateByClick(){
    this.update.activateUpdate().then(() => location.reload());
    this.showUpdate=false
  }

  checkUpdate() {
    const appIsStable$ = this.appRef.isStable.pipe(
      first((isStable) => isStable === true)
    );
    const everySixHours$ = interval(1 * 60 * 1000); //Every 30mins check for update
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.subscribe(
      () => this.update.checkForUpdate()
      .then(() => console.log("checked for update "))
    );
    console.log("update checked");
  }

  onActivate(e, outlet) {
    // console.log("Acc");

    outlet.scrollTop = 0;
  }

  addSvgIcons(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry
      .addSvgIcon(
        "send",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/send.svg")
      )
      .addSvgIcon(
        "line",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/line.svg")
      )
      .addSvgIcon(
        "filter",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/filter.svg")
      )
      .addSvgIcon(
        "tick",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/tick.svg")
      )
      .addSvgIcon(
        "up",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/up.svg")
      )
      .addSvgIcon(
        "down",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/down.svg")
      )
      .addSvgIcon(
        "menu",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/menu.svg")
      )
      .addSvgIcon(
        "india",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/india.svg")
      )
      .addSvgIcon(
        "search",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/search.svg")
      )
      .addSvgIcon(
        "profile",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/profile.svg")
      )
      .addSvgIcon(
        "support",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/support.svg")
      )
      .addSvgIcon(
        "cart",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/cart.svg")
      )
      .addSvgIcon(
        "mail",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/mail.svg")
      )
      .addSvgIcon(
        "home",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/home.svg")
      )
      .addSvgIcon(
        "past-order",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/past-order.svg")
      )
      .addSvgIcon(
        "close",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/close.svg")
      )
      .addSvgIcon(
        "regular-Schemes",
        sanitizer.bypassSecurityTrustResourceUrl(
          "assets/svg/regularSchemes.svg"
        )
      )
      .addSvgIcon(
        "special-Schemes",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/specialOffers.svg")
      )
      .addSvgIcon(
        "company-section",
        sanitizer.bypassSecurityTrustResourceUrl(
          "assets/svg/company-section.svg"
        )
      )
      .addSvgIcon(
        "allopathy",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/Allopathy.svg")
      )
      .addSvgIcon(
        "ayurvedic",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/Ayurvedic.svg")
      )
      .addSvgIcon(
        "covid-essentials",
        sanitizer.bypassSecurityTrustResourceUrl(
          "assets/svg/COVID-essentials.svg"
        )
      )
      .addSvgIcon(
        "generics",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/Generics.svg")
      )
      .addSvgIcon(
        "exclusive",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/Exclusive.svg")
      )
      .addSvgIcon(
        "cosmetics",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/Cosmetics.svg")
      )
      .addSvgIcon(
        "otc",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/OTC.svg")
      )
      .addSvgIcon(
        "surgicals",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/Surgicals.svg")
      )
      .addSvgIcon(
        "cancel",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/cancel.svg")
      )
      .addSvgIcon(
        "delivered",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/delivered.svg")
      )
      .addSvgIcon(
        "loading",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/loading.svg")
      )
      .addSvgIcon(
        "phone",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/phone.svg")
      )
      .addSvgIcon(
        "check",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/check.svg")
      )
      .addSvgIcon(
        "edit",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/edit.svg")
      )
      .addSvgIcon(
        "cross",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/cross.svg")
      )
      .addSvgIcon(
        "incorrect",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/incorrect.svg")
      )
      .addSvgIcon(
        "pin",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/pin.svg")
      )
      .addSvgIcon(
        "back",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/back.svg")
      )
      .addSvgIcon(
        "delete",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/delete.svg")
      )
      .addSvgIcon(
        "stats_1",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/stats_1.svg")
      )
      .addSvgIcon(
        "stats_2",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/stats_2.svg")
      )
      .addSvgIcon(
        "stats_3",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/stats_3.svg")
      )
      .addSvgIcon(
        "stats_4",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/stats_4.svg")
      )
      .addSvgIcon(
        "tele",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/tele.svg")
      )
      .addSvgIcon(
        "clock",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/clock.svg")
      )
      .addSvgIcon(
        "med_search",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/med_search.svg")
      )
      .addSvgIcon(
        "medical",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/medical.svg")
      )
      .addSvgIcon(
        "emptyCart",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/emptyCart.svg")
      )
      .addSvgIcon(
        "map",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/map.svg")
      )
      .addSvgIcon(
        "add",
        sanitizer.bypassSecurityTrustResourceUrl("assets/svg/add.svg")
      );
  }
}
