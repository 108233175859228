import { CommonModule } from "@angular/common";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "src/app/material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatIconModule, MatRippleModule } from "@angular/material";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { CommonSearchContainerComponent } from './common-search-container.component';



@NgModule({
  declarations: [CommonSearchContainerComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    MatIconModule,
    MatRippleModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [CommonSearchContainerComponent]
})
export class CommonSearchContainerModule {}
