import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ApiService } from "../../../common/api.service";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  API_URL: string = environment.adminUrl + "/admin";
  preprod_url = "https://preprod.saveo.in/admin";

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getOrderRetrieveSlotsByLocation(params): Observable<any> {
    const urlPath = this.API_URL + "/getAllAvailableSlotsByLocation";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getPushNotificationLogs(params): Observable<any> {
    const urlPath = this.API_URL + "/getAllScheduledNotification";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }
  
  getRetailerCodesOnBehalf(params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/communityLeader/v2/getRetailerCodesOnBehalf";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getBillUpdateReasons(): Observable<any> {
    const urlPath = this.API_URL + "/invoice/getAllReasonsForEditBill";
    return this.apiService.doGetObservable(urlPath);
  }

  getExceptionMedicines(): Observable<any> {
    const urlPath = this.API_URL + "/getAllExceptionMedicine";
    return this.apiService.doGetObservable(urlPath);
  }

  updateExceptionMedicines(params, body): Observable<any> {
    const urlPath = this.API_URL + "/updateExceptionMedicine";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getDistributorSlots(): Observable<any> {
    const urlPath = this.API_URL + "/getAllRegularSlots";
    return this.apiService.doGetObservable(urlPath);
  }

  getSubCategories(): Observable<any> {
    const urlPath = this.API_URL + "/getInventorySubSection";
    return this.apiService.doGetObservable(urlPath);
  }

  getWMMOptions(): Observable<any> {
    const urlPath = this.API_URL + "/getOptionsInWronglyMappedMedicine";
    return this.apiService.doGetObservable(urlPath);
  }

  mergeWronglyMappedMedicines(params, body): Observable<any> {
    const urlPath = this.API_URL + "/mergeWronglyMappedMedicine";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getPromotionSchedule(params): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/getScheduledPromationNew";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getScheduledPromotion(params): Observable<any> {
    const urlPath = this.API_URL + "/getScheduledPromation";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getDaysList(): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/daysOfWeek";
    return this.apiService.doGetObservable(urlPath);
  }

  updatePromotionSchedule(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/updateScheduledPromation";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  addScheduleToPromotion(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/addScheduleToPromation";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  deletePromotionSchedule(params): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/deleteScheduledPromation";
    return this.apiService.doDeleteForQuery(urlPath, params);
  }

  getWronglyMappedMedicines(params): Observable<any> {
    const urlPath = this.API_URL + "/search/medicne/wronglyMapped";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateWronglyMappedMedicines(params): Observable<any> {
    const urlPath = this.API_URL + "/update/wronglyMappedMedicine";
    return this.apiService.doUpdateForQuery(urlPath, params);
  }

  getDeals(params): Observable<any> {
    const urlPath = environment.adminUrl + "/dealDisable/getDealProducts";
    return this.apiService.doUpdateForQuery(urlPath, params);
  }

  closeDeal(params): Observable<any> {
    const urlPath = environment.adminUrl + "/dealDisable/disableDeal";
    return this.apiService.doUpdateForQuery(urlPath, params);
  }

  searchMedicine(params, body): Observable<any> {
    const urlPath = this.API_URL + "/search/all/medicine";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getBatchInfo(params): Observable<any> {
    const urlPath = this.API_URL + "/stock/getBatchInfosByMedicineId";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAllSalesPersons(): Observable<any> {
    const urlPath = this.API_URL + "/communityLeader/getAllCommunityLeaders";
    return this.apiService.doGetObservable(urlPath);
  }

  getSalesPersonRetailers(params): Observable<any> {
    const urlPath = this.API_URL + "/getRetailerDetailOfSalesPerson";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }
  getTimeSlots(): Observable<any> {
    const urlPath = this.API_URL + "/invoie/getAvailableInvoiceSlot";
    return this.apiService.doGetObservable(urlPath);
  }

  getAllDistributorCategories(): Observable<any> {
    const urlPath = this.API_URL + "/getMedicineCategories";
    return this.apiService.doGetObservable(urlPath);
  }

  getLedgerEntries(params): Observable<any> {
    const urlPath = this.API_URL + "/ledger/getEntriesForDateRangePage";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getLedgerPaymentBills(params): Observable<any> {
    console.log(environment.production);
    const urlPath =
      this.API_URL + "/ledger/payment/getSellerInvoiceNumbersForDate";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getPaymentMethods(): Observable<any> {
    const urlPath = this.API_URL + "/ledger/payment/getPaymentModes";
    return this.apiService.doGetObservable(urlPath);
  }

  makeDebitEntry(params, body): Observable<any> {
    const urlPath = this.API_URL + "/ledger/payment/makeDebitEntry";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getInLedgerInvoices(params): Observable<any> {
    const urlPath = this.API_URL + "/ledger/getPastSellerInvoiceInLedger";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getNotInLedgerInvoices(params): Observable<any> {
    const urlPath = this.API_URL + "/ledger/getPastSellerInvoiceNotInLedger";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  makeCreditEntry(params, body): Observable<any> {
    const urlPath = this.API_URL + "/ledger/makeCreditEntry";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  resendClubbedOrder(params, body): Observable<any> {
    const urlPath = this.API_URL + "/update/resendClubOrderAndEmail";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  settleDebitNote(params, body): Observable<any> {
    const urlPath = this.API_URL + "/returns/settleDebitNote";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getDebitNoteDetails(params): Observable<any> {
    const urlPath = this.API_URL + "/returns/getDebitNoteDetailsPage";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getSettledDebitNote(params): Observable<any> {
    const urlPath = this.API_URL + "/returns/getSettledDebitNotes";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getPendingDebitNote(params): Observable<any> {
    const urlPath = this.API_URL + "/returns/getPendingDebitNotes";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getDistributorInfoForDebitNote(params): Observable<any> {
    const urlPath = this.API_URL + "/getDistributorInfoForDebitNote";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  sendDebitItemsToSaveo(params, body): Observable<any> {
    const urlPath = this.API_URL + "/returns/sendDebitNoteItemsToInventory";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  sendCreditItemsToSaveo(params, body): Observable<any> {
    const urlPath = this.API_URL + "/returns/sendCreditNoteItemsToOurInventory";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  sendCreditItemsToDistributor(params, body): Observable<any> {
    const urlPath = this.API_URL + "/returns/clubAndGenerateDebitNotes";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getCreditNoteItems(params): Observable<any> {
    const urlPath = this.API_URL + "/returns/getCreditNoteItemsByDistributor";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  sendCreditNote(params, body): Observable<any> {
    const urlPath = this.API_URL + "/returns/updateUserSaveoCredits";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getFinalizedCreditNotes(params): Observable<any> {
    const urlPath = this.API_URL + "/returns/getFinalizedCreditNotes";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  finalizeCreditNote(params): Observable<any> {
    const urlPath = this.API_URL + "/returns/finalizeCreditNote";
    return this.apiService.doPostObservableForQuery(urlPath, {}, params);
  }

  updateCreditNote(params, body): Observable<any> {
    const urlPath = this.API_URL + "/returns/updateCreditNoteDetails";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  deleteCreditNoteMedicine(params, body): Observable<any> {
    const urlPath = this.API_URL + "/returns/deleteCreditNoteMedicine";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getActiveCreditNote(params): Observable<any> {
    const urlPath = this.API_URL + "/returns/getActiveCreditNotes";
    return this.apiService.doGetObservableForQuery(urlPath,params);
  }

  getCreditNoteDetails(params): Observable<any> {
    const urlPath = this.API_URL + "/returns/getCreditNoteDetailsPage";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  addCreditNote(params, body): Observable<any> {
    const urlPath = this.API_URL + "/returns/generateCreditNote";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getLiveBuyerInvoiceCount(params): Observable<any> {
    const urlPath = this.API_URL + "/invoice/getLiveBuyerInvoiceCount";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updatePrintStatus(params): Observable<any> {
    const urlPath = this.API_URL + "/invoice/updatePrintStatus";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  verifyNewUser(body): Observable<any> {
    const urlPath = this.API_URL + "/updateVerifiedUserDetails";
    return this.apiService.doPostObservableForQuery(urlPath, body, {});
  }

  getActiveReturnOrders(): Observable<any> {
    const urlPath = this.API_URL + "/returns/getActiveReturns";
    return this.apiService.doGetObservable(urlPath);
  }

  getApprovedReturnOrders(): Observable<any> {
    const urlPath = this.API_URL + "/returns/getApprovedReturns";
    return this.apiService.doGetObservable(urlPath);
  }

  updateReturnOrder(params, body): Observable<any> {
    const urlPath = this.API_URL + "/returns/approveReturnOrder";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getPossibleStatus(): Observable<any> {
    const urlPath = this.API_URL + "/accounts/getPossiblePaymentStatus";
    return this.apiService.doGetObservable(urlPath);
  }

  getRiders(): Observable<any> {
    const urlPath = this.API_URL + "/accounts/getRiders";
    return this.apiService.doGetObservable(urlPath);
  }

  getAccountPastBuyerInvoices(params): Observable<any> {
    const urlPath = this.API_URL + "/accounts/getPastBuyerInvoices";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAccountBuyerInvoices(params): Observable<any> {
    const urlPath = this.API_URL + "/accounts/getBuyerInvoices";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateAccountBuyerInvoice(params, body): Observable<any> {
    const urlPath = this.API_URL + "/accounts/updateBuyerInvoiceAmount";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getDistributorForMedicine(params): Observable<any> {
    const urlPath = this.API_URL + "/stock/getDistributorIdsByMedicineId";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  deleteSellerInvoice(params): Observable<any> {
    const urlPath = this.API_URL + "/deleteSellerInvoice";
    return this.apiService.doDeleteForQuery(urlPath, params);
  }

  getAllManufacturers(): Observable<any> {
    const urlPath = this.API_URL + "/getAllManufacturers";
    return this.apiService.doGetObservable(urlPath);
  }

  getAllZipCode(): Observable<any> {
    const urlPath = this.API_URL + "/getAllZipArea";
    return this.apiService.doGetObservable(urlPath);
  }

  getAllZipCodeV2(): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/getAllZipArea";
    return this.apiService.doGetObservable(urlPath);
  }

  updateZipCode(params): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/enableDisableZip";
    return this.apiService.doPostObservableForQuery(urlPath, {}, params);
  }

  addZipcode(params): Observable<any> {
    const urlPath = this.API_URL + "/getZipAreaAndSave";
    return this.apiService.doPostObservableForQuery(urlPath, {}, params);
  }

  getReturnOrders(): Observable<any> {
    const urlPath = this.API_URL + "/getAllReturns";
    return this.apiService.doGetObservable(urlPath);
  }

  getSaveoInfo(): Observable<any> {
    const urlPath = this.API_URL + "/getSaveoInfo";
    return this.apiService.doGetObservable(urlPath);
  }

  getV2SaveoInfo(params): Observable<any> {
    const urlPath = this.API_URL + "/v2/getSaveoInfo";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getSaveoInfoForCNDN(params): Observable<any> {
    const urlPath = this.API_URL + "/getSaveoInfoForCNDN";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getBuyerInfo(params): Observable<any> {
    const urlPath = this.API_URL + "/getUserInfoByRetailerCode";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  remveMedicineFromBill(params, body): Observable<any> {
    const urlPath = this.API_URL + "/invoice/deleteBuyerInvoiceMedicine";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateBill(data): Observable<any> {
    const urlPath = this.API_URL + "/invoice/updateBuyerInvoiceDetails";
    return this.apiService.doUpdate(urlPath, data);
  }

  updateBillTest2(data): Observable<any> {
    const urlPath =
      environment.BASE_URL + "/admin/billing/updateBuyerInvoiceDetails";
    return this.apiService.doUpdate(urlPath, data);
  }

  getAllOrderRetrieveIds(): Observable<any> {
    const urlPath = this.API_URL + "/invoice/getAllBuyerInvoiceOrderIds";
    return this.apiService.doGetObservable(urlPath);
  }

  getRetailerDetails(params): Observable<any> {
    const urlPath = this.API_URL + "/getRetailerDetail/" + params.retailerCode;
    return this.apiService.doGetObservable(urlPath);
  }

  searchBuyerInvoice(params): Observable<any> {
    const urlPath = this.API_URL + "/invoice/searchBuyerInvoice";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAllBuyerInvoice(params): Observable<any> {
    const urlPath = this.API_URL + "/invoice/getAllBuyerInvoice";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getBillDetails(params): Observable<any> {
    const urlPath = this.API_URL + "/invoice/getBuyerInvoiceDetailWithPage";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  convertUploadedInvoice(params): Observable<any> {
    const urlPath = this.API_URL + "/invoice/convertLiveSellerInvoice";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  deleteUploadedInvoice(params): Observable<any> {
    const urlPath = this.API_URL + "/invoice/deleteRawSellerInvoice";
    return this.apiService.doDeleteForQuery(urlPath, params);
  }

  getPastUploadedInvoice(params): Observable<any> {
    const urlPath = this.API_URL + "/invoice/getAllPastRawSellerInvoice";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getLiveUploadedInvoice(params): Observable<any> {
    const urlPath = this.API_URL + "/invoice/getAllLiveRawSellerInvoice";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  // getAllSalesPersons(): Observable<any> {
  //   const urlPath = this.API_URL + "/getAllSalesPerson";
  //   return this.apiService.doGetObservable(urlPath);
  // }

  mapSalesPersonToShop(params, body): Observable<any> {
    const urlPath = this.API_URL + "/bdReatailer";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getAllActiveConsollidatedOrders(): Observable<any> {
    const urlPath = this.API_URL + "/invoice/getAllActiveConsolidatedOrder";
    return this.apiService.doGetObservable(urlPath);
  }

  getClubOrderIds(params): Observable<any> {
    const urlPath = this.API_URL + "/invoice/getClubOrderIds";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getInventorySubSections(): Observable<any> {
    const urlPath = this.API_URL + "/getInventorySubSection";
    return this.apiService.doGetObservable(urlPath);
  }

  getRetrieveLocations(): Observable<any> {
    const urlPath = this.API_URL + "/getRetrieveLocations";
    return this.apiService.doGetObservable(urlPath);
  }

  getAllNewUsers(params): Observable<any> {
    const urlPath = this.API_URL + "/viewNewUsersWithoutPageable";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getDistributorByCategory(params): Observable<any> {
    const urlPath = this.API_URL + "/getAllDistributorByCategory";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  convertMultiUploadedInvoice(params): Observable<any> {
    const urlPath = this.API_URL + "/invoice/convertLiveSellerInvoice";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getUploadInvoices(params): Observable<any> {
    const urlPath = this.API_URL + "/invoice/getAllRawSellerInvoice";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getInventoryType(params): Observable<any> {
    const urlPath = this.API_URL + "/getInventoryFileType";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getMedicineCategories(): Observable<any> {
    const urlPath = this.API_URL + "/getMedicineCategories";
    return this.apiService.doGetObservable(urlPath);
  }

  getPastConvertedInvoiceCSV(params): Observable<any> {
    const urlPath = this.API_URL + "/downloadConvertedInvoice";
    return this.apiService.doGetObservableForCSV(urlPath, params);
  }

  getPastConvertedInvoiceDetails(params): Observable<any> {
    const urlPath = this.API_URL + "/getConvertedInvoiceDetail";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getPastConvertedInvoices(params): Observable<any> {
    const urlPath = this.API_URL + "/getPastConvertedInvoice";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  placeOrderForRetailer(cart, params): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/orderForRetailer";
    return this.apiService.doPostObservableForQuery(urlPath, cart, params);
  }

  getAllRetailerCodes(): Observable<any> {
    const urlPath = this.API_URL + "/getAllRetailerCodes";
    return this.apiService.doGetObservable(urlPath);
  }

  getLiveConsOrders(): Observable<any> {
    const urlPath = this.API_URL + "/view/liveConsolidatedOrders";
    return this.apiService.doGetObservable(urlPath);
  }

  getPastConsOrders(): Observable<any> {
    const urlPath = this.API_URL + "/view/PastConsolidatedOrders";
    return this.apiService.doGetObservable(urlPath);
  }

  sendToMarg(body): Observable<any> {
    const urlPath = this.API_URL + "/margOrderInsert";
    const params = {};
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getRetentionGraphData(params): Observable<any> {
    const urlPath = this.API_URL + "/getUserRetentionDetails";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getWeeklyActiveUserData(params): Observable<any> {
    const urlPath = this.API_URL + "/getWeeklyUserRetentionDetails";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getWeeklyRepeatUserGraphData(params): Observable<any> {
    const urlPath = this.API_URL + "/getWeeklyRepeatUserDetails";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  downloadWeeklyRepeatUserGraphData(params): Observable<any> {
    const urlPath = this.API_URL + "/downloadWeeklyRepeatUsers";
    return this.apiService.doGetObservableForCSV(urlPath, params);
  }

  getNewUserGraphData(params): Observable<any> {
    const urlPath = this.API_URL + "/getDailyFirstOrderUserDetails";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  downloadNewUserGraphData(params): Observable<any> {
    const urlPath = this.API_URL + "/downloadDailyNewUsers";
    return this.apiService.doGetObservableForCSV(urlPath, params);
  }

  getLostUserGraphData(params): Observable<any> {
    const urlPath = this.API_URL + "/getDailyLostUserCount";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  downloadLostUserGraphData(params): Observable<any> {
    const urlPath = this.API_URL + "/downloadDailyLostUsers";
    return this.apiService.doGetObservableForCSV(urlPath, params);
  }

  getCohortData(params): Observable<any> {
    const urlPath = this.API_URL + "/getRetentionCohortCount";
    return this.apiService.doGetObservable(urlPath);
  }

  getWeeklyCohortData(params): Observable<any> {
    const urlPath = this.API_URL + "/getRetentionCohortCount";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getMonthlyCohortData(params): Observable<any> {
    const urlPath = this.API_URL + "/getRetentionCohortCountMonthly";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getDisabledMeds(params): Observable<any> {
    const urlPath = this.API_URL + "/getAllDisabledMedicines";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  searchMeds(params): Observable<any> {
    const urlPath = this.API_URL + "/searchMedicines";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateMeds(body): Observable<any> {
    const params = {};
    const urlPath = this.API_URL + "/updateMedicineStatus";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getMedsForImages(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/home/search/medicine";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  retrieveAll(data, params): Observable<any> {
    const urlPath = this.API_URL + "/update/retrieveAll";
    return this.apiService.doPostObservableForQuery(urlPath, data, params);
  }

  getNewMedicines(params): Observable<any> {
    const urlPath = this.API_URL + "/getNewMedicines";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  addNewMedicine(newMed, params, body) {
    const med = encodeURIComponent(newMed);
    const urlPath = this.API_URL + "/mapNewMedicines?newMedicine=" + med;
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getNewUsers(params): Observable<any> {
    const urlPath = this.API_URL + "/viewNewUsers";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  enableRetailer(mobileNumber): Observable<any> {
    const urlPath = this.API_URL + "/enableRetailer";
    return this.apiService
      .doUpdateForQuery(urlPath, mobileNumber)
      .pipe(map((response) => response.data));
  }

  getBlockReasons(): Observable<any> {
    const urlPath = this.API_URL + "/getBlockReasons";
    return this.apiService.doGetObservable(urlPath);
  }

  blockUser(params): Observable<any> {
    const body = {};
    const urlPath = this.API_URL + "/blockUser";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getUnBlockReasons(): Observable<any> {
    const urlPath = this.API_URL + "/getUnBlockReasons";
    return this.apiService.doGetObservable(urlPath);
  }

  unBlockUser(params, body): Observable<any> {
    const urlPath = this.API_URL + "/unBlockUser";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getBlockLogs(params): Observable<any> {
    const urlPath = this.API_URL + "/getUserBlockLogs";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getOrders(params): Observable<any> {
    const urlPath = this.API_URL + "/view/orders";
    return this.apiService
      .doGetObservableForQuery(urlPath, params)
      .pipe(map((response) => response.data));
  }

  getOrdersByRetailerId(params): Observable<any> {
    const urlPath = this.API_URL + "/view/orders/retailerId";
    return this.apiService
      .doGetObservableForQuery(urlPath, params)
      .pipe(map((response) => response.data));
  }

  updateRetrieved(data, params): Observable<any> {
    const urlPath = this.API_URL + "/update/retrieve";
    return this.apiService
      .doPostObservableForQuery(urlPath, data, params)
      .pipe(map((response) => response));
  }

  getAvailableSlots(): Observable<any> {
    const urlPath = this.API_URL + "/getAllAvailableSlots";
    return this.apiService
      .doGetObservable(urlPath)
      .pipe(map((response) => response.data));
  }

  getDistributors() {
    const urlPath = this.API_URL + "/v2/getAllDistributors";
    return this.apiService
      .doGetObservable(urlPath)
      .pipe(map((response) => response.data));
  }

  getDistributorsWholesale() {
    const urlPath = environment.adminUrl + "/wholesale/home/getAllDistributors";
    return this.apiService
      .doGetObservable(urlPath)
      .pipe(map((response) => response.data));
  }

  getDisableRetailer(params) {
    const urlPath = this.API_URL + "/find/disableRetailer";
    return this.apiService
      .doGetObservableForQuery(urlPath, params)
      .pipe(map((response) => response.data));
  }

  getDefaultDisableRetailers(params, creditAdmin = false) {
    let urlPath;
    if(!creditAdmin){
      urlPath = this.API_URL + "/find/allRetailersDisable";
    } else {
      urlPath = this.API_URL + "/find/allRetailersDisableWithHubAndRoute";
    }
    // const urlPath = this.API_URL + "/find/allRetailersDisableWithHubAndRoute";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  disableRetailer(params) {
    const urlPath = this.API_URL + "/disableRetailer";
    const body = {};
    return this.apiService
      .doPostObservableForQuery(urlPath, body, params)
      .pipe(map((response) => response.data));
  }

  getMapMeds(name): Observable<any> {
    const params = {
      distributorName: name,
    };
    const urlPath =
      environment.adminUrl + "/distributor/home/distributorColumnMapping";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  postMap(data, params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/distributor/home/updateDistributorColumnMapping";
    return this.apiService.doPostObservableForQuery(urlPath, data, params);
  }

  getManufacturer(pageno) {
    const urlPath = this.API_URL + "/getMatchingManufacturer";
    const params = {
      page: pageno,
    };
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getSuggestion(text) {
    const urlPath = this.API_URL + "/getManufacturerSuggestion";
    const params = {
      str: text,
    };
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  mapManufacturer(body) {
    const urlPath = this.API_URL + "/mapNewManufacturer";
    const params = {};
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getClubbedOrders(dist, status, p): Observable<any> {
    const params = {
      distributor: dist,
      deliveryStatus: status,
      page: p,
    };
    const urlPath = this.API_URL + "/view/pastClubbedOrders";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  downloadClubbedOrder(params): Observable<any> {
    const urlPath = this.API_URL + "/downloadClubOrder";
    return this.apiService.doGetObservableForCSV(urlPath, params);
  }

  getAllDistributorV2Info(): Observable<any> {
    const urlPath = this.API_URL + "/v2/getAllDistributorInfo";
    return this.apiService.doGetObservable(urlPath);
  }

  getAllDistributoInfo(): Observable<any> {
    const urlPath = this.API_URL + "/getAllDistributorInfo";
    return this.apiService.doGetObservable(urlPath);
  }

  getAllDistributoInfoForPromotion(): Observable<any> {
    const urlPath = environment.BASE_URL + "/admin/getAllDistributorInfo";
    return this.apiService.doGetObservable(urlPath);
  }

  getMfacByDist(body): Observable<any> {
    const params = {};
    const urlPath =
      environment.BASE_URL + "/admin/getAllManufacturerByDistributor";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  searchMfacByDist(params, body): Observable<any> {
    const urlPath = this.API_URL + "/searchManufacturerByDistributor";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  promote(body): Observable<any> {
    const params = {};
    const urlPath = environment.BASE_URL + "/admin/doPromotion";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  verify(params) {
    const urlPath = this.API_URL + "/getZipAreaAndSave";
    const body = {};
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getBuyerInvoice(): Observable<any> {
    const urlPath = this.API_URL + "/getBuyerInvoice";
    return this.apiService.doGetObservable(urlPath);
  }

  getSellerInvoice(): Observable<any> {
    const urlPath = this.API_URL + "/getSellerInvoice";
    return this.apiService.doGetObservable(urlPath);
  }

  getSellerInvoiceDetails(id): Observable<any> {
    const urlPath = this.API_URL + "/getSellerInvoiceDetail";
    const params = {
      billId: id,
    };
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getBuyerInvoiceDetails(id): Observable<any> {
    const urlPath = this.API_URL + "/getBuyerInvoiceDetail";
    const params = {
      buyerInvoiceId: id,
    };
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getOtherInvoices(id): Observable<any> {
    const urlPath = this.API_URL + "/view/buyerInvoice/orderRetrieveId";
    const params = {
      orderRetrieveId: id,
    };
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  downloadSellerInvoice(id): Observable<any> {
    const urlPath = this.API_URL + "/downloadSellerInvoice";
    const params = {
      billId: id,
    };
    return this.apiService.doGetObservableForCSV(urlPath, params);
  }

  downloadBuyerInvoice(id, move, ids): Observable<any> {
    const urlPath = this.API_URL + "/downloadBuyerInvoice";
    // console.log(ids)
    const params = {
      idList: ids,
      orderRetrieveId: id,
      moveToPast: move,
    };
    return this.apiService.doGetObservableForCSV(urlPath, params);
  }

  downloadPastBuyerInvoice(code, ids): Observable<any> {
    const urlPath = this.API_URL + "/downloadPastBuyerInvoice";
    // console.log(ids)
    const params = {
      idList: ids,
      retailerCode: code,
    };
    return this.apiService.doGetObservableForCSV(urlPath, params);
  }

  splitInvoice(id, slot, reason, forceSplit): Observable<any> {
    const urlPath = this.API_URL + "/doInvoiceSplit";
    const params = {
      billId: id,
      invoiceCreationSlot: slot,
      reason: reason,
      forceSplit: forceSplit,
    };
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  pastSellerInvoice(params): Observable<any> {
    const urlPath = this.API_URL + "/getPastSellerInvoice";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  pastBuyerInvoice(params): Observable<any> {
    const urlPath = this.API_URL + "/getPastBuyerInvoice";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  pastBuyerInvoiceByStr(params): Observable<any> {
    const urlPath = this.API_URL + "/getPastBuyerInvoice/byRetailerCode";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  pastBuyerInvoiceBydate(params): Observable<any> {
    const urlPath = this.API_URL + "/getPastBuyerInvoice/byDate";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  cancelOrder(params): Observable<any> {
    const urlPath = this.API_URL + "/cancelOrder";
    return this.apiService.doUpdateForQuery(urlPath, params);
  }

  getOldDistributor(params): Observable<any> {
    const urlPath = this.API_URL + "/getDistInfoMapping";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getNewDistributor(): Observable<any> {
    const urlPath = this.API_URL + "/getNewDistInfoMapping";
    return this.apiService.doGetObservable(urlPath);
  }

  updateDistributor(params, body): Observable<any> {
    const urlPath = this.API_URL + "/updateDistInfoMapping";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getInventoryCategories(params): Observable<any> {
    const urlPath = this.API_URL + "/getDistributorCategory";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getInventorySubCategories(params): Observable<any> {
    const urlPath = this.API_URL + "/getDistributorSubCategory";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getCoinItems(params): Observable<any> {
    const urlPath = this.API_URL + "/issueCoins/getItems";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getCoinIssueReasons(): Observable<any> {
    const urlPath = this.API_URL + "/issueCoins/getCoinIssueReasons";
    return this.apiService.doGetObservable(urlPath);
  }

  getCoinAdjustmentType(): Observable<any> {
    const urlPath = this.API_URL + "/issueCoins/getAdjustmentType ";
    return this.apiService.doGetObservable(urlPath);
  }

  issueCoins(params, body): Observable<any> {
    const urlPath = this.API_URL + "/issueCoins/addCoinsForUser";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getMedicineToSetQuantityToZero(params): Observable<any> {
    const urlPath = this.API_URL + "/getMedicineToSetQuantityToZero";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  setMedicineToSetQuantityToZero(params): Observable<any> {
    const urlPath = this.API_URL + "/setMedicineToSetQuantityToZero";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  searchPrintLogs(params): Observable<any> {
    const urlPath = this.API_URL + "/invoice/searchOrderIdFromPrintLog";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getPrintLogDetails(params): Observable<any> {
    const urlPath =
      this.API_URL + "/invoice/getBuyerInvoiceDetailFromPrintLogWithPage";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAllDistricts(): Observable<any> {
    const urlPath = this.API_URL + "/getAllDistricts";
    return this.apiService.doGetObservable(urlPath);
  }

  updateSellerInvoice(params, body): Observable<any> {
    const urlPath = this.API_URL + "/update/sellerInvoice";
    return this.apiService.doUpdate(urlPath, body);
  }

  searchSellerPastInvoice(params): Observable<any> {
    const urlPath = this.API_URL + "/v2/getPastSellerInvoice";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateSellerPastInvoice(params): Observable<any> {
    const urlPath = this.API_URL + "/updateSubmitStatus";
    return this.apiService.doUpdateForQuery(urlPath, params);
  }

  getGlobalSearchOptions(): Observable<any> {
    const urlPath = this.API_URL + "/invoiceTrack/getSearchOptions";
    return this.apiService.doGetObservable(urlPath);
  }

  globalSearchByInvoiceNumber(params): Observable<any> {
    const urlPath = this.API_URL + "/invoiceTrack/searchByInvoiceNumber";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  globalSearchByOrderId(params): Observable<any> {
    const urlPath = this.API_URL + "/invoiceTrack/searchByOrderId";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  globalSearchByRetailerCode(params): Observable<any> {
    const urlPath = this.API_URL + "/invoiceTrack/searchByRetailerCode";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  globalSearchGetRetailerData(params): Observable<any> {
    const urlPath = this.API_URL + "/invoiceTrack/getRetailerDetails";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  globalSearchGetOrderedBilledData(params): Observable<any> {
    const urlPath = this.API_URL + "/invoiceTrack/compareOrderVsBill";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  calculatePendingDebitNoteNetValue(params, body, isExpiry): Observable<any> {
    const urlPath =
      this.API_URL +
      (isExpiry
        ? "/expiry/supply/dnPending/calculateNetValue"
        : "/supply/dnPending/calculateNetValue");
    if (isExpiry) {
      params = {};
    }
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getSlotsBySpokeId(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/v2/admin/getAllAvaialbleSlotBySpokeId";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAllOrders(params): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/getAllOrder";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getSpokeLocations(): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/getAllAvailableSpokes";
    return this.apiService.doGetObservable(urlPath);
  }

  getClubOrdersBySpoke(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/v2/admin/getAllOrderByDistributorId";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAllRetrieveSpokeCodes(): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/getAllRetrievalSpokeCode";
    return this.apiService.doGetObservable(urlPath);
  }

  storeDEReasonSelection(params, body): Observable<any> {
    const urlPath = this.API_URL + "/outstanding/store/DEIssue";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  storeRetailerReasonSelection(params, body): Observable<any> {
    const urlPath = this.API_URL + "/outstanding/store/RetailerIssue";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  storeBillingReasonSelection(params, body): Observable<any> {
    const urlPath = this.API_URL + "/outstanding/store/BillingIssue";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  storeOtherReasonSelection(params, body): Observable<any> {
    const urlPath = this.API_URL + "/outstanding/store/OtherIssue";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getInvoiceItems(params): Observable<any> {
    const urlPath = this.API_URL + "/outstanding/view/invoiceItems";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  collectPaymentForRetailerReason(params, body): Observable<any> {
    const urlPath = this.API_URL + "/outstanding/collect/Retailer";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  collectPaymentForDEReason(params, body): Observable<any> {
    const urlPath = this.API_URL + "/outstanding/collect/DE ";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getActiveConsollidatedOrders(params): Observable<any> {
    const urlPath = this.API_URL + "/invoice/getAllActiveConsolidatedOrder";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAllPrintBills(params): Observable<any> {
    const urlPath = this.API_URL + "/invoice/getFullFilledBills";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getRateDifferenceCreditNotes(params): Observable<any> {
    const urlPath = this.API_URL + "/outstanding/viewAllRdCN";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getRateDifferenceCreditNoteDetails(params): Observable<any> {
    const urlPath = this.API_URL + "/outstanding/viewRdCNPage";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getRateDifferenceCreditNoteSaveoInfo(params): Observable<any> {
    const urlPath = this.API_URL + "/getSaveoInfoForCNDN";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  checkInvoiceStatusBeforePrint(params): Observable<any> {
    const urlPath = this.API_URL + "/invoice/checkInvoiceStatusBeforePrint";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getSelectedBills(params): Observable<any> {
    const urlPath = this.API_URL + "/invoice/getBuyerInvoiceByInvoiceNumber";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  searchMedicines(params): Observable<any> {
    const urlPath = this.API_URL + "/search/v2/all/medicine";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  searchMedicinesTest2(params): Observable<any> {
    const urlPath = environment.BASE_URL + "/admin/billing/search/medicine";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  searchEnableDisableMedicines(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/home/search/medicine/withoutpromotion";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getEnableDisableMedicineDetails(params): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/getdisablemedicinedetail";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  enableMedicineForPlatform(params, body): Observable<any> {
    const urlPath =
      environment.adminUrl + "/v2/admin/disablemedicine/hubwise/enable";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  disableMedicineForPlatform(params, body): Observable<any> {
    const urlPath =
      environment.adminUrl + "/v2/admin/disablemedicine/hubwise/disable";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  enableMedicineForDistributor(params, body): Observable<any> {
    const urlPath =
      environment.adminUrl + "/v2/admin/disablemedicine/distributorwise/enable";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  disableMedicineForDistributor(params, body): Observable<any> {
    const urlPath =
      environment.adminUrl +
      "/v2/admin/disablemedicine/distributorwise/disable";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  enableMedicineForDestination(params, body): Observable<any> {
    const urlPath =
      environment.adminUrl + "/v2/admin/disablemedicine/rspwise/enable";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  disableMedicineForDestination(params, body): Observable<any> {
    const urlPath =
      environment.adminUrl + "/v2/admin/disablemedicine/rspwise/disable";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  createManualUser(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/auth/v2/user/manual/add";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  generateManualBill(params, body): Observable<any> {
    const urlPath = this.API_URL + "/invoice/createManualBill";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }
  generateManualBillV2(params, body): Observable<any> {
    const urlPath = this.API_URL + "/v2/invoice/createManualBill";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getCreditPartners(): Observable<any> {
    const urlPath = environment.adminUrl + "/credit/user/getNbfcData";
    return this.apiService.doGetObservable(urlPath);
  }

  getCreditPartnersForRetailer(params): Observable<any> {
    const urlPath = environment.adminUrl + "/credit/user/getNbfcDataByRetailer";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getInventoryDistributors(): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/getAllDistributorNames";
    return this.apiService.doGetObservable(urlPath);
  }

  getInventoryCategoryAndSubcategory(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/v2/admin/getDistributorSubsectionAndCategory";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getFinancePartners(): Observable<any> {
    const urlPath = environment.adminUrl + "/credit/user/CreditFinancePartnerList";
    return this.apiService.doGetObservable(urlPath);
  }

  getProdMasterMedicinesToMap(): Observable<any> {
    const urlPath = environment.adminUrl + "/home/prodmaster/getMedicine";
    return this.apiService.doGetObservable(urlPath);
  }

  updateProdMasterMedicinesToMap(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/home/prodmaster/updateMedicine";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getProdMasterPendingCount(): Observable<any> {
    const urlPath = environment.adminUrl + "/home/prodmaster/pendingCount";
    return this.apiService.doGetObservable(urlPath);
  }

  getCreditCredentials(params): Observable<any> {
    const urlPath = environment.adminUrl + "/credit/admin/getPlainCreditStatus";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateCreditCredentials(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/credit/admin/updateCreditUserDetails";
    return this.apiService.doUpdate(urlPath, params);
  }

  toggleCreditUser(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/credit/admin/toggleCreditCustomerStatus";
    return this.apiService.doUpdate(urlPath, params);
  }

  getAllDistributorsForMapMeds(): Observable<any> {
    const urlPath =
      environment.adminUrl + "/admin/getAllDistributorsForMapMeds";
    return this.apiService.doGetObservable(urlPath);
  }

  getPendingMapMedicines(params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/getPendingMapMedicines";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getRetBdeMappingData(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/home/getRetBdeMappingData/page";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getAllCollectionAgents(): Observable<any> {
    const urlPath = environment.adminUrl + "/home/getAllCollectionAgents";
    return this.apiService.doGetObservable(urlPath);
  }

  getAllMedRep(): Observable<any> {
    const urlPath = environment.adminUrl + "/home/getAllMedicalRepresentatives";
    return this.apiService.doGetObservable(urlPath);
  }

  getAllSalesPerson(): Observable<any> {
    const urlPath = environment.adminUrl + "/home/getAllSalesPerson";
    return this.apiService.doGetObservable(urlPath);
  }

  getCounterCustomerDetails(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/home/counterCustomerByRetailerCode";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  setCounterCustomerDetails(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/home/setCounterCustomer";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getRetBdeMappingDataByRetailer(params, body): Observable<any> {
    const urlPath =
      environment.adminUrl + "/home/getRetBdeMappingDataByRetailerCode";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getRetBdeMappingDataBySalesPerson(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/home/getAllBdeBySalesPerson";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateRetBdeMapping(params, body): Observable<any> {
    const urlPath =
      environment.adminUrl + "/home/updateRetBdeCollectionDetails";
    return this.apiService.doUpdate(urlPath, body);
  }

  getRetailerBillInfo(params): Observable<any> {
    const urlPath = environment.adminUrl + "/home/getBills";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateRetailerCarryForwardAmount(body): Observable<any> {
    const urlPath =
      environment.adminUrl + "/credit/admin/updateCreditBillCarryForwardAmount";
    return this.apiService.doUpdate(urlPath, body);
  }

  searchPtrPtsItems(params): Observable<any> {
    const urlPath = this.API_URL + "/getMedicinePtrPts";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updatePtrPtsItem(params, body): Observable<any> {
    const urlPath = this.API_URL + "/update/priceMapping/medicine";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getRetailerRoute(params): Observable<any> {
    const urlPath = environment.adminUrl + "/home/getRetailerRoute";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  setRetailerRoute(params): Observable<any> {
    const urlPath = environment.adminUrl + "/home/setRetailerRouteException";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  deleteRetailerRoute(params): Observable<any> {
    const urlPath = environment.adminUrl + "/home/deleteRetailerRouteException";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAllRoutes(): Observable<any> {
    const urlPath = environment.adminUrl + "/home/fetchAllRoutes";
    return this.apiService.doGetObservable(urlPath);
  }

  addInternalRole(params, body): Observable<any> {
    const urlPath = this.API_URL + "/enableInternalUser";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getAllRoles(): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/getRolesToAssign";
    return this.apiService.doGetObservable(urlPath);
  }

  getAllServicingHub(): Observable<any> {
    const urlPath = environment.adminUrl + "/hub/fetchAllHub";
    return this.apiService.doGetObservable(urlPath);
  }

  updateMaxCashDiscount(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/credit/admin/updateMaxCashDiscount";
    return this.apiService.doUpdateForQuery(urlPath, params);
  }

  updateSanctionedLimit(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/credit/admin/sanctionLimitIncrease";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAllDistributorTypes(): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/getDistributorType";
    return this.apiService.doGetObservable(urlPath);
  }

  getUpdateSanctionedLimitReasons(): Observable<any> {
    const urlPath =
      environment.adminUrl + "/credit/admin/getSanctionLimitIncreaseReason";
    return this.apiService.doGetObservable(urlPath);
  }

  getAllLiveBatches(params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/getAllLiveBatches";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  UpdateBatchChange(data): Observable<any> {
    const url = environment.adminUrl + "/admin/update/batch";
    return this.apiService.doPostObservable(url, data);
  }

  getCreditApplicationStatus(params): Observable<any> {
    const urlPath = environment.adminUrl + "/credit/admin/getCreditUser";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateCreditApplicationStatus(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/credit/admin/updateCreditUserStatus";
    return this.apiService.doUpdateForQuery(urlPath, params);
  }

  // /admin/invoice/updatePrintStatusForBulkPrint
  updateBulkPrintStatus(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/admin/invoice/updatePrintStatusForBulkPrint";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getMedicineTags(): Observable<any> {
    const urlPath = environment.adminUrl + "/home/medicine/getListingTags";
    return this.apiService.doPostObservableForQuery(urlPath, {}, {});
  }

  updateMedicineTag(params): Observable<any> {
    const urlPath = environment.adminUrl + "/home/medicine/updateListingTag";
    return this.apiService.doPostObservableForQuery(urlPath, {}, params);
  }

  rejectNewUser(params, body): Observable<any> {
    const urlPath = this.API_URL + "/rejectUpdateVerifiedUserDetails";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }
  saveUserDocument(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/onboarding/saveUserDocument";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getBussinessTypes(params): Observable<any> {
    const urlPath = environment.adminUrl + "/onboarding/businessType";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getUserRejectionReasons(params) {
    const urlPath =
      environment.adminUrl + "/onboarding/getUserOnboardingRejection";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  uploadUserDoc(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/onboarding/addManualDocsImages";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getUserDocs(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/onboarding/getUserDocument";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getVerificationStatus(params) {
    const urlPath =
      environment.adminUrl + "/onboarding/getVerificationDocumentEnable";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getBannerSections(): Observable<any> {
    const urlPath = environment.bannerUrl + "/admin/banner/getAllSections";
    return this.apiService.doGetObservable(urlPath);
  }

  getBanners(params): Observable<any> {
    const urlPath = environment.bannerUrl + "/admin/banner/getBanners";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateBanner(params, body): Observable<any> {
    const urlPath = environment.bannerUrl + "/admin/banner/addBanner";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  editBanner(params, body): Observable<any> {
    const urlPath = environment.bannerUrl + "/admin/banner/editBanner";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  verifyGst(params, body): Observable<any> {
    const urlPath = environment.bannerUrl + "/admin/verify/GSTNo";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  unrejectUser(params, body): Observable<any> {
    const urlPath =
      environment.bannerUrl + "/admin/UnrejectUpdateVerifiedUserDetails";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getUpdateInventoryLogs(params): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("source", "web")
      .set("serviceHudId", params.servicingHubId)
      .set("pageId", params.page);
    const urlPath = environment.adminUrl + "/file/uploadInventoryStatus";
    return this.apiService.doGetObservableByHeaders(urlPath, headers);
  }

  getInventoryLogDetails(params): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("source", "web")
      .set("id", params.id);
    const urlPath = environment.adminUrl + "/file/inventoryData";
    return this.apiService.doGetObservableByHeaders(urlPath, headers);
  }

  fixedDiscountCustomer(params, body): Observable<any> {
    const urlPath = environment.bannerUrl + "/home/setFixedDiscountCustomer";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getExtraLimitReasons(): Observable<any> {
    const urlPath = environment.adminUrl + "/credit/admin/getExtraLimitReasons";
    return this.apiService.doGetObservable(urlPath);
  }

  getSampleCSVForCL(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/admin/communityLeader/getSampleCSV";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  uploadCSVForBdeMapping(file: any, params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/file/communityLeader/uploadCommunityLeader";
    return this.apiService.doPostObservableForCSV(urlPath, file, params);
  }

  getCollectionAgents(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/admin/communityLeader/getAllCollectionAgents";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }
  getMRS(params): Observable<any> {
    const urlPath =
      environment.adminUrl +
      "/admin/communityLeader/getAllMedicalRepresentatives";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateSalesPerson(params, body): Observable<any> {
    const urlPath =
      environment.adminUrl +
      "/admin/communityLeader/updateSalesPersonUsingMobileNo";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getBdeMapByRet(params): Observable<any> {
    const urlPath =
      environment.adminUrl +
      "/admin/communityLeader/getRetailerBdeMappingDataByRetailerCode";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getRoutesForSL(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/admin/supply/salesInvoice/getRoutes/byFilter";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getLiveSales(params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/sales/getLiveSales";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getExpiredSales(params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/sales/getExpiredSales";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getUpcomingSales(params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/sales/getUpcomingSales";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getSaleById(params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/sales/getSaleById";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }
  createSale(file: any, params): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl + "/admin/sales/createSale";
    return this.apiService.doPostObservableForCSV(urlPath, file, params);
  }
  editSale(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/sales/editSale";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  deleteSale(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/sales/deleteSale";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  editSaleCSV(file: any, params): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl + "/admin/sales/editSaleUsingCSV";
    return this.apiService.doPostObservableForCSV(urlPath, file, params);
  }

  deleteSaleCSV(file: any, params): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl + "/admin/sales/deleteSaleUsingFile";
    return this.apiService.doPostObservableForCSV(urlPath, file, params);
  }

  getSaleMedicines(params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/sales/getSaleProducts";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }
  

  getAllRetrieval(params): Observable<any> {
    const urlPath =
      environment.BASE_URL + "/admin/banner/getAllRetrievalCodeByHub";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  uploadDeliverySlot(file: any): Observable<any> {
    let params;
    const urlPath =
      environment.BASE_URL + "/admin/deliverySlot/uploadMultipleDS";
    return this.apiService.doPostObservableForCSV(urlPath, file, params);
  }

  uploadDeliveryCheck(file: any, params): Observable<any> {
    const urlPath =
      environment.BASE_URL + "/admin/deliverySlot/checkRSPCodeForFile";
    return this.apiService.doPostObservableForCSV(urlPath, file, params);
  }
  getAllConstantsForGamification(): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/gamification/allConstants";
    return this.apiService.doGetObservable(urlPath);
  }

  getAllMedicinesCategoriesForGamification(): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/gamification/getAllMedicinesCategories";
    return this.apiService.doGetObservable(urlPath);
  }

  getAllCampaigns(params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/gamification/getAllCampaign";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getFilteredBanners(body,params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/gamification/getFilterCampaigns";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }
  
  getTargetType(params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/gamification/getTargetType";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAllMedicineByMedicineIds(body): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/gamification/getAllMedicineByMedicineIds";
    return this.apiService.doPostObservable(urlPath, body);
  }
  
  createCampaign(body,params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/gamification/createCampaign";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateCampaign(body,params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/gamification/EditCampaign";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getHubDistributorId(params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/gamification/hubIdDistributorId";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getMedicinesForGamification(params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/gamification/getAllMedicineBySearchName";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }
  getAllGiftMedicines(params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/gamification/getAllGiftMedicines";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  uploadCohortFile(file: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", file.type);
    const urlPath = environment.adminUrl + "/admin/gamification/uploadCohortFile";
    return this.http.post<any>(urlPath, file);

  }

  getCampaignDetails(params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/gamification/getCampaignDetails";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }
  

 deleteCampaign(data): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/gamification/deleteCampaign";
    return this.apiService.doUpdateForQuery(urlPath, data);
  }

  downloadCampaignWinnersList(params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/gamification/downloadCampaignWinners";
    return this.apiService.doGetObservableForQuery(urlPath, params)
  }

  downloadFilteredPaymentCSV(params, body): Observable<any> {
    const urlPath = this.API_URL + "/finance/payment/download/filtered";
    return this.apiService.doPostObservableForCSV(urlPath, body, params);
  }

  uploadCampaignWinnersList(file: any, params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/gamification/uploadCampaignWinnersCSV";
    return this.apiService.doPostObservableForCSV(urlPath, file, params)
  }

  getAllCompanyBySearch(params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/gamification/getAllCompanyBySearchName";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAllMedicineBySearch(params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/gamification/getAllMedicineBySearchName";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  retryUploadCampaignWinnersCSV(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/gamification/retryUploadCampaignWinnersCSV";
    return this.apiService.doPostObservableForQuery(urlPath, body, params)
  }


  getClUploadRes(params): Observable<any> {
    const urlPath =
      environment.BASE_URL + "/file/communityLeader/cLCsvResponse";
      return this.apiService.doGetObservableForQuery(urlPath, params);

  }
  
  getUserByRoutes(params):Observable<any>{
    const urlPath = environment.BASE_URL + '/credit/user/getUserByRoutes';
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getActiveRoutesForHub(path): Observable<any>{
    const urlPath = environment.BASE_URL + `/credit/user/getActiveRoutesByHubId/${path}`;
    return this.apiService.doGetObservable(urlPath)
  }

  getNbfcUserStats(params): Observable<any>{
    const urlPath = environment.BASE_URL + `/credit/user/getUserByRoutes`;
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }


  getSampleCarinRCcsv(): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/communityLeader/getCarinRateContractSampleCSV";
    return this.apiService.doGetObservable(urlPath);
  }

  getSampleCarinRetMapcsv(): Observable<any> {
    const urlPath = this.API_URL + "/communityLeader/getCarinRetailerMappingSampleCSV";
    return this.apiService.doGetObservable(urlPath);
  }

  uploadCarinRCcsv(file: any, params, type): Observable<any> {
    let urlPath;
    type === "crc"
      ? (urlPath = environment.adminUrl + "/file/carinRateContract/uploadCsvForCarinRateContractCSV")
      : (urlPath =environment.adminUrl + "/file/carinRateContract/uploadCsvForRetailerMappingCSV");
    return this.apiService.doPostObservableForCSV(urlPath, file, params)
  }
  getCsvStatusCarinRc(params, type):Observable <any> {
    let urlPath;
    type === 'crc'
    ? urlPath = environment.adminUrl + '/file/carinRateContract/carinRateContractCsvResponse'
    : urlPath = environment.adminUrl + '/file/carinRateContract/carinRetailerMappingCsvResponse'
    return this.apiService.doGetObservableForQuery(urlPath, params)
  }

  getPinCodeMapping(params){
    const urlPath = environment.adminUrl + "/v2/admin/getPinCodeMapping";
    return this.apiService.doGetObservableForQuery(urlPath, params)
  }

  getRoutesByHsrId(params){
    const urlPath = environment.adminUrl + '/v2/admin/getRoutesByHsrId';
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAllAvailableSpokes(){
    const urlPath = environment.adminUrl + '/v2/admin/getAllAvailableSpokes';
    return this.apiService.doGetObservable(urlPath)
  }

  updatePinCodeMapping(body){
    const urlPath = environment.adminUrl + '/v2/admin/updatePinCodeMapping';
    return this.apiService.doPostObservable(urlPath, body);

  }

  getSearchOrderId(params){
    const urlPath = environment.adminUrl + '/v2/admin/liveSO/search';
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateRSPAndRoute(params){
    const urlPath = environment.adminUrl + '/v2/admin/liveSO/updateRSPAndRoute';
    return this.apiService.doPostObservableForQuery(urlPath,{}, params);
  }

  
  saveRSPSaleData(body){
    const urlPath = environment.adminUrl + '/admin/sales/sale/editRSP';
    return this.apiService.doPostObservable(urlPath, body);
  }
  disableBanner(params){
    const urlPath = environment.adminUrl + '/admin/banner/removeBanner';
    return this.apiService.doPostObservableForQuery(urlPath,{}, params);
  }

  // PRICE MAPPING
  // Price Mapping Logs
  getPriceMappingLogs(params) {
    const urlPath = environment.adminUrl + '/file/priceMapping/priceMappingLogs';
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  // Price Mapping Errors
  getPriceMappingErrors(params) {
    const urlPath = environment.adminUrl + '/file/priceMapping/priceMappingErrors';
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  // Price Mapping Update
  priceMappingUpdate(params, file: any): Observable<any> {
    const urlPath = environment.adminUrl + '/file/priceMapping/priceMappingUpdate';
    return this.http.post<any>(urlPath, file, {params: params});
  }

  // Get sample file
  getPriceMappingSampleFile(params) {
    const urlPath = environment.adminUrl + '/file/priceMapping/getHeader';
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  reverifyUser(params){
    const urlPath = environment.adminUrl + '/admin/markReverificationDone';
    return this.apiService.doPostObservableForQuery(urlPath,{}, params);
  }
  bulkSendForReverification(body){
    const urlPath = environment.adminUrl + '/admin/markReverificationPending';
    return this.apiService.doPostObservableForQuery(urlPath,body, {});
  }

  uploadEnableLedger(body): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/enableRetailersToShowLedger";
    return this.apiService.doPostObservableForCSV(urlPath, body, {});
  }

  updateLedgerRetailer(params): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/enableDisableRetailersForLedger";
    return this.apiService.doUpdateForQuery(urlPath, params);
  }
}
