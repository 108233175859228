import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { AuthService } from "src/app/core/services/api/api-auth.service";
import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  ElementRef,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  MatSidenav,
  MatSidenavContainer,
  MatBottomSheet,
} from "@angular/material";
import { Subscription } from "rxjs";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { SideNavEventHandlerService } from "src/app/common/event/sidenav-event-handler.service";
import { ScrollEventService } from "src/app/common/event/scroll-event.service";
import { AppDataService } from "src/app/app-data/app-data.service";
import { DistributorService } from "src/app/core/services/api/distributor.service";
import { ThrowStmt } from "@angular/compiler";
import { HubService } from "src/app/core/services/api/hub.service";
import { PopupService } from "src/app/core/services/popup/popup.service";

@Component({
  selector: "app-warehouse",
  templateUrl: "./warehouse.component.html",
  styleUrls: ["./warehouse.component.scss"],
})
export class WarehouseComponent implements OnInit {
  showWarehouse = false;
  showInventoryRoom = false;
  showDR = false;
  showBin = false;
  showBulkUpload = false;

  _drawerOpened: boolean = false;
  _slideMode = "over";

  _mNavMode = "side";
  _mNavOpen = false;
  _mShowMenu = false;
  auth;
  isAuthenticated: boolean = false;
  panelOpenState = false;
  isExpanded = false;
  isAdmin = false;

  subscriptions: Subscription[] = [];
  mCurrentHeading = 1;

  hubs = [];
  currHub = "";
  currHubId = "";
  newHubId = "";
  showHubSelection = false;

  constructor(
    private authService: AuthService,
    private appDataService: AppDataService,
    private location: Location,
    private router: Router,
    private scrollEventService: ScrollEventService,
    private media: MediaObserver,
    private sideNavEventHandlerService: SideNavEventHandlerService,
    private distributorService: DistributorService,
    private hubService: HubService,
    private popupService: PopupService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.media.media$.subscribe((mediaChange: MediaChange) => {
      this._mNavMode = this.getMode(mediaChange);
      // console.log("sidena3:", this._mNavMode);
      this.updateSlideMode(mediaChange);
      // this.opened = this.getOpened(mediaChange);
      this.router.events.subscribe((val) => {
        if (this.location.path() != "") {
          const routePath = this.location.path();

          return;
        } else {
        }
      });
    });
  }

  @ViewChild("fixedContent", { static: true }) public fixedContent: ElementRef;

  @ViewChild("mainDrawer", { static: true }) sidenav: MatSidenav;

  @ViewChild("sideNavContainer", { static: true })
  myScrollContainer: MatSidenavContainer;

  @ViewChild("contentScroller", { static: true }) contentScroller: ElementRef;

  @Output() public sidenavToggle = new EventEmitter();
  @Output() sidenavClose = new EventEmitter();

  logout() {
    this.authService.logout().subscribe(
      (value) => {
        // console.log("logout: ", value);
        this.router.navigate(["/authenticate"]);
        this.appDataService.setData("IsUserExist", "false", "local");
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  ngOnInit() {
    this.getHubs();
    if (
      this.router.url.includes("add-items") ||
      this.router.url.includes("pick") ||
      this.router.url.includes("grn") ||
      this.router.url.includes("/issues")
    ) {
      this.showInventoryRoom = true;
    } else if (
      this.router.url.includes("warehouse-management") ||
      this.router.url.includes("update-tag")
    ) {
      this.showWarehouse = true;
    } else if (
      this.router.url.includes("open-issues") ||
      this.router.url.includes("issue-items")
    ) {
      this.showDR = true;
    }

    this.distributorService.getPhNo().subscribe((num) => {
      const phone = num;
      this.distributorService.checkAdmin(phone).subscribe((response) => {
        this.isAdmin = response.data.user.admin;
      });
    });
    this.subscriptions[this.subscriptions.length] = this.scrollEventService
      .getActionMainEmitter()
      .subscribe((heading: Number) => {
        this.mCurrentHeading = heading as number;
        this.scrollToHeader(heading);
      });
  }

  navigateTo(heading: Number) {
    this.scrollEventService.emitHeadingEvent(heading);
    // this.closeDrawer()
  }

  scrollToHeader(heading: Number) {
    const headerClass: String = this.getHeaderClass(heading);
  }

  getHeaderClass(heading: Number): String {
    // switch (heading) {
    //   case ScrollEventService.SUB_HEADING.home:
    //     return '#home'

    //   default:
    //     break;
    // }
    return "";
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private updateSlideMode(mediaChange: MediaChange) {
    if (this.media.isActive("lt-md")) {
      this._slideMode = "over";
      this._drawerOpened = false;
    } else {
      this._slideMode = "over";
      this._drawerOpened = false;
    }
  }

  onActionLaunch(event) {
    this._mNavOpen = true;
    const isOpened = this.sidenav.opened;
    this.sidenav.toggle();
    this.sideNavEventHandlerService.emitMainEvent(!isOpened);
    // console.log(event);
  }

  onActivate(e, outlet) {
    this.contentScroller.nativeElement.scrollTop = 0;
    // this.myScrollContainer.scrollable.getElementRef().nativeElement.scrollTop = 0;
  }

  getMode(mediaChange: MediaChange): string {
    if (this.media.isActive("lt-md")) {
      // this._mNavOpen = false;
      return "";
    } else {
      // this._mNavOpen = false;
      return "side";
    }
  }

  _backdropToggle(type) {
    if (type === "backdrop") {
      this._drawerOpened = !this._drawerOpened;
    }
  }

  _toggleSideNav(sideNav) {
    // console.log(sideNav.opened);
    // if (sideNav.opened == false) {
    this._drawerOpened = !this._drawerOpened;
    // }
  }

  toggleInventoryRoom() {
    this.showInventoryRoom = !this.showInventoryRoom;
  }

  toggleWarehouse() {
    this.showWarehouse = !this.showWarehouse;
  }

  toggleDR() {
    this.showDR = !this.showDR;
  }

  toggleBin() {
    this.showBin = !this.showBin;
  }

  toggleBulkUpload() {
    this.showBulkUpload = !this.showBulkUpload;
  }

  getHubs() {
    this.hubService.getHubs().subscribe(
      (response) => {
        this.currHubId = response.headers.getAll("hubId");
        this.newHubId = response.headers.getAll("hubId");
        this.hubs = response.body.data;
        this.hubs.map((hub) => {
          if (hub.id == this.currHubId) {
            this.currHub = hub.hubCode;
          }
        });
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  openHubSelection() {
    this.showHubSelection = true;
  }

  updateHub() {
    this.popupService.openProgress();
    const body = {
      hubId: this.newHubId,
    };
    this.hubService.updateHub({}, body).subscribe(
      (response) => {
        localStorage.setItem("hubId", body.hubId);
        this.currHubId = this.newHubId;
        this.hubs.map((hub) => {
          if (hub.id == this.currHubId) {
            this.currHub = hub.hubCode;
          }
        });
        this.showHubSelection = false;
        console.log(this.currHub);
        this.popupService.closeProgress();
        location.reload();
      },
      (error) => {
        this.showHubSelection = false;
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  checkHub() {
    var checkHubCode, checkHubId, currHubId;
    checkHubId = JSON.parse(localStorage.getItem("hubId"));
    this.hubs.map((hub) => {
      if (hub.id == checkHubId) {
        checkHubCode = hub.hubCode;
      }
      if (hub.hubCode == this.currHub) {
        currHubId = hub.id;
      }
    });
    if (checkHubCode && checkHubCode != this.currHub) {
      localStorage.setItem("hubId", currHubId);
      location.reload();
    }
  }
}
