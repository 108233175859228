import { createOfflineCompileUrlResolver } from '@angular/compiler';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor() { }

  // Get unique values of array
  getUniqueValues(array, key) {
    const valueArray = [];
    array.map(value => {
      valueArray.push(value[key]);
    });
    const uniqueArray = [...new Set(valueArray)];
    const returnArray = [];
    uniqueArray.map(uniqueValue => {
      returnArray.push({value: uniqueValue, selected: false});
    })
    return uniqueArray;
  }

  // fillup filter object for given array
  getFilters(dataArray, filters) {
    const keys = Object.keys(filters);
    keys.map(key => {
      filters[key].uniqueValues = this.getUniqueValues(dataArray, key);
    })
    return filters;
  }

  // filter data
  filterData(dataArray, filters) {
    let copyArray = JSON.parse(JSON.stringify(dataArray));
    copyArray.map(arrayValue => {
      arrayValue.flag = true;
    })
    const keys = Object.keys(filters);
    let returnArray = [];
    keys.map(key => {
      if (filters[key].active && filters[key].values.length > 0) {
        copyArray.map(arrayValue => {
          arrayValue.flag = false;
          filters[key].values.map(filterValue => {
            if (filterValue == arrayValue[key]) {
              arrayValue.flag = true;
            }
          })
        })
        copyArray.map(arrayValue => {
          if (arrayValue.flag) {
            returnArray.push(arrayValue);
          }
        })
        copyArray = JSON.parse(JSON.stringify(returnArray));
        returnArray = [];
      }
    })
    
    copyArray.map(arrayValue => {
      if (arrayValue.flag) {
        returnArray.push(arrayValue);
      }
    })
    return returnArray;
  }
  
  // fillup filter object for given array(past expiry orders)
  getPastExpiryFilters(dataArray, filters) {
    const keys = Object.keys(filters);
    const arr = [];
    dataArray.map(val => {
      arr.push(val.expiryReturnRetrieveOrder)
    })
    keys.map(key => {
      filters[key].uniqueValues = this.getUniqueValues(arr, key);
    })
    console.log(filters)
    return filters;
  }

  // filter past expiry data
  filterPastExpiryData(dataArray, filters) {
    const arr = [];
    dataArray.map(val => {
      arr.push(val.expiryReturnRetrieveOrder)
    })
    let copyArray = JSON.parse(JSON.stringify(arr));
    copyArray.map(arrayValue => {
      arrayValue.flag = true;
    })
    const keys = Object.keys(filters);
    let returnArray = [];
    keys.map(key => {
      if (filters[key].active && filters[key].values.length > 0) {
        copyArray.map(arrayValue => {
          arrayValue.flag = false;
          filters[key].values.map(filterValue => {
            if (filterValue == arrayValue[key]) {
              arrayValue.flag = true;
            }
          })
        })
        copyArray.map(arrayValue => {
          if (arrayValue.flag) {
            returnArray.push(arrayValue);
          }
        })
        copyArray = JSON.parse(JSON.stringify(returnArray));
        returnArray = [];
      }
    })
    
    copyArray.map(arrayValue => {
      if (arrayValue.flag) {
        returnArray.push(arrayValue);
      }
    })
    const expiryReturnArray = [];
    dataArray.map(val => {
      returnArray.map(returnVal => {
        if (returnVal.id == val.expiryReturnRetrieveOrder.id) {
          expiryReturnArray.push(val)
        }
      })
    })
    console.log(expiryReturnArray)
    return expiryReturnArray;
  }

  // search and filter
  // search in filter list
  searchFilter(array, searchString, key) {
    searchString = searchString.toLowerCase();
    let copyArray = JSON.parse(JSON.stringify(array));
    copyArray.map(arrayValue => {
      arrayValue[key] = arrayValue[key].toLowerCase();
      arrayValue.flag = false;
    })
    const returnArray = [];
    copyArray.map(arrayValue => {
      if (arrayValue[key].includes(searchString)) {
        arrayValue.flag = true;
      }
    });
    for(let i = 0; i < array.length; i++) {
      if (copyArray[i].flag) {
        returnArray.push(array[i]);
      }
    }
    return returnArray
  }

  // Get unique values of array
  getUniqueValuesV2(array, key) {
    const valueArray = [];
    array.map(value => {
      if(Array.isArray(value[key])){
        valueArray.push(value[key].join(","));
      }else{
        valueArray.push(value[key]);
      }
     
    });

    const uniqueArray = [...new Set(valueArray)];
    const returnArray = [];
    uniqueArray.map(uniqueValue => {
      returnArray.push({value: uniqueValue, selected: false, show: true});
    })
    return returnArray;
  }

  // fillup filter object for given array
  getFiltersV2(dataArray, filters) {
    const keys = Object.keys(filters);
    keys.map(key => {
      filters[key].uniqueValues = this.getUniqueValuesV2(dataArray, key);
    })
    return filters;
  }

  // filter data
   filterDataV2(dataArray, filters) {
    let copyArray = JSON.parse(JSON.stringify(dataArray));
    copyArray.map(arrayValue => {
      arrayValue.flag = true;
    })
    const keys = Object.keys(filters);
    let returnArray = [];
    keys.map(key => {
      if (filters[key].active && filters[key].values.length > 0) {
        copyArray.map(arrayValue => {
          arrayValue.flag = false;
          filters[key].values.map(filterValue => {
            if (filterValue == arrayValue[key]) {
              arrayValue.flag = true;
            }
          })
        })
        copyArray.map(arrayValue => {
          if (arrayValue.flag) {
            returnArray.push(arrayValue);
          }
        })
        copyArray = JSON.parse(JSON.stringify(returnArray));
        returnArray = [];
      }
    })
    
    copyArray.map(arrayValue => {
      if (arrayValue.flag) {
        returnArray.push(arrayValue);
      }
    })
    return returnArray;
  }

  // search in filter
  searchInFilter(filter) {
    filter.searchString = filter.searchString.toUpperCase();    
    filter.uniqueValues.map(uniqueVal => {
      uniqueVal.copyValue = uniqueVal.value.toUpperCase();
      if (uniqueVal.copyValue.includes(filter.searchString)) {
        uniqueVal.show = true;
      } else {
        uniqueVal.show = false;
      }
    })
    return filter;
  }

  // search and filter for expiry credit note
  // filter expiry credit note data
  filterExpiryDataV2(dataArray, filters) {
    const arr = [];
    dataArray.map(val => {
      arr.push(val.retailerCreditNote)
    })
    let copyArray = JSON.parse(JSON.stringify(arr));
    copyArray.map(arrayValue => {
      arrayValue.flag = true;
    })
    const keys = Object.keys(filters);
    let returnArray = [];
    keys.map(key => {
      if (filters[key].active && filters[key].values.length > 0) {
        copyArray.map(arrayValue => {
          arrayValue.flag = false;
          filters[key].values.map(filterValue => {
            if (filterValue == arrayValue[key]) {
              arrayValue.flag = true;
            }
          })
        })
        copyArray.map(arrayValue => {
          if (arrayValue.flag) {
            returnArray.push(arrayValue);
          }
        })
        copyArray = JSON.parse(JSON.stringify(returnArray));
        returnArray = [];
      }
    })
    
    copyArray.map(arrayValue => {
      if (arrayValue.flag) {
        returnArray.push(arrayValue);
      }
    })

    const expiryReturnArray = [];
    dataArray.map(val => {
      returnArray.map(returnVal => {
        if (returnVal.invoiceNumber == val.retailerCreditNote.invoiceNumber) {
          expiryReturnArray.push(val);
        }
      })
    })
    return expiryReturnArray;
  }

  // fillup filter object for given expiry credit note array
  getExpiryFiltersV2(dataArray, filters) {
    const arr = [];
    dataArray.map(val => {
      arr.push(val.retailerCreditNote)
    })
    const keys = Object.keys(filters);
    keys.map(key => {
      filters[key].uniqueValues = this.getUniqueValuesV2(arr, key);
    })
    return filters;
  }

}
