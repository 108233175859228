import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollEventService {

  static SUB_HEADING = {
    // home: 0,
    // screens: 1,
    // colors: 2,
    // typography: 3,
    // icons: 4,
    // components: 5,


  };

  private actionMainEmitter: EventEmitter<Number> = new EventEmitter<Number>();
  private mCurrentHeading: Number = 1;

  constructor() { }

  emitHeadingEvent(heading: Number) {

    if(this.mCurrentHeading == heading)return
    this.mCurrentHeading = heading
    this.actionMainEmitter.emit(heading);
  }

  getActionMainEmitter(): EventEmitter<Number> {
    return this.actionMainEmitter;
  }
}
