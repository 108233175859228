import { Component, OnInit, Inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { AdminService } from "src/app/core/services/api/admin.service";
import { LogisticsService } from "src/app/core/services/api/logistics.service";
import { EventService } from "src/app/core/services/event/event.service";
import { PopupService } from "src/app/core/services/popup/popup.service";
import { DialogModelCaCollection } from "./model";

@Component({
  selector: "app-ca-collection",
  templateUrl: "./ca-collection.component.html",
  styleUrls: ["./ca-collection.component.scss"],
})
export class CaCollectionComponent implements OnInit {
  invNo = "";
  signSlip = "";
  payments = [];
  deAmount = 0;
  deComment = "";
  otherAmount = 0;
  otherComment = "";
  retailerAmount = 0;
  retailerComment = "";
  billingAmount = 0;
  billingComment = "";
  totalDeficitAmount = 0;
  medicines = [];
  showItems = false;
  collectAmount = 0;
  paymentModes = [];
  tableColumns = [
    "Date",
    "MOP",
    "Amount",
    "Avl. to Adjust",
    "Bank",
    "UTR No",
    "Select",
  ];
  PAYMENT_OBJECT = {
    amountEntered: null,
    searchString: "",
    utrSuggestionList: [],
    page: 1,
    totalSearchCount: "",
    totalPages: "",
    selectedUTRNumber: "",
    selectedUTRID: "",
    showChangeMOPSelect: false,
    confirmDisabled: true,
    paymentConfirmed: false,
    mappedUTRObject: {
      tableColumns: ["Date", "MOP", "UTR Amount", "Bank", "UTR No", ""],
      date: "",
      MOP: "",
      utrAmount: "",
      bank: "",
      utrNumber: "",
    },
    resultString: "",
    cashDepositAmount: 0,
    cashDepositDate: "",
    showUTREdit: false,
    selectedFilters: [],
    selectedFilterName: "",
    selectedFilterValue: "",
    filteredOptions: "",
  };
  utrExclusions = [
    "cash",
    "credit note",
    "post dated cheque",
    "cheque",
    "other",
  ];
  changeMOPExclusions = ["post dated cheque", "cheque"];
  OUTSTANDING_OBJECT = {
    amount: null,
    reason: "DE",
    comment: "",
    totalOSBalance: 0,
    osConfirmed: false,
  };
  MAPPED_UTR_OBJECT = {
    tableColumns: ["Date", "MOP", "UTR Amount", "Bank", "UTR No", ""],
    date: "",
    MOP: "",
    utrAmount: "",
    bank: "",
    utrNumber: "",
  };

  totalOutstanding = [];
  totalAmountReconciled = 0;
  collectEnable = false;
  PAYMENT_KEYS = [
    "actionDate",
    "amountPaid",
    "billNumber",
    "caToWarehouseConfirmedTimestamp",
    "confirmedAmount",
    "confirmedByMobileNumber",
    "confirmedByName",
    "createdBy",
    "createdTimestamp",
    "extraForSaveo",
    "financeExecutiveVerifiedBy",
    "financeExecutiveVerifiedTimestamp",
    "financeRejected",
    "financeVerified",
    "id",
    "imageUrl",
    "instrumentNumber",
    "invoiceNumber",
    "loanIdentifier",
    "paymentMode",
    "retailerCode",
    "signSlipId",
    "submittedBy",
    "throughCSV",
    "txnId",
    "updatedBy",
    "updatedTimestamp",
    "utrNoId",
    "verified",
    "verifiedAmount",
  ];
  showShakeAnimation = false;
  availableSearchFilters = []; //filters for the utr search
  availableBankAccounts = [];
  filteredOptions: Observable<string[]>;
  myControl = new FormControl("");

  constructor(
    public dialogRef: MatDialogRef<CaCollectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelCaCollection,
    private popupService: PopupService,
    private logisticsService: LogisticsService,
    private adminService: AdminService,
    private eventService: EventService
  ) {}

  ngOnInit(): void {
    this.invNo = this.data.invoiceDetails.invoice.invoiceNumber;
    this.signSlip = this.data.invoiceDetails.invoice.signSlipId;

    // ca_collect_popup_open event
    this.triggerEvent("ca_collect_popup_open");

    this.popupService.openProgress();
    this.getPaymentModes(true);

    // add one outstanding object to the total outstanding array
    this.totalOutstanding.push(
      JSON.parse(JSON.stringify(this.OUTSTANDING_OBJECT))
    );
  }

  // get all available payment modes
  getPaymentModes(closeProgress) {
    this.logisticsService.getPaymentModes().subscribe(
      (response) => {
        this.paymentModes = response.data;
        this.getAvailableBankAccounts(closeProgress);
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  // get all available bank accounts
  getAvailableBankAccounts(closeProgress) {
    this.logisticsService.getBankAccounts().subscribe(
      (response) => {
        this.availableBankAccounts = response.data;
        this.getAvailableUtrSearchFilters(closeProgress);
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  // get all filters available for the utr search
  getAvailableUtrSearchFilters(closeProgress) {
    this.logisticsService.getUtrSearchFilters().subscribe(
      (response) => {
        this.availableSearchFilters = response.data;
        this.getInvoiceData(closeProgress);
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  // get invoice data
  getInvoiceData(closeProgress) {
    this.logisticsService
      .getCaCollectionInvoiceDetails(this.invNo, this.signSlip)
      .subscribe(
        (response) => {
          this.payments = response.data;
          this.collectAmount = 0;

          // push the payment object into each payments
          this.payments.forEach((item, index) => {
            this.payments[index] = {
              ...item,
              ...JSON.parse(JSON.stringify(this.PAYMENT_OBJECT)),
            };
            this.payments[index].osAmt = 0;
          });

          this.payments.map((payment) => {
            payment.showEditTransactionId = false;
            this.collectAmount = this.collectAmount + payment.amountPaid;
          });
          // call the utr search api
          this.getUtrData(closeProgress);
        },
        (error) => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      );
  }

  // Get Invoices
  getInvoices() {
    this.popupService.openProgress();
    this.adminService
      .getInvoiceItems({
        invoiceNumber: this.data.invoiceDetails.invoice.invoiceNumber,
      })
      .subscribe(
        (response) => {
          this.medicines = response.data;
          this.showItems = true;
          this.popupService.closeProgress();
        },
        (error) => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      );
  }

  // Submit deficit amount for billing reason
  storeBillingReason() {
    this.popupService.openProgress();
    const body = this.data.invoiceDetails.invoice;
    body.source = "CA_TO_WAREHOUSE";
    body.deficitReason = "BILLING";
    body.deficitAmount = this.billingAmount;
    body.comment = this.billingComment;
    body.deficitItemDetailList = [];
    this.medicines.map((medicine) => {
      if (medicine.deficitAmount > 0) {
        body.deficitItemDetailList.push(medicine);
      }
    });
    this.adminService.storeBillingReasonSelection({}, body).subscribe(
      (response) => {
        this.showItems = false;
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data);
      },
      (error) => {
        this.showItems = false;
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  // submit deficit amount
  submitDeficit(amount, reason, comment) {
    this.popupService.openProgress();
    const body = this.data.invoiceDetails.invoice;
    body.source = "CA_TO_WAREHOUSE";
    body.deficitReason = reason;
    body.deficitAmount = amount;
    body.comment = comment;
    if (reason == "RETAILER") {
      this.storeRetailerReason(body);
    } else if (reason == "DELIVERY_EXECUTIVE") {
      this.storeDEReason(body);
    } else if (reason == "OTHER") {
      this.storeOtherReason(body);
    }
  }

  // Submit deficit amount for other reason
  storeOtherReason(body) {
    this.adminService.storeOtherReasonSelection({}, body).subscribe(
      (response) => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data);
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  // Submit deficit amount for retailer reason
  storeRetailerReason(body) {
    this.adminService.storeRetailerReasonSelection({}, body).subscribe(
      (response) => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data);
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  // Submit deficit amount for DE reason
  storeDEReason(body) {
    this.adminService.storeDEReasonSelection({}, body).subscribe(
      (response) => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data);
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  // open proof
  openProof(image) {
    this.popupService.openImagePopup(image);
  }

  // back
  back() {
    this.showItems = false;
  }

  // close popup
  closePopup() {
    // ca_collect_popup_close event
    this.triggerEvent("ca_collect_popup_close");
    this.dialogRef.close();
  }

  // collect invoice
  collect() {
    // ca_collect_click event
    this.collectEnable = false;
    this.triggerEvent("ca_collect_click");

    // this.popupService.openProgress();
    let totalAmount = 0;
    let paymentData = JSON.parse(JSON.stringify(this.payments));
    paymentData.map((payment) => {
      totalAmount = totalAmount + payment.verifiedAmount;
      payment.verifiedAmount = payment.amountEntered;
      payment.selectedUTRNumber.length > 0
        ? (payment.instrumentNumber = payment.selectedUTRNumber)
        : (payment.instrumentNumber = payment.instrumentNumber);
      payment.selectedUTRID.toString().length > 0
        ? (payment.utrNoId = payment.selectedUTRID)
        : (payment.utrNoId = "");
      //remove the keys not in payment keys array
      // this may cause some of the input fields to nit render properly due to the missing keys -> FIX : clone the payments object to the scope of this function
      Object.keys(payment).forEach((key, _) => {
        if (!this.PAYMENT_KEYS.includes(key)) delete payment[key];
      });
    });

    // setup body
    let body = {
      creditBillVerifiedPaymentRequestDTO: {
        billPayments: paymentData,
        // total outstading amount entered in the o/s section
        totalOutstandingAmount: this.totalOutstanding[0].amount,
      },
      deIssue: { ...this.data.invoiceDetails.invoice },
    };

    // de issue details
    body.deIssue.deficitReason = "DELIVERY_EXECUTIVE";
    body.deIssue.deficitAmount = this.totalOutstanding[0].amount;
    body.deIssue.comment = this.totalOutstanding[0].comment;
    body.deIssue.source = "CA_TO_WAREHOUSE";

    this.logisticsService.collectCaInvoice({}, body).subscribe(
      (response) => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data.message);
        // ca_collect_popup_close event
        this.triggerEvent("ca_collect_popup_close");
        setTimeout(() => {
          this.dialogRef.close();
        }, 2000);
      },
      (error) => {
        this.popupService.closeProgress();
        this.collectEnable = true;
        this.popupService.openError(
          error.error.error.error || "something went wrong"
        );
      }
    );
  }

  collectIndividual(index) {
    // ca_collect_click event
    this.collectEnable = false;
    this.triggerEvent("ca_collect_click");

    // this.popupService.openProgress();
    let totalAmount = 0;
    let paymentData = JSON.parse(JSON.stringify(this.payments[index]));

    // paymentData.map((payment) => {
    totalAmount = totalAmount + paymentData.verifiedAmount;
    paymentData.verifiedAmount = paymentData.amountEntered;
    paymentData.selectedUTRNumber.length > 0
      ? (paymentData.instrumentNumber = paymentData.selectedUTRNumber)
      : (paymentData.instrumentNumber = paymentData.instrumentNumber);
    paymentData.selectedUTRID.toString().length > 0
      ? (paymentData.utrNoId = paymentData.selectedUTRID)
      : (paymentData.utrNoId = "");
    //remove the keys not in payment keys array
    // this may cause some of the input fields to nit render properly due to the missing keys -> FIX : clone the payments object to the scope of this function
    Object.keys(paymentData).forEach((key, _) => {
      if (!this.PAYMENT_KEYS.includes(key)) delete paymentData[key];
    });
    // });

    // setup body
    let body = {
      creditBillVerifiedPaymentRequestDTO: {
        billPayments: [paymentData],
        // total outstading amount entered in the o/s section
        totalOutstandingAmount: this.payments[index].osAmt,
      },
      deIssue: { ...this.data.invoiceDetails.invoice },
    };

    // de issue details
    body.deIssue.collectAmount = this.payments[index].amountPaid;
    body.deIssue.deficitReason = "DELIVERY_EXECUTIVE";
    body.deIssue.deficitAmount = this.payments[index].osAmt;
    body.deIssue.comment = this.payments[index].osComment;
    body.deIssue.source = "CA_TO_WAREHOUSE";

    this.logisticsService.collectCaInvoice({}, body).subscribe(
      (response) => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data.message);
        // ca_collect_popup_close event
        this.triggerEvent("ca_collect_popup_close");

        this.searchInvoice(true);
      },
      (error) => {
        this.popupService.closeProgress();
        this.collectEnable = true;
        this.popupService.openError(
          error.error.error.error || "something went wrong"
        );
      }
    );
  }

  searchInvoice(closeProgress) {
    const params = {
      filterType: "INVOICENUMBER",
      searchString: this.invNo,
      page: 1
    }

    this.logisticsService.searchInvoicesAtLogistics(params).subscribe(
      response => {
        if (response.data.length < 1) {
          setTimeout(() => {
            this.dialogRef.close();
          }, 2000);
        } else {
          this.data.invoiceDetails.invoice = response.data[0];
          this.invNo = response.data[0].invoiceNumber;
          this.signSlip = response.data[0].signSlipId;

          this.getInvoiceData(closeProgress);
        }
      }, error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      })
  }

  // show edit transaction id
  editTransactionId(payment) {
    payment.showEditTransactionId = true;
  }

  // hide edit transaction id
  removeTransactionIdEdit(payment) {
    payment.showEditTransactionId = false;
  }

  // get utr data on load for all the payments received
  getUtrData(closeProgress: boolean, type = "default") {
    // open loader
    this.popupService.openProgress();
    // get utr suggestions list for each payment not in utr exclusions
    this.payments.forEach((payment, index) => {
      // only get the search results for the items not in utr exclusions
      if (!this.utrExclusions.includes(payment.paymentMode)) {
        let path = {
          utrno:
            type === "manual" ? payment.searchString : payment.instrumentNumber,
          page: payment.page,
          modeOfPayment: payment.paymentMode,
        };

        this.logisticsService.getAllBankStatementWithUTRNo(path).subscribe(
          (response) => {
            // update the result search string to show in the table
            type === "manual"
              ? (payment.resultString = payment.searchString)
              : (payment.resultString = "");
            // update the utr suggestions list
            this.payments[index].utrSuggestionList =
              response.data.bankStatementResponseList;
            // calculate the pages available
            let pages = Math.floor(response.data.count / 5);
            response.data.count % 5 > 0 ? (pages += 1) : "";
            this.payments[index].totalPages = pages;
            // update the total search count
            this.payments[index].totalSearchCount = response.data.count;

            // close the progress for the last item
            if (closeProgress && index === this.payments.length - 1) {
              this.popupService.closeProgress();
            }
          },
          (error) => {
            this.popupService.closeProgress();
            this.popupService.openError(error.error.error.error);
          }
        );
      } else {
        // if no payments require the utr suggestion list, close the progress
        this.popupService.closeProgress();
      }
    });
  }

  // get utr data for a certain payment
  getUtrDataForPayment(index: number, type = "default", showProgress = true) {
    let payment = this.payments[index];
    // update the search type
    payment.searchString.length > 0 || payment.selectedFilters.length > 0
      ? (type = "manual")
      : (type = "default");
    // open loader
    if (showProgress) this.popupService.openProgress();
    let params = {};
    // build the query params
    if (type === "default") {
      params = {
        utrno: payment.instrumentNumber,
        page: payment.page,
      };
    } else {
      let filters = {
        modeOfPayment: "",
        amount: "",
        bankName: "",
        narration: "",
        utrno: "",
        date: "",
      };
      // update the filters object
      this.payments[index].selectedFilters.forEach((filter, _) => {
        if (filter.filterName === "PaymentMode")
          filters.modeOfPayment = filter.filterValue;
        else if (filter.filterName === "Amount")
          filters.amount = filter.filterValue;
        else if (filter.filterName === "BankName")
          filters.bankName = filter.filterValue;
        else if (filter.filterName === "Narration")
          filters.narration = filter.filterValue;
        else if (filter.filterName === "UTR")
          filters.utrno = filter.filterValue;
        else if (filter.filterName === "DepositDate")
          filters.date = filter.filterValue;
      });

      params = {
        page: payment.page,
        ...filters,
      };

      Object.keys(params).forEach((key) => {
        if (!params[key]) delete params[key];
      });
    }

    // empty the responses list
    payment.utrSuggestionList = [];

    this.logisticsService.getAllBankStatementWithUTRNo(params).subscribe(
      (response) => {
        // update the result search string to show in the table
        type === "manual"
          ? (payment.resultString = payment.searchString)
          : (payment.resultString = "");
        // update the utr suggestions list
        this.payments[index].utrSuggestionList =
          response.data.bankStatementResponseList;
        // calculate the pages available
        let pages = Math.floor(response.data.count / 5);
        response.data.count % 5 > 0 ? (pages += 1) : "";
        this.payments[index].totalPages = pages;
        // update the total search count
        this.payments[index].totalSearchCount = response.data.count;

        // close the progress for the last item
        if (showProgress) this.popupService.closeProgress();

        // reset the search string for manual search
        if (type === "manual") {
          this.payments[index].selectedFilterName = "";
          this.payments[index].searchString = "";
        }
      },
      (error) => {
        if (showProgress) this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  // handler to update the UTR item selection
  updateUTRselect(event, suggestionIndex, paymentIndex) {
    let payment = this.payments[paymentIndex];
    // ca_collect_utr_selected event
    let data = {
      mop: payment.paymentMode,
      amount: payment.amountEntered,
      paymentId: paymentIndex,
      creditNoteNumber:
        payment.paymentMode === "credit note" ? payment.instrumentNumber : "",
      chequeNo:
        payment.paymentMode === "cheque" ? payment.instrumentNumber : "",
      utr: payment.selectedUTRNumber,
    };
    // delete the empty keys
    Object.keys(data).forEach((key, _) => {
      if (!data[key]) delete data[key];
    });
    this.triggerEvent("ca_collect_utr_selected", data);

    this.payments[paymentIndex].selectedUTRNumber = event.value;

    this.payments[paymentIndex].selectedUTRID =
      this.payments[paymentIndex].utrSuggestionList[suggestionIndex].utrNoId;

    let selectedUTR =
      this.payments[paymentIndex].utrSuggestionList[suggestionIndex];

    // checks to engforce on utr selection
    if (
      this.checkUTRBalance(
        selectedUTR,
        this.payments[paymentIndex].amountEntered
      )
    ) {
      // above method returns a flag that indicates, if an error is raised
      // if error raised, reset the selectedUTR data and return
      this.payments[paymentIndex].selectedUTRNumber = "";
      this.payments[paymentIndex].selectedUTRID = "";
      this.handleManualUTRSearch(paymentIndex, false);
      this.checkConfirmEnable(paymentIndex);
      return;
    }

    // this method returns a flag if the utr has been totally consumed in any other payments
    // in this scenario, reset the utr selection
    if (this.checkUtrSelection(paymentIndex)) {
      this.payments[paymentIndex].selectedUTRNumber = "";
      this.payments[paymentIndex].selectedUTRID = "";
      this.handleManualUTRSearch(paymentIndex, false);
      this.checkConfirmEnable(paymentIndex);
      return;
    }

    // update the mapped utr object
    this.payments[paymentIndex].mappedUTRObject.date = selectedUTR.valueDate;
    this.payments[paymentIndex].mappedUTRObject.MOP = selectedUTR.modeOfPayment;
    this.payments[paymentIndex].mappedUTRObject.utrAmount =
      selectedUTR.unsettledAmount;
    this.payments[paymentIndex].mappedUTRObject.bank = selectedUTR.bankName;
    this.payments[paymentIndex].mappedUTRObject.utrNumber =
      selectedUTR.utrnumber;
    this.payments[paymentIndex].mappedUTRObject.narration =
      selectedUTR.narration;

    // check the confirm button enable case post update
    this.checkConfirmEnable(paymentIndex);
  }

  // handler to navigate the pages in table
  handlePageNav(type, index: number) {
    // handle the first page and last page edge cases
    type === "next"
      ? (this.payments[index].page += 1)
      : type === "prev"
      ? (this.payments[index].page -= 1)
      : "";
    // hit the search utr api with updated page number
    this.getUtrDataForPayment(index);
  }

  // handler to change the MOP for a certain payment item
  handleChangeMOP(index: number) {
    //ca_collect_mop_change_click event
    let data = {
      mop: this.payments[index].paymentMode,
      paymentId: index,
    };
    this.triggerEvent("ca_collect_mop_change_click", data);

    //  update the show mop container key
    this.payments[index].showChangeMOPSelect = true;
  }

  // handler to close the change MOP option
  handleMOPBlur(index: number) {
    this.payments[index].showChangeMOPSelect = false;
  }

  // handler to run side effects on changing MOP
  handleMOPSelectionChange(index: number) {
    // ca_collect_mop_select event
    let data = {
      mop: this.payments[index].paymentMode,
      paymentId: index,
    };
    this.triggerEvent("ca_collect_mop_select", data);

    // mop maybe changed when an existing utr suggestion list is present, in this case empty the utr suggestion list; reset the search string, result string; refresh the utr suggestion list
    this.payments[index].utrSuggestionList = [];
    this.payments[index].searchString = "";
    this.payments[index].resultString = "";
    this.handleManualUTRSearch(index);

    this.payments[index].showChangeMOPSelect = false;
    // edge case where the confirm button doesn't enable cus the entered amount doesn't trigger the onChange handler
    // check for the confirm button enable manually in this scenario
    this.checkConfirmEnable(index);
    // the user might change the MOP after mapping is done without clicking on the edit button
    // in this case reset all the data during the edit button click and proceed
    this.handleMappedEditClick(index);
    // update the confirmed payment state
    this.payments[index].paymentConfirmed = false;
    // recalculate outstanding and reconciled and collect button enable
    this.checkCollectButtonEnable();
  }

  // handler for manual utr search button
  handleManualUTRSearch(index: number, showProgress = true) {
    // reset the page number to 1
    this.payments[index].page = 1;
    this.getUtrDataForPayment(index, "manual", showProgress);
  }

  // handler to check for the negative values on enter amount
  handleAmountInput(event, index: number) {
    if (event.target.value < 0) {
      event.target.value = Math.abs(event.target.value);
      this.payments[index].amountEntered = Math.abs(event.target.value);
    } else if (event.target.value > this.payments[index].amountPaid) {
      event.target.value = this.payments[index].amountPaid;
      this.payments[index].amountEntered = this.payments[index].amountPaid;
    }

    // enforce checks based on selected utr
    if (this.checkUTRBalanceAmountChange(index)) {
      // above method throws an error for the enforced checks and returns a boolean
      // reset the selected utr if error is raised
      this.payments[index].selectedUTRNumber = "";
      this.payments[index].selectedUTRID = "";
      // WHEN THE ERROR IS THROWN, REFRESH THE SEARCH TABLE
      this.handleManualUTRSearch(index, false);
      this.checkConfirmEnable(index);
    }

    // this method returns a flag if the utr has been totally consumed in any other payments
    // in this scenario, reset the utr selection
    if (this.checkUtrSelection(index)) {
      this.payments[index].selectedUTRNumber = "";
      this.payments[index].selectedUTRID = "";
      this.handleManualUTRSearch(index, false);
      this.checkConfirmEnable(index);
      return;
    }

    // hit amount change on enter press
    if (event.keycode === 13) {
      this.handleAmountChange(index);
    }

    // reset the confirmed state on change
    if (this.payments[index].paymentConfirmed) {
      this.payments[index].paymentConfirmed = false;
    }

    // check confirm button enable
    this.checkConfirmEnable(index);

    // calculate o/s and reconciled amount and collect button enable
    this.checkCollectButtonEnable();

    // on enter click run the side effect to confirm the button
    if (event.keyCode === 13) {
      // if confirm button is enabled, hit the confirm button handler
      if (this.utrExclusions.includes(this.payments[index].paymentMode)) {
        this.handleConfirmClick(index);
      }
    }
  }

  // handler to run side effects on amount enter change
  handleAmountChange(index: number) {
    // ca_collect_amount_entered event
    let data = {
      mop: this.payments[index].paymentMode,
      amount: this.payments[index].amountEntered,
      paymentId: index,
    };
    this.triggerEvent("ca_collect_amount_entered", data);

    // check confirm button enable
    this.checkConfirmEnable(index);
    // calculate o/s and reconciled amount and collect button enable
    this.checkCollectButtonEnable();
  }

  // calculates outstanding amount
  calculateOutstandingAmount() {
    let totalAmountConfirmed = 0;
    // add the amount entered in all payments
    this.payments.forEach((payment, _) => {
      // amount entered can be zero when no utr is selected
      // add the amount paid to outstanding
      totalAmountConfirmed +=
        payment.amountEntered === null ? 0 : payment.amountEntered;
    });
    // add the amount entered in outstanding
    totalAmountConfirmed += parseInt(
      this.totalOutstanding[0].amount === null
        ? "0"
        : this.totalOutstanding[0].amount
    );

    // calculate the total outstanding
    this.totalOutstanding[0].totalOSBalance =
      this.data.invoiceDetails.invoice.collectAmount - totalAmountConfirmed;
  }

  // calculate reconciled amount
  calculateReconciledAmount() {
    let totalAmountRecon = 0;
    // add the confirmed amount in all payments
    this.payments.forEach((payment, _) => {
      if (payment.paymentConfirmed) totalAmountRecon += payment.amountEntered;
    });
    // add the amount in the outstanding object
    if (this.totalOutstanding[0].osConfirmed)
      totalAmountRecon += this.totalOutstanding[0].amount;
    // update the reconciled amount
    this.totalAmountReconciled = totalAmountRecon;
  }

  // check confirm enable for a certain payment
  checkConfirmEnable(index: number) {
    let payment = this.payments[index];

    if (this.utrExclusions.includes(payment.paymentMode)) {
      // dont check if any utr is selected
      // we donot show the search utr table for this type of payment
      // check only if amount > 0
      // amount can't be 0 for this kind of payment
      if (payment.amountEntered >= 0) payment.confirmDisabled = false;
      else payment.confirmDisabled = true;

      if (payment.amountEntered === null) payment.confirmDisabled = true;
    } else {
      if (payment.amountEntered === 0 && payment.selectedUTRNumber) {
        payment.confirmDisabled = true;
      } else if (payment.amountEntered === null && payment.selectedUTRNumber) {
        payment.confirmDisabled = true;
      } else if (payment.amountEntered === null && !payment.selectedUTRNumber) {
        payment.confirmDisabled = true;
      } else if (payment.amountEntered === 0 && !payment.selectedUTRNumber) {
        payment.confirmDisabled = false;
      } else if (payment.amountEntered > 0 && !payment.selectedUTRNumber) {
        payment.confirmDisabled = true;
      } else if (payment.amountEntered > 0 && payment.selectedUTRNumber) {
        payment.confirmDisabled = false;
      }
    }
  }

  // handler to run side effects on confirm button click
  handleConfirmClick(index: number) {
    let payment = this.payments[index];

    // ca_collect_payment_confirm event
    let data = {
      mop: this.payments[index].paymentMode,
      amount: this.payments[index].amountEntered,
      paymentId: index,
      creditNoteNumber:
        payment.paymentMode === "credit note" ? payment.instrumentNumber : "",
      chequeNo:
        payment.paymentMode === "cheque" ? payment.instrumentNumber : "",
      utr: payment.selectedUTRNumber,
      cashDepositDate: this.payments[index].cashDepositDate,
    };
    // delete the empty keys
    Object.keys(data).forEach((key, _) => {
      if (!data[key]) delete data[key];
    });
    this.triggerEvent("ca_collect_payment_confirm", data);

    payment.paymentConfirmed = true;
    
    payment.osAmt = this.payments[index].amountPaid - this.payments[index].amountEntered;

    // calculate outstanding and amount reconciled and collect button enable
    this.checkCollectEnableNew(index);
  }

  // checks to enable the final collect button
  checkCollectEnableNew(index) {
    let paymentConfirmed = this.payments[index].paymentConfirmed;
    let amtCheck = (
      this.payments[index].amountEntered + this.payments[index].osAmt
    ).toFixed(2);

    this.payments[index].amountPaid.toFixed(2) === amtCheck && paymentConfirmed
      ? (this.payments[index].collectEnable = true)
      : (this.payments[index].collectEnable = false);
  }

  // handler to run side effects on os confirm button
  handleOSConfirmClick() {
    // ca_collect_os_amount_confirm event
    let data = {
      osAmount: this.totalOutstanding[0].amount,
      osReason: this.totalOutstanding[0].reason,
      osComments: this.totalOutstanding[0].comment,
    };
    this.triggerEvent("ca_collect_os_amount_confirm", data);

    this.totalOutstanding[0].osConfirmed = true;
    // update outstanding and reconcilation amount and collect button enable
    this.checkCollectButtonEnable();
  }

  // key up handler for the os amount input
  handleOSAmountKeyUpNew(event, index) {
    if (event.keyCode === 13) this.handleOsAmountChangeNew(event, index);
    // reset the confirmed state
    this.payments[index].osConfirmed = false;
    // check for the negative values
    if (event.target.value < 0) {
      event.target.value = Math.abs(event.target.value);
      this.payments[index].osAmt = Math.abs(event.target.value);
    } else if (event.target.value === null) {
      event.target.value = 0;
      this.payments[index].osAmt = 0;
    }
    // trigger the confirm button click on retrun key press
    if (event.keyCode === 13) this.handleOSConfirmClickNew(index);
  }

  // handler to run side effects on os amount change
  handleOsAmountChangeNew(event, index) {
    // ca_collect_os_amount_entered event
    let data = {
      osAmount: this.payments[index].totalOutstandingAmount,
      osReason: this.payments[index].osReason,
    };
    this.triggerEvent("ca_collect_os_amount_entered", data);

    if (event.target.value === null) {
      event.target.value = 0;
      this.payments[index].totalOutstandingAmount = 0;
    }
    // on os amount change, recalculate the outstanding and reconciled amount and collect button enable
    this.checkCollectButtonEnable();
  }

  handleOSConfirmClickNew(index) {
    // ca_collect_os_amount_confirm event
    let data = {
      osAmount: this.payments[index].osAmt,
      osReason: this.payments[index].osReason,
      osComments: this.payments[index].osComment,
    };
    this.triggerEvent("ca_collect_os_amount_confirm", data);

    this.payments[index].osConfirmed = true;
    // update outstanding and reconcilation amount and collect button enable
    this.checkCollectEnableNew(index);
  }

  // key up handler for the os amount input
  handleOSAmountKeyUp(event) {
    if (event.keyCode === 13) this.handleOsAmountChange(event);
    // reset the confirmed state
    this.totalOutstanding[0].osConfirmed = false;
    // check for the negative values
    if (event.target.value < 0) {
      event.target.value = Math.abs(event.target.value);
      this.totalOutstanding[0].amount = Math.abs(event.target.value);
    } else if (event.target.value === null) {
      event.target.value = 0;
      this.totalOutstanding[0].amount = 0;
    }
    // trigger the confirm button click on retrun key press
    if (event.keyCode === 13) this.handleOSConfirmClick();
  }

  // handler to run side effects on os amount change
  handleOsAmountChange(event) {
    // ca_collect_os_amount_entered event
    let data = {
      osAmount: this.totalOutstanding[0].amount,
      osReason: this.totalOutstanding[0].reason,
    };
    this.triggerEvent("ca_collect_os_amount_entered", data);

    if (event.target.value === null) {
      event.target.value = 0;
      this.totalOutstanding[0].amount = 0;
    }
    // on os amount change, recalculate the outstanding and reconciled amount and collect button enable
    this.checkCollectButtonEnable();
  }

  // handler to run side effects on mapped edit button click
  handleMappedEditClick(index: number) {
    // reset the payment confirmed state
    this.payments[index].paymentConfirmed = false;
    // remove the mapped object in the payment item
    this.payments[index].mappedUTRObject = JSON.parse(
      JSON.stringify(this.MAPPED_UTR_OBJECT)
    );
    // also remove selected utr and utr id fields
    this.payments[index].selectedUTRNumber = "";
    this.payments[index].selectedUTRID = "";

    // check confirm button enable status
    this.checkConfirmEnable(index);

    // recalculate the os and recon amount
    this.checkCollectButtonEnable();

    // focus on the amount input element
    document.getElementById(`amountInput${index}`).focus();
  }

  // handler to reset any negative values entered in input fields
  handleNegativeValueInput(event, index: number) {
    if (event.target.value < 0) {
      event.target.value = Math.abs(event.target.value);
      this.payments[index].cashDepositAmount = Math.abs(event.target.value);
    }
  }

  // handler to edit the utr number for the utr exclusions payments
  handleEditUTRClick(index: number) {
    this.payments[index].showUTREdit = true;
  }

  // handler to close the edit UTR button
  handleUTREditBlur(index: number) {
    this.payments[index].showUTREdit = false;
  }

  // close the utr editor for the utr exclusion payments
  closeUtrEditor(index: number) {
    this.payments[index].showUTREdit = false;
  }

  // checks to enable the final collect button
  checkCollectButtonEnable() {
    // update the o/s and recon amount
    this.calculateOutstandingAmount();
    this.calculateReconciledAmount();

    let allPaymentsConfirmed = this.payments.every(
      (payment) => payment.paymentConfirmed
    );

    this.totalAmountReconciled.toFixed(2) ==
      this.data.invoiceDetails.invoice.collectAmount.toFixed(2) &&
    allPaymentsConfirmed
      ? (this.collectEnable = true)
      : (this.collectEnable = false);
  }

  // check utr available balance checks
  checkUTRBalance(selectedUTR, amountEntered) {
    let flag = false;
    if (amountEntered && amountEntered > selectedUTR.unsettledAmount) {
      this.popupService.openError(
        "Amount entered cannot be greater than the amount available to adjust"
      );
      flag = true;
    }
    return flag;
  }

  // check utr balance on amount change
  checkUTRBalanceAmountChange(index: number) {
    if (!this.payments[index].selectedUTRNumber) return;
    let flag = false;
    // get the selected utr object
    let selectedUTR = null;
    this.payments[index].utrSuggestionList.forEach((utr) => {
      if (utr.utrNoId === this.payments[index].selectedUTRID) {
        selectedUTR = utr;
        return;
      }
    });
    // compare if the amount entered is greater than that of selected utr
    if (this.payments[index].amountEntered > selectedUTR.unsettledAmount) {
      this.popupService.openError(
        "Amount entered cannot be greater than the amount available to adjust"
      );
      flag = true;
    }
    return flag;
  }

  // handler to trigger the shake animation on the search info
  handleShake() {
    this.showShakeAnimation = true;
    setTimeout(() => {
      this.showShakeAnimation = false;
    }, 300);
  }

  // triggers the events available
  triggerEvent(eventName, data = {}) {
    // common data for the events in this screen
    let body = {
      eventName: eventName,
      screen_name: "ca_to_warehouse_popup",
      invoiceNumber: this.data.invoiceDetails.invoice.invoiceNumber,
      invoiceAmount: this.data.invoiceDetails.invoice.invoiceValue,
      reconciliationAmount: this.data.invoiceDetails.invoice.collectAmount,
    };

    body = { ...body, ...data };
    // hit the final track api
    this.eventService.trackEvent(eventName, body);
  }
  // handle os comment change
  handleOsCommentChange(index) {
    // ca_collect_os_comments_entered event
    let data = {
      osAmount: this.totalOutstanding[0].amount,
      osReason: this.totalOutstanding[0].reason,
      osComments: this.totalOutstanding[0].comment,
    };
    this.triggerEvent("ca_collect_os_comments_entered", data);
  }

  // enforce check on amount available to adjust in utr selection
  checkUtrSelection(paymentIndex) {
    if (!this.payments[paymentIndex].selectedUTRNumber) return;
    let selectedUTR = null;
    this.payments[paymentIndex].utrSuggestionList.forEach((utr) => {
      if (utr.utrNoId === this.payments[paymentIndex].selectedUTRID) {
        selectedUTR = utr;
        return;
      }
    });
    let flag = false;
    // loop through the payments and summate the amount consumed
    let amountConsumed = 0;
    this.payments.forEach((payment, index) => {
      // find the payments with the same utr id and summate
      if (payment.selectedUTRID === selectedUTR.utrNoId) {
        amountConsumed += payment.amountEntered;
      }
    });
    // if total consumed > avl. to adjust for that utr, throw error
    if (amountConsumed > selectedUTR.unsettledAmount) {
      let message;
      this.popupService.openError(
        `This utr has been exhausted. Amount available to adjust : ${Math.abs(
          selectedUTR.unsettledAmount -
            (amountConsumed - this.payments[paymentIndex].amountEntered)
        )} `
      );
      flag = true;
    }
    return flag;
  }

  handleFilterInputKeyUp(event, index) {
    if (event.keyCode === 13) {
      if (
        this.payments[index].selectedFilterName === "BankName" ||
        this.payments[index].selectedFilterName === "PaymentMode" ||
        this.payments[index].selectedFilterName === "Amount"
      ) {
        this.handleAddFilter(index);
      } else {
        if (this.payments[index].searchString.length < 5) {
          this.handleShake();
        } else {
          this.handleAddFilter(index);
        }
      }
    }
  }

  private _filter(value: string, options: string[]): string[] {
    const filterValue = value.toLowerCase();
    return options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  handleSearchInputFocus(index) {
    // update the filtered options
    let options = [];
    if (this.payments[index].selectedFilterName === "PaymentMode") {
      options = [...this.paymentModes];
      // remove cash and credit note from the list
      options.splice(options.indexOf("cash"), 1);
      options.splice(options.indexOf("credit note"), 1);
    } else if (this.payments[index].selectedFilterName === "BankName") {
      options = [...this.availableBankAccounts];
    }
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value, options))
    );
  }

  // handler to add the selected filter
  handleAddFilter(index) {
    let payment = this.payments[index];
    // checks on autocomplete option select
    if (
      payment.selectedFilterName === "BankName" &&
      !this.availableBankAccounts.includes(payment.searchString)
    )
      return;
    if (
      payment.selectedFilterName === "PaymentMode" &&
      !this.paymentModes.includes(payment.searchString)
    )
      return;
    if (
      !(
        payment.selectedFilterName === "BankName" ||
        payment.selectedFilterName === "PaymentMode" ||
        payment.selectedFilterName === "Amount"
      ) &&
      payment.searchString.length < 5
    ) {
      this.handleShake();
      return;
    }

    // checks
    if (!payment.selectedFilterName || !payment.searchString) {
      // dont hit the api + add user cta
      this.popupService.openError(
        "Please select filter and enter search string"
      );
      return;
    }

    // check the deposit date string format
    if (payment.selectedFilterName === "DepositDate") {
      let dateRegex =
        /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
      if (!payment.searchString.match(dateRegex)) {
        this.popupService.openError("invalid date format");
        payment.selectedFilterName = "";
        payment.searchString = "";
        return;
      }
    }
    payment.selectedFilters.push({
      filterName: payment.selectedFilterName,
      filterValue: payment.searchString,
    });
    // reset the selection and input field for the filter
    // payment.selectedFilterName = null;
    // payment.searchString = null;
    // hit the manual utr search api
    this.handleManualUTRSearch(index);
  }

  // handler for filter remove
  handleFilterRemove(index: number, filterIndex: number) {
    // remove the filter obj from the selected filters arr
    this.payments[index].selectedFilters.splice(filterIndex, 1);
    // reload the suggestion list data
    this.handleManualUTRSearch(index);
  }

  // handler to run side effects on filter selection change
  handleFilterSelectionChange(event, index) {
    let payment = this.payments[index];
    // check if the filter has already benn added
    payment.selectedFilters.forEach((filter, _) => {
      if (filter.filterName === event.value) {
        //  throw an error to the user and reset the fields
        this.popupService.openError("This filter has already been applied");
        // reset the selection and input field for the filter
        payment.selectedFilterName = "";
        payment.searchString = "";
      }
    });
  }
}
