import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelPastSI } from './model';
import { SupplyService } from "src/app/core/services/api/supply.service";
import { PopupService } from "src/app/core/services/popup/popup.service";


@Component({
  selector: 'app-past-si-order',
  templateUrl: './past-si-order.component.html',
  styleUrls: ['./past-si-order.component.scss']
})
export class PastSiOrderComponent implements OnInit {

  orderDetails=[];

  constructor(private supplyService: SupplyService,
    private popupService: PopupService,
    public dialogRef: MatDialogRef<PastSiOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelPastSI,) { }

  ngOnInit(): void {
    this.getOrderDetails();
  }

  cancel() {
    this.dialogRef.close(null);
  }

  getOrderDetails(){
    
    this.orderDetails=[
      {
        med:'Vita SYP',
        manf:'Cipla',
        qty:'10',
        pack:'10s',
        batch:'3242435345',
        exp:'3/5/2022',
        mrp:'300',
        tradeprice:'300',
        schDisc:'2 + 1',
        cd:'10%',
        tax:300,
        hsn:354645657,
        rate:'10',
        gst:'10',
        total:300

    },
    {
      med:'Dolo',
      manf:'Cipla',
      qty:'10',
      pack:'10s',
      batch:'3242435345',
      exp:'3/5/2022',
      mrp:'300',
      tradeprice:'300',
      schDisc:'2 + 1',
      cd:'10%',
      tax:300,
      hsn:354645657,
      rate:'10',
      gst:'10',
      total:300

  },
  {
    med:'Zincovit',
    manf:'Cipla',
    qty:'10',
    pack:'10s',
    batch:'3242435345',
    exp:'3/5/2022',
    mrp:'300',
    tradeprice:'300',
    schDisc:'2 + 1',
    cd:'10%',
    tax:300,
    hsn:354645657,
    rate:'10',
    gst:'10',
    total:300

},
{
  med:'Paracetemol',
  manf:'Cipla',
  qty:'10',
  pack:'10s',
  batch:'3242435345',
  exp:'3/5/2022',
  mrp:'300',
  tradeprice:'300',
  schDisc:'2 + 1',
  cd:'10%',
  tax:300,
  hsn:354645657,
  rate:'10',
  gst:'10',
  total:300

},

  ]
 
  }


  
  

}
