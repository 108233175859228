import { Component, OnInit,Inject } from '@angular/core';
import { DialogModelSaleMedicines } from './model';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { AdminService } from 'src/app/core/services/api/admin.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
@Component({
  selector: 'app-sale-medicines',
  templateUrl: './sale-medicines.component.html',
  styleUrls: ['./sale-medicines.component.scss']
})
export class SaleMedicinesComponent implements OnInit {

  page=0;
  medicines=[];
  constructor(
    public dialogRef: MatDialogRef<SaleMedicinesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelSaleMedicines,
    private adminService: AdminService,
    private popupService: PopupService,
  ) { }

  ngOnInit(): void {
    this.getSaleMedicines()
  }

  getSaleMedicines(){
      this.popupService.openProgress()
      let params={
        saleId:this.data.data.saleId,
        page:this.page
      }

      this.adminService.getSaleMedicines(params).subscribe((res)=>{
         this.medicines=res.data.saleProductsResponseList
         this.popupService.closeProgress()
      },
              error => {
                this.popupService.closeProgress();
                this.popupService.openError(error.error.error.error);
              })
  }

  previousPage(){
    this.page--
    this.getSaleMedicines()
  }

  nextPage(){
    this.page++
    this.getSaleMedicines()
  }

  cancel(){
    this.dialogRef.close()
  }
}
