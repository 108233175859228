import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FileUploadService } from 'src/app/core/services/api/file-upload.service';
import { HelpDeskService } from 'src/app/core/services/api/help-desk.service';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { DialogModelCreateTicket } from "src/app/shared/dialog/create-ticket/model";

@Component({
  selector: 'app-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.scss']
})
export class CreateTicketComponent implements OnInit {
  searchType = "Retailer Code";
  searchTypeOptions = ["Retailer Code", "Mobile Number", "Invoice Number","Order ID"];
  _mFormSearch: FormGroup;
  _mForm: FormGroup;
  issueCategory = 'Live Order Issue';
  issueType;
  subIssue;
  ownership;
  currDepartment;
  currSource;
  currPriority;
  currStatus;
  currentAttachment;
  attachmentUrls = [];
  departments = [];
  modesOfCommunication = [];
  executives = [];
  priorityOptions = [];
  statusOptions = [];
  issues = [];
  subIssues = [];
  issueCategoryOptions = ['Live Order Issue', 'Past Order Issue', 'General Issue', 'Other Issue'];
  searchResult = {
    shopName: "",
    retailerMobileNo: "",
    retailerCode: "",
    city: "",
    hubspokeInfo: "",
    bdeName: "",
    bdeMobNo: ""
  }
  orderSuggestions: Observable<string[]>;
  searchOrderResult = [];
  orderId = new FormControl();
  invoiceSuggestions: Observable<string[]>;
  searchInvoiceResult = [];
  invoiceNumber = new FormControl();
  
  constructor(
    public dialogRef: MatDialogRef<CreateTicketComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelCreateTicket,
    private popupService: PopupService,
    private formBuilder: FormBuilder,
    private fileUploadService: FileUploadService,
    private helpDeskService: HelpDeskService) { 
      this._mFormSearch = this.formBuilder.group({
        searchString: ["", [Validators.required]]
      });
      this._mForm = this.formBuilder.group({
        retailerCode: [""],
        shopName: [""],
        hubSpoke: [""],
        city: [""],
        bdeName: [""],
        bdeMobileNo: ["", Validators.pattern("[6-9]\\d{9}")],
        mobileNo: ["", [Validators.required, Validators.pattern("[6-9]\\d{9}")]],
        description: ["", [Validators.required]],
        attachment: [""]
      });
    }

  ngOnInit(): void {
    this.popupService.openProgress();
    this.getDepartments(true);
  }

  getDepartments(closeProgress) {
    this.helpDeskService.getAllDepartments().subscribe(
      response => {
        this.departments = response.data;
        this.currDepartment = response.data[0].param;
        this.getModesOfCommunication(closeProgress);
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.message);
      }
    )
  }

  getModesOfCommunication(closeProgress) {
    this.helpDeskService.getModeOfCommunication().subscribe(
      response => {
        this.modesOfCommunication = response.data;
        this.currSource = response.data[0].param;
        console.log(this.currSource)
        this.getExecutives(closeProgress);
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.message);
      }
    )
  }

  getExecutives(closeProgress) {
    this.helpDeskService.getAllExecutives().subscribe(
      response => {
        this.executives = response.data;
        this.ownership = response.data[0].id;
        this.getPriorityOptions(closeProgress);
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.message);
      }
    )
  }

  getPriorityOptions(closeProgress) {
    this.helpDeskService.getAllPriority().subscribe(
      response => {
        this.priorityOptions = response.data;
        this.currPriority = response.data[0].param;
        this.getStatusOptions(closeProgress);
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.message);
      }
    )
  }

  getStatusOptions(closeProgress) {
    this.helpDeskService.getAllStatus().subscribe(
      response => {
        this.statusOptions = response.data;
        this.currStatus = response.data[0].param;
        this.getIssueCategories(closeProgress)
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.message);
      }
    )
  }

  getIssueCategories(closeProgress) {
    this.helpDeskService.getIssueCategories().subscribe(
      response => {
        this.issueCategoryOptions = response.data;
        this.issueCategory = response.data[0].id;
        this.getIssues(closeProgress)
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.message);
      }
    )
  }

  getIssues(closeProgress) {
    const params = {
      categoryId: this.issueCategory
    }
    this.helpDeskService.getIssues(params).subscribe(
      response => {
        this.issues = response.data;
        this.issueType = response.data[0].id;
        this.getSubIssues(closeProgress)
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.message);
      }
    )
  }

  getSubIssues(closeProgress) {
    this.helpDeskService.getSubIssues({issueId: this.issueType}).subscribe(
      response => {
        this.subIssues = response.data;
        this.subIssue = response.data[0].id;
        this.getOrderSuggestions();
        this.getInvoiceSuggestions();
        if (closeProgress) {
          this.popupService.closeProgress();
        }
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.message);
      }
    )
  }

  getOrderSuggestions() {
    const params = {
      retailerCode: this._mForm.value.retailerCode
    }
    this.helpDeskService.getOrderIdSuggestions(params).subscribe(
      response => {
        let sample = [];
        response.data.map(med => {
          sample.push(med)
        })
        this.searchOrderResult = sample;
        this.orderSuggestions = this.orderId.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filterOrderSuggestions(value))
          );
      },
      error=> {
        let sample = [];

        this.searchOrderResult = sample;
        this.popupService.closeProgress();
        // this.popupService.openError(error.error.error.error);
    
        this.orderSuggestions = this.orderId.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filterOrderSuggestions(value))
          );
      }
    )
  }

  private filterOrderSuggestions(value): string[] {
    let sample = [];
    const params = {
      retailerCode: this._mForm.value.retailerCode
    }
      this.helpDeskService.getOrderIdSuggestions(params).subscribe(
        response => {
          response.data.map(med => {
            sample.push(med)
          })
        }
      );
    
    return sample;
  }

  getInvoiceSuggestions() {
    const params = {
      retailerCode: this._mForm.value.retailerCode,
      invoiceNo: this.invoiceNumber
    }
    this.helpDeskService.getInvoiceNumberSuggestions(params).subscribe(
      response => {
        let sample = [];
        response.data.map(med => {
          sample.push(med)
        })
        this.searchInvoiceResult = sample;
        this.invoiceSuggestions = this.invoiceNumber.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filterInvoiceSuggestions(value))
          );
      },
      error=> {
        let sample = [];

        this.searchInvoiceResult = sample;
        this.popupService.closeProgress();
        // this.popupService.openError(error.error.error.error);
    
        this.invoiceSuggestions = this.invoiceNumber.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filterInvoiceSuggestions(value))
          );
      }
    )
  }

  private filterInvoiceSuggestions(value): string[] {
    let sample = [];
    const params = {
      retailerCode: this._mForm.value.retailerCode,
      invoiceNo: value
    }
      this.helpDeskService.getInvoiceNumberSuggestions(params).subscribe(
        response => {
          response.data.map(med => {
            sample.push(med)
          })
        }
      );
    
    return sample;
  }

  selectOrderId(e, option) {
    this.invoiceNumber.setValue(option.invoiceNo)
  }

  selectInvoiceNumber(e, option) {
  }

  searchDetails() {
    this.popupService.openProgress();
    let params = {
      searchString: this._mFormSearch.value.searchString,
      category: this.searchType
    }
    if (this.searchType == 'Retailer Code') {
      params.category = "RETAILER_CODE";
    } else if (this.searchType == 'Mobile Number') {
      params.category = "MOBILE_NO";
    } else if (this.searchType == 'Invoice Number') {
      params.category = "INVOICE_NO";
    }else if(this.searchType == 'Order ID'){
      params.category = "ORDER_ID";
    }
    this.helpDeskService.searchUser(params).subscribe(
      response => {
        this.searchResult = response.data;
        let ticketData = this._mForm.value
        ticketData.retailerCode = this.searchResult.retailerCode;
        ticketData.mobileNo = this.searchResult.retailerMobileNo;
        ticketData.shopName = this.searchResult.shopName;
        ticketData.city = this.searchResult.city;
        ticketData.hubSpoke = this.searchResult.hubspokeInfo;
        ticketData.bdeName = this.searchResult.bdeName;
        ticketData.bdeMobileNo = this.searchResult.bdeMobNo;
        if (this.searchType == "Invoice Number") {
          this.invoiceNumber.setValue(this._mFormSearch.value.searchString);
        }
        this._mForm.setValue(ticketData);
        this.getOrderSuggestions();
        this.getInvoiceSuggestions();
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  selectOnlineFile(e) {
    if (e.target.files.length > 0) {
      this.popupService.openProgress();
    }
    let imagesCount = e.target.files.length;
    for(let index = 0; index < e.target.files.length; index++) {
      this.currentAttachment = e.target.files[index];
      const formData = new FormData();
      formData.append("file", this.currentAttachment, this.currentAttachment.name);
      this.fileUploadService.uploadOrderImage(formData).subscribe(
        response => {
          console.log(response)
          this.attachmentUrls.push(response.data);
          if (this.attachmentUrls.length == imagesCount) {
            this.popupService.closeProgress();
          }
        },
        error => {
          imagesCount--;
          if (this.attachmentUrls.length == imagesCount) {
            this.popupService.closeProgress();
          }
          this.popupService.openError(error.error.error.error);
        }
      )
    }
  }

  createTicket() {
    this.popupService.openProgress();
    console.log(this.issueCategory);
    const params = {
      ticketAttachmentUrl: this.attachmentUrls,
      issue: this.issueType,
      subIssue: this.subIssue,
      priority: this.currPriority,
      status: this.currStatus,
      description: this._mForm.value.description,
      retailerCode: this._mForm.value.retailerCode,
      retailerMobileNo: this._mForm.value.mobileNo,
      invoiceNumber: this.invoiceNumber.value,
      department: this.currDepartment,
      modeOfCommunication: this.currSource,
      orderId: this.orderId.value,
      assignedTo: this.ownership,
      category: this.issueCategory,
      routeName: this._mForm.value.hubSpoke,
      bdeName: this._mForm.value.bdeName,

    }
    this.helpDeskService.createTicket(params).subscribe(
      response => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data);
        this.closePopup();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.message);
      }
    )
  }

  closePopup() {
    this.dialogRef.close();
  }

}
