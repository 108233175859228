import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModel } from './model';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { AdminService } from 'src/app/core/services/api/admin.service';

@Component({
  selector: 'app-wmm-popup',
  templateUrl: './wmm-popup.component.html',
  styleUrls: ['./wmm-popup.component.scss']
})
export class WmmPopupComponent implements OnInit {
  selectedMed;
  constructor(
    public dialogRef: MatDialogRef<WmmPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModel,
    private popupService: PopupService,
    private adminService: AdminService
    ) { }

  ngOnInit(): void {
    if (this.data.medicineList.length > 0) {
      this.selectedMed = this.data.medicineList[0].id;
    }
    console.log(this.data)
  }

  merge() {
    this.popupService.openProgress();
    const medList = [];
    this.data.medicineList.map(med => {
      medList.push(med.id);
    })
    const params = {
      mappedMedicineId: this.selectedMed,
      medicineList: medList
    };
    this.adminService.mergeWronglyMappedMedicines(params, {}).subscribe(
      response => {
        this.popupService.closeProgress();
        this.popupService.openSuccess("Merged Successfully!!!");
        this.dialogRef.close(true);
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.erorr.error.error);
        this.dialogRef.close(true);
      }
    )
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
