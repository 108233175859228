import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FinanceDistributorDialogModel } from './model';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { FinanceService } from 'src/app/core/services/api/finance.service';

@Component({
  selector: 'app-finance-distributor-details',
  templateUrl: './finance-distributor-details.component.html',
  styleUrls: ['./finance-distributor-details.component.scss']
})
export class FinanceDistributorDetailsComponent implements OnInit {
  distributorDetails = {
    distributorName: "",
    distributorEmailId: "",
    distributorAddress: "",
    creditDays: null,
    ageingWithOps: null,
    ageingWithCategory: null
  };

  constructor(
    public dialogRef: MatDialogRef<FinanceDistributorDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FinanceDistributorDialogModel,
    private financeService: FinanceService,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {
    this.popupService.openProgress();
    this.getDistributorDetails();
  }

  getDistributorDetails() {
    const params = {
      distributorName: this.data.distirbutorName
    }
    this.financeService.getDistributorDetails(params).subscribe(
      response => {
        this.distributorDetails = response.data;
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  close() {
    this.dialogRef.close();
  }
}
