import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// import { stat } from 'fs';
import { DialogWholesaleSplit } from './model';
import { PopupService} from 'src/app/core/services/popup/popup.service';
import { WholesaleService } from 'src/app/core/services/api/wholesale.service';

@Component({
  selector: 'app-wholesale-split',
  templateUrl: './wholesale-split.component.html',
  styleUrls: ['./wholesale-split.component.scss']
})
export class WholesaleSplitComponent implements OnInit {

  orderItem=[]
  totalQty=this.data.data.item.totalQuantity
  enableSplit=true
  todayDate=new Date()
  constructor( private popupService: PopupService,
    private wholesaleService: WholesaleService,
    public dialogRef: MatDialogRef<WholesaleSplitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogWholesaleSplit) { }

  ngOnInit(): void {
    this.orderItem=[JSON.parse(JSON.stringify(this.data.data.item))]
  }

  cancel() {
    this.dialogRef.close(false);
  }

  inputFocus(item){
    item.edit=true
  }

  focusout(item){
  item.edit=false
  }

  inputEdited(item){
    item.edited=true
    this.enableSplit=false
  }

  addRow(){
    this.orderItem.push({
      totalQuantity:0,
      batch:'',
      expiryDate:'',
      edited:true
    })
    this.enableSplit=false
  }

  deleteItem(index){
    this.orderItem.splice(index,1)
    let splittedQty=0
    this.orderItem.forEach((data,i)=>{
         splittedQty+=data.totalQuantity
         
    })
    let remainingQty=this.totalQty-splittedQty
    this.orderItem[this.orderItem.length-1].totalQuantity=remainingQty+this.orderItem[this.orderItem.length-1].totalQuantity

  }

  splitQty(item,index){
    let updatedQty=item.totalQuantity;
    let editedPending=0
    console.log(updatedQty)
    if(!item.batch || !item.expiryDate){
      this.popupService.openError("Please enter all fields")
      editedPending++
    }else if(+updatedQty<1){
      this.popupService.openError("Quantity Should be greater than Zero")
      editedPending++
    }else{
      if(updatedQty>this.totalQty){
        this.popupService.openError("Split Quantity shouldn't be greater than Total Quantity")
        editedPending++
      }else{
       
        let splittedQty=0
        this.orderItem.forEach((data,i)=>{
             splittedQty+=data.totalQuantity
             if(data.edited){
              editedPending++
             }
        })
       
       if(splittedQty>this.totalQty){
        this.popupService.openError("Split Quantity shouldn't be greater than Total Quantity")
       }else{
        let remainingQty=this.totalQty-splittedQty
        if(remainingQty>0){
          this.orderItem[this.orderItem.length-1].totalQuantity=remainingQty+this.orderItem[this.orderItem.length-1].totalQuantity
        }
  
  
        this.orderItem[index].edited=false
        editedPending--
       } 
       
      }
  
    }
   
    
    if(editedPending>0 || !item.batch || !item.expiryDate){
      this.enableSplit=false
    }else{
      this.enableSplit=true
    }
   
  }


  saveSplitQty(){
    this.popupService.openProgress()
    let body={
      "splitBatchRequestFieldsList": [
        
      ]
    }
   this.orderItem.forEach((item)=>{
     body.splitBatchRequestFieldsList.push(
      {
        "batch":item.batch,
        "buyerInvoiceDetailId":this.data.data.item._id,
        "buyerInvoiceId": this.data.data.orderData.buyerInvoiceId,
        "expiryDate":new Date(item.expiryDate).getTime(),
        "hsnCode": this.data.data.item.hsnCode,
        "orderId":this.data.data.orderData.orderId,
        "quantity":item.totalQuantity
      }
     )
   })
    this.wholesaleService.splitQuantity({},body).subscribe((res)=>{
      this.popupService.closeProgress()
      this.dialogRef.close({data:res.data});
    },
    error => {
      this.popupService.closeProgress()
      this.popupService.openError(error?.error?.error?.error?error?.error?.error?.error:'Something went wrong');
    
    }) 
    
  }
}
